import React, { useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"

import {
  Box,
  Button,
  FormControl,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { t } from "i18next"
import { useParams, useSearchParams } from "react-router-dom"
import ExpandMoreFilled from "@mui/icons-material/ExpandMore"
import { flushSync } from "react-dom"
import { fetchRefinementZipFile } from "@/core/app/slices/clinical/clinicalThunkApi"
import {
  caseManagement,
  treatView,
  viewControlInTreatment,
  initialfinal,
} from "@/gluelayer"
import { refinementModule } from "@/gluelayer/core/modules/refinement"
import {
  setRefinementNum,
  setOpenHistorySplitView,
  setStageShowType,
  setStageCompareLoadData,
  setGoAutoProcesses,
  setopenGrid,
  setopenInitialFinal,
  setIsCreateNewRefinement,
} from "../udTreatSlice"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit"
import { useMyNavigation } from "@/hooks/useMyNavigation"

let curRefinementZipFolderNames: string[]
let curSelectedRefinement: string = ""
let curSelectedRefinementData: string = ""

const ClinicalHeader: React.FC = () => {
  const dispatch = useAppDispatch()

  const [frontPhoto, setFrontPhoto] = useState()
  const [showHistoryList, setShowHistoryList] = useState(false)

  const [photoURL, setPhotoURL] = useState<string>()

  const [openRefDialog, setOpenRefDialog] = useState<boolean>(false)

  const { profile } = useAppSelector((state: RootState) => state.xrayService)

  const [historyList, setHistoryList] = useState([])
  const [oriCreateDate, setOriCreateDate] = useState("")
  const [currentRefinement, setCurrentRefinement] = useState({
    name: "",
    date: "",
  })
  const [lastRefinement, setLastRefinement] = useState({ name: "", date: "" })
  const [showSplitCloseBtn, setShowSplitCloseBtn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showDropdownBtn, setShowDropdownBtn] = useState(false)

  const { refinementReady } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  const { refinementZips } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const {
    user: { current_orgId },
  } = useAppSelector((state: RootState) => state.userService)
  const { stageCompareLoadData, isCreateNewRefinement, goAutoProcesses } =
    useAppSelector((state: RootState) => state.udTreatService)
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { caseId, patientId } = useParams()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const navigate = useMyNavigation()

  const {
    patientData: { firstName = "", lastName = "" },
  } = useAppSelector((state: RootState) => {
    return state.patientService
  })
  const [searchParams, setSearchParams] = useSearchParams()

  const [full, setFull] = useState<boolean>(false)
  const clickFull = () => {
    if (!full) {
      const docElm = document.documentElement
      /*W3C*/
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } /*FireFox */ else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } /*Chrome等 */ else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      } /*IE11*/ else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
    setFull(!full)
  }

  const onClickSaveCase = (e) => {
    let path = "/patients"
    const from = searchParams.get("from")
    if (from === "bundle" || location.hash === "#uassist") {
      console.log("location :", location.hash)
      path = "/patients"
    }
    if (goAutoProcesses) {
      path = "/patients"
      dispatch(setGoAutoProcesses(false))
    }
    navigate(path)
  }

  const creatDate = caseDetail.createDate
    ? `${caseDetail.createDate.substring(
        5,
        7,
      )}/${caseDetail.createDate.substring(
        8,
        10,
      )}/${caseDetail.createDate.substring(2, 4)}`
    : ""

  useEffect(() => {
    return () => {
      dispatch(setRefinementNum(0))
    }
  }, [])

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  useEffect(() => {
    if (creatDate !== "" && caseManagement.isCaseInit() && refinementReady) {
      setShowDropdownBtn(true)
      setOriCreateDate(creatDate)
      const refinementHistoryList = refinementModule.getRefinementDateInfo()
      const orgId = current_orgId

      const firstKey = Object.keys(refinementHistoryList)[0]
      if (firstKey && refinementHistoryList[firstKey] !== "") {
        setCurrentRefinement({
          name: firstKey,
          date: refinementHistoryList[firstKey],
        })
        setLastRefinement({
          name: firstKey,
          date: refinementHistoryList[firstKey],
        })
      } else {
        setCurrentRefinement({ name: "initial plan", date: creatDate })
        setLastRefinement({ name: "initial plan", date: creatDate })
      }

      const tempList = []
      for (const item in refinementHistoryList) {
        if (item !== "original")
          tempList.push({
            name: item,
            date:
              refinementHistoryList[item] !== ""
                ? refinementHistoryList[item]
                : creatDate,
          })
      }
      tempList.push({ name: "initial plan", date: creatDate })
      setHistoryList([...tempList])
      dispatch(setRefinementNum(tempList.length))
      console.log("refinementHistoryList", tempList)
    }
  }, [creatDate, caseManagement.isCaseInit(), refinementReady])

  useEffect(() => {
    if (Object.keys(refinementZips).length > 0) {
      caseManagement
        .AddARefinementHistory(
          curRefinementZipFolderNames,
          refinementZips as Record<string, Blob>,
        )
        .then(() => {
          openRefinementHistory(
            curSelectedRefinement,
            curSelectedRefinementData,
          )
        })
    }
  }, [refinementZips])

  const openRefinementHistory = async (name, date) => {
    const historyName = name === "initial plan" ? "original" : name
    if (currentRefinement.name === lastRefinement.name) {
      treatView.Open(true)
      treatView.RefinementHistory(historyName)
      treatView.SetBackgroundPicture(1, "backgroundvtk.png")
      dispatch(setOpenHistorySplitView(true))
    } else {
      treatView.SwitchRefinementHistory(historyName)
    }
    setShowHistoryList(false)
    setShowSplitCloseBtn(true)
    setCurrentRefinement({ name, date })
    flushSync(() => setIsLoading((pre) => !pre))
    dispatch(setStageShowType("compare"))
    dispatch(setStageCompareLoadData(!stageCompareLoadData))
    viewControlInTreatment.hoverToothToggle(false)
    viewControlInTreatment.setSpacecheckVisibility(false)
    viewControlInTreatment.setOcclusionVisibility(false)
    viewControlInTreatment.setSuperimposeVisibility(false)
    viewControlInTreatment.setGridVisibility(false)
    initialfinal.setInitialFinalEnabled(false)
  }

  const historyItemClick = (name, date) => {
    const wasmName = name === "initial plan" ? "original" : name
    if (name === lastRefinement.name && name === currentRefinement.name) {
      flushSync(() => setIsLoading((pre) => !pre))
      return
    }
    if (name === lastRefinement.name) {
      treatView.Open(false)
      dispatch(setStageShowType("normal"))
      dispatch(setOpenHistorySplitView(false))
      setShowSplitCloseBtn(false)
      setCurrentRefinement({
        name: lastRefinement.name,
        date: lastRefinement.date,
      })
      setShowHistoryList(false)
      flushSync(() => setIsLoading((pre) => !pre))
      return
    }
    const folderNames = refinementModule.getDownloadDataNames(wasmName)

    curRefinementZipFolderNames = []
    let refinementFiles = []
    let AllExisted = true
    for (let j = 0; j < folderNames.size(); j++) {
      const folderName = folderNames.get(j)

      const isExisted = caseManagement.IsRefinementItemExisted(folderName)
      if (isExisted == false) {
        curRefinementZipFolderNames.push(folderName)
        refinementFiles.push(folderName + ".zip")
        AllExisted = false
      }
    }

    if (AllExisted == true) {
      openRefinementHistory(name, date)
    } else {
      curSelectedRefinement = name
      curSelectedRefinementData = date

      dispatch(
        fetchRefinementZipFile({
          orgId: "",
          patientId,
          caseId,
          refinementFiles,
        }),
      )
    }
  }

  const HistoryItem = ({ id, name, date }) => {
    return (
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="caption"
          sx={{
            color: name === lastRefinement.name ? "#9C9C9C" : "#000",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "400",
            letterSpacing: "0.46px",
            marginTop: "7px",
            marginBottom: "7px",
            marginLeft: "15px",
            display: "inline-block",
            width: "auto",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={async () => {
            flushSync(() => setIsLoading((pre) => !pre))
            await new Promise((resolve) => setTimeout(resolve, 0))
            historyItemClick(name, date)
          }}
        >
          {` #` + id + " | " + capitalizeFirstLetter(name) + " - " + date}
        </Typography>
        {/* <Launch
            sx={{
              display: "inline-block",
              position: "absolute",
              right: "15px",
              marginTop: "9px",
              width: 20,
              height: 20,
              color: id === caseId ? "#9C9C9C" : "#000",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          ></Launch> */}
      </Box>
    )
  }
  const HistoryListForm = () => {
    return (
      <FormControl
        sx={{
          width: "331px",
          position: "absolute",
          top: "calc(100% + 8px)",
          left: 140,
          zIndex: 1000,
          background: "white",
          paddingBottom: 1,
          paddingTop: 1,
          borderRadius: "4px",
          boxShadow: 5,
        }}
      >
        {historyList.map((item, index) => {
          return (
            <HistoryItem
              key={"history-item-" + index}
              id={caseId}
              name={item.name}
              date={item.date}
            />
          )
        })}
        <Typography
          variant="caption"
          sx={{
            color: "#000",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "400",
            letterSpacing: "0.46px",
            marginTop: "7px",
            marginBottom: "7px",
            marginLeft: "15px",
            display: "inline-block",
            width: "auto",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            setShowHistoryList(false)
            flushSync(() => dispatch(setopenInitialFinal(false)))
            flushSync(() => dispatch(setopenGrid(false)))
            treatView.startSplitScreenToCreateRefinement()
            dispatch(setIsCreateNewRefinement(true))
            dispatch(setStageShowType("compare"))
          }}
        >
          Add new refinement
        </Typography>
      </FormControl>
    )
  }

  const closeHistoryCompare = () => {
    treatView.Open(false)
    setShowSplitCloseBtn(false)
    dispatch(setStageShowType("normal"))
    dispatch(setOpenHistorySplitView(false))
    setCurrentRefinement({
      name: lastRefinement.name,
      date: lastRefinement.date,
    })
  }
  return (
    <Box
      sx={{
        left: 0,
        top: 0,
        right: 0,
        height: "35px",
        backgroundColor: "white",
        justifyContent: "center",
        position: "absolute",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
        paddingLeft={2}
        paddingRight={2}
      >
        <Stack
          sx={{
            marginLeft: "12px",
            height: "35px",
            display: isCreateNewRefinement ? "none" : "",
            alignItems: "center",
            flexGrow: 1,
          }}
          direction={"row"}
          spacing={4}
        >
          <Tooltip
            arrow
            title={
              <span>
                <strong>{t("records.tooltipNotesLabel")}</strong>{" "}
                {patientData.notes}
              </span>
            }
            placement="right"
            followCursor
            disableHoverListener={
              patientData.notes === null || patientData.notes.trim() === ""
            }
          >
            <Typography
              variant="caption"
              sx={{
                color: "#16AADC",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "600",
                letterSpacing: "0.46px",
                textTransform: "uppercase",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {firstName + " " + lastName}
            </Typography>
          </Tooltip>

          <Box>
            <Typography
              variant="caption"
              sx={{
                color: "gray",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "600",
                letterSpacing: "0.46px",
                textTransform: "uppercase",
              }}
            >
              {showDropdownBtn
                ? ` #` +
                  caseId +
                  " | " +
                  currentRefinement.name +
                  " - " +
                  currentRefinement.date
                : `#${caseId}`}
            </Typography>
            {showDropdownBtn && (
              <IconButton
                aria-label="more"
                sx={{
                  width: 28,
                  height: 28,
                  transform: showHistoryList ? "scaleY(-1)" : "scaleY(1)",
                }}
                onClick={() => {
                  setShowHistoryList(!showHistoryList)
                }}
              >
                <ExpandMoreFilled sx={{ color: "#5A5A5A" }} />
              </IconButton>
            )}
            {showHistoryList && <HistoryListForm />}
          </Box>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              letterSpacing: "0.46px",
            }}
            color={"#16AADC"}
          >
            uCloud AI
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        >
          {!full ? (
            <FullscreenIcon
              onClick={() => {
                clickFull()
              }}
              sx={{
                color: "#757575",
                mr: 2,
                cursor: "pointer",
              }}
            />
          ) : (
            <FullscreenExitIcon
              onClick={() => {
                clickFull()
              }}
              sx={{
                color: "#757575",
                mr: 2,
                cursor: "pointer",
              }}
            />
          )}

          {
            <IconButton
              sx={{
                // opacity: 0.5,
                backgroundColor: "transparent",
                height: "24px",
                width: "24px",
              }}
              aria-label="settings"
              onClick={onClickSaveCase}
            >
              <CloseIcon />
            </IconButton>
          }
        </Box>
      </Stack>

      {showSplitCloseBtn && (
        <Button
          aria-label="close"
          onClick={closeHistoryCompare}
          sx={{
            background: (theme) => theme.palette.grey[300],
            position: "absolute",
            left: "calc(46.5% - 40px)",
            top: 70,
            width: "81px",
            height: "48px",
            borderRadius: 6,
            boxShadow: 4,
            color: "black",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[500],
            },
          }}
        >
          Close
        </Button>
      )}
    </Box>
  )
}

export default ClinicalHeader
