import { wasmModule } from "../wasm/wasmModule"

class SpaceCorrectionModule {
  wasmSpaceCorrectionModule = null
  private _zoomCb?: (val: number) => void
  //open or close Space Correction
  openSpaceCorrectionModule(isOpen: boolean) {
    if(!wasmModule.isInit) return
    if(isOpen) {
      console.log("openRelabelModule")
    } else {
      console.log("closeRelabelModule")
    }

    wasmModule.moduleManager.ToggleSpaceCorrect(isOpen)
    this.wasmSpaceCorrectionModule = wasmModule.moduleManager.GetSpaceCorrectionModule()
    if (this.wasmSpaceCorrectionModule === null) {
      console.log(
        "wasmSpaceCorrectionModule is null")
    } else {
      console.log(
        "wasmSpaceCorrectionModule is not null")
    }
  }

  setZoomCallback(cb: (val: number) => void) {
    if (!wasmModule.isWASMInit) {
      return
    }
    if (!(window as any).treatment) {
      ;(window as any).treatment = {}
    }
    ;(window as any).treatment.spaceViewZoomCallback = cb

    this.wasmSpaceCorrectionModule = wasmModule.moduleManager.GetSpaceCorrectionModule()
    if (this.wasmSpaceCorrectionModule === null) {
      return
    }
    this.wasmSpaceCorrectionModule.setUpdateZoomCB("treatment.spaceViewZoomCallback")
  }

  saveData() {
    if (!wasmModule.isWASMInit) {
      return
    }

    this.wasmSpaceCorrectionModule = wasmModule.moduleManager.GetSpaceCorrectionModule()
    if (this.wasmSpaceCorrectionModule === null) {
      return
    }
    this.wasmSpaceCorrectionModule.saveData()
  }
}

export const spaceCorrectionModule = new SpaceCorrectionModule();