import { Grid, Button, Stack } from "@mui/material"
import { useState } from "react"
import { UDTabs } from "../../custom/UDTabs"
import { UDSelectOptions, UDTitleSelect } from "../../custom/UDSelectOptions"
import { Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from "@mui/material"
import { RightPage } from "../../right/RightPage"
import { Box, Paper, InputBase, IconButton } from "@mui/material"
import { updateCaseById } from "@/core/app/slices/case"
import { useAppDispatch } from "@/core/app/hooks"
import { useParams } from "react-router-dom"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { viewControlInTreatment } from "@/gluelayer"
import { wasmModule } from "@/gluelayer"
import {
  setopenInitialFinal,
  setopenGrid,
  setOpenOrientation,
} from "@/UDTreat/udTreatSlice"

interface QuickRxParam {
  id: number
  name: string
  before: string
  beforeColor?: string
  after: string
  afterColor: string
  treatment: string
  treatmentColor: string
  view: string
  arch?: string
  detail: string
}

const QuickRxData: QuickRxParam[] = [
  {
    id: -1,
    name: "Occlusion",
    before: "Before",
    beforeColor: "black",
    after: "After",
    afterColor: "black",
    treatment: "Treatment",
    treatmentColor: "green",
    view: "front",
    detail: "Please review case and add notes.",
  },
  {
    id: 0,
    name: "Left AP",
    before: "Class I",
    beforeColor: "green",
    after: "Class I",
    afterColor: "green",
    treatment: "Easy",
    treatmentColor: "green",
    view: "left",
    detail: "Improved with no significant tooth movement.",
  },
  {
    id: 1,
    name: "Right AP",
    before: "Class II",
    beforeColor: "blue",
    after: "Class I",
    afterColor: "green",
    treatment: "Difficult",
    treatmentColor: "red",
    view: "right",
    detail: "Improved. Distalization is 3mm.",
  },
  {
    id: 2,
    name: "Overjet",
    before: "Severe",
    beforeColor: "red",
    after: "Normal",
    afterColor: "green",
    treatment: "Difficult",
    treatmentColor: "Red",
    view: "right",
    detail:
      "Improved by reduce overjet to 2.5mm. Upper incisor's roots were moved by 2mm.",
  },
  {
    id: 3,
    name: "Overbite",
    before: "Deep bite",
    beforeColor: "blue",
    after: "Normal",
    afterColor: "green",
    treatment: "Easy",
    treatmentColor: "green",
    view: "front",
    detail: "Improved to normal overbite 1mm.",
  },
  {
    id: 4,
    name: "Curve of Spee",
    before: "Deep",
    beforeColor: "red",
    after: "Normal",
    afterColor: "green",
    treatment: "Moderate",
    treatmentColor: "blue",
    view: "right",
    arch: "low",
    detail: "Improved to normal by intrude lower incisor 2mm",
  },
  {
    id: 5,
    name: "Dental Profile",
    before: "Moderate",
    beforeColor: "blue",
    after: "Normal",
    afterColor: "green",
    treatment: "Moderate",
    treatmentColor: "blue",
    view: "right",
    detail: "Lower jaw profile is improved. Upper root lingual torque is 1.5mm",
  },
  {
    id: 6,
    name: "Midline",
    before: "Moderate",
    beforeColor: "blue",
    after: "Normal",
    afterColor: "green",
    treatment: "Moderate",
    treatmentColor: "blue",
    view: "front",
    detail: "Midline is improved by upper 1mm to left.",
  },
  {
    id: 7,
    name: "T movement",
    before: "n/a",
    beforeColor: "green",
    after: "n/a",
    afterColor: "green",
    treatment: "Diffcult",
    treatmentColor: "red",
    view: "split",
    detail: "T1, 2, 3 are moved >2mm. T7, 8,11 are rotated > 30 degrees.",
  },
  {
    id: 8,
    name: "Auxiliary",
    before: "n/a",
    beforeColor: "green",
    after: "n/a",
    afterColor: "green",
    treatment: "Diffcult",
    treatmentColor: "red",
    view: "split",
    detail: "Total 8 attachments, 2 buttons, 2 elastics are used.",
  },
]

const QuickRxNote = (props) => {
  const { patientId, caseId } = useParams()
  const [inputValue, setinputValue] = useState("")
  const dispatch = useAppDispatch()
  return (
    <Box
      id="casenote-foot"
      sx={{
        width: "100%",
        padding: "5px 5px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          boxShadow: "none",
        }}
      >
        <InputBase
          sx={{
            width: "100%",
            height: "80%",
          }}
          placeholder={"Enter case notes here"}
          disabled={false}
          value={inputValue}
          onChange={({ target: { value } }) => {
            setinputValue(value)
          }}
        />
        <IconButton
          aria-label="directions"
          disabled={inputValue.trim() === ""}
          onClick={() => {
            if (inputValue.trim() === "") return
            dispatch(
              updateCaseById({
                patientId: patientId,
                caseId: caseId,
                payload: {
                  notes: inputValue,
                },
              }),
            )
            setinputValue("")
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
      </Paper>
    </Box>
  )
}

export function QuickRxMain(props: any) {
  const dispatch = useAppDispatch()
  const [expanded, setExpanded] = useState<string | false>(false)
  const handleChange =
    (name: string, view: string, arch: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? name : false)
      show3DView(view, arch)
    }
  const show3DView = (view, arch) => {
    if (view === "split") {
      dispatch(setopenInitialFinal(false))
      dispatch(setopenGrid(false))
      dispatch(setOpenOrientation(false))
    }
    wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
    viewControlInTreatment.showArchMode(arch ? arch : "all")
    viewControlInTreatment.setViewType(view)
  }
  function QuickRxAccordion(props: any) {
    return (
      <Accordion
        expanded={expanded === props.param.name}
        onChange={handleChange(
          props.param.name,
          props.param.view,
          props.param.arch,
        )}
      >
        <AccordionSummary
          expandIcon={props.param.id != -1 && <ExpandMoreIcon />}
          aria-controls="panel1-content"
          id={props.param.id}
        >
          <Stack direction="row" spacing={1}>
            <Typography sx={{ width: 120 }} variant="body1" component="span">
              {props.param.name}
            </Typography>
            <Typography
              sx={{ width: 80 }}
              component="span"
              color={props.param.beforeColor}
            >
              {props.param.before}
            </Typography>
            <Typography
              sx={{ width: 80 }}
              component="span"
              color={props.param.afterColor}
            >
              {props.param.after}
            </Typography>
            <Typography
              sx={{ width: 80 }}
              component="span"
              color={props.param.treatmentColor}
            >
              {props.param.treatment}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{props.param.detail}</AccordionDetails>
      </Accordion>
    )
  }
  return (
    <Stack spacing={0.5}>
      {QuickRxData.map((param) => {
        return <QuickRxAccordion param={param} />
      })}
    </Stack>
  )
}

const QuickRxActions = (props) => {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      alignItems={"center"}
      justifyContent="flex-end"
    >
      <QuickRxNote />
      <Button sx={{ height: 35 }} size="small" variant="contained">
        Submit...
      </Button>
    </Stack>
  )
}

export function QuickRx(props: any) {
  return (
    <RightPage
      title="Quick Rx: Review and Rx"
      onClose={props.onClose}
      content={<QuickRxMain />}
      actions={<QuickRxActions />}
    />
  )
}
