import React from 'react';
import { Box, Stack, IconButton, Grid, Input, Typography, Button, Paper, experimentalStyled as styled, Hidden } from "@mui/material";
import { FC, useEffect, useState, useRef, useImperativeHandle } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"

const StageItemNoneSimple = (props) => {

  const ButtonItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  }));

  const curStageArchQuick3Index = (props.archType === 0 ? props.upQuick3Index : props.downQuick3Index)
  
  const topAreaHeight = 9
  const addMargin = 3
  const triangleMargin = 1
  const triangleHeight = 5
  const stageWidth = props.singleStageWidth

  return (

    <div style={{ 
      width: props.actualStageWidth + props.stageDiv, 
      height: (topAreaHeight + props.singleStageHeight + triangleMargin + triangleHeight),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: -2,
      zIndex:1,
    }}>
      <Stack direction="row"
        spacing={0}>

        <div style={{
          width: props.actualStageWidth,
          height: (topAreaHeight + props.singleStageHeight + triangleMargin + triangleHeight),
          position: "relative",
        }}>

          <ButtonItem sx={{
            width: props.actualStageWidth,
            height: props.singleStageHeight,
            position: "absolute",
            top: props.archType === 0 ? (topAreaHeight) : (triangleMargin + triangleHeight),
            borderRadius: "0px",
            border: "none",//props.stageCurrentStep === props.index ? 'solid 1px #02AFEC' : (props.index <= curStageArchQuick3Index ? 'solid 1px #b1b1b1' : 'solid 1px #999999'),
            color: "black",
            backgroundColor: '#999999',
            fontSize: 14,
            userSelect: 'none',
            zIndex: 0,
          }}
            onClick={() => {}}
          >

          </ButtonItem>

        </div>

      </Stack>
    </div>

    
  );
};

export default StageItemNoneSimple;