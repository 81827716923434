import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  ULoading,
  UModalPopup,
  UText,
} from "../../../components"
import NavigationBlockPopup from "../../../components/NavigationBlockPopup/NavigationBlockPopup"
import Preview from "../../../components/Preview/preview2"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  UploadZips,
} from "../../../core/app/slices/clinical/clinical.types"
import {
  setClinicalData,
} from "../../../core/app/slices/clinical/clinicalSlice"
import {
  setUploadStls
}from "../../../core/app/slices/records/scans/scansSlice"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { updateUserPreferences } from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"
import { checkIfObjectHasKeys } from "../../../core/utils/formatters"
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "../../../routes/status.route.config"
import { Box, Checkbox, Grid } from "../../../ui-component/mui.components"
import { CASE_STATUS } from "../../Patient/config/status.config"

import {
  file,
  fileBox,
  fileBoxContent,
  previewBox,
  stltitle,
  title,
  zone,
  zoneBox,
} from "./Scans.style"

/**
 * a component for upload stl & show the teeth model
 * @param downLoadZips: 下载stl文件
 * @param uploadScans: 上传stl文件
 * @param isClickNext: click the next button
 * @returns scans compoent
 */

interface scansType {
  isRefinement: boolean
  handleLowerStl?: any
  handleUpperStl?: any
  handleShowStl?: any
  caseType: "new_scan" | "hold_scan" | "hold_refinement"
}


const Scans: FC = ({
  isRefinement,
  handleLowerStl,
  handleUpperStl,
  handleShowStl,
}: scansType) => {
  const { t } = useTranslation("common")

  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })
  const [holePopup, setholePopup] = useState(false)
  const [nextPopup, setnextPopup] = useState(false)
  const [showStl, setShowStl] = useState({ isupper: false, islower: false })

  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )


  const { uploadSTL } = useAppSelector((state) => state.scansService)
  const { zipList, zipNames } = useAppSelector((state)=>state.clinicalService)

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  const [isShowBothScansPopup, setIsShowBothScansPopup] =
    useState<boolean>(true)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const dispatch = useAppDispatch()
  const location = useLocation()

  const gotoNextStep = () => {
    dispatch(setClinicalData({ isLoading: "pending" }))
  }

  const checkLowerFileUpload = (file) => {
    handleLowerStl && handleLowerStl(file.action)
  }

  const checkUpperFileUpload = (file) => {
    handleUpperStl && handleUpperStl(file.action)
  }

  useEffect(()=>{
    console.log("component scans value:",uploadSTL)
    if (uploadSTL.upper) {
      setUpperStl({
        data: uploadSTL.upper,
        action: "stl",
      })

      checkUpperFileUpload({ data: uploadSTL.upper, action: "stl" })
    }else{
      setUpperStl({
        data: null,
        action: "delete",
      })
      checkUpperFileUpload({ data: null, action: "delete" })
    }

    if (uploadSTL.lower) {
      setLowerStl({
        data: uploadSTL.lower,
        action: "stl",
      })
      checkLowerFileUpload({ data: uploadSTL.lower, action: "stl" })
    }else{
      setLowerStl({
        data: null,
        action: "delete",
      })
      checkLowerFileUpload({ data: null, action: "delete" })
    }
  }, [uploadSTL])


  useEffect(() => {
    if (caseDetail) {
      const udesignCategory = caseDetail?.udesign_category
      if (udesignCategory) {
        const arches = udesignCategory.split("/")

        // It was used to determine whether there was an upper or lower jaw
        setShowStl({
          isupper: arches[0].includes("R") || arches[0].includes("A"),
          islower: arches[1].includes("R") || arches[1].includes("A"),
        })
      }
    }
  }, [caseDetail])

  useEffect(() => {
    handleLowerStl && handleShowStl(showStl)
  }, [showStl])

  return (
    <Box id="scansroot" sx={{ width: "100%" }}>
      <Grid>
        <UModalPopup
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
              height: 192,
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          title={
            <UText variant={"h6"}>{t("records.scans.holePopupTile")}</UText>
          }
          content={
            <Box>
              <span>{t("records.scans.holePopup1")}</span>
              <br />
              <br />
              <span>{t("records.scans.holePopup2")}</span>
            </Box>
          }
          isAlert={holePopup}
          btnList={[
            <UButton
              variant={"contained"}
              btnText={t("button.ok")}
              sxProp={{ width: 53, height: 36 }}
              key={"hole"}
              onClickHandler={() => setholePopup(false)}
            />,
          ]}
        />
        <UModalPopup
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
              // height: 182,
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          title={
            <UText variant={"h6"}>{t("records.scans.nextPopupTile")}</UText>
          }
          content={
            <>
              <Grid container flexDirection={"column"}>
                <UText variant={"body1"}>
                  {t("records.scans.nextPopup1")}
                  <br />
                  <br />
                  {t("records.scans.nextPopup2")}
                </UText>
              </Grid>
              {/* <Grid sx={{ height: 38 }}>
              <Checkbox
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    dispatch(
                      updateUserPreferences({
                        on_show_both_scan_upload_warning: true,
                      }),
                    )
                  } else {
                    dispatch(
                      updateUserPreferences({
                        on_show_both_scan_upload_warning: false,
                      }),
                    )
                  }
                }}
              />
              <UText variant={"body1"}>{t("popup.showAgain")}</UText>
            </Grid> */}
            </>
          }
          isAlert={
            (isShowBothScansPopup && nextPopup && !isRefinement) ||
            (isShowBothScansPopup &&
              nextPopup &&
              isRefinement &&
              showStl.islower &&
              showStl.isupper)
          }
          btnList={[
            <UButton
              variant={"contained"}
              btnText={"YES"}
              key={"yes"}
              sxProp={{ minWidth: 58, height: 36 }}
              onClickHandler={gotoNextStep}
            />,
            <UButton
              variant={"shade"}
              btnText={"NO"}
              key={"no"}
              onClickHandler={() => {
                setnextPopup(false)
              }}
              sxProp={{ minWidth: 54, height: 36 }}
            />,
          ]}
        />
        <UText sxProp={title} variant={"h5"}>
          {t("records.scans.title")}
        </UText>
        {isRefinement && (
          <UText
            variant="caption"
            sxProp={{
              display: "flex",
              justifyContent: "center",
              color: "#616161",
            }}
          >
            {t("records.refinement.description")}
          </UText>
        )}
        <Grid
          id="functionroot"
          container
          sx={{ ...previewBox, flexWrap: "nowrap" }}
        >
          <Box style={fileBox}>
            {((isRefinement) ||
              (location.pathname.includes(
                ROUTE_ATTENTION_BANNER.attentionBanner,
              ) &&
                showStl.isupper) ||
              (!isRefinement &&
                !location.pathname.includes(
                  ROUTE_ATTENTION_BANNER.attentionBanner,
                ))) && (
              <Grid sx={{ ...zone }}>
                <UText
                  sxProp={{
                    ...stltitle,
                  }}
                  variant={"h6"}
                >
                  {"Upper"}
                </UText>
                {upperStl.action === "stl" || upperStl.action === "download" ? (
                  <UFileProcessingCard
                    fileName={
                      upperStl.action === "stl"
                        ? upperStl.data?.name
                        : "Uploaded Scan"
                    }
                    fileSize={upperStl.data?.size}
                    progressValue={100}
                    progressstatus={"complete"}
                    onRemove={() => {
                      setUpperStl({ data: null, action: "delete" })
                      checkUpperFileUpload({ data: null, action: "delete" })
                      dispatch(setUploadStls({
                        upper:null
                      }))
                    }}
                    removable={true}
                  ></UFileProcessingCard>
                ) : (
                  <UFileBox
                    id={"Upper"}
                    allowedFileExtensions={["stl","mtc"]}
                    allowedfileSize={51200}
                    selectedFile={(file) => {
                      dispatch(setUploadStls({
                        upper:file[0]
                      }))
                      setUpperStl({ data: file[0], action: "stl" })

                      checkUpperFileUpload({ data: file[0], action: "stl" })
                    }}
                    fileAcceptType={".stl,.mtc"}
                    boxSize={file}
                    fileBoxContent={fileBoxContent}
                    isRequired={false}
                    messages={{
                      fileNote: t("records.scans.fileNote"),
                      uploadButton: t("records.scans.uploadButton"),
                      uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                      invalidfileFormat: t("records.scans.invalidfileFormat"),
                      invalidFileSize: t("records.scans.invalidSTLFileSize"),
                    }}
                    fileLimit={1}
                  ></UFileBox>
                )}
              </Grid>
            )}
            {((isRefinement ) ||
              (location.pathname.includes(
                ROUTE_ATTENTION_BANNER.attentionBanner,
              ) &&
                showStl.islower) ||
              (!isRefinement &&
                !location.pathname.includes(
                  ROUTE_ATTENTION_BANNER.attentionBanner,
                ))) && (
              <Grid sx={{ ...zone }}>
                <UText sxProp={{ ...stltitle }} variant={"h6"}>
                  {"Lower"}
                </UText>
                {lowerStl.action === "stl" || lowerStl.action === "download" ? (
                  <UFileProcessingCard
                    fileName={
                      lowerStl.action === "stl"
                        ? lowerStl.data?.name
                        : "Uploaded Scan"
                    }
                    fileSize={lowerStl.data?.size}
                    progressValue={100}
                    progressstatus={"complete"}
                    onRemove={() => {
                      setLowerStl({ data: null, action: "delete" })
                      checkLowerFileUpload({ data: null, action: "delete" })
                      dispatch(setUploadStls({
                        lower:null
                      }))
                    }}
                    removable={true}
                  ></UFileProcessingCard>
                ) : (
                  <UFileBox
                    id={"Lower"}
                    allowedFileExtensions={["stl","mtc"]}
                    allowedfileSize={51200}
                    selectedFile={(file) => {
                      // setScanErrors(false)
                      dispatch(
                        setUploadStls({
                          lower: file[0],
                        }),
                      )
                      setLowerStl({ data: file[0], action: "stl" })
                      checkLowerFileUpload({ data: file[0], action: "stl" })
                    }}
                    fileAcceptType={".stl,.mtc"}
                    boxSize={file}
                    fileBoxContent={fileBoxContent}
                    isRequired={false}
                    messages={{
                      fileNote: t("records.scans.fileNote"),
                      uploadButton: t("records.scans.uploadButton"),
                      uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                      invalidfileFormat: t("records.scans.invalidfileFormat"),
                      invalidFileSize: t("records.scans.invalidSTLFileSize"),
                    }}
                    fileLimit={1}
                  ></UFileBox>
                )}
              </Grid>
            )}
          </Box>
          <Box
            id="3dcontainer"
            sx={{
              backgroundColor: "#333",
              position: "relative",
              width: "100%",
              margin: "8px",
              borderRadius: "10px",
            }}
          >
            {(
              <Preview
                upperStl={upperStl}
                lowerStl={lowerStl}
                fillholeCallback={(isSuccess: boolean) => {
                  if (!isSuccess) {
                    setholePopup(true)
                  }
                }}
                placeHolder={t("records.scans.previewPlaceHolder")}
                zips={zipList}
                setUpperStl={setUpperStl}
                setLowerStl={setLowerStl}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </Box>
  )
}

export default Scans