import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material"
import ConstructionIcon from "@mui/icons-material/Construction"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import {
  setAutoProActiveStep,
  setGoAutoProcesses,
  setCurArch,
  setOpenBiteReg,
} from "@/UDTreat/udTreatSlice"
import { viewControlInTreatment, wasmModule, caseManagement } from "@/gluelayer"
import { relabelModule } from "@/gluelayer/core/modules/relabel"
import { resegmentationModule } from "@/gluelayer/core/modules/resegmentation/resegmentationModule"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { useParams } from "react-router-dom"
const AutoBottom: FC = () => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { autoProActiveStep, curArch } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { caseId, patientId } = useParams()
  const handleNext = () => {
    if (autoProActiveStep < 4) {
      dispatch(setAutoProActiveStep(autoProActiveStep + 1))
    }
    if (autoProActiveStep === 4) {
      // finish
      // upload zips bulk1 bulk2
      caseManagement.getCaseFiles(["bulk1", "bulk2"]).then((r) => {
        const zips = []
        if (r) {
          for (const k in r) {
            zips.push({
              fileName: k,
              file: r[k],
            })
          }

          dispatch(
            uploadCaseZips({
              zips,
              patientId,
              caseId,
            }),
          ).then(({ payload }) => {
            if (zips.length === payload.length) {
              for (let i = 0; i < payload.length; i++) {
                if (payload[i].status !== 200) {
                  return
                }
              }
              console.log("finish")
              dispatch(setGoAutoProcesses(false))
              wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
            }
          })
        }
      })
    }
  }
  const handleBack = () => {
    if (autoProActiveStep > 0) {
      dispatch(setAutoProActiveStep(autoProActiveStep - 1))
    }
  }

  useEffect(() => {
    console.log("autoProActiveStep", autoProActiveStep)

    if (!wasmModule.isInit) return

    wasmModule.moduleManager.ToggleSpaceCorrect(false)
    wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
    relabelModule.openRelabelModule(false)
    wasmModule.moduleManager.SwitchModuleBiteReg(false)
    resegmentationModule.openResegmentationModule(false)
    dispatch(setOpenBiteReg(false))

    switch (autoProActiveStep) {
      case 0:
        dispatch(setCurArch("up"))
        resegmentationModule.openResegmentationModule(true)
        break
      case 1:
        viewControlInTreatment.zoomWithValueInTreatment(1)

        const relabel = async () => {
          if (wasmModule.moduleManager.GetSplitScreenModule() === null) {
            if (curArch === "all") await dispatch(setCurArch("up"))
          }
          relabelModule.openRelabelModule(true)
          if ((window as any).pulloutCallback()) {
            ;(window as any).pulloutCallback()
          }
          // if (relabelModule.IsRelabelModuleOpened()) {
          //   if ((window as any).treatment.RelabelViewZoomCallback(0.83)) {
          //     ;(window as any).treatment.RelabelViewZoomCallback(0.83)
          //   }
          // }
        }

        relabel()
        break
      case 2:
        dispatch(setCurArch("all"))
        viewControlInTreatment.setViewType("front")
        dispatch(setOpenBiteReg(true))
        break
      case 3:
        if (curArch === "all") dispatch(setCurArch("up"))
        viewControlInTreatment.setViewType("front")
        wasmModule.moduleManager.ToggleSpaceCorrect(true)
        break
      case 4:
        dispatch(setCurArch("all"))
        viewControlInTreatment.setViewType("front")
        wasmModule.moduleManager.SwitchModuleOrientationAdjustment(true)
        break
      default:
    }
  }, [autoProActiveStep])
  return (
    <Box
      sx={{
        width: "100%",
        height: "40px",
        position: "absolute",
        bottom: "20px",
        // background: "red",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button
        disabled={autoProActiveStep === 0}
        onClick={handleBack}
        sx={{ width: "100px", marginRight: "200px" }}
        variant="contained"
      >
        {"Back"}
      </Button>
      <Button onClick={handleNext} sx={{ width: "100px" }} variant="contained">
        {autoProActiveStep === 4 ? "Finish" : "Next"}
      </Button>
      <Box
        sx={{
          position: "absolute",
          left: "calc(50% + 300px)",
          top: "-10px",
          display: autoProActiveStep === 4 ? "block" : "none",
        }}
      >
        <FormControlLabel
          control={<Checkbox />}
          label={
            <Box>
              <Typography
                variant="body1"
                component="span"
                display="inline"
                style={{ color: "#fff" }}
              >
                Don't run checks automatically next time. I will check and fix
                individually from the tools set
                <ConstructionIcon
                  sx={{ marginLeft: "12px" }}
                  color={"primary"}
                ></ConstructionIcon>
              </Typography>
            </Box>
          }
        />
      </Box>
    </Box>
  )
}
export default AutoBottom
