import React, { useState, useCallback, useEffect } from "react"
import {
  Menu,
  MenuItem,
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { relabel, resegmentation, viewControlInTreatment, wasmModule } from "@/gluelayer"
import { relabelModule } from "@/gluelayer/core/modules/relabel"
import { resegmentationModule } from "@/gluelayer/core/modules/resegmentation/resegmentationModule"
import {
  setCurArch,
  setIsOpenModelCheck,
  setOpenBiteReg,
  setOpenOrientation,
  setOpenRelabel,
  setOpenSpaceCorrection,
  setStageShowType,
  setOpenResegmentation,
} from "../udTreatSlice"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { ReactSVG } from "react-svg"
import ModelCheck from "../assets/right/modelcheck.svg"
import { useTheme } from "@mui/material/styles"
import { setIsShowProgress } from "@/core/app/slices/clinical/clinicalSlice"

const UDModelCheck: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const [isPressed, setIsPressed] = useState(false) // 控制按钮背景色
  const open = !!anchorEl
  const dispatch = useAppDispatch()
  const { curArch, openBiteReg } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [sameLabelDlg, setSameLabelDlg] = useState(false)
  const { setstageShowTypeNormalCallback } = relabel
  const theme = useTheme()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      console.log("🚀 ~ isPressed:", isPressed)

      if (isPressed) {
        if (relabelModule.IsRelabelModuleOpened()) {
          console.log("Relabel module is opened")
          if (
            relabelModule.IsExistSameLabelForSingleJaw(0) ||
            relabelModule.IsExistSameLabelForSingleJaw(1)
          ) {
            setSameLabelDlg(true)
            return
          }

          relabelModule.openRelabelModule(false)
          if (relabelModule.IsRelabelModuleOpened()) {
            dispatch(setIsShowProgress(true))

            // 这是做了更改后需要和服务器交互等待时间
            setstageShowTypeNormalCallback(() => {
              dispatch(setStageShowType("normal"))
              setIsPressed(false)
              setSelectedItem(null)
              dispatch(setIsOpenModelCheck(false))
              dispatch(setIsShowProgress(false))
            })
          } else {
            setIsPressed(false)
            setSelectedItem(null)
            dispatch(setIsOpenModelCheck(false))
          }
        } else {
          setIsPressed(false)
          setSelectedItem(null)
          dispatch(setIsOpenModelCheck(false))
        }
      } else {
        setAnchorEl(event.currentTarget)
      }
    },
    [isPressed],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSelect = (item: string) => {
    setSelectedItem(item)
    setIsPressed(true)
    handleClose()
    dispatch(setIsOpenModelCheck(true))
  }

  const [showSaveButton, setShowSaveButton] = useState(false)

  const saveOrientationButton = showSaveButton ? (
    <Button
      variant="contained"
      color="primary"
      sx={{
        position: "absolute",
        top: "30%",
        right: 100,
        // transform: "translateX(-50%)",
        zIndex: 1100,
      }}
      onClick={() => {
        const oriModule =
          wasmModule.moduleManager.GetModuleOrientationAdjustment()
        oriModule.SaveOrientation()
      }}
    >
      Save
    </Button>
  ) : null
  useEffect(() => {
    console.log("selectedItem: ", selectedItem)
    if (!selectedItem || !wasmModule.isInit) return
    dispatch(setStageShowType(""))
    switch (selectedItem) {
      case "Segmentation":
        console.log("Segmentation")
        const seg = async () => {
          if (curArch === "all") {
            await dispatch(setCurArch("up"))
          }
          console.log("curArch: ", curArch)
          resegmentationModule.openResegmentationModule(true)
        }
        seg()
        
        break
      case "Labeling":
        if (
          relabelModule.IsExistSameLabelForSingleJaw(0) ||
          relabelModule.IsExistSameLabelForSingleJaw(1)
        ) {
          console.log("Same label exists")
          return
        }
        const relabel = async () => {
          viewControlInTreatment.zoomWithValueInTreatment(1)
          if (wasmModule.moduleManager.GetSplitScreenModule() === null) {
            if (curArch === "all") await dispatch(setCurArch("up"))
          }
          relabelModule.openRelabelModule(true)
          if ((window as any).pulloutCallback()) {
            ;(window as any).pulloutCallback()
          }
          // await dispatch(setStageShowType(""))
          if (relabelModule.IsRelabelModuleOpened()) {
            await dispatch(setOpenRelabel(true))
            if ((window as any).treatment.RelabelViewZoomCallback) {
              ;(window as any).treatment.RelabelViewZoomCallback(
                relabelModule.zoomValue,
              )
            }
          }
        }

        relabel()
        break
      case "Bite Registration":
        dispatch(setOpenBiteReg(true))

        break
      case "Spacing":
        dispatch(setStageShowType(""))
        dispatch(setCurArch("up"))
        wasmModule.moduleManager.ToggleSpaceCorrect(true)
        dispatch(setOpenSpaceCorrection(true))
        break
      case "Orientation":
        dispatch(setOpenOrientation(true))
        setShowSaveButton(true)
        viewControlInTreatment.setViewType("front")
        wasmModule.moduleManager.SwitchModuleOrientationAdjustment(true)
        break
      case "Tooth Surface":
        // wasmModule.moduleManager.FixModelSurface()
        break
      default:
        console.warn("Unknown menu item selected:", selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    if (isPressed || !wasmModule.isInit) return

    console.log("Model Check button is closed")
    if (wasmModule.moduleManager.GetModuleOrientationAdjustment() !== null) {
      console.log("SwitchModuleOrientationAdjustment(false)")
      wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
    }
    if (wasmModule.moduleManager.GetSpaceCorrectionModule() !== null) {
      console.log("ToggleSpaceCorrect(false)")
      wasmModule.moduleManager.ToggleSpaceCorrect(false)
    }
    if (resegmentationModule.isResegmentationModuleOpened()){
      console.log("resegmentationModule.openResegmentationModule(false)")
      resegmentationModule.openResegmentationModule(false)
    }
    // if (relabelModule.IsRelabelModuleOpened()) {
    //   console.log("relabelModule.openRelabelModule(false)")
    //   relabelModule.openRelabelModule(false)
    // }

    if (openBiteReg) dispatch(setOpenBiteReg(false))

    dispatch(setCurArch("all"))
    viewControlInTreatment.setViewType("front")
    dispatch(setStageShowType("normal"))
    setShowSaveButton(false)
  }, [isPressed])

  const menuItems = [
    "Segmentation",
    "Labeling",
    "Bite Registration",
    "Spacing",
    "Orientation",
    "Tooth Surface",
  ]

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          right: 16,
          top: 405,
        }}
      >
        <Button
          sx={{
            height: 72,
            minWidth: 0, // 解决 MUI 默认最小宽度 64px 的问题
            padding: 0, // 取消默认的内边距，确保大小准确
            backgroundColor: theme.transBkColor.light,
            textTransform: "none",
            width: "48px",
            flexWrap: "wrap",
            border: "none",
            borderRadius: "8px",
            boxShadow: "0 6px 10px 0px rgba(0, 0, 0, 0.15)",
            "&:hover": {
              backgroundColor: theme.transBkColor.light,
              opacity: 0.65,
            },
            "&.Mui-selected": {
              backgroundColor: theme.transBkColor.light,
              opacity: 0.65,
            },
          }}
          onClick={handleClick}
        >
          <ReactSVG
            src={ModelCheck}
            afterInjection={(svg) => {
              const path = svg.getElementsByTagName("path")[0]
              if (isPressed) {
                path?.setAttribute("fill", "#215ECD")
                path?.setAttribute("fill-opacity", "1")
              }
            }}
            style={{ marginTop: 6 }}
          ></ReactSVG>
          <Typography sx={{ fontSize: "11px", color: "rgba(96, 96, 96, 1)" }}>
            Tooth Model
          </Typography>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: -10 }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={() => handleSelect(item)}>
              {item}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Dialog open={sameLabelDlg}>
        <DialogTitle id="alert-dialog-title">{"uDesign Cloud"}</DialogTitle>
        <DialogContent>{"There are duplicates,please relabel!"}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSameLabelDlg(false)}
            autoFocus
            sx={{ textTransform: "samelabel" }}
            size="medium"
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {saveOrientationButton}
    </>
  )
}

export default UDModelCheck
