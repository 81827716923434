import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactSVG } from "react-svg"
import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { caseManagement, toothTools } from "@/gluelayer"

import Close from "../../../../assets/svgs/close.svg"
import Lower from "../../../../assets/svgs/lower.svg"
import Next from "../../../../assets/svgs/next.svg"
import Pre from "../../../../assets/svgs/pre.svg"
import Upper from "../../../../assets/svgs/upper.svg"
import SvgBtn from "../../../../components/DarkMode/SvgButton/svgbtn"
import Drag from "../../../../components/Drag/Drag"
import { useAppSelector } from "../../../../core/app/hooks"
import { RootState } from "../../../../core/app/store"
import { UText } from "../../../../ui-component"

import "./movement.scss"
import { NumberDomain } from "recharts/types/util/types"

const Movement: FC<{
  open: boolean
  setOpen: (arg: boolean) => void
  refinementKey: string
  stageObj: {
    upList: []
    downList: []
    up_current: number
    down_current: number
  }
}> = ({ open, setOpen, refinementKey, stageObj }) => {
  const { t } = useTranslation("common")
  const {
    userService: {
      clinicalSettings: { toothLabel = [], NumberSystem },
    },
  } = useAppSelector((state: RootState) => state)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [isUpper, setisUpper] = useState(true)
  // total:编号为0，实际显示是lastStage数据; 顺序是total，1，2，3，4，......lastStage-2,lastStage-1,lastStage.index；stage 0不显示
  const [currentStage, setcurrentStage] = useState({
    name: "",
    index: 0,
  })

  const [isDisableDrag, setisDisableDrag] = useState(false)

  const [lastStage, setlastStage] = useState({ name: "", index: 0 })

  const [tableData, settableData] = useState([])

  const [tableColor, settableColor] = useState([])

  useEffect(() => {
    if (!open) return

    setlastStage(
      isUpper
        ? {
            name: stageObj.upList[stageObj.upList.length - 1]?.showStage,
            index: stageObj.upList.length - 1,
          }
        : {
            name: stageObj.downList[stageObj.downList.length - 1]?.showStage,
            index: stageObj.downList.length - 1,
          },
    )
    // 默认显示total数据
    setcurrentStage(
      isUpper
        ? {
            name: stageObj.upList[stageObj.upList.length - 1]?.showStage,
            index: 0,
          }
        : {
            name: stageObj.downList[stageObj.downList.length - 1]?.showStage,
            index: 0,
          },
    )
  }, [open, isUpper, refinementKey])

  useEffect(() => {
    setisUpper(getArch(caseDetail.udesign_category) !== "lower")
  }, [caseDetail.udesign_category])

  const getArch = (udesign_category) => {
    let caseArch = "both"
    if (udesign_category) {
      const [UpperType, LowerType] = udesign_category.split("/")
      if (UpperType.includes("--")) {
        caseArch = "lower"
      } else if (LowerType.includes("--")) {
        caseArch = "upper"
      }
    }
    return caseArch
  }

  /**
   *  获取当前stage的数据
   */
  useEffect(() => {
    if (!open) return
    const report = caseManagement.getToothReports(isUpper)
    const currentPageReport =
      report.get(
        currentStage.index === 0 ? report.size - 1 : currentStage.index,
      ) || []

    const rgbToString = (
      toothId: number,
      r: number,
      g: number,
      b: number,
    ): string => {
      let isUpper = true
      if (toothId % 100 > 16) {
        isUpper = false
      }

      let rgbaStr = "rgba(156, 39, 176, 0.12)"
      if (isUpper) {
        rgbaStr =
          toothId % 100 < 9
            ? "rgba(156, 39, 176, 0.12)"
            : "rgba(33, 150, 243, 0.12)"
      } else {
        rgbaStr =
          33 - (toothId % 100) < 9
            ? "rgba(156, 39, 176, 0.12)"
            : "rgba(33, 150, 243, 0.12)"
      }

      if (r === 255 && g === 0 && b === 0) {
        // red
        rgbaStr = "rgba(244, 67, 54, 0.50)"
      } else if (r === 255 && g === 255 && b === 0) {
        // yellow
        rgbaStr = "rgba(255, 167, 38, 0.50)"
      }
      return rgbaStr
    }

    let res = [[], [], [], [], [], []]
    let resColor = [[], [], [], [], [], []]

    // table的行数
    for (let i = 0; i < 6; i++) {
      // table的列数
      for (let y = 0; y < 16; y++) {
        res[i].push("-")
      }
    }

    for (let i = 0; i < 6; i++) {
      for (let y = 0; y < 16; y++) {
        let defaultColor =
          y + 1 < 9 ? "rgba(156, 39, 176, 0.12)" : "rgba(33, 150, 243, 0.12)"
        resColor[i].push(defaultColor)
      }
    }

    // fetch distance, rotate data
    currentPageReport.forEach(({ id, distanceRotate }) => {
      res = [
        ...res.map((item, index) => {
          const value = distanceRotate[index].toString()

          /**
           *  id 为 wasm tooth id,初始值 = 1
           */
          if (isUpper) {
            item[(id % 100) - 1] = value
          } else {
            item[32 - (id % 100)] = value
          }
          return item
        }),
      ]
    })

    console.log("stage index info: ", currentStage.index, ", ", lastStage.index)

    // fetch color for value
    currentPageReport.forEach(({ id, distanceRotate, rgbArray }) => {
      if (currentStage.index === 0) {
        // only stage index is total stage, set color
        resColor = [
          ...resColor.map((item, index) => {
            const offset = index * 3
            const colorString = rgbToString(
              id,
              rgbArray[offset],
              rgbArray[offset + 1],
              rgbArray[offset + 2],
            )
            console.log(
              "id: ",
              id,
              "distanceRotate: ",
              distanceRotate[index],
              ", colorString: ",
              colorString,
            )
            if (isUpper) {
              item[(id % 100) - 1] = colorString
            } else {
              item[32 - (id % 100)] = colorString
            }

            return item
          }),
        ]
      }
    })

    settableData([
      {
        key: t("treat.movement.messial"),
        name: 0,
        unit: t("treat.movement.unit"),
        value: res[0],
      },
      {
        key: t("treat.movement.buccal"),
        name: 1,
        unit: t("treat.movement.unit"),
        value: res[1],
      },
      {
        key: t("treat.movement.extrusion"),
        name: 2,
        unit: t("treat.movement.unit"),
        value: res[2],
      },
      {
        key: t("treat.movement.torque"),
        name: 3,
        value: res[3],
      },
      {
        key: t("treat.movement.tipping"),
        name: 4,
        value: res[4],
      },
      {
        key: t("treat.movement.rotation"),
        name: 5,
        value: res[5],
      },
    ])

    settableColor(resColor)
  }, [currentStage])

  /**
   * 仅total stage有tooth背景色
   * @param id :wasm tooth id, 从上牙右边智齿开始算初始值 = 1，顺时针递增
   * @param type: 0:Mesial/Distal;1:Buccal/Lingual;2:Extrusion/Intrusion;3:Torque;4:Tipping;5:Rotation
   * @returns 当前id牙齿的背景色
   */
  const getToothColor = ({ id, type, value }) => {
    if ((value === "-" || currentStage.index !== 0) && isUpper) {
      // 上牙左右牙的默认背景色
      return id < 9 ? "rgba(156, 39, 176, 0.12)" : "rgba(33, 150, 243, 0.12)"
    }

    if ((value === "-" || currentStage.index !== 0) && !isUpper) {
      // 下牙左右牙的默认背景色
      return 33 - id < 9
        ? "rgba(156, 39, 176, 0.12)"
        : "rgba(33, 150, 243, 0.12)"
    }
    // 背景色临界值
    let yellow, red
    // 位移量的绝对值
    const _value = Math.abs(value)

    /**
     * 切牙/尖牙Distal/Mesial 边界值
     */
    if (
      (toothTools.isIncisorTeeth(id) || toothTools.isCaninesTeeth(id)) &&
      type === 0
    ) {
      red = 1
    } else if (
      /**
       * 切牙/尖牙/前磨牙/磨牙Lingual 边界值
       */
      (toothTools.isIncisorTeeth(id) ||
        toothTools.isCaninesTeeth(id) ||
        toothTools.isPremolarsTeeth(id) ||
        toothTools.isMolarTeeth(id)) &&
      type === 1 &&
      value.indexOf("-") !== -1
    ) {
      yellow = 1
      red = 2
    } else if (
      (toothTools.isIncisorTeeth(id) ||
        toothTools.isCaninesTeeth(id) ||
        toothTools.isPremolarsTeeth(id) ||
        toothTools.isMolarTeeth(id)) &&
      type === 1 &&
      value.indexOf("-") === -1
    ) {
      /**
       * 切牙/尖牙/磨牙/前磨牙的buccal边界值
       */
      yellow = 2
      red = 3
    } else if (
      /**
       * 切牙/尖牙的Extrusion边界值
       */
      (toothTools.isIncisorTeeth(id) || toothTools.isCaninesTeeth(id)) &&
      type === 2 &&
      value.indexOf("-") === -1
    ) {
      yellow = 2.5
      red = 3
    } else if (
      /**
       * 切牙/尖牙/磨牙/前磨牙的Intrusion边界值
       */
      (toothTools.isIncisorTeeth(id) ||
        toothTools.isCaninesTeeth(id) ||
        toothTools.isPremolarsTeeth(id) ||
        toothTools.isMolarTeeth(id)) &&
      type === 2 &&
      value.indexOf("-") !== -1
    ) {
      yellow = 0.5
      red = 1
    } else if (toothTools.isCaninesTeeth(id) && type === 3) {
      /**
       * 尖牙的Torque边界值
       */
      red = 1
    } else if (toothTools.isIncisorTeeth(id) && type === 3) {
      /**
       * 切牙的Torque边界值
       */
      yellow = 10
      red = 15
    } else if (
      /**
       * 中切牙的Rotate边界值
       */
      toothTools.isIncisorTeeth(id) &&
      type === 5 &&
      (id === 8 || id === 9 || id === 24 || id === 25)
    ) {
      yellow = 40
      red = 50
    } else if (
      /**
       * 侧切牙的Rotate边界值
       */
      toothTools.isIncisorTeeth(id) &&
      type === 5 &&
      id !== 8 &&
      id !== 9 &&
      id !== 24 &&
      id !== 25
    ) {
      yellow = 30
      red = 40
    } else if (toothTools.isCaninesTeeth(id) && type === 5) {
      /**
       * 尖牙的Rotate边界值
       */
      yellow = 45
      red = 55
    } else if (toothTools.isPremolarsTeeth(id) && type === 5) {
      /**
       * 前磨牙的Rotate边界值
       */
      yellow = 20
      red = 30
    } else if (
      /**
       * 前磨牙/磨牙的Mesial边界值
       */
      (toothTools.isPremolarsTeeth(id) || toothTools.isMolarTeeth(id)) &&
      type === 0 &&
      value.indexOf("-") === -1
    ) {
      yellow = 1
      red = 2
    } else if (
      /**
       * 前磨牙/磨牙的Distal边界值
       */
      (toothTools.isPremolarsTeeth(id) || toothTools.isMolarTeeth(id)) &&
      type === 0 &&
      value.indexOf("-") !== -1
    ) {
      if (id < 17) {
        yellow = 2
        red = 4
      } else {
        red = 1
      }
    } else if (
      /**
       * 前磨牙/磨牙的Extrusion边界值
       */
      (toothTools.isPremolarsTeeth(id) || toothTools.isMolarTeeth(id)) &&
      type === 2 &&
      value.indexOf("-") === -1
    ) {
      yellow = 0.1
      red = 0.5
    } else if (
      /**
       * 前磨牙/磨牙的Torque边界值
       */
      (toothTools.isPremolarsTeeth(id) || toothTools.isMolarTeeth(id)) &&
      type === 3
    ) {
      yellow = 5
      red = 10
    } else if (toothTools.isMolarTeeth(id) && type === 5) {
      /**
       * 磨牙的Rotation边界值
       */
      yellow = 20
      red = 30
    }

    /**
     *  Torque类型判断真实值，非Torque类型判断绝对值
     */
    if (red && (type === 3 ? Number(value) > red : _value > red)) {
      return "rgba(244, 67, 54, 0.50)"
    }

    if (yellow && (type === 3 ? Number(value) > yellow : _value > yellow)) {
      return "rgba(255, 167, 38, 0.50)"
    }

    // 返回默认背景色
    if (isUpper) {
      return id < 9 ? "rgba(156, 39, 176, 0.12)" : "rgba(33, 150, 243, 0.12)"
    }

    if (!isUpper) {
      return 33 - id < 9
        ? "rgba(156, 39, 176, 0.12)"
        : "rgba(33, 150, 243, 0.12)"
    }
  }

  const getTable = () => {
    const labelList = isUpper
      ? [...toothLabel.slice(0, 16)]
      : [...toothLabel.slice(16, 32)]
    return (
      <TableContainer component={"div"}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: "none" }}></TableCell>
              {labelList.map((item, index) => {
                const isRight = index < 8
                return (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{ borderBottom: "none" }}
                  >
                    <Box
                      sx={{
                        fontSize: 12,
                        fontWeight: 400,

                        height: 20,
                        width: 25,
                        textAlign: "center",
                        borderBottom:
                          isUpper && NumberSystem.toLowerCase() === "palmer"
                            ? "1px solid rgba(0, 0, 0, 0.87)"
                            : "none",
                        borderRight:
                          isRight && NumberSystem.toLowerCase() === "palmer"
                            ? "1px solid rgba(0, 0, 0, 0.87)"
                            : "none",
                        borderLeft:
                          !isRight && NumberSystem.toLowerCase() === "palmer"
                            ? "1px solid rgba(0, 0, 0, 0.87)"
                            : "none",
                        borderTop:
                          !isUpper && NumberSystem.toLowerCase() === "palmer"
                            ? "1px solid rgba(0, 0, 0, 0.87)"
                            : "none",
                        lineHeight: "20px",
                        // display: "inline-block",
                      }}
                    >
                      {item}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <TableRow
                key={row.key}
                sx={{
                  "&:last-child td, &:last-child th": { border: "none" },
                  height: 32,
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    padding: "0px",
                    fontSize: 10,
                    fontWeight: 500,
                    pr: 1,
                    lineHeight: "32px",
                    borderBottom: "none",
                  }}
                >
                  {row.key}
                  {row.unit && (
                    <Box
                      component={"span"}
                      sx={{ color: "rgba(0, 0, 0, 0.60)" }}
                    >
                      {row.unit}
                    </Box>
                  )}
                </TableCell>
                {row.value.map((item, index) => {
                  let bgColor = tableColor[rowIndex][index]
                  //let bgColor = ""

                  // if (isUpper) {
                  //   bgColor = getToothColor({
                  //     id: index + 1,
                  //     type: row.name,
                  //     value: item,
                  //   })
                  // } else {
                  //   bgColor = getToothColor({
                  //     id: 32 - index,
                  //     type: row.name,
                  //     value: item,
                  //   })
                  // }
                  let value = (Math.round(item * 10) / 10).toString()
                  if (value === "0" || value === "-0" || value === "NaN") {
                    value = "-"
                  } else {
                    if (value.indexOf(".") === -1) {
                      value += ".0"
                    }
                    if (value.indexOf("-") === -1) {
                      value = "+" + value
                    }
                  }
                  return (
                    <TableCell
                      key={item + index}
                      align="center"
                      sx={{
                        backgroundColor: bgColor,

                        fontSize: 12,
                        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                        borderTop: "1px solid rgba(255, 255, 255, 0.12)",
                        width: 56,
                        padding: "6px 8px",
                        letterSpacing: "0.4px",
                      }}
                    >
                      {value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const handlePage = (action: "pre" | "next") => {
    // currentStage = 1时
    if (action === "pre" && currentStage.index === 1) {
      setcurrentStage({ ...lastStage, index: 0 })
      return
    }
    // currentStage 非total非1时
    if (action === "pre" && currentStage.index <= lastStage.index) {
      setcurrentStage({
        name: `${Number(currentStage.name) - 1}`,
        index: currentStage.index - 1,
      })
      return
    }
    // currentStage = total时
    if (action === "next" && currentStage.index === lastStage.index) {
      setcurrentStage({
        name: isUpper
          ? stageObj.upList[1].showStage
          : stageObj.downList[1].showStage,
        index: 1,
      })
      return
    }
    // currentStage 非total非last stage时
    if (action === "next" && currentStage.index < lastStage.index) {
      setcurrentStage({
        name: `${Number(currentStage.name) + 1}`,
        index: currentStage.index + 1,
      })
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 336,
        width: 120,
      },
    },
  }
  if (!open) return <></>
  return (
    <Drag
      DragPosition={{
        x: 14,
        y: 133,
      }}
      DragSize={{ width: 1120, height: 420 }}
      disableDragging={isDisableDrag}
    >
      <Box
        sx={{
          padding: "0",
          backgroundColor: "#FFF",
          boxShadow:
            "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
          borderRadius: 2,
          width: "1120px",
          height: "420px",
        }}
      >
        <Box
          id={"title"}
          sx={{
            height: 72,
            padding: "0",
            color: "#000",
            fontSize: 24,
            fontWeight: 500,
            letterSpacing: "0.17px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#F1F1F2",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <span style={{ marginLeft: "32px" }}>{`Tooth Movement Table`}</span>
          <SvgBtn
            boxSx={{
              marginTop: "-20px",
              marginRight: "10px",
              borderRadius: "16px",
            }}
            svg={Close}
            clickFun={() => {
              setOpen(false)
            }}
            svgColor="rgba(0,0,0,1)"
          ></SvgBtn>
        </Box>
        <Box
          id={"movement"}
          sx={{
            width: "calc(100% - 48px)",
            marginLeft: "24px",
            paddingBottom: "8px",
          }}
        >
          <Box
            id={"subtitle"}
            sx={{
              height: 42,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {getArch(caseDetail.udesign_category) !== "lower" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "9px 16px",
                }}
                onClick={() => {
                  setisUpper(true)
                }}
              >
                <ReactSVG
                  src={Upper}
                  afterInjection={(svg) => {
                    const path = svg.getElementsByTagName("path")[0]
                    if (isUpper) {
                      path?.setAttribute("fill", "#215ECD")
                      path?.setAttribute("fill-opacity", "1")
                    } else {
                      path?.setAttribute("fill", "#000000")
                      path?.setAttribute("fill-opacity", "0.6")
                    }
                  }}
                  style={{ marginRight: 8.5 }}
                />
                <UText
                  variant="body2"
                  sxProp={{
                    color: isUpper ? "#215ECD" : "rgba(0, 0, 0, 0.60)",
                    fontWeight: 500,
                  }}
                >
                  {t("treat.movement.upper")}
                </UText>
              </Box>
            )}
            {getArch(caseDetail.udesign_category) !== "upper" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "9px 16px",
                }}
                onClick={() => {
                  setisUpper(false)
                }}
              >
                <ReactSVG
                  src={Lower}
                  afterInjection={(svg) => {
                    const path = svg.getElementsByTagName("path")[0]
                    if (!isUpper) {
                      path?.setAttribute("fill", "#215ECD")
                      path?.setAttribute("fill-opacity", "1")
                    } else {
                      path?.setAttribute("fill", "#000")
                      path?.setAttribute("fill-opacity", "0.6")
                    }
                  }}
                  style={{ marginRight: 8.5 }}
                />
                <UText
                  variant="body2"
                  sxProp={{
                    color: !isUpper ? "#215ECD" : "rgba(0, 0, 0, 0.60)",
                    fontWeight: 500,
                  }}
                >
                  {t("treat.movement.lower")}
                </UText>
              </Box>
            )}
          </Box>
          <Box id={"table"}>{getTable()}</Box>
          <Box
            id="foot"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: 40,
              marginTop: 1,
            }}
          >
            <Select
              labelId="stage"
              value={currentStage.index}
              onChange={({ target }) => {
                setcurrentStage({
                  name: isUpper
                    ? stageObj.upList[Number(target?.value)].showStage
                    : stageObj.downList[Number(target?.value)].showStage,
                  index: Number(target?.value),
                })
              }}
              onOpen={() => {
                setisDisableDrag(true)
              }}
              onClose={() => {
                setisDisableDrag(false)
              }}
              MenuProps={MenuProps}
              className={isDisableDrag ? "open-select" : ""}
              sx={{
                width: 120,
                height: "100%",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: "0.17px",
                cursor: "pointer",
                "&:hover": { border: "1px solid rgba(0, 0, 0, 0.23)" },
              }}
            >
              <MenuItem value={0}>Total</MenuItem>
              {(function () {
                let list = stageObj.upList
                if (!isUpper) {
                  list = stageObj.downList
                }
                const res = list.map(({ showStage }, index) => {
                  if (index === 0) return <></>
                  return (
                    <MenuItem
                      key={index}
                      value={index}
                      sx={{
                        height: 32,
                      }}
                    >
                      {"Stage" + showStage}
                    </MenuItem>
                  )
                })

                return res
              })()}
            </Select>

            <SvgBtn
              svg={Pre}
              clickFun={() => {
                handlePage("pre")
              }}
              isdisable={currentStage.index === 0}
              boxSx={{ marginLeft: 1.5, marginRight: 3 }}
              svgColor="black"
            ></SvgBtn>
            <SvgBtn
              svg={Next}
              clickFun={() => {
                handlePage("next")
              }}
              svgColor="black"
              isdisable={currentStage.index === lastStage.index}
            ></SvgBtn>
          </Box>
        </Box>
        <div
          style={{
            width: "100%",
            height: "24px",
            borderRadius: "0 0 8px 8px",
            background: "#F1F1F2",
          }}
        ></div>
      </Box>
    </Drag>
  )
}

export default Movement
