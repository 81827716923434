import { useState } from "react"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import MenuList from "@mui/material/MenuList"
import MenuItem from "@mui/material/MenuItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import Typography from "@mui/material/Typography"
import ContentCut from "@mui/icons-material/ContentCut"
import ContentCopy from "@mui/icons-material/ContentCopy"
import ContentPaste from "@mui/icons-material/ContentPaste"
import Cloud from "@mui/icons-material/Cloud"
import OrderIcon from "@mui/icons-material/AddShoppingCartOutlined"
import MenuIcon from "@mui/icons-material/Menu"

import { Tooltip, IconButton, Menu, Box } from "@mui/material"

export default function NewCaseMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSelect = (e: any, statusType: string) => {
    setAnchorEl(null)
  }
  return (
    <>
      <Tooltip title="Status" placement="bottom">
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleSelect(e, "Plan")}>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Treatment Plan</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelect(e, "Modi")}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Modification</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelect(e, "Order")}>
          <ListItemIcon>
            <OrderIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Order</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelect(e, "Order")}>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>3D Printing</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleSelect(e, "Order")}>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>More</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => handleSelect(e, "CloseCase")}>
          <ListItemIcon>
            <Cloud fontSize="small" />
          </ListItemIcon>
          <ListItemText>Close Case</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
