import React from 'react';
import { Box, Stack, IconButton, Grid, Input, Typography, Button, Paper, experimentalStyled as styled, Hidden } from "@mui/material";
import { FC, useEffect, useState, useRef, useImperativeHandle } from "react";
import TriangleIcon from './triangleIcon';
import StageAddIcon from './stageAddIcon';
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import IprIconSimple from './iprIconSimple';

const StageItemSimple = (props) => {

  const ButtonItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  }));

  const curStageArchQuick3Index = (props.archType === 0 ? props.upQuick3Index : props.downQuick3Index)
  
  const topAreaHeight = 9
  const addMargin = 3
  const triangleMargin = 1
  const triangleHeight = 5
  const stageWidth = props.singleStageWidth

  return (

    <div style={{ 
      width: props.actualStageWidth + props.stageDiv, 
      height: (topAreaHeight + props.singleStageHeight + triangleMargin + triangleHeight),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex:1,
    }}>
      <Stack direction="row"
        spacing={0}>

        <div style={{
          width: props.actualStageWidth,
          height: (topAreaHeight + props.singleStageHeight + triangleMargin + triangleHeight),
          position: "relative",
        }}>

          {props.archType === 1 ? <TriangleIcon color={"#02AFEC"} position={"absolute"} left={props.actualStageWidth / 2} top={0} direction="down" visibility={props.index === props.stageCurrentStep ? "" : "hidden"} /> : <></>}

          <ButtonItem sx={{
            width: props.actualStageWidth,
            height: props.singleStageHeight,
            position: "absolute",
            top: props.archType === 0 ? (topAreaHeight) : (triangleMargin + triangleHeight),
            // alignItems: 'center',
            // justifyContent: 'center',
            borderRadius: "0px",
            border: "none",//props.stageCurrentStep === props.index ? 'solid 1px #02AFEC' : (props.index <= curStageArchQuick3Index ? 'solid 1px #b1b1b1' : 'solid 1px #999999'),
            color: props.stageCurrentStep === props.index ? "white" : "black",
            backgroundColor: props.isNodeContains(props.item.nodeTypes, 14) ? "#33CC33" : (props.stageCurrentStep === props.index || props.isNodeContains(props.item.nodeTypes, 0) ? '#02AFEC' : '#999999'),
            fontSize: 14,
            userSelect: 'none',
            zIndex: 0,
          }}
            onClick={() => props.stageButtonClickHandle(props.item, props.index, props.itemArray)}
          >

          </ButtonItem>

          {props.archType === 0 ? <TriangleIcon color={"#02AFEC"} position={"absolute"} left={props.actualStageWidth / 2} top={topAreaHeight + props.singleStageHeight + triangleMargin} direction="up" visibility={props.index === props.stageCurrentStep ? "" : "hidden"} /> : <></>}

        </div>

        <Box sx={{ width: (props.stageDiv + "px"), height: "1px"}}>
          {props.archType === 0 ? <IprIconSimple visibility={props.isNodeContains(props.item.nodeTypes, 1) ? "" : "hidden"} position={"relative"} left={props.stageDiv / 2} top={topAreaHeight - 4} direction="up"/> : <IprIconSimple visibility={props.isNodeContains(props.item.nodeTypes, 1) ? "" : "hidden"} position={"relative"} left={props.stageDiv / 2} top={9} direction="down"/>}
        </Box>

      </Stack>
    </div>

    
  );
};

export default StageItemSimple;