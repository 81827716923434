import React, { FC, useState } from "react"
import {
  Tabs,
  Tab,
  Box,
  Typography,
  useTheme,
  Button,
  Stack,
  IconButton,
} from "@mui/material"
import { LeftPage } from "./LeftPage"
import Message from "@/modules/Clinical/components/SideBar/message"
import SmartRx from "../components/SmartRx"
import { setCurLeftPage, setSmartRxShow } from "../udTreatSlice"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import { useAppDispatch } from "@/core/app/hooks"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  }
}

const UassistTabs: FC<{
  isEdit: boolean
  isSubmitModification
  setisSubmitModification
  showPrompt: boolean | (() => void)
  confirmNavigation: boolean | (() => void)
  cancelNavigation: boolean | (() => void)
  updateCaseFun
  setisSubmitReply
  isSubmitReply
  onClosePage
}> = ({
  isEdit,
  isSubmitModification,
  setisSubmitModification,
  setisSubmitReply,
  isSubmitReply,
  updateCaseFun,
  onClosePage,
  showPrompt,
  confirmNavigation,
  cancelNavigation,
}) => {
  const [value, setValue] = useState(0)
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        left: 14,
        top: 133,
        minWidth: "300px",
        maxWidth: "600px",
        borderRadius: "8px",
        backgroundColor: theme.transBkColor?.light,
        maxHeight: `calc(100% - 114px)`,
        position: "absolute",
        boxShadow: 6,
        // overflow: "hidden",
      }}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ p: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Message"
            sx={{ textTransform: "none" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Prescription"
            sx={{ textTransform: "none" }}
            {...a11yProps(1)}
          />
        </Tabs>
        <IconButton
          sx={{
            width: 30,
            height: 30,
          }}
          onClick={() => {
            dispatch(setCurLeftPage(""))
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <TabPanel value={value} index={0}>
        <Box
          sx={{
            width: "536px",
            height: "auto",
            // maxHeight: `calc(100vh - 250px)`,
          }}
        >
          <Message
            isEdit={isEdit}
            isSubmitModification={isSubmitModification}
            setisSubmitModification={setisSubmitModification}
            showPrompt={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            updateCaseFun={updateCaseFun}
            setisSubmitReply={setisSubmitReply}
            isSubmitReply={isSubmitReply}
            onClosePage={onClosePage}
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            width: "auto",
            minWidth: "536px",
            height: `calc(100vh - 280px)`,
          }}
        >
          <SmartRx
            needToSubmit={false}
            smartRxShow={true}
            setSmartRxShow={setSmartRxShow}
          />
        </Box>
      </TabPanel>
    </Box>
  )
}

export default UassistTabs
