import React, { useRef, useEffect } from 'react';
 
const StageGmButton = (props) => {

  const canvasRef = useRef(null)
  const buttonRadius = props.radius ? props.radius : 4
  const buttonFontSize = props.fontSize ? props.fontSize : "14px"

  const drawRoundRect = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    // 设左上角为（x, y）, 圆角半径为r, 边长为b
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);

    ctx.arcTo(x + width, y, x + width, y + radius, radius);

    ctx.lineTo(x + width, y + height - radius);
    ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
    ctx.lineTo(x + radius, y + height);
    ctx.arcTo(x, y + height, x, y + height - radius, radius)
    ctx.lineTo(x, y + radius);
    ctx.arcTo(x, y, x + radius, y, radius);
    
    ctx.stroke();
    ctx.closePath();

    ctx.fillStyle = '#02AFEC';
    ctx.fill();
  }

  const drawImg = () => {
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const ctx = canvas.getContext('2d')
    const drawLength = props.percent * rect.width
    ctx.clearRect(0, 0, rect.width, rect.height)
    ctx.stroke();
    if(drawLength <= 0) {
      return;
    } 
    //ctx.drawRect()
    ctx.strokeStyle = 'transparent';
    drawRoundRect(ctx, 0, 0, drawLength-2, rect.height-2, buttonRadius)
  }

  useEffect(() => {
    drawImg()
  },
    []
  );

  useEffect(() => {
    drawImg()
  },
    [props.percent]
  );
 
  return (
    <div style={{
      width: props.width + "px", 
      height: props.height + "px",
      backgroundColor: "white",
      border: "1px solid #919191",
      borderRadius: buttonRadius + "px",
      position: "relative",
      marginTop: "0px",
      marginBottom: "0px",
    }}>
      <canvas ref={canvasRef} 
        width={props.width + "px"}
        height={props.height + "px"}
        style={{verticalAlign: "top"}}>
        
      </canvas>
      {/* <div style={{
        width: (props.percent * (props.width - 2)),
        height: props.height-2,
        backgroundColor: "#02AFEC", 
        borderRadius: "4px 0px 0px 4px",
        position: "absolute",
        left: "0px",
        top: "0px",
      }}>
      </div> */}
      <div style={{
        width: props.width + "px", 
        height: props.height + "px",
        backgroundColor: "transparent", 
        position: "absolute",
        left: "0px",
        top: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        fontSize: buttonFontSize,
      }}
      onClick={props.onClick}>
        GM
      </div>
    </div>
  )
}
 
export default StageGmButton;