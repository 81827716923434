import { useEffect, useState, useCallback, useReducer } from "react"
import { Box, Stack, Grid, FormControlLabel } from "@mui/material"
import { Divider, Backdrop, Switch, LinearProgress } from "@mui/material"
import { SetupType, OJOBType, MidlineType, IPRRange } from "@/gluelayer"
import { SetupByRx } from "../../UDesign/UDSetupType"
import {
  UDTitleSelectOptions,
  UDTitleButtons,
} from "@/UDTreat/custom/UDSelectOptions"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { gdsPlusSetup, wasmModule } from "@/gluelayer"
import {
  setDisable,
  updateAutoSetup,
  setAutoOccContact,
  setOpenProgressDlg,
  setProgressDlgMsg,
} from "@/UDTreat/udSetupSlice"
import { setUpdateSetup, setUpdateStaging } from "@/UDTreat/udTreatSlice"
import { RootState } from "@/core/app/store"

export const WeSetupTypeRx = (props) => {
  const setupTypeAndStr = [
    [SetupType.Full, "Auto"],
    [SetupType.Anterior, "Anterior"],
    [SetupType.FullNoAP, "Molar to Molar"],
    [SetupType.Full, "Full Arch"],
    [SetupType.Anterior, "Upper Only"],
    [SetupType.Anterior, "Lower Only"],
    [SetupType.FineTune, "Fine Tune"],
    [SetupType.AlignmentOnly, "Alignment Only"],
    [SetupType.Aligner5, "Aligner 5"],
    [SetupType.Init, "Init"],
  ]
  return (
    <Stack>
      <SetupByRx setupTypeAndStr={setupTypeAndStr} />
      <Divider />
    </Stack>
  )
}

const setupTypeOptions = {
  title: "Setup Type",
  group: "SetupType",
  options: [
    "Fine Tune",
    "Full",
    "Molar to Molar",
    "Ant 5x5",
    "Ant 3x3",
    "Alignment Only",
    "Init",
  ],
}

const setupParaOptions = [
  {
    title: "Sel. Tooth Extraction",
    group: "ToothExtraction",
    options: ["Pull Out", "Restore All"],
  },
  {
    title: "Anterior Space",
    group: "AntSpace",
    options: ["0 mm", "0.5 mm", "1.0 mm"],
  },

  {
    title: "Overbite",
    group: "Overbite",
    options: ["-1 mm", "0 mm", "1 mm", "2 mm", "3 mm"],
  },
  {
    title: "Midline",
    group: "Midline",
    options: ["Center", "Keep Init", "To Upper", "To Lower"],
  },
  {
    title: "AP Control",
    group: "AP",
    options: ["Auto", "No AP"],
  },
  {
    title: "Upper IPR/Space",
    group: "UpperIPR",
    options: [
      "Full Arch",
      "Ant Only",
      "Incisor Only",
      "Canine Only",
      "Auto All",
    ],
  },
  {
    title: "Lower IPR/Space",
    group: "LowerIPR",
    options: [
      "Full Arch",
      "Ant Only",
      "Incisor Only",
      "Canine Only",
      "Auto All",
    ],
  },
  {
    title: "Global Movement",
    group: "GM",
    options: ["Auto", "Remove", "Jump"],
  },
]

export const WeSetupType = (props) => {
  const dispatch = useAppDispatch()
  const { disabled, autoOccContact } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  // Somehow this component is always re-rendered after each setup step.
  // and openProgress is always flase after first step.
  // const [progressMsg, setProgressMsg] = useState("")
  // const [openProgress, setOpenProgress] = useState(false)
  const setupTypes = [
    SetupType.FineTune,
    SetupType.Full,
    SetupType.FullNoAP,
    SetupType.Anterior,
    SetupType.Ant3x3,
    SetupType.AlignmentOnly,
    SetupType.Init,
  ]
  const OJOBTypes = [
    OJOBType.Improve,
    OJOBType.HalfMmSpace,
    OJOBType.OneMmSpace,
  ]
  const midlineTypes = [
    MidlineType.MIDTYPE_CENTER,
    MidlineType.MIDTYPE_INIT,
    MidlineType.MIDTYPE_TOUPPER,
    MidlineType.MIDTYPE_TOLOWER,
  ]
  const IPRRangeTypes = [
    IPRRange.Full,
    IPRRange.AntOnly,
    IPRRange.IncisorOnly,
    IPRRange.CanineOnly,
    IPRRange.AutoAll,
  ]
  const OBValues = [-1, 0, 1, 2, 3]

  const extractRestoreTooth = (extract) => {
    setTimeout(() => {
      if (extract) gdsPlusSetup.ExtractTooth()
      else gdsPlusSetup.RestoreAllTeeth()
      dispatch(setUpdateSetup())
      dispatch(updateAutoSetup())
      dispatch(setUpdateStaging())
    }, 100) // wait 100ms so the dialog is shown.
  }

  const setupByTypeStep = (type: SetupType, step: number) => {
    if (step == -1) {
      dispatch(setOpenProgressDlg(false))
      dispatch(setProgressDlgMsg("End"))
      dispatch(setUpdateSetup())
      dispatch(setDisable(false))
      dispatch(updateAutoSetup())
      dispatch(setUpdateStaging())
      return // no next step,
    }
    if (step == 0) {
      gdsPlusSetup.setSetupType(type)
    }
    const msg = gdsPlusSetup.getSetupStepMsg(step)
    dispatch(setOpenProgressDlg(true))
    dispatch(setProgressDlgMsg("Auto setup: " + msg))
    setTimeout(() => {
      // wait 0.1s to let UI refresh then run next step
      const nextStep = gdsPlusSetup.runSetupStep(step)
      // let nextStep = step + 1
      // if (nextStep == 10) nextStep = -1
      setupByTypeStep(type, nextStep) // continue to next step
    }, 1000)
  }

  useEffect(() => {
    console.log("WeSetupType  rerendered")
  })

  const onChangeSel = (group: string, sel: number) => {
    console.log(group, ":", sel, "is selected!")
    switch (group) {
      case "SetupType":
        const type = setupTypes[sel]
        setupByTypeStep(type, 0)
        break
      case "ToothExtraction":
        extractRestoreTooth(sel == 0)
        break
      case "AntSpace":
        gdsPlusSetup.SetOJOBByTypeID(OJOBTypes[sel], () => ({}))
        break
      case "Overbite":
        gdsPlusSetup.SetParamValue("OB", OBValues[sel])
        break
      case "Midline":
        gdsPlusSetup.SetMidlineByTypeID(midlineTypes[sel], () => ({}))
        break
      case "AP":
        if (sel === 0) gdsPlusSetup.SetupAPUpperToLower() // AP auto
        else gdsPlusSetup.NoAPControl() // No AP
        break
      case "UpperIPR":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRangeTypes[sel])
        break
      case "LowerIPR":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRangeTypes[sel])
        break
      case "GM":
        if (sel === 0) gdsPlusSetup.SetLowerGlobalMovement(true, false)
        else if (sel === 1) gdsPlusSetup.SetLowerGlobalMovement(false, false)
        else gdsPlusSetup.SetLowerGlobalMovement(true, true)
        break
    }
  }

  useEffect(() => {
    if (!wasmModule.isInit) return
    gdsPlusSetup.EnableAutoOccContact(autoOccContact)
  }, [autoOccContact])

  return (
    <Grid container alignItems={"center"} spacing={0.25}>
      <UDTitleButtons
        key={399}
        title={setupTypeOptions.title}
        options={setupTypeOptions.options}
        onClick={(sel) => {
          onChangeSel(setupTypeOptions.group, sel)
        }}
      />
      <Grid item xs={5}></Grid>
      <Grid item xs={7}>
        <FormControlLabel
          control={<Switch checked={autoOccContact} />}
          label="Auto Occ Contact"
          onChange={(_, value) => {
            dispatch(setAutoOccContact(value))
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {!disabled ? (
        setupParaOptions.map((paraOption, index) => {
          return (
            <>
              <UDTitleButtons
                key={index}
                title={paraOption.title}
                options={paraOption.options}
                onClick={(sel) => {
                  onChangeSel(paraOption.group, sel)
                }}
              />
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )
        })
      ) : (
        <></>
      )}
    </Grid>
  )
}
