import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import { FormInput, UButton, UText } from "../../../components"
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
} from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"

import usePermissions from "../../../hooks/userPermission"

const Integrations: FC = () => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation("common")

  const dispatch = useAppDispatch()
  return (
    <Grid sx={{ width: "600px", p: 4 }}>
      <UText
        variant={"h4"}
        component={"div"}
        sxProp={{ textAlign: "center", pb: 2 }}
      >
        {t("settings.Integrations.title")}
      </UText>
    </Grid>
  )
}

export default Integrations
