import React, { useEffect, useState } from "react"
import { Rnd } from "react-rnd"
import {
  Button,
  Box,
  Grid,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import EastIcon from "@mui/icons-material/East"
import WestIcon from "@mui/icons-material/West"
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw"
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw"

import TurnSlightLeftIcon from "@mui/icons-material/TurnSlightLeft"
import TurnSlightRightIcon from "@mui/icons-material/TurnSlightRight"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { wasmModule } from "@/gluelayer"
import { debounce } from "lodash"

const UDBiteReg: React.FC = () => {
  const { openBiteReg } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [dragxy, setDragxy] = useState({ x: 1400, y: 200 })

  const [show3DWidget, setShow3DWidget] = useState(false)

  useEffect(() => {
    return () => {
      if (!wasmModule.isInit) return
      wasmModule.moduleManager.SwitchModuleBiteReg(false)
    }
  }, [])

  useEffect(() => {
    if (!wasmModule.isInit) return
    console.log("openBiteReg", openBiteReg)
    wasmModule.moduleManager.SwitchModuleBiteReg(openBiteReg)
    if (openBiteReg) {
      wasmModule.moduleManager.GetModuleBiteReg().Set3DWidgetVisiable(true)
    }
  }, [openBiteReg])

  const ButtonGroups = [
    {
      id: 1,
      title: "button1",
      icon: <ArrowBackIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().MoveLeft()
      },
    },
    {
      id: 2,
      title: "button2",
      icon: <ArrowForwardIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().MoveRight()
      },
    },
    {
      id: 3,
      title: "button3",
      icon: <ArrowDownwardIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().MoveDown()
      },
    },
    {
      id: 4,
      title: "button4",
      icon: <ArrowUpwardIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().MoveUp()
      },
    },
    {
      id: 5,
      title: "button5",
      icon: <EastIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().MoveFront()
      },
    },
    {
      id: 6,
      title: "button6",
      icon: <WestIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().MoveBack()
      },
    },
    {
      id: 7,
      title: "button7",
      icon: <SubdirectoryArrowLeftIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().RotateXFront()
      },
    },
    {
      id: 8,
      title: "button8",
      icon: <SubdirectoryArrowRightIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().RotateXBack()
      },
    },
    {
      id: 9,
      title: "button9",
      icon: <Rotate90DegreesCwIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().RotateYLeft()
      },
    },
    {
      id: 10,
      title: "button10",
      icon: <Rotate90DegreesCcwIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().RotateYRight()
      },
    },
    {
      id: 11,
      title: "button11",
      icon: <TurnSlightLeftIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().RotateZLeft()
      },
    },
    {
      id: 12,
      title: "button12",
      icon: <TurnSlightRightIcon />,
      onclick: () => {
        wasmModule.moduleManager.GetModuleBiteReg().RotateZRight()
      },
    },
  ]

  const handleDebouncedClick = () => {
    console.log("Button debouncedClick!")
    // 执行其他操作
  }
  const debouncedClick = debounce(handleDebouncedClick, 500) // 设置延迟时间为500毫秒
  return (
    <Rnd
      position={{ x: dragxy.x, y: dragxy.y }}
      onDragStop={(e, d) => {
        setDragxy({ x: d.x, y: d.y })
      }}
      dragHandleClassName="dragarea"
    >
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "background.paper",
          p: 2,
          justifyContent: "center",
          alignItems: "center",
          width: 300,
          height: 450,
        }}
      >
        <Stack className="dragarea" direction="row-reverse">
          <Button
            variant="contained"
            onClick={() => wasmModule.moduleManager.GetModuleBiteReg().Reset()}
          >
            reset
          </Button>
        </Stack>
        <Box sx={{ my: 2 }}>
          <hr />
        </Box>
        <Grid container spacing={2}>
          {ButtonGroups.map((item) => (
            <Grid item xs={6} key={item.id}>
              <Button
                variant="outlined"
                startIcon={item.id % 2 === 0 ? item.icon : undefined}
                endIcon={item.id % 2 !== 0 ? item.icon : undefined}
                onClick={() => {
                  item.onclick()
                  debouncedClick()
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "lightgray",
                }}
              >
                {item.title}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ my: 2 }}>
          <hr />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
            // checked={show3DWidget}
            // onChange={(e) => {
            //   setShow3DWidget(e.target.checked)
            // }}
            />
          }
          label="Show 3D Widget"
        />
      </Box>
    </Rnd>
  )
}

export default UDBiteReg
