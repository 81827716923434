import React from "react"
import { Box, Stack } from "@mui/material"
import { IAddOptionType, wasmModule } from "@/gluelayer"
import { useAppDispatch } from "@/core/app/hooks"
import { setOpenProgressDlg, setProgressDlgMsg } from "@/UDTreat/udSetupSlice"
import { RightPage } from "@/UDTreat/right/RightPage"
import { UDTitleButtons } from "@/UDTreat/custom/UDSelectOptions"
import { attachmentModule } from "@/gluelayer"

const attOptions = {
  title: "Attachment",
  group: "Attachment",
  options: ["Add All", "Remove All"],
}

const protocolOptions = {
  title: "Att Protocol",
  group: "AttProtocol",
  options: [
    "Deep bite",
    "Distalization",
    "Max retention",
    "Open bite",
    "Remove All",
  ],
}

export const QuickAux = (props) => {
  const dispatch = useAppDispatch()
  const onClickAutoAtt = (sel) => {
    if (!wasmModule) {
      console.log("wasmModule null ptr")
      return
    }
    attachmentModule.openAttachmentModule(true)
    if (sel === 0) attachmentModule.addAllAttachment()
    else if (sel === 1) attachmentModule.deleteAllAttachment()
    attachmentModule.openAttachmentModule(false)
  }
  return (
    <RightPage
      title="Auxillary"
      onClose={props.onClose}
      sx={{ width: 205 }}
      content={
        <Stack spacing={2}>
          <UDTitleButtons
            wrap={false}
            key={399}
            title={attOptions.title}
            options={attOptions.options}
            onClick={(sel) => {
              onClickAutoAtt(sel)
            }}
          />
          {/* 
          <UDTitleButtons
            wrap={false}
            key={499}
            title={protocolOptions.title}
            options={protocolOptions.options}
            onClick={(sel) => {
              onClickProtocol(sel)
            }}
          />*/}
        </Stack>
      }
      actions={<Box sx={{ height: 20 }}></Box>}
    />
  )
}
