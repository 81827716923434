import React from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import { wasmModule } from "@/gluelayer"
import { useAppDispatch } from "@/core/app/hooks"
import { setOpenProgressDlg, setProgressDlgMsg } from "@/UDTreat/udSetupSlice"
import { RightPage } from "@/UDTreat/right/RightPage"
import { UDTitleButtons } from "@/UDTreat/custom/UDSelectOptions"

const autoStagingOptions = {
  title: "Auto Staging",
  group: "AutoStaging",
  options: ["Add All", "Remove All"],
}

export const QuickStaging = (props) => {
  const dispatch = useAppDispatch()
  const onquick3 = () => {}
  const onCChain = (TbyT) => {}

  const onAutoStaging = () => {
    dispatch(setOpenProgressDlg(true))
    dispatch(setProgressDlgMsg("Auto Staging: upper jaw..."))
    setTimeout(() => {
      // wait 0.1s to let UI refresh then run next step
      wasmModule.stagingcontoler.DelTx(0)
      wasmModule.stagingcontoler.AutoAllStage(0, 3)
      dispatch(
        setProgressDlgMsg("Auto Staging: upper jaw... done! Now lower jaw..."),
      )
      setTimeout(() => {
        wasmModule.stagingcontoler.AutoAllStage(1, 3)
        dispatch(
          setProgressDlgMsg(
            "Auto Staging: upper jaw... done! Now lower jaw... done! ",
          ),
        )
        dispatch(setOpenProgressDlg(false))
      }, 100)
    }, 100)
  }

  const onChangeSel1 = (group: string, sel: number) => {
    console.log(group, ":", sel, "is selected!")
    if (group === "AutoStaging") {
      switch (sel) {
        case 0:
          onAutoStaging()
          break
        case 1:
          wasmModule.stagingcontoler.RemoveAutoAllStage(0)
          wasmModule.stagingcontoler.RemoveAutoAllStage(1)
          break
      }
    }
  }

  return (
    <RightPage
      title="Staging"
      sx={{ width: 205 }}
      onClose={props.onClose}
      content={
        <Stack direction={"column"} spacing={2}>
          <UDTitleButtons
            wrap={true}
            key={399}
            title={autoStagingOptions.title}
            options={autoStagingOptions.options}
            onClick={(sel) => {
              onChangeSel1(autoStagingOptions.group, sel)
            }}
          />
          {/**
          <Typography>Staging</Typography>
          <Stack direction={"row"} spacing={0.5}>
            <Button variant={"contained"} onClick={onAutoStaging}>
              Auto All
            </Button>
            <Button
              variant={"contained"}
              onClick={() => {
                wasmModule.stagingcontoler.RemoveAutoAllStage(0)
                wasmModule.stagingcontoler.RemoveAutoAllStage(1)
              }}
            >
              Remove All
            </Button>
          </Stack> */}
          {/** 
          <Typography>Quick 3</Typography>
          <Stack direction={"row"} spacing={0.5}>
            <Button variant={"contained"} onClick={onCChain}>
              Add
            </Button>
            <Button variant={"contained"} onClick={() => {}}>
              Remove
            </Button>
          </Stack>
          <Typography>C-chain</Typography>
          <Stack direction={"row"} spacing={0.5}>
            <Button variant={"contained"} onClick={() => onCChain(true)}>
              3x3
            </Button>
            <Button variant={"contained"} onClick={() => onCChain(false)}>
              4x4
            </Button>
            <Button variant={"contained"} onClick={() => {}}>
              Remove
            </Button>
          </Stack>*/}
        </Stack>
      }
      actions={<Box></Box>}
    />
  )
}
