import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  ToggleButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { lowerBiteRampData, upperBiteRampData } from "./columns"
import {
  attachmentModule,
  biteRampModule,
  EArchType,
  stagingManager,
  wasmModule,
} from "@/gluelayer"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

export const UDAuxBiteRamp = () => {
  const [startFrom, setStartFrom] = useState(1)
  const [endAt, setEndAt] = useState(1)
  const [upperCheckedBiteramp, setUpperCheckedBiteramp] = useState<number[]>([])
  const [lowerCheckedBiteramp, setLowerCheckedBiteramp] = useState<number[]>([])

  const [overlayClick, setOverlayClick] = useState(false)
  const [heightPanel, setHeightPanel] = useState(false)

  const { curArch } = useAppSelector((state: RootState) => state.udTreatService)

  const [alert, setAlert] = useState(false)
  const [overlayButtonDisable, setOverButtonDisable] = useState(false)
  const [heightButtonDisable, setHeightButtonDisable] = useState(false)

  const [isSingleArch, setIsSingleArch] = useState(false)

  const upStage = stagingManager.wasmStageData.jointUpKeypoints
  const lowerStage = stagingManager.wasmStageData.jointLowerKeypoints

  const stages = upStage.length > lowerStage.length ? upStage : lowerStage

  let stageMenuIems = []
  stages.forEach((val) => {
    if (val.name === "0" || val.name.includes("R")) return
    // if (val.name.includes("R")) stageMenuIems.push(val.name)
    else stageMenuIems.push(`stage${val.name}`)
  })

  useEffect(() => {
    biteRampModule.onShowDownTeethIn3to3Range(overlayClick)
  }, [overlayClick])

  useEffect(() => {
    biteRampModule.SetPlaneVisiable(heightPanel)
  }, [heightPanel])

  useEffect(() => {
    attachmentModule.setAttachmentSelectAbleType("NormalAttachment")

    const upperRampTmp = []
    upperBiteRampData.map((data) => {
      if (biteRampModule.isToothHasBiteRamp(data.key)) {
        upperRampTmp.push(data.key)
      }
    })
    setUpperCheckedBiteramp(upperRampTmp)

    const lowerRampTmp = []
    lowerBiteRampData.map((data) => {
      if (biteRampModule.isToothHasBiteRamp(data.key)) {
        lowerRampTmp.push(data.key)
      }
    })
    setLowerCheckedBiteramp(lowerRampTmp)

    biteRampModule.setOperationEnable(true)

    const hasUpper = wasmModule.getArchModel(EArchType.UpArch)
    const hasLower = wasmModule.getArchModel(EArchType.LowArch)
    setIsSingleArch(hasUpper == null || hasLower == null)

    return () => {
      biteRampModule.setOperationEnable(false)
    }
  }, [])

  useEffect(() => {
    //get start stage and end stage
    const start = biteRampModule.getStartStage()
    const end = biteRampModule.getEndStage()
    setStartFrom(start)
    setEndAt(end)
  }, [upperCheckedBiteramp, lowerCheckedBiteramp])

  useEffect(() => {
    // console.log("🚀 ~ UDAuxBiteRamp ~ curArch:", curArch)
    if (curArch === "all" && !isSingleArch) {
      setOverlayClick(false)
      setHeightPanel(false)
    } else {
      setOverButtonDisable(false)
      setHeightButtonDisable(false)
    }
  }, [curArch])

  // checkbox bite ramp
  const handleCheckRamp = (
    event: React.ChangeEvent<HTMLInputElement>,
    isUpper: boolean,
    num: number,
    key: number,
  ) => {
    let rampTmp = isUpper
      ? JSON.parse(JSON.stringify(upperCheckedBiteramp))
      : JSON.parse(JSON.stringify(lowerCheckedBiteramp))
    if (event.target.checked) {
      // const keys = []
      // for (const item of isUpper ? upperBiteRampData : lowerBiteRampData) {
      //   if (item.num === num) {
      //     keys.push(item.key)
      //   }
      // }
      const addBool = biteRampModule.autoAddBiteRampToTooth(key)
      if (!addBool) {
        return
      }
      rampTmp.push(key)
      // const addBoolMirror = biteRampModule.autoAddBiteRampToTooth(keys[1])
      // if (!addBoolMirror) {
      //   return
      // }
      // rampTmp.push(keys[1])
    } else {
      rampTmp = rampTmp.filter((item) => item !== key)
      biteRampModule.deleteBiteRamp(key)
    }

    isUpper
      ? setUpperCheckedBiteramp(rampTmp)
      : setLowerCheckedBiteramp(rampTmp)
  }

  // change start stage
  const startStageChange = (event) => {
    let startVal: number = event.target.value
    if (startVal > endAt) startVal = endAt
    console.log("🚀 ~ startStageChange ~ startVal:", startVal)

    biteRampModule.changeStartStage(startVal)
    setStartFrom(startVal)
  }
  // change end stage
  const endStageChange = (event) => {
    let endVal: number = event.target.value
    if (endVal < startFrom) {
      setStartFrom(endVal)
      biteRampModule.changeStartStage(endVal)
    }
    console.log("🚀 ~ endStageChange ~ endVal:", endVal)

    biteRampModule.changeEndStage(endVal)
    setEndAt(endVal)
  }
  return (
    <Box width={"404px"} paddingLeft={1}>
      <Stack justifyContent="center" spacing={1} direction={"column"}>
        <Typography>Select teeth to add/remove bite ramp</Typography>

        <Stack spacing={0}>
          <Stack
            direction="row"
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{
              // backgroundColor: "greenyellow",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                bottom: 5,
                left: -5,
                position: "absolute",
              }}
              fontSize={20}
            >
              U
            </Typography>

            <Stack
              justifyContent={"center"}
              direction="row"
              alignItems={"center"}
              alignContent={"center"}
              spacing={-1.8}
            >
              {upperBiteRampData.map((data, index) => {
                return (
                  <FormControlLabel
                    key={data.key}
                    value={data.key}
                    control={
                      <Checkbox
                        sx={{ marginTop: "0" }}
                        checked={upperCheckedBiteramp.includes(data.key)}
                        onChange={(event) => {
                          handleCheckRamp(event, true, data.num, data.key)
                        }}
                        size="small"
                      />
                    }
                    label={data.num}
                    labelPlacement="top"
                  />
                )
              })}
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{
              // backgroundColor: "greenyellow",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                bottom: 5,
                left: -3,
                position: "absolute",
              }}
              fontSize={20}
            >
              L
            </Typography>

            <Stack
              justifyContent={"center"}
              direction="row"
              alignItems={"center"}
              alignContent={"center"}
              spacing={-1.8}
            >
              {lowerBiteRampData.map((data) => {
                return (
                  <Checkbox
                    key={data.key}
                    checked={lowerCheckedBiteramp.includes(data.key)}
                    onChange={(event) => {
                      handleCheckRamp(event, false, data.num, data.key)
                    }}
                    size="small"
                  />
                )
              })}
            </Stack>
          </Stack>
        </Stack>

        <Divider orientation="horizontal" flexItem />
        <Typography>Choose the start and the end stage of bite ramp</Typography>

        <Stack direction="row" justifyContent={"center"} alignItems={"center"}>
          <FormControl sx={{ m: 1, minWidth: 140 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Start from
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={startFrom}
              onChange={startStageChange}
              label="Start from"
            >
              {stageMenuIems.map((val, index) => {
                return (
                  <MenuItem key={val} value={index + 1}>
                    {val}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 140 }}>
            <InputLabel id="demo-simple-select-standard-label">
              End at
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={endAt}
              onChange={endStageChange}
              label="End at"
            >
              {stageMenuIems.map((val, index) => {
                return (
                  <MenuItem key={val} value={index + 1}>
                    {val}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Stack>

        <Stack
          spacing={5}
          direction="row"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={overlayClick}
                disabled={overlayButtonDisable}
                onChange={() => {
                  if (curArch === "all" && !isSingleArch) {
                    setAlert(true)
                    setOverButtonDisable(true)
                    return
                  }
                  setOverlayClick(!overlayClick)
                }}
              />
            }
            label={
              <Typography sx={{ fontSize: 14, whiteSpace: "nowrap" }}>
                Opposing Overlay
              </Typography>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={heightPanel}
                disabled={heightButtonDisable}
                onChange={() => {
                  if (curArch === "all" && !isSingleArch) {
                    setAlert(true)
                    setHeightButtonDisable(true)
                    return
                  }
                  setHeightPanel(!heightPanel)
                }}
              />
            }
            label={
              <Typography sx={{ fontSize: 14, whiteSpace: "nowrap" }}>
                Height Adjustment Panel
              </Typography>
            }
          />
        </Stack>
      </Stack>

      <Dialog open={alert}>
        {/* <DialogTitle></DialogTitle> */}
        <DialogContent>
          Please select a single arch to use this function.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlert(false)
            }}
            sx={{
              textTransform: "uppercase",
            }}
            size="medium"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
