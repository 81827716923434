import {
  Box,
  Button,
  Stack,
  ToggleButton,
  Tooltip,
  useTheme,
} from "@mui/material"
import Correction2 from "../assets/left/correction/2.svg"
import Correction3 from "../assets/left/correction/3.svg"
import Correction4 from "../assets/left/correction/4.svg"
import Correction5 from "../assets/left/correction/5.svg"
import Correction2_2 from "../assets/left/correction/2_2.svg"
import Correction3_3 from "../assets/left/correction/3_3.svg"
import Correction4_4 from "../assets/left/correction/4_4.svg"
import Correction5_5 from "../assets/left/correction/5_5.svg"
import { ReactSVG } from "react-svg"
import { useEffect, useState } from "react"
import { wasmModule, viewControlInTreatment, relabel } from "@/gluelayer"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import {
  setCurArch,
  setStageShowType,
  setOpenOrientation,
  setOpenSpaceCorrection,
  setOpenBiteReg,
  setOpenRelabel,
} from "../udTreatSlice"
import { RootState } from "../../core/app/store"
import { relabelModule } from "@/gluelayer/core/modules/relabel"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
const { setstageShowTypeNormalCallback } = relabel

const CorrectionTool = () => {
  const [sameLabel, setSameLabel] = useState(false)
  const theme = useTheme()

  const [tool, setTool] = useState("")
  const dispatch = useAppDispatch()
  const { curArch, openOrientation } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const handleToolChange = (
    event: React.MouseEvent<HTMLElement>,
    newTool: string,
  ) => {
    if (tool === "Tool4") {
      if (
        relabelModule.IsExistSameLabelForSingleJaw(0) ||
        relabelModule.IsExistSameLabelForSingleJaw(1)
      ) {
        setSameLabel(true)
        return
      }
      setstageShowTypeNormalCallback(() => {
        console.log("setStageShowType, normal start... ")
        dispatch(setStageShowType("normal"))
        console.log("setStageShowType, normal end... ")
      })
    }
    if (tool === newTool) {
      setTool("")
      return
    }
    setTool(newTool)
  }

  useEffect(() => {
    return () => {
      if (!wasmModule.isInit) return
      wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
      wasmModule.moduleManager.ToggleSpaceCorrect(false)
      relabelModule.openRelabelModule(false)
      dispatch(setOpenBiteReg(false))

      dispatch(setStageShowType("normal"))

    }
  }, [])

  useEffect(() => {
    if (!wasmModule.isInit) return
    if (!openOrientation) {
      wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
      if (tool === "Orientation") {
        setTool("")
      }
    } else {
      viewControlInTreatment.setViewType("front")
      wasmModule.moduleManager.SwitchModuleOrientationAdjustment(true)
    }

    setShowSaveButton(openOrientation)
  }, [openOrientation])

  useEffect(() => {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.SwitchModuleOrientationAdjustment(false)
    dispatch(setOpenOrientation(false))
    wasmModule.moduleManager.ToggleSpaceCorrect(false)
    dispatch(setOpenSpaceCorrection(false))
    relabelModule.openRelabelModule(false)
    dispatch(setOpenRelabel(false))
    if (!relabelModule.IsRelabelModuleOpened()) {
      dispatch(setStageShowType("normal"))
    }
    dispatch(setOpenBiteReg(false))

    // -------test code---------------
    //just for test BiteReg
    if (tool === "Tool1") {
      dispatch(setOpenBiteReg(true))
    }
    //--------------------------------

    if (tool === "Orientation") {
      dispatch(setOpenOrientation(true))
      setShowSaveButton(true)
      return
    }
    if (tool === "Space Correction") {
      dispatch(setStageShowType(""))
      if (curArch === "all") dispatch(setCurArch("up"))
      wasmModule.moduleManager.ToggleSpaceCorrect(true)
      dispatch(setOpenSpaceCorrection(true))
      return
    }
    if (tool === "Tool4") {
      const relabel = async () => {
        viewControlInTreatment.zoomWithValueInTreatment(1)
        //if(wasmModule.moduleManager.GetSplitScreenModule() === null) {
          //if (curArch === "all") await dispatch(setCurArch("up"))
        //}
        if (curArch === "all") await dispatch(setCurArch("up"))
        relabelModule.openRelabelModule(true)
        if ((window as any).pulloutCallback()) {
          ;(window as any).pulloutCallback()
        }
        await dispatch(setStageShowType(""))
        if (relabelModule.IsRelabelModuleOpened()) {
          await dispatch(setOpenRelabel(true))
          if((window as any).treatment.RelabelViewZoomCallback) {
            ;(window as any).treatment.RelabelViewZoomCallback(relabelModule.zoomValue)
          }
        }
      }

      relabel()
      return
    }
  }, [tool])

  const [showSaveButton, setShowSaveButton] = useState(false)

  const saveButton = showSaveButton ? (
    <Button
      variant="contained"
      color="primary"
      sx={{
        position: "absolute",
        top: "30%",
        left: 164,
        // transform: "translateX(-50%)",
        zIndex: 1100,
      }}
      onClick={() => {
        const oriModule =
          wasmModule.moduleManager.GetModuleOrientationAdjustment()
        oriModule.SaveOrientation()
      }}
    >
      Save
    </Button>
  ) : null

  return (
    <>
      <Box
        sx={{
          left: 84,
          top: 104,
          borderRadius: "8px",
          backgroundColor: theme.transBkColor?.light,
          maxHeight: `calc(100% - 114px)`,
          boxShadow: 6,
          overflow: "hidden",
          position: "relative",
          zIndex: 1000,
          width: "150px",
        }}
      >
        <Stack spacing={1} direction="row">
          <Tooltip
            onClick={(e) => handleToolChange(e, "Tool1")}
            title="Tool1"
            placement="top"
          >
            <ToggleButton
              sx={{
                border: "none",
                width: "240px",
                height: "80px",
              }}
              value={"Tool1"}
            >
              <ReactSVG
                style={{ width: "100%", margin: "auto" }}
                src={tool === "Tool1" ? Correction2_2 : Correction2}
              />
            </ToggleButton>
          </Tooltip>
          <Tooltip
            onClick={(e) => handleToolChange(e, "Space Correction")}
            title="Space Correction"
            placement="top"
          >
            <ToggleButton
              sx={{ border: "none", width: "240px", height: "80px" }}
              value={"Space Correction"}
            >
              <ReactSVG
                style={{ width: "100%", margin: "auto" }}
                src={tool === "Space Correction" ? Correction3_3 : Correction3}
              />
            </ToggleButton>
          </Tooltip>
        </Stack>
        <Stack spacing={1} direction="row">
          <Tooltip
            onClick={(e) => handleToolChange(e, "Orientation")}
            title="Orientation"
            placement="bottom"
          >
            <ToggleButton
              sx={{ border: "none", width: "240px", height: "80px" }}
              value={"Orientation"}
            >
              <ReactSVG
                style={{ width: "100%", margin: "auto" }}
                src={openOrientation ? Correction4_4 : Correction4}
              />
            </ToggleButton>
          </Tooltip>
          <Tooltip
            onClick={(e) => handleToolChange(e, "Tool4")}
            title="Tool4"
            placement="bottom"
          >
            <ToggleButton
              sx={{ border: "none", width: "240px", height: "80px" }}
              value={"Tool4"}
            >
              <ReactSVG
                style={{ width: "100%", margin: "auto" }}
                src={tool === "Tool4" ? Correction5_5 : Correction5}
              />
            </ToggleButton>
          </Tooltip>
        </Stack>
      </Box>

      <Dialog open={sameLabel}>
        <DialogTitle id="alert-dialog-title">{"uDesign"}</DialogTitle>
        <DialogContent>{"There are duplicates,please relabel!"}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSameLabel(false)}
            autoFocus
            sx={{ textTransform: "samelabel" }}
            size="medium"
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {saveButton}
    </>
  )
}

export default CorrectionTool
