import { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  useTheme,
} from "@mui/material"
import { RootState } from "@/core/app/store"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"

import { caseManagement, wasmModule } from "@/gluelayer"

import { resetCase } from "@/core/app/slices/clinical/clinicalSlice"

import { useMyNavigation } from "@/hooks/useMyNavigation"

export const UDOpenCase = ({ open, onClose, switchEditCallBack }) => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const { isShowProgress, ProgressMsg } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  const [alert, setAlert] = useState(false)
  const navigate = useMyNavigation()
  const { openMode } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  // Get Treatment Infos and zip file names
  // useEffect(() => {
  //   console.log("getTreatmentInfos ::")
  //   dispatch(setCurCaseMode("WeDesign"))
  //   dispatch(setCurLeftPage(""))
  //   stagingManager.clearWasmStageData()
  //   dispatch(setStageShowType(""))
  //   dispatch(setOpenHistorySplitView(false))
  //   caseManagement.closeCase()
  //   dispatch(resetCase())
  //   dispatch(setSwitchViewEdit(false))
  //   dispatch(setIsCasePreview(true)) // default is preview only
  //   setAlert(false)

  //   // Get zip file names
  //   dispatch(
  //     fetchFileList({
  //       patientId,
  //       caseId,
  //     }),
  //   )
  //   dispatch(fetchClinicalSettings())

  //   dispatch(setRefinementReady(false))
  //   // dispatch(setIsShowProgress(true))
  //   // dispatch(setProgressMsg("Start download case..."))

  //   return () => {
  //     dispatch(resetCase())
  //     dispatch(setIsShowProgress(false))
  //   }
  // }, [])

  // const isZipFilesReady = (zips) => {
  //   return zips && Object.keys(zips).length > 1
  // }

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetCase())
  //   }
  // }, [])

  useEffect(() => {
    if (openMode === "needdopresetupbyudesign") {
      setAlert(true)
    }
  }, [openMode])

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isShowProgress}
    >
      <Box id={"PrograssBar"} sx={{ width: "40%" }}>
        {ProgressMsg}
        <LinearProgress />
      </Box>

      <Dialog
        sx={{
          width: 510,
          height: 330,
          alignSelf: "center",
          justifySelf: "center",
        }}
        open={alert}
      >
        <DialogTitle
          sx={{
            fontsize: "20px",
            fontstyle: "normal",
            fontweight: 500,
            lineheight: "160%",
            letterspacing: " 0.15px",
          }}
        >
          Self-planned cases must complete pre-setup in uDesign 10
        </DialogTitle>
        <DialogContent
          sx={{
            fontsize: "16px",
            fontstyle: "normal",
            fontweight: 400,
            lineheight: "150%",
            letterspacing: "0.15px",
          }}
        >
          To view or modify a self planned case in uDesign Cloud, you must first{" "}
          <strong>open the case in uDesign desktop software</strong> and
          complete tooth identification and segmentation.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlert(false)
              navigate(-1)
            }}
            sx={{
              textTransform: "uppercase",
            }}
            size="medium"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Backdrop>
  )
}
