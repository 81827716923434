import { createSlice } from "@reduxjs/toolkit"
import { find } from "lodash"
import {
  downloadFiles,
  requestCloudPresetup,
  testForLoading,
  uploadFiles,
} from "@/core/app/slices/v2/v2apis"

import { getCaseById } from "@/core/app/slices/case/caseThunkApi"

import {
  fetchFileList,
  fetchFileZips,
  fetchRefinementJsonFile,
  fetchRefinementZipFile,
  submitRefinementFiles,
  forcelockCaseApi,
  getIsReadApi,
  getMessageApi,
  getTreatmentInfos,
  lockCaseApi,
  postMessageApi,
  postModiApi,
  unlockCaseApi,
  updateMessageApi,
  uploadCaseZips,
  initializeWASM,
  fetchZipsFromCacheOrRemote,
} from "./clinicalThunkApi"
import { fetchZipsAndLoadCase } from "./clinicalworkflow"
import { hasFileHistoryChanged } from "@/modules/Patient/PatientList/util/commonUtil"
export const FILEZIPS = [
  "bulk0",
  `bulk1`,
  `bulk2`,
  `bulk10`,
  "storage.version.json",
  "raw",
  "photo",
]

export type OpenModeType =
  | "setup"
  | "preview"
  | "inpresetup"
  | "needdopresetupbyudesign"
  | null

interface clinicalType {
  /**
   * the case mode for loading
   */
  openMode: OpenModeType
  /**
   * cache the fileinfo list from server
   */
  fileList: object[]
  /**
   * cache all file names
   */
  fileNames: string[]
  /**
   * cache all zip names
   */
  zipNames: string[] | null
  refinementFiles: string[] | null
  refinementZips: object
  zipList: object
  treatmentList: []
  fileListReady: boolean
  isShowProgress: boolean
  refinementReady: boolean
  ProgressMsg: string
  messageList: object[]
  historyList: object[]
  isReadMessage: boolean
  isScansRendered: boolean
  caseArch: "both" | "upper" | "lower"
  upperType: "R" | "A" | "--"
  lowerType: "R" | "A" | "--"
  isLoading: "idle" | "pending" | "complete" | "failed"
  isWasmLoading: "idle" | "pending" | "complete" | "failed"
  /**
   * used to check if the zip file has changed
   * <caseId,fileHistory>
   *  fileHistory: {filename:string,uploadtime:string}[]
   */
  fileHistory: Record<string, { name: string; time: string }[]>
  isCaseFilesUpdated: boolean
}
const initState: clinicalType = {
  openMode: null,
  fileList: [],
  fileNames: [],
  zipNames: [],
  refinementFiles: [],
  refinementZips: {},
  zipList: {},
  treatmentList: [],
  isShowProgress: false,
  ProgressMsg: "",
  refinementReady: false,
  fileListReady: false,
  isScansRendered: false,
  messageList: [],
  historyList: [],
  isReadMessage: true,
  caseArch: "both",
  upperType: "--",
  lowerType: "--",
  isLoading: "idle",
  isWasmLoading: "idle",
  fileHistory: {},
  isCaseFilesUpdated: false,
}
export const clinicalServiceSlice = createSlice({
  name: "clinicalService",
  initialState: initState,
  reducers: {
    setIsScansRendered: (state, action) => {
      state.isScansRendered = action.payload
    },
    setIsShowProgress: (state, action) => {
      state.isShowProgress = action.payload
      if (!state.isShowProgress) {
        state.ProgressMsg = ""
      }
    },
    setProgressMsg: (state, action) => {
      state.ProgressMsg = action.payload
    },
    setRefinementReady: (state, action) => {
      state.refinementReady = action.payload
    },
    resetCase(state) {
      const newState = {
        ...initState,
        
        isScansRendered: state.isScansRendered,
        // v2 don't clear fileHistory to cache the case file history
        fileHistory: state.fileHistory,
        isCaseFilesUpdated: state.isCaseFilesUpdated,
      }
      return newState
    },
    setData(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
    setOpenMode<s>(state, { payload }: { payload: OpenModeType }) {
      state.openMode = payload
    },
    // New processes dedicated to data cleansing
    cleanUpDataforNew: (state, action) => {
      // clear all
      if (action === "all") {
        state.zipNames = []
        state.zipList = {}
      }
    },
  },
  extraReducers: (builder) => {
    /**
     * loading case
     */
    builder.addCase(fetchZipsAndLoadCase.pending, (state, action: any) => {
      state.isShowProgress = true
    }),
      builder.addCase(fetchZipsAndLoadCase.fulfilled, (state, action: any) => {
        state.isShowProgress = false
      }),
      builder.addCase(fetchZipsAndLoadCase.rejected, (state, action: any) => {
        state.isShowProgress = false
      }),
      builder.addCase(
        fetchZipsFromCacheOrRemote.fulfilled,
        (state, action: any) => {
          if (action.error) {
            console.warn("fetchZipsFromCacheOrRemote error", action)
            return
          }
          const payload = action.payload
          state.zipList = { ...payload }
        },
      ),
      builder.addCase(initializeWASM.rejected, (state) => {
        state.isWasmLoading = "failed"
      }),
      builder.addCase(initializeWASM.pending, (state) => {
        state.isWasmLoading = "pending"
      }),
      builder.addCase(initializeWASM.fulfilled, (state) => {
        state.isWasmLoading = "complete"
      }),
      // builder.addCase(requestCloudPresetup.rejected,(state)=>{
      //   state.isLoading = "failed"
      // }),
      // builder.addCase(requestCloudPresetup.pending,(state)=>{
      //   state.isLoading = "pending"
      // }),
      // builder.addCase(requestCloudPresetup.fulfilled,(state)=>{
      //   state.isLoading = "complete"
      // }),
      builder.addCase(uploadFiles.pending, (state) => {
        state.isLoading = "pending"
      }),
      builder.addCase(uploadFiles.fulfilled, (state) => {
        state.isLoading = "complete"
      }),
      builder.addCase(uploadFiles.rejected, (state) => {
        state.isLoading = "failed"
      }),
      builder.addCase(testForLoading.pending, (state) => {
        state.isLoading = "pending"
      }),
      //   builder.addCase(downloadFiles.fulfilled, (state, action) => {
      //     const { payload } = action
      //     if (payload.status === "Success") {
      //       for (let i = 0; i < state.zipNames.length; i++) {
      //         const name = state.zipNames[i] as string
      //         state.zipList[name] = payload.files[i]
      //       }
      //       state.isLoading = "complete"
      //     }
      //   }),
      //   builder.addCase(downloadFiles.rejected, (state) => {
      //     state.isLoading = "failed"
      //   })
      // builder.addCase(downloadFiles.pending, (state) => {
      //   state.isLoading = "pending"
      // })
      builder.addCase(fetchFileList.pending, (state) => {
        console.log("pending")
        state.isLoading = "pending"
        state.ProgressMsg = "Check file list ..."
      })
    builder.addCase(fetchFileList.fulfilled, (state, action) => {
      if (action.payload.status === "Success") {
        state.fileListReady = true
        state.isLoading = "complete"
        const res = action.payload?.result
        if (res?.length) {
          const caseId = action.meta.arg.caseId
          const fileHisotry = state.fileHistory[caseId]
          const filteredFiles = res
            .filter((file) => file.attachment_type === "cases")
            .map((file) => ({
              name: file.file_name,
              time: file.updated_date,
            }))
          if (action.meta.arg.checkFileUpdates) {
            const isCaseFilesUpdated = hasFileHistoryChanged(
              fileHisotry,
              filteredFiles,
            )
            if (isCaseFilesUpdated === true) {
              state.fileHistory[caseId] = filteredFiles
            }
            state.isCaseFilesUpdated = isCaseFilesUpdated
            return
          }
        }

        const { result } = action.payload
        state.fileNames = []
        // state.zipList = {}
        state.fileList = result
        let error = ""
        const download_fileNames = action.meta.arg?.fileNames || FILEZIPS
        for (const { file_name } of result) {
          state.fileNames.push(file_name)
        }
        state.zipNames = []
        for (const name of download_fileNames) {
          if (!state.fileNames.includes(`${name}.zip`)) {
            error = `not find ${name}.zip`
          } else {
            state.zipNames.push(name)
          }
        }

        state.refinementFiles = []
        if (!state.fileNames.includes(`RetouchHistory.json`)) {
          error = `not find RetouchHistory.json`
        } else {
          state.refinementFiles.push(`RetouchHistory.json`)
        }
      } else {
        // failed, for example, it's empty case.
        console.log("fetchFileList is fullfilled but failed")
        state.isLoading = "failed"
      }
      state.ProgressMsg = "Check file list successfully"
    })
    builder.addCase(fetchFileList.rejected, (state) => {
      console.log("fetchFileList is rejected")
      state.isLoading = "failed"
      state.ProgressMsg = "Check file list failed"
    })
    builder.addCase(fetchFileZips.pending, (state) => {
      console.log("pending")
      state.isLoading = "pending"
      state.ProgressMsg = "Download Case files ..."
    })
    builder.addCase(fetchFileZips.fulfilled, (state, action) => {
      const { payload } = action
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        const data = item.data
        const respUrl = item.request.responseURL
        const fileName = respUrl.split("/").pop()
        state.zipList[fileName] = data
      }
      state.isLoading = "complete"
      state.ProgressMsg = "Download Ok ..."
    })
    builder.addCase(fetchFileZips.rejected, (state) => {
      console.log("rejected")
      state.isLoading = "failed"
      state.ProgressMsg = "Download False ..."
    })
    builder.addCase(fetchRefinementJsonFile.fulfilled, (state, action) => {
      const { payload } = action
      for (let i = 0; i < state.refinementFiles.length; i++) {
        const name = state.refinementFiles[i] as string
        state.zipList["RetouchHistory/" + name] = payload[i].data
      }
    })
    builder.addCase(fetchRefinementZipFile.fulfilled, (state, action) => {
      const { payload } = action
      state.refinementZips = []
      for (let i = 0; i < payload.length; i++) {
        state.refinementZips["RetouchHistoryZip" + i] = payload[i].data
      }
    })
    builder.addCase(fetchRefinementZipFile.rejected, (state) => {
      console.log("rejected")
      state.refinementZips = []
    })
    builder.addCase(submitRefinementFiles.fulfilled, (state, action) => {
      const { payload } = action
    })
    builder.addCase(getTreatmentInfos.pending, (state) => {
      console.log("pending")
    })
    builder.addCase(getTreatmentInfos.fulfilled, (state, action) => {
      const { payload } = action
      state.treatmentList = payload.result
      console.log("treatmentlist:::", state.treatmentList)
    })
    builder.addCase(getTreatmentInfos.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(uploadCaseZips.pending, (state) => {
      state.isLoading = "pending"
    })
    builder.addCase(uploadCaseZips.fulfilled, (state, action) => {
      const {
        payload,
        meta: {
          arg: { callback, zips },
        },
      } = action
      const zipList = {}
      for (const { file, fileName } of zips) {
        zipList[fileName] = file
      }
      if (zips.length !== 1) {
        state.zipList = zipList
      }
      const filteredFiles = payload.map((item) => ({
        name: item.data.result.file_name,
        time: item.data.result.updated_date,
      }))
      const caseId = action.meta.arg.caseId
      const fileHisotry = state.fileHistory[caseId]
      const isCaseUpdated = hasFileHistoryChanged(fileHisotry, filteredFiles)
      if (isCaseUpdated && fileHisotry) {
        const updatedFilesMap = new Map(
          filteredFiles.map((item) => [item.name, item]),
        )
        const newFileHistory = fileHisotry.map((file) => {
          return updatedFilesMap.has(file.name)
            ? updatedFilesMap.get(file.name)
            : file
        })
        state.fileHistory[caseId] = newFileHistory
      }
      state.isLoading = "complete"
      callback && callback()
    })
    builder.addCase(uploadCaseZips.rejected, (state) => {
      console.log("rejected")
      state.isLoading = "failed"
    })
    builder.addCase(postMessageApi.fulfilled, (state, action) => {
      const {
        payload: { result, status },
        meta: { arg: cb },
      } = action
      setTimeout(() => {
        cb?.cb && cb.cb()
      }, 0)
    })
    builder.addCase(postMessageApi.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(postModiApi.fulfilled, (state, action) => {
      const {
        payload: { data },
        meta: { arg: cb },
      } = action

      setTimeout(() => {
        cb?.cb && cb.cb()
      }, 0)
    })
    builder.addCase(postModiApi.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(getMessageApi.fulfilled, (state, action) => {
      const {
        payload: {
          results: {
            current_thread,
            previous_thread,
            users_avatar,
            unread_messages,
          },
          status,
        },
      } = action
      const current = [],
        previous = []

      current_thread &&
        current_thread.map(
          ({
            created_at = "",
            body,
            message_type,
            id,
            plan_name,
            created_by,
            button_enable,
          }) => {
            const {
              subject = "",
              content = "",
              // sender: { firstname, lastname, avatar_link, avatar_color, title },
            } = JSON.parse(body)
            const {
              first_name: firstname,
              last_name: lastname,
              avatar_link,
              avatar_color,
              title_name: { type_name: title },
            } = find(users_avatar, ["id", created_by])
            current.push({
              firstname,
              lastname,
              time: created_at,
              photo: avatar_link === "-" ? "" : avatar_link,
              color: avatar_color,
              role: title.toUpperCase(),
              messageStatus: message_type,
              message: { title: plan_name, text: content, id, subject },
              button_enable: button_enable,
            })
          },
        )
      previous_thread &&
        previous_thread.map(
          ({ created_at = "", body, message_type, id, plan_name }) => {
            const {
              subject = "",
              content = "",
              sender: { firstname, lastname, avatar_link, avatar_color, title },
            } = JSON.parse(body)
            previous.push({
              firstname,
              lastname,
              time: created_at,
              photo: avatar_link === "-" ? "" : avatar_link,
              color: avatar_color,
              role: title.toUpperCase(),
              messageStatus: message_type,
              message: { title: plan_name, text: content, id, subject },
            })
          },
        )
      state.messageList = [...current]
      state.historyList = [...previous]
    })
    builder.addCase(getMessageApi.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(getIsReadApi.fulfilled, (state, action) => {
      const {
        payload: {
          results: { unread_message = false },
        },
      } = action
      state.isReadMessage = !unread_message
    })
    builder.addCase(getIsReadApi.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(getCaseById.fulfilled, (state, action) => {
      const {
        payload: { udesign_category },
      } = action
      // there is only one caseInfo,It's caseDetail in caseSlice
      //  so remove caseInfo from here.
      if (udesign_category) {
        const [UpperType, LowerType] = udesign_category.split("/")
        state.upperType = UpperType
        state.lowerType = LowerType
        if (UpperType.includes("--")) {
          state.caseArch = "lower"
          return
        }
        if (LowerType.includes("--")) {
          state.caseArch = "upper"
          return
        }
        state.caseArch = "both"
      } else {
        state.caseArch = "both"
      }
    })
    builder.addCase(getCaseById.rejected, (state) => {
      console.log("rejected")
    })
    builder.addCase(lockCaseApi.pending, (state) => {
      console.log("pending")
      state.isLoading = "pending"
    })
    builder.addCase(lockCaseApi.fulfilled, (state, action) => {
      state.isLoading = "complete"
    })
    builder.addCase(lockCaseApi.rejected, (state) => {
      console.log("rejected")
      state.isLoading = "failed"
    })
    builder.addCase(unlockCaseApi.pending, (state) => {
      console.log("pending")
      state.isLoading = "pending"
    })
    builder.addCase(unlockCaseApi.fulfilled, (state, action) => {
      const { payload } = action
      state.isLoading = "complete"
    })
    builder.addCase(unlockCaseApi.rejected, (state) => {
      console.log("rejected")
      state.isLoading = "failed"
    })
    builder.addCase(forcelockCaseApi.pending, (state) => {
      console.log("pending")
      state.isLoading = "pending"
    })
    builder.addCase(forcelockCaseApi.fulfilled, (state, action) => {
      const { payload } = action
      state.isLoading = "complete"
    })
    builder.addCase(forcelockCaseApi.rejected, (state) => {
      console.log("rejected")
      state.isLoading = "failed"
    })
    builder.addCase(updateMessageApi.rejected, (state) => {
      state.isLoading = "failed"
    })
    builder.addCase(updateMessageApi.fulfilled, (state, action) => {
      state.isLoading = "complete"
      const {
        meta: { arg: cb },
      } = action

      setTimeout(() => {
        cb?.cb && cb.cb()
      }, 0)
    })
    builder.addCase(updateMessageApi.pending, (state) => {
      state.isLoading = "pending"
    })
  },
})
export const {
  resetCase,
  setIsScansRendered,
  setIsShowProgress,
  setProgressMsg,
  setRefinementReady,
  setData: setClinicalData,
  setOpenMode,
  cleanUpDataforNew,
} = clinicalServiceSlice.actions
