import { useEffect, useState } from "react"
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Grid,
  IconButton,
  Stack,
  Button,
} from "@mui/material"
import PlusIcon from "@mui/icons-material/Add"
import PlusPlusIcon from "@mui/icons-material/AddCircleOutline"
import MinusIcon from "@mui/icons-material/Remove"
import MinusMinusIcon from "@mui/icons-material/RemoveCircleOutline"
import { string } from "prop-types"
export const UDTitleSelect = (props) => {
  return (
    <>
      <Grid display="flex" justifyItems="center" item xs={5}>
        <Typography variant="body1">{props.title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={7}>
        <UDSelectOptions {...props} />
      </Grid>
    </>
  )
}

export const UDTitleButtons = (props) => {
  return (
    <>
      <Grid display="flex" justifyItems="center" item>
        <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "16px"}}>{props.title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item>
        <Stack spacing={2} direction="row" flexWrap="wrap">
          {props.options.map((option, index) => (
            <Button
              size="small"
              variant="outlined"
              key={index}
              value={index}
              sx={{ 
                textTransform: "none", 
                width: props.wrap? "55px" : "auto", 
                height: props.wrap? "46px" : "30px", 
                fontSize: "13px", 
                whiteSpace: "pre-wrap", 
                fontWeight: 600, 
                color: "rgba(0, 0, 0, 0.6)",
                backgroundColor: "rgba(240, 240, 240, 1)",
                boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.15)",
                border: "none",
                "&:hover": {
                  border: "none",
                },
              }}
              onClick={() => {
                props.onClick(index)
              }}
            >
              {option}
            </Button>
          ))}
        </Stack>
      </Grid>
    </>
  )
}
export const UDTitleSelectOptions = (props) => {
  const [value, setValue] = useState(0)
  useEffect(() => {
    setValue(0)
  }, [])
  const handleChange = (event) => {
    setValue(event.target.value)
    props.onChangeSel?.(event.target.value)
  }
  return (
    <>
      <Grid display="flex" justifyItems="center" item xs={5}>
        <Typography variant="body1">{props.title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={7}>
        <Select
          sx={{ height: 35 }}
          value={value}
          onChange={handleChange}
          label={props.label}
          displayEmpty
        >
          {props.options.map((option, index) => (
            <MenuItem key={index} value={index}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </>
  )
}
export const UDSelectOptions = (props) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    props.onChangeSel?.(value)
  }, [value])
  useEffect(() => {
    setValue(props.options[0])
  }, [])
  const handleChange = (event) => {
    setValue(event.target.value)
  }
  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 100 }}>
      <Select
        sx={{ height: 35 }}
        value={value}
        onChange={handleChange}
        label={props.label}
        displayEmpty
      >
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const UDTitlePlusMinus = ({
  title,
  value,
  deltaSmall,
  deltaLarge,
  onChange,
  size = "medium",
}) => {
  const small = size === "small"
  return (
    <>
      <Grid
        display="flex"
        justifyItems="center"
        item
        xs={size === "small" ? 4 : 5}
      >
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 1.5 : 1}>
        <IconButton onClick={() => onChange(value - deltaLarge)}>
          <MinusMinusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 1.5 : 1}>
        <IconButton onClick={() => onChange(value - deltaSmall)}>
          <MinusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 2 : 3}>
        <Typography variant="body1">{value.toFixed(2)}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 1.5 : 1}>
        <IconButton onClick={() => onChange(value + deltaSmall)}>
          <PlusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 1.5 : 1}>
        <IconButton onClick={() => onChange(value + deltaLarge)}>
          <PlusPlusIcon />
        </IconButton>
      </Grid>
    </>
  )
}

export const UDTitlePlusMinus2 = ({
  title,
  value,
  deltaSmall,
  deltaLarge,
  onChange,
  size,
}) => {
  return (
    <>
      <Grid display="flex" justifyItems="center" item xs={size / 4}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={size / 8}>
        <IconButton onClick={() => onChange(value - deltaLarge)}>
          <MinusMinusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={size / 8}>
        <IconButton onClick={() => onChange(value - deltaSmall)}>
          <MinusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={size / 8}>
        <Typography variant="body1" justifyItems="center">
          {value.toFixed(2)}
        </Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={size / 8}>
        <IconButton onClick={() => onChange(value + deltaSmall)}>
          <PlusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={size / 8}>
        <IconButton onClick={() => onChange(value + deltaLarge)}>
          <PlusPlusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={size / 8}></Grid>
    </>
  )
}
