import { wasmModule } from "../wasm/wasmModule"

class ResegmentationModule {
  wasmResegmentationModule = null

  openResegmentationModule(isOpen: boolean) {
    if (!wasmModule.isInit) return

    console.log(isOpen ? "openResegmentationModule" : "closeResegmentationModule")

    wasmModule.moduleManager.ToggleResegmentation(isOpen)
    this.wasmResegmentationModule = wasmModule.moduleManager.GetResegmentationModule()

    if (this.wasmResegmentationModule === null) {
      console.log("wasmResegmentationModule is null")
    } else {
      console.log("wasmResegmentationModule is not null")
    }
  }

  isResegmentationModuleOpened() {
    this.wasmResegmentationModule = wasmModule.moduleManager.GetResegmentationModule()
    const opened = this.wasmResegmentationModule !== null
    console.log(`wasmResegmentationModule is ${opened ? "opened" : "closed"}`)
    return opened
  }
}

export const resegmentationModule = new ResegmentationModule()