import { FC, lazy, Suspense, useEffect } from "react"
import { matchPath, Outlet, useLocation } from "react-router-dom"

import { ULoading } from "@/components"

import { CssBaseline, Grid } from "../../components/mui.components"
import { useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"
import { isTokenValid } from "../../core/config/okta.config"
import { useMyNavigation } from "../../hooks/useMyNavigation"

import Footer from "./Footer/Footer"
import Header from "./Header/Header"
import CsHeader from "./Header/CsHeader"

const AlertBanner = lazy(
  () => import("../../components/AlertBanner/AlertBanner"),
)

const MainLayout: FC = () => {
  const location = useLocation()
  const navigate = useMyNavigation()
  const isLogin = isTokenValid()
  const { user, systemBannerMessage, marketBannerMessage } = useAppSelector(
    (state: RootState) => state.userService,
  )
  useEffect(() => {
    const destinationPath = `${
      location.pathname + location.search + location.hash
    }`
    if (isLogin) {
      if (location.pathname.includes("/auth") || location.pathname === "/") {
        navigate(user.isAllowToViewAllOrg ? "/organization" : "/patients")
      }
    } else {
      if (
        !location.pathname.includes("/auth") &&
        destinationPath != "/" &&
        destinationPath != "/patients" &&
        destinationPath != "/organization"
      ) {
        navigate(
          `/auth/login?redirectTo=${encodeURIComponent(destinationPath)}`,
          {
            replace: true,
          },
        )
      } else {
        navigate(`/auth/login`, {
          replace: true,
        })
      }
    }
  }, [location, user])
  return (
    <>
      {isLogin && (
        <>
          <CssBaseline />
          {systemBannerMessage?.id && (
            <Suspense fallback={<ULoading isLoading={true} />}>
              <AlertBanner
                bannerInfo={systemBannerMessage}
                isMarketingBanner={false}
              />
            </Suspense>
          )}
          {!systemBannerMessage?.id && marketBannerMessage?.id && (
            <Suspense fallback={<ULoading isLoading={true} />}>
              <AlertBanner
                bannerInfo={marketBannerMessage}
                isMarketingBanner={true}
              />
            </Suspense>
          )}
          {user.isAllowToViewAllOrg && <CsHeader />}
          {((user.isAllowToViewAllOrg &&
            user.current_orgId !== user.my_orgId &&
            !matchPath("/organization", location.pathname)) ||
            !user.isAllowToViewAllOrg) &&
            !matchPath(
              "/clinical/patient/:patientId/case/:caseId/*",
              location.pathname,
            ) && <Header />}

          <Grid
            sx={{
              background: "#F7F8F9",
              flexGrow: 1,
            }}
            container
            component={"main"}
          >
            <Outlet />
          </Grid>
          {!location.pathname.includes("/clinical/") && (
            <>
              <Footer />
              {systemBannerMessage?.id && marketBannerMessage?.id && (
                <Suspense fallback={<ULoading isLoading={true} />}>
                  <AlertBanner
                    bannerInfo={marketBannerMessage}
                    isMarketingBanner={true}
                  />
                </Suspense>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default MainLayout
