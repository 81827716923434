import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import Slider from "react-slick"

import stl from "../../assets/images/cube-outline.svg"
import zoomOut from "../../assets/svgs/arrow-expand-all.svg"
import { UImagePreview } from "../../components"
import { Box, IconButton } from "../../components/mui.components"
import Preview from "../../components/Preview/preview"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { getCaseById } from "../../core/app/slices/case"
import { fetchFileZips } from "../../core/app/slices/clinical/clinicalThunkApi"
import { downloadPhotographs } from "../../core/app/slices/records/photograph"
import {
  FileItem,
  PhotoObject,
} from "../../core/app/slices/records/photograph/photograph.type"
import { setDownloadStatus } from "../../core/app/slices/records/photograph/photographSlice"
import { downloadXray } from "../../core/app/slices/records/xrays"
import { RootState } from "../../core/app/store"
import { getFiles } from "../../core/utils/commonMethod"
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "../../ui-component/mui.icons"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./photoList.scss"
import {
  downloadPhotoJSON,
  downloadPhotographs1_1,
} from "@/core/app/slices/records/photograph/photographThunkApi"
import { setClinicalData } from "@/core/app/slices/clinical/clinicalSlice"
import { isEmpty } from "lodash"
interface ArrowProps {
  onClick: () => void
  currentSlide: number
  slideCount: number
}

const NextArrow: FC<ArrowProps> = ({ onClick, currentSlide, slideCount }) => {
  return (
    <Box component={"div"} className="next-arrow">
      <IconButton
        onClick={onClick}
        disabled={currentSlide + 3 === slideCount}
        sx={{
          position: "absolute",
          right: "21px",
          top: "50%",
          transform: "translateY(-50%)",
          p: 0,
        }}
      >
        <KeyboardArrowRight
          fontSize="large"
          sx={{ height: "35px", width: "35px" }}
        />
      </IconButton>
    </Box>
  )
}

const PrevArrow: FC<ArrowProps> = ({ onClick, currentSlide }) => {
  return (
    <Box component={"div"} className="prev-arrow">
      <IconButton
        onClick={onClick}
        disabled={currentSlide === 0}
        sx={{
          position: "absolute",
          left: "34px",
          top: "50%",
          transform: "translateY(-50%)",
          p: 0,
        }}
      >
        <KeyboardArrowLeft
          fontSize="large"
          sx={{ height: "35px", width: "35px" }}
        />
      </IconButton>
    </Box>
  )
}

interface PhotoListProps {
  setSelectedPreview?: (value: string) => void
  isRefinement?: boolean
  upperArchType?: number
  lowerArchType?: number
}

const PhotoList: FC<PhotoListProps> = ({
  setSelectedPreview,
  isRefinement,
  upperArchType,
  lowerArchType,
}) => {
  const { t } = useTranslation("common")
  const [prevImage, setPrevImage] = useState<File[]>([])
  const [selectedImage, setSelectedImage] = useState<string>("")
  const [isStlPreview, setIsstlPreview] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<number>(-1)
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const { zipList, zipNames } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const { compositePhoto, individualPhotos, additionalPhotos, fileList } =
    useAppSelector((state: RootState) => state.PhotosService)
  const { pano, ceph, otherXrays } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const handleImageClick = async (image: string, index: number) => {
    if (index === -1) {
      setIsstlPreview(true)
      setPrevImage([])
    } else {
      const response = await fetch(image)
      const blob = await response.blob()
      const file = new File([blob], `Image`, { type: blob.type })
      setSelectedImage(image)
      setPrevImage([file])
      setIsstlPreview(false)
    }
    setCurrentImage(index)
  }

  useEffect(() => {
    setIsstlPreview(true)
    if (isRefinement && caseDetail.id + "" !== caseId) {
      dispatch(getCaseById({ patientId, caseId }))
    }
  }, [])

  useEffect(() => {
    if (!zipNames.length) {
      dispatch(setClinicalData({ zipNames: ["bulk2", "raw"] }))
    }
    if (zipNames.length && isEmpty(zipList)) {
      dispatch(
        fetchFileZips({
          patientId,
          caseId,
          zipNames,
        }),
      )
    }
  }, [zipNames])

  // useEffect(() => {
  //   console.log('here')
  //   dispatch(fetchFileList({ patientId, caseId }))
  //   if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
  // }, [dispatch])

  useEffect(() => {
    let downloadTotal = 0
    const downloadFiles = (
      item: FileItem,
      totalFilesToDownload: number,
      attachmentType: string,
    ) => {
      const { file_name, original_file_name, sequence, photo_position } = item

      dispatch(setDownloadStatus("pending"))

      const fn =
        attachmentType === "photographs"
          ? downloadPhotographs({
              patientId: patientId,
              caseId: caseId,
              fileName: file_name,
              photographType: file_name.includes("composite")
                ? "composite"
                : file_name.includes("individual")
                ? "individual"
                : "others",
              original_file_name: original_file_name,
              sequence: sequence,
              position: photo_position,
            })
          : downloadXray({
              patientId: patientId,
              caseId: caseId,
              fileName: file_name,
              XrayType: file_name.includes("pano")
                ? "pano"
                : file_name.includes("ceph")
                ? "ceph"
                : "others",
              original_file_name: original_file_name,
            })

      dispatch(fn).then((res) => {
        downloadTotal++
        if (downloadTotal === totalFilesToDownload) {
          dispatch(setDownloadStatus("succeeded"))
        }
      })
    }

    if (fileList.length) {
      if ((compositePhoto.length || individualPhotos.length) && pano.length)
        return

      let photos = []
      let refinementPhotos = []
      let xrays = []
      let refinementXrays = []
      if (!(compositePhoto.length && individualPhotos.length)) {
        const photoGraphType = ["composite", "individual", "others"]
        photoGraphType.map((type) => {
          photos = [
            ...photos,
            ...getFiles(fileList, "photographs", false, type),
          ]
          refinementPhotos = [
            ...refinementPhotos,
            ...getFiles(
              fileList,
              "photographs",
              true,
              type,
              caseDetail.refinementRound + 1,
            ),
          ]
        })
      }
      if (!pano.length) {
        const radioGraphType = ["pano", "ceph", "others"]
        radioGraphType.map((type) => {
          xrays = [...xrays, ...getFiles(fileList, "radiograph", false, type)]
          refinementXrays = [
            ...refinementXrays,
            ...getFiles(
              fileList,
              "radiograph",
              true,
              type,
              caseDetail.refinementRound + 1,
            ),
          ]
        })
      }
      let photosToDownload = []
      let xraysToDownload = []
      if (!isRefinement) {
        photosToDownload = photos
        xraysToDownload = xrays
      } else {
        photosToDownload = photosToDownload.concat(
          refinementPhotos.length ? refinementPhotos : photos,
        )
        xraysToDownload = xraysToDownload.concat(
          refinementXrays.length ? refinementXrays : xrays,
        )
      }
      photosToDownload.map((file) =>
        downloadFiles(file, photosToDownload.length, "photographs"),
      )
      xraysToDownload.map((file) =>
        downloadFiles(file, xraysToDownload.length, "radiograph"),
      )
    }
  }, [fileList])

  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })

  const [jsonData, setJsonData] = useState({ thumbs: null })
  const [fileThumbs, setFileThumbs] = useState<File[]>([])
  useEffect(() => {
    if (caseDetail.uassist_round === undefined) {
      return
    }
    // get type.json
    getType()
  }, [caseDetail.uassist_round])
  const getType = () => {
    const refinementIndex =
      caseDetail.uassist_round > 0 ? caseDetail.uassist_round : 0
    dispatch(
      downloadPhotoJSON({
        caseId,
        patientId,
        refinementIndex: refinementIndex ?? 0,
      }),
    ).then((res) => {
      if (res && res.payload) {
        const { status, data } = res.payload
        if (status === 200) {
          setJsonData(data)
        } else {
          console.error("download type.json error")
        }
      } else {
        console.error("download type.json error")
      }
    })
  }
  const downPhotos = async () => {
    const fileTmp = []
    for (const key in jsonData) {
      if (key !== "thumbs") {
        fileTmp.push(key)
      }
    }
    const refinementIndex =
      caseDetail.uassist_round > 0 ? caseDetail.uassist_round : 0
    const ret = await dispatch(
      downloadPhotographs1_1({
        patientId,
        caseId,
        fileNames: fileTmp,
        refinementIndex,
      }),
    )
    const tmp: File[] = []
    for (const f in ret.payload) {
      if (ret.payload[f] instanceof File) {
        tmp.push(ret.payload[f])
      }
    }
    const downloadFiles: File[] = tmp
    setFileThumbs(downloadFiles)
  }
  useEffect(() => {
    if (!jsonData.thumbs) {
      return
    }
    downPhotos()
  }, [jsonData])
  return (
    <Box>
      <Box
        className="preview"
        sx={{ position: "relative", width: "466px", height: "466px" }}
      >
        {caseDetail.uassist_round !== undefined && (
          <Preview
            zips={zipList}
            upperStl={upperStl}
            lowerStl={lowerStl}
            setUpperStl={setUpperStl}
            setLowerStl={setLowerStl}
            sx={{ display: isStlPreview ? "" : "none" }}
            upperArchType={upperArchType}
            lowerArchType={lowerArchType}
            isShowRefinement={caseDetail.uassist_round > 0}
          />
        )}

        {!isStlPreview && (
          <>
            <UImagePreview
              imageHeight={466}
              imageWidth={466}
              files={prevImage.map((img) => {
                return {
                  src: URL.createObjectURL(img),
                  name: img.name,
                }
              })}
            />
            <IconButton
              onClick={() => setSelectedPreview(selectedImage)}
              className="zoom"
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "#EEE",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "4px",
                zIndex: 1,
                display: "none",
                padding: "7px",
              }}
            >
              <Box
                component={"img"}
                src={zoomOut}
                alt={"zoomout"}
                loading="lazy"
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          </>
        )}
      </Box>
      {/* <Box
        key="stlviewbtn"
        sx={{ display: "inline-block", }}
        onClick={() => handleImageClick(stl, -1, true)}
      > */}
      <Box sx={{ display: "flex" }}>
        <Box
          component={"div"}
          id="imagecontainer"
          sx={{
            width: "80px",
            height: "80px",
            borderRadius: "4px",
            outline:
              currentImage === -1
                ? "3px solid #1E88E5"
                : "3px solid transparent",
            display: "inline-block",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
            marginLeft: "-5px",
          }}
          onClick={() => handleImageClick(stl, -1)}
        >
          <Box
            id="imagebox"
            sx={{
              width: "80px",
              height: "80px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#A0A2A6",
              borderRadius: "4px",
            }}
          >
            <img
              style={{
                height: "36px",
                width: "36px",
              }}
              src={stl}
              alt={"stl"}
            />
            <Box component={"div"} className="preview-text">
              {t("uassist.stlPreview")}
            </Box>
          </Box>
        </Box>
        {/* </Box> */}

        <Box
          id="slick"
          component={"div"}
          sx={{
            // height: "100px",
            paddingLeft: "20px",
            mt: "2px",
            display: "inline-block",
            // alignItems: "center",
            // justifyContent: "center",
            // width: "350px !important",
            marginTop: "27px",
          }}
        >
          <Slider {...settings}>
            {[...fileThumbs].map((file, index) => (
              <Box
                className={"slick-slider"}
                key={index}
                sx={{ p: "3px", m: 0, width: "100px", height: "100px" }}
              >
                <img
                  style={{
                    objectFit: "contain",
                    borderRadius: "4px",
                    border: "3px solid",
                    borderColor:
                      currentImage === index ? "#1E88E5" : "transparent",
                  }}
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  onClick={() =>
                    handleImageClick(URL.createObjectURL(file), index)
                  }
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  )
}

export default PhotoList
