import { caseManagement } from "@/gluelayer"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { setSwitchViewEdit } from "@/UDTreat/udTreatSlice"
import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { useAppDispatch } from "../../../../core/app/hooks"
import {
  forcelockCaseApi,
  unlockCaseApi,
} from "../../../../core/app/slices/clinical/clinicalThunkApi"
import UButton from "../../../../ui-component/components/UButton/UButton"
import DialogBox from "../DialogBox/dialogBox"
import { formatDate } from "@/modules/Patient/PatientList/util/commonUtil"
const CaseLockPopup: FC = ({
  lockType,
  openLockPopup,
  setOpenLockPopup,
  lockInfo,
  clickControlOverride,
  allowControl = true,
  uploadAllData,
  cancelNavigation,
}) => {
  const navigate = useMyNavigation()
  const { t } = useTranslation("common")
  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const lockContent = (type: number) => {
    switch (type) {
      //lock by uassist
      case 0:
        return (
          <>
            <p style={{ margin: "0", fontSize: "14px" }}>
              {t("treat.lockPopUp.uassist1")}
            </p>
            {/* <p
              style={{ margin: "0", fontSize: "14px" }}
            >{`They will let you know when it is "Ready to review".`}</p> */}
          </>
        )
      //order been place
      case 1:
        return (
          <p style={{ margin: "0", fontSize: "14px" }}>
            {t("treat.lockPopUp.orderPlace")}
          </p>
        )
      //edit by other user
      case 2:
        return (
          <>
            <p style={{ margin: "0", fontSize: "14px" }}>
              {t("treat.lockPopUp.otherUser")}
              <br />
              <span
                style={{
                  color: "#000",
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                }}
              >{`${lockInfo.username}\u00A0\u00A0\u00A0${lockInfo.device_info}`}</span>

              <br />
              <br />
              {allowControl && t("treat.lockPopUp.controlContent")}
            </p>
          </>
        )
      case 3:
        return (
          <>
            <p style={{ margin: "0", fontSize: "14px" }}>
              {t("treat.lockPopUp.overContent1")}
              <br />
              <span
                style={{
                  color: "#000",
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                }}
              >{`${lockInfo.username}\u00A0\u00A0\u00A0 ${formatDate(
                lockInfo.locked_date,
              )}`}</span>
              <br />
              <br />
              {/* {t("treat.lockPopUp.overContent2")} */}
            </p>
          </>
        )
      //order been place
      case -1:
        return (
          <p style={{ margin: "0", fontSize: "14px" }}>
            {t("treat.lockPopUp.byCRB")}
          </p>
        )
    }
  }

  return (
    <DialogBox
      open={openLockPopup}
      title={
        lockType > 2
          ? t("treat.lockPopUp.overTitle")
          : t("treat.lockPopUp.title")
      }
      content={lockContent(lockType)}
      actions={
        lockType > 1 && allowControl ? (
          <>
            <UButton
              onClickHandler={() => {
                setOpenLockPopup(false)
                if (lockType === 3) {
                  cancelNavigation()
                }
              }}
              variant={"text"}
              btnText={t("button.cancel")}
            />
            {lockType === 3 && (
              <UButton
                onClickHandler={async () => {
                  await dispatch(setSwitchViewEdit(false))
                  setOpenLockPopup(false)
                  window.close()
                }}
                variant={"contained"}
                btnText={`discard & close`}
              />
            )}
            <UButton
              onClickHandler={async () => {
                if (lockType === 3) {
                  clickControlOverride(() => {
                    uploadAllData({
                      callback: () => {
                        dispatch(unlockCaseApi({ patientId, caseId })).then(
                          async () => {
                            await dispatch(setSwitchViewEdit(false))
                            setOpenLockPopup(false)
                            window.close()
                          },
                        )
                      },
                    })
                  })
                } else {
                  clickControlOverride()
                }
              }}
              variant={"contained"}
              btnText={
                lockType === 2
                  ? t("treat.lockPopUp.control")
                  : t("treat.lockPopUp.override")
              }
            />
          </>
        ) : (
          <>
            <UButton
              onClickHandler={() => {
                setOpenLockPopup(false)
              }}
              variant={"contained"}
              btnText={t("treat.lockPopUp.okbtn")}
            />
          </>
        )
      }
    />
  )
}
export default CaseLockPopup
