import { useEffect, useState } from "react"
import { Grid, Box, Stack, Typography, Switch, Button } from "@mui/material"
import { FormControlLabel } from "@mui/material"
import { RootState } from "@/core/app/store"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setOpenCollisionCheck } from "@/UDTreat/udTreatSlice"
import { SetupType, OJOBType, MidlineType, IPRRange } from "@/gluelayer"
import {
  UDTitleButtons,
  UDTitlePlusMinus2,
} from "@/UDTreat/custom/UDSelectOptions"

import { gdsPlusSetup, wasmModule, stagingManager } from "@/gluelayer"
import {
  setDisable,
  updateAutoSetup,
  setOpenProgressDlg,
  setProgressDlgMsg,
} from "@/UDTreat/udSetupSlice"

import { RightPage } from "@/UDTreat/right/RightPage"
import {
  setShowKFEditor,
  setUpdateSetup,
  setUpdateStaging,
} from "@/UDTreat/udTreatSlice"
import { QuickIPREditor } from "./QuickIPREditor"

const setupTypeOptions = {
  title: "Quick start over",
  group: "SetupType",
  options: ["Full\nw/ AP", "Full\nw/o AP", "Anterior"],
}

const optimizeActions = {
  title: "Optimize Setup",
  group: "OptimizeSetup",
  options: ["Occ. Contact"],
}

const antSpaceOptions = {
  title: "Anterior Space",
  group: "AntSpace",
  options: ["0.25mm", "0.5mm", "1mm"],
}

export const QuickSetup = (props) => {
  const [showIPREditor, setShowIPREditor] = useState(false)
  const { curCaseMode } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const dispatch = useAppDispatch()
  const { Params, disabled, autoOccContact } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  const { openCollisionCheck } = useAppSelector((state: RootState) => state.udTreatService)

  const setupTypes = [SetupType.Full, SetupType.FullNoAP, SetupType.Anterior]
  const OJOBTypes = [
    OJOBType.Improve,
    OJOBType.HalfMmSpace,
    OJOBType.OneMmSpace,
  ]
  const midlineTypes = [
    MidlineType.MIDTYPE_CENTER,
    MidlineType.MIDTYPE_INIT,
    MidlineType.MIDTYPE_TOUPPER,
    MidlineType.MIDTYPE_TOLOWER,
  ]
  const IPRRangeTypes = [
    IPRRange.Full,
    IPRRange.AntOnly,
    IPRRange.IncisorOnly,
    IPRRange.CanineOnly,
  ]

  const OBValues = [-1, 0, 1, 2, 3]
  const extractRestoreTooth = (extract) => {
    setTimeout(() => {
      if (extract) gdsPlusSetup.ExtractTooth()
      else gdsPlusSetup.RestoreAllTeeth()
      dispatch(setUpdateSetup())
      dispatch(updateAutoSetup())
      dispatch(setUpdateStaging())
    }, 100) // wait 100ms so the dialog is shown.
  }
  const setupByTypeStep = (type: SetupType, step: number) => {
    if (step == -1) {
      dispatch(setOpenProgressDlg(false))
      dispatch(setProgressDlgMsg("End"))
      dispatch(setUpdateSetup())
      dispatch(setDisable(false))
      dispatch(updateAutoSetup())
      dispatch(setUpdateStaging())
      stagingManager.updateStagesFromWasm()
      /* 
      if (type == SetupType.Full || type == SetupType.FullNoAP) {
        gdsPlusSetup.SetLowerGlobalMovement(true, false)
      } */
      return // no next step,
    }
    if (step == 0) {
      gdsPlusSetup.setSetupType(type)
    }
    const msg = gdsPlusSetup.getSetupStepMsg(step)
    dispatch(setOpenProgressDlg(true))
    dispatch(setProgressDlgMsg("Auto setup: " + msg))
    setTimeout(() => {
      // wait 0.1s to let UI refresh then run next step
      const nextStep = gdsPlusSetup.runSetupStep(step)
      setupByTypeStep(type, nextStep) // continue to next step
    }, 1000)
  }

  useEffect(() => {
    console.log("WeSetupType  rerendered")
  })

  const onValueChanged = (name: string, value: number) => {
    dispatch(setOpenProgressDlg(true))
    const msg = "Change " + name + " to " + value.toFixed(2)
    dispatch(setProgressDlgMsg(msg))
    setTimeout(() => {
      gdsPlusSetup.SetParamValue(name, value)
      dispatch(updateAutoSetup())
      dispatch(setUpdateSetup())
      dispatch(setUpdateStaging())
      dispatch(setOpenProgressDlg(false))
    }, 100)
  }

  const onChangeSel1 = (group: string, sel: number) => {
    console.log(group, ":", sel, "is selected!")
    switch (group) {
      case "SetupType":
        const type = setupTypes[sel]
        setupByTypeStep(type, 0)
        break
      case "ToothExtraction":
        extractRestoreTooth(sel == 0)
        break
      case "AntSpace":
        gdsPlusSetup.SetOJOBByTypeID(OJOBTypes[sel], () => ({}))
        break
      case "Overbite":
        gdsPlusSetup.SetParamValue("OB", OBValues[sel])
        break
      case "Midline":
        gdsPlusSetup.SetMidlineByTypeID(midlineTypes[sel], () => ({}))
        break
      case "AP":
        if (sel === 0) gdsPlusSetup.SetupAPUpperToLower() // AP auto
        else gdsPlusSetup.NoAPControl() // No AP
        break
      case "UpperIPR":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRangeTypes[sel])
        break
      case "LowerIPR":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRangeTypes[sel])
        break
      case "GM":
        if (sel === 0) gdsPlusSetup.SetLowerGlobalMovement(true, false)
        else if (sel === 1) gdsPlusSetup.SetLowerGlobalMovement(false, false)
        else gdsPlusSetup.SetLowerGlobalMovement(true, true)
        break
      case "OptimizeSetup":
        gdsPlusSetup.ResolveOccColl(true, false, () => {})
        /*  switch (sel) {
          case 0: // AP
            gdsPlusSetup.SetupAPUpperToLower()
            break
          case 1: // Occ Contact
            gdsPlusSetup.ResolveOccColl(true, false, () => {})
            break
       
          case 1: // Space
            gdsPlusSetup.ResolveAllColl(() => {})
            break
          case 3: //GM
            gdsPlusSetup.SetLowerGlobalMovement(true, false)
            break
          default:
            break
        } */
        break
    }
  }

  useEffect(() => {
    if (!wasmModule.isInit) return
    gdsPlusSetup.EnableAutoOccContact(autoOccContact)
  }, [autoOccContact])

  useEffect(() => {
    if (!wasmModule.isInit) return
    gdsPlusSetup.SwitchAutoSetupModule(true)
    gdsPlusSetup.EnableAutoOccContact(true)
    console.log("setupByTypeStep FineTune");
    setupByTypeStep(SetupType.FineTune, 0)
    return () => {
      gdsPlusSetup.SwitchAutoSetupModule(false)
      console.log("End chairside setup, showIPREditor: ", showIPREditor)
      gdsPlusSetup.EnterIPREditMode( false );
      dispatch(setDisable(true))
    }
  }, [])

  useEffect(() => {
    if (!wasmModule.isInit) return
    console.log("openCollisionCheck: ", openCollisionCheck, ", showIPREditor: ", showIPREditor)
    if( !openCollisionCheck && showIPREditor )
    {
      gdsPlusSetup.EnterIPREditMode( false );
      setShowIPREditor(false)
    }
  }, [openCollisionCheck])

  const QuickSetupType = (props) => {
    return (
      <UDTitleButtons
        wrap={true}
        key={399}
        title={setupTypeOptions.title}
        options={setupTypeOptions.options}
        onClick={(sel) => {
          onChangeSel1(setupTypeOptions.group, sel)
        }}
      />
    )
  }
  const { selectedTooth } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  const getIPRSpaceValue = (idx: number) => {
    let value = 0
    if (idx === 0) {
      value =
        Params.IPRToPrev.value > 0
          ? Params.IPRToPrev.value
          : -Params.SpaceToPrev.value
    } else {
      value =
        Params.IPRToNext.value > 0
          ? Params.IPRToNext.value
          : -Params.SpaceToNext.value
    }
    value = Math.round(value * 10) / 10
    return value
  }
  const onChangeIPRSpace = (idx: number, value: number) => {
    let value1 = Math.abs(Math.round(value * 10) / 10)
    const paraName = ["IPRToPrev", "SpaceToPrev", "IPRToNext", "SpaceToNext"]
    let idx1 = idx * 2
    if (value < 0) idx1++
    gdsPlusSetup.SetParamDoubleValue(paraName[idx1], value1)
    dispatch(updateAutoSetup())
  }

  const onOptimize = (sel: number) => {
    dispatch(setOpenProgressDlg(true))
    // const opNames = ["AP", "Close space", "Occ contact", "Bite jump"]
    dispatch(setProgressDlgMsg("Optimize: Remove occlusal contact"))
    setTimeout(() => {
      if (sel === 0) gdsPlusSetup.ResolveOccColl(false, false, () => {})
      else if (sel === 1) {
        gdsPlusSetup.SetIPRRangeByID(false, IPRRange.AutoAll)
        gdsPlusSetup.SetIPRRangeByID(true, IPRRange.AutoAll)
      }
      dispatch(updateAutoSetup())
      dispatch(setUpdateSetup())
      dispatch(setUpdateStaging())
      dispatch(setOpenProgressDlg(false))
    }, 100)
  }
  const onAutoIPR = () => {
    gdsPlusSetup.SetIPRRangeByID(false, IPRRange.AutoAll)
    gdsPlusSetup.SetIPRRangeByID(true, IPRRange.AutoAll)
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  const QuickPlanToothIPR = () => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const isOpen = event.target.checked;
      console.log( "IPR Edit Mode: ", isOpen )
      setShowIPREditor(isOpen)
      if( !openCollisionCheck && isOpen )
      {
        dispatch( setOpenCollisionCheck(isOpen) ) // auto open collision check
      }

      setTimeout(() => {
        gdsPlusSetup.EnterIPREditMode( isOpen );
      }, 100);
    }

    return (
      <>
        <Typography variant="body1" sx={{ paddingTop: "8px" }}>
          IPR/Space
        </Typography>
        <Grid display="flex" justifyItems="center" container>
          <Grid display="flex" justifyItems="center" item>
            <FormControlLabel
              control={
                <Switch checked={showIPREditor} onChange={handleChange} />
              }
              label="IPR Editor"
            />
          </Grid>
          <Grid display="flex" justifyItems="center" item>
            <Button
              size="small"
              variant="outlined"
              onClick={onAutoIPR}
              sx={{
                textTransform: "none",
                width: "55px",
                height: "46px",
                fontSize: "13px",
                whiteSpace: "pre-wrap",
                fontWeight: 600,
                color: "rgba(0, 0, 0, 0.6)",
                backgroundColor: "rgba(240, 240, 240, 1)",
                boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.15)",
                border: "none",
                "&:hover": {
                  border: "none",
                },
              }}
            >
              Auto IPR
            </Button>
          </Grid>
          {/**  
          {selectedTooth.ID > 0 && !disabled ? (
            <>
              <UDTitlePlusMinus2
                title="P IPR"
                value={getIPRSpaceValue(0)}
                deltaSmall={0.1}
                deltaLarge={0.5}
                size={6}
                onChange={(newValue) => {
                  onChangeIPRSpace(0, newValue)
                }}
              />
              <UDTitlePlusMinus2
                title="N IPR"
                value={getIPRSpaceValue(1)}
                size={6}
                deltaSmall={0.1}
                deltaLarge={0.5}
                onChange={(newValue) => {
                  onChangeIPRSpace(1, newValue)
                }}
              />
            </>
          ) : (
            <></>
          )}*/}
        </Grid>
      </>
    )
  }
  const QuickModi = () => {
    return (
      <Stack direction={"column"} spacing={2}>
        <QuickPlanToothIPR />
        {/* 
        <UDTitleButtons
          key={398}
          title={antSpaceOptions.title}
          options={antSpaceOptions.options}
          onClick={(sel) => {
            onChangeSel1(antSpaceOptions.group, sel)
          }}
        /> */}
        <UDTitleButtons
          wrap={false}
          key={399}
          title={optimizeActions.title}
          options={optimizeActions.options}
          onClick={(sel) => {
            onOptimize(sel)
          }}
        />
      </Stack>
    )
  }

  return (
    <>
      <RightPage
        title="Setup"
        onClose={props.onClose}
        sx={{ width: 205 }}
        content={
          <Stack direction={"column"} spacing={2}>
            {(curCaseMode == "UDPlan" || curCaseMode == "WeDesign" || curCaseMode == "UDModi" || curCaseMode == "UDView") && (
              <QuickSetupType />
            )}
            <QuickModi />
          </Stack>
        }
        actions={<Box></Box>}
      />
      {/* {showIPREditor && <QuickIPREditor />} */}
    </>
  )
}
