import { JsonFormsDispatchRenderer } from "@jsonforms/react";
import { globalEvents, GlobalEvents } from "../../../utils";
import { stagingManager } from "../stagingdatamanager/stagingmanager";
import { wasmModule } from "../wasm/wasmModule";

class RefinementModule {
  wasmRefinementDisplayModule = null;
  refinementInfo: string[];

  openRefinementModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;
    console.log("openHistoryModule");
    wasmModule.moduleManager.SwitchRefinementDisplayModule(isOpen);
    this.wasmRefinementDisplayModule =
      wasmModule.moduleManager.GetRefinementDisplayModule();
    if (isOpen)
      this.wasmRefinementDisplayModule.initHistoryData();
    console.log(
      "wasmRefinementDisplayModule :",
      this.wasmRefinementDisplayModule
    );
  }

  changeDisplayStageValue(index: number, archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    this.wasmRefinementDisplayModule.changeDisplayStageValue(index, archTpye);
  }

  setRefinementDisplayRange(displayRange: string) {
    if (!this.wasmRefinementDisplayModule) return false;
    stagingManager.showRefinementHistoryRangeType = displayRange;
    const res =
      this.wasmRefinementDisplayModule.setRefinementDisplayRange(displayRange);

    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE);

    return res;
  }

  getNumberOfRefinement(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return 0;
    return this.wasmRefinementDisplayModule.getRefinementCount(archTpye);
  }

  getRefinementInfo(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    const info =
      this.wasmRefinementDisplayModule.getRefinementDataInfo(archTpye, false);
    this.refinementInfo = info.split(";");
    return this.refinementInfo;
  }

  getCurNodes(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    const nodes = this.wasmRefinementDisplayModule.getCurNodes(archTpye);
    return nodes;
  }

  getCurStartStageIndex(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    return this.wasmRefinementDisplayModule.getCurStartStageIndex(archTpye);
  }

  getHistoryStageNumber(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    const maxStageNumber =
      this.wasmRefinementDisplayModule.getMaxHistoryStageNumber(archTpye);
    const refinmentNumber =
      this.wasmRefinementDisplayModule.getNumberOfRefinement(archTpye);
    return maxStageNumber - refinmentNumber + 1;
  }

  undoRefinement(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
  }

  getRefinementDateInfo() {
    this.checkRefinementDisplayModule();
    const jsonStr = this.wasmRefinementDisplayModule.getRefinementDateInfo();
    console.log(
      "getRefinementDateInfo :", jsonStr, ", wasmRefinementDisplayModule: ", this.wasmRefinementDisplayModule);
    const jsonObject = JSON.parse(jsonStr);
    console.log(
      "getRefinementDateInfo :", jsonObject);
    return jsonObject;
  }

  getDownloadDataNames(displayRange: string) {
    if (!this.wasmRefinementDisplayModule) return;
    const res =
      this.wasmRefinementDisplayModule.getDownloadDataNames(displayRange);
    for (let j = 0; j < res.size(); j++) {
      const name = res.get(j);
      console.info(
        "getDownloadDataNames :" + name
      );
    }
    return res;
  }

  convertRefinementHistory = () => {
    return new Promise((resolve, reject) => {
      if (!this.wasmRefinementDisplayModule) resolve(false);
      const res =
        this.wasmRefinementDisplayModule.convertRefinementHistory();
      resolve(res);
    });
  };

  getUploadDirList() {
    if (!this.wasmRefinementDisplayModule) return;
    const res =
      this.wasmRefinementDisplayModule.getUploadDirList();
    return res;
  }

  getStageStartNumber(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return 0;
    const res =
      this.wasmRefinementDisplayModule.getStageStartNumber(archTpye, false);
    return res;
  }

  getIsStartFromOne() {
    if (!this.wasmRefinementDisplayModule) return true;
    const res =
      this.wasmRefinementDisplayModule.getIsStartFromOne();
    return res;
  }

  getDetailedHistoryDateInfo() {
    this.checkRefinementDisplayModule();
    const jsonStr = this.wasmRefinementDisplayModule.getDetailedHistoryDateInfo();
    console.log(
      "getDetailedHistoryDateInfo :", jsonStr);
    const jsonObject = JSON.parse(jsonStr);
    console.log(
      "getDetailedHistoryDateInfo :", jsonObject);
    return jsonObject;
  }

  checkRefinementDisplayModule() {
    this.wasmRefinementDisplayModule =
      wasmModule.moduleManager.GetRefinementDisplayModule();
    if (!this.wasmRefinementDisplayModule){
      this.openRefinementModule(true);
    }
  }

  saveNewHistoryData() {
    this.checkRefinementDisplayModule();
    this.wasmRefinementDisplayModule.saveNewHistoryData();
  }
}

export const refinementModule = new RefinementModule();
