import React, { useEffect, useState } from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import { Button, Stack, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setSmartRxShow, setCurLeftPage } from "../udTreatSlice"
import { Person } from "@mui/icons-material"
import { usePageWidth } from "./GetViewWidth"

const LeftToolMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const {
    curLeftPage,
    smartRxShow,
    singleArchBool,
    goAutoProcesses,
    isOpenModelCheck,
  } = useAppSelector((state: RootState) => state.udTreatService)
  const dispatch = useAppDispatch()

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const windowWidth = usePageWidth()

  useEffect(() => {
    return () => {
      // Clean up the event listener when the component unmounts
      setAnchorEl(null)
      dispatch(setSmartRxShow(false))
      dispatch(setCurLeftPage(""))
    }
  }, [])

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextTool: string,
  ) => {
    setAnchorEl(null)

    if (nextTool === "smartRx") {
      if (smartRxShow) {
        dispatch(setSmartRxShow(false))
        dispatch(setCurLeftPage(""))
      } else {
        dispatch(setSmartRxShow(true))
        dispatch(setCurLeftPage("smartRx"))
      }
    } else {
      if (smartRxShow) {
        dispatch(setSmartRxShow(false))
        dispatch(setCurLeftPage(""))
      }
    }
    dispatch(setCurLeftPage(nextTool))
  }
  const LeftBtnColumn = [
    {
      title: "Photos and X-Rays",
      value: "records",
      onClick: () => {
        if (curLeftPage === "records") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "records")
      },
      isHide: false,
      isDisable: false,
    },
    {
      title: "IPR/Aux Report",
      value: "report",
      onClick: () => {
        if (curLeftPage === "report") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "report")
      },
      isHide:
        caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
        caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
        caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false,
    },
    {
      title: "Tooth Movement Table",
      value: "tmtable",
      onClick: () => {
        if (curLeftPage === "tmtable") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "tmtable")
      },
      isHide:
        caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
        caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
        caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false,
    },
    {
      title: "Bolton Analysis",
      value: "bolton",
      onClick: () => {
        if (curLeftPage === "bolton") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "bolton")
      },
      isHide:
        caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
        caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
        caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: singleArchBool,
    },

    {
      title: "Case Notes",
      value: "notes",
      onClick: () => {
        if (curLeftPage === "notes") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "notes")
      },
      isHide: caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST",
      isDisable: false,
    },
  ]

  return (
    !goAutoProcesses && (
      <Stack
        sx={{
          width: "70%",
          // backgroundColor:"red",
          justifyContent: "left",
        }}
        direction={"row"}
        spacing={windowWidth > 1600 ? 2 : 1}
      >
        <Button
          sx={{
            height: "40px",
            width: "auto",
            backgroundColor: "white",
            opacity: 0.85,
            color: "inherit",
            borderRadius: "20px",
            fontSize: "14px",
            boxShadow: "0 6px 10px 0px rgba(0, 0, 0, 0.15)",
            "&:hover": {
              backgroundColor: "white",
              opacity: 0.65,
            },
          }}
          // variant="contained"
          onClick={() => {
            if (curLeftPage === "uassist") {
              handleChange(undefined, "")
              return
            }
            handleChange(undefined, "uassist")
          }}
          endIcon={
            windowWidth > 1600 && (
              <Person
                sx={{ fontSize: 25, width: 25, height: 25 }}
                color="action"
              />
            )
          }
        >
          Uassist
        </Button>

        <IconButton
          sx={{
            height: "40px",
            width: "auto",

            backgroundColor: "white",
            opacity: 0.85,
            color: "black",
            boxShadow: "0 6px 10px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "20px",
            "&:hover": {
              backgroundColor: "white",
              opacity: 0.65,
            },
          }}
          edge="start"
          aria-label="menu"
          onClick={handleMenuOpen}
        >
          <Typography
            sx={{
              fontSize: 25,
              width: 25,
              height: 25,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              color: "gray",
            }}
          >
            i
          </Typography>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {LeftBtnColumn.map((option) => (
            <MenuItem key={option.value} onClick={option.onClick}>
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    )
  )
}

export default LeftToolMenu
