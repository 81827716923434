function defaultValue(a, b) {
  if (a !== void 0 && a !== null) {
    return a;
  }
  return b;
}
function defaultValueOrNew(a, constructor) {
  if (a !== void 0 && a !== null) {
    return a;
  }
  return new constructor();
}
class Vector2 {
  constructor(x, y) {
    this._datas = new Float32Array(2);
    this._datas[0] = x ?? 0;
    this._datas[1] = y ?? 0;
  }
  get data() {
    return this._datas;
  }
  get 0() {
    return this._datas[0];
  }
  get 1() {
    return this._datas[1];
  }
  get x() {
    return this._datas[0];
  }
  get y() {
    return this._datas[1];
  }
  set 0(v) {
    this._datas[0] = v;
  }
  set 1(v) {
    this._datas[1] = v;
  }
  set x(v) {
    this._datas[0] = v;
  }
  set y(v) {
    this._datas[1] = v;
  }
  set(x, y) {
    this._datas[0] = x;
    this._datas[1] = y;
  }
  setByAnother(another) {
    this._datas[0] = another[0];
    this._datas[1] = another[1];
    this._datas[2] = another[2];
  }
  clone() {
    const data = this._datas;
    return new Vector2(data[0], data[1]);
  }
  toString() {
    return [this._datas[0], this._datas[1]].toString();
  }
  toArray() {
    return [this._datas[0], this._datas[1]];
  }
  /**-------------------------static functions---------------------------- */
  /**
   * create a Vector2 from Array
   * @param args [x,y]
   * @returns
   */
  static createFromArray(args) {
    return new Vector2(args[0], args[1]);
  }
  static copy(src, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = src[0];
    ret[1] = src[1];
    return this;
  }
  static add(left, right, result) {
    let ret = defaultValueOrNew(result, Vector2);
    const dl = left;
    const dr = right;
    ret[0] = dl[0] + dr[0];
    ret[1] = dl[1] + dr[1];
    return ret;
  }
  // static cross(left:Vector2,right:Vector2,result?:Vector2):Vector2{
  //     let ret = result || new Vector2(0,0);
  //     var leftX = left.x;
  //     var leftY = left.y;
  //     var rightX = right.x;
  //     var rightY = right.y;
  //     ret.x = leftY * rightZ - leftZ * rightY;
  //     ret.y = leftZ * rightX - leftX * rightZ;
  //     ret.z = leftX * rightY - leftY * rightX;
  //     return ret;
  // }
  static addScalar(left, s, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = left[0] + s;
    ret[1] = left[1] + s;
    return ret;
  }
  static sub(left, right, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = left[0] - right[0];
    ret[1] = left[1] - right[1];
    return ret;
  }
  static subScalar(left, s, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = left[0] - s;
    ret[1] = left[1] - s;
    return ret;
  }
  static multiply(left, right, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = left[0] * right[0];
    ret[1] = left[1] * right[1];
    return ret;
  }
  static multiplyScalar(left, s, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = left[0] * s;
    ret[1] = left[1] * s;
    return ret;
  }
  static divide(left, t, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = left[0] / t[0];
    ret[1] = left[1] / t[1];
    return ret;
  }
  static divideScalar(src, s, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = src[0] / s;
    ret[1] = src[1] / s;
    return ret;
  }
  static negate(src, result) {
    let ret = defaultValueOrNew(result, Vector2);
    ret[0] = -src[0];
    ret[1] = -src[1];
    return ret;
  }
  static dot(left, right) {
    return left[0] * right[0] + left[1] * right[1];
  }
  static sizeSquare(src) {
    return src[0] * src[0] + src[1] * src[1];
  }
  static size(src) {
    return Math.sqrt(src[0] * src[0] + src[1] * src[1]);
  }
  static normalize(src, result) {
    let ret = defaultValueOrNew(result, Vector2);
    const size = Vector2.size(src);
    Vector2.divideScalar(src, size, ret);
    return ret;
  }
  static distanceToSquare(left, right) {
    var dx = left[0] - right[0], dy = left[1] - right[1];
    return dx * dx + dy * dy;
  }
  static distanceTo(left, right) {
    return Math.sqrt(Vector2.distanceToSquare(left, right));
  }
}
class Vector3 extends Float32Array {
  constructor(x, y, z) {
    super(3);
    this[0] = x ?? 0;
    this[1] = y ?? 0;
    this[2] = z ?? 0;
  }
  get x() {
    return this[0];
  }
  get y() {
    return this[1];
  }
  get z() {
    return this[2];
  }
  set x(v) {
    this[0] = v;
  }
  set y(v) {
    this[1] = v;
  }
  set z(v) {
    this[2] = v;
  }
  setByAnother(another) {
    this[0] = another[0];
    this[1] = another[1];
    this[2] = another[2];
  }
  toArray() {
    return [this[0], this[1], this[2]];
  }
  toString() {
    return [this[0], this[1], this[2]].toString();
  }
  clone() {
    return new Vector3(this[0], this[1], this[2]);
  }
  /**-------------------------static functions---------------------------- */
  static fromArray(datas) {
    return new Vector3(datas[0], datas[1], datas[2]);
  }
  static copy(src, dest) {
    dest[0] = src[0];
    dest[1] = src[1];
    dest[2] = src[2];
    return dest;
  }
  static add(left, right, result = new Vector3()) {
    result[0] = left[0] + right[0];
    result[1] = left[1] + right[1];
    result[2] = left[2] + right[2];
    return result;
  }
  static cross(left, right, result = new Vector3()) {
    result[0] = left[1] * right[2] - left[2] * right[1];
    result[1] = left[2] * right[0] - left[0] * right[2];
    result[2] = left[0] * right[1] - left[1] * right[0];
    return result;
  }
  static addScalar(left, s, result = new Vector3()) {
    result[0] = left[0] + s;
    result[1] = left[1] + s;
    result[2] = left[2] + s;
    return result;
  }
  static sub(left, right, result = new Vector3()) {
    result[0] = left[0] - right[0];
    result[1] = left[1] - right[1];
    result[2] = left[2] - right[2];
    return result;
  }
  static subScalar(left, s, result = new Vector3()) {
    result[0] = left[0] - s;
    result[1] = left[1] - s;
    result[2] = left[2] - s;
    return result;
  }
  static multiply(left, right, result = new Vector3()) {
    result[0] = left[0] * right[0];
    result[1] = left[1] * right[1];
    result[2] = left[2] * right[2];
    return result;
  }
  static multiplyScalar(left, s, result = new Vector3()) {
    result[0] = left[0] * s;
    result[1] = left[1] * s;
    result[2] = left[2] * s;
    return result;
  }
  static divide(left, t, result = new Vector3()) {
    result[0] = left[0] / t[0];
    result[1] = left[1] / t[1];
    result[2] = left[2] / t[2];
    return result;
  }
  static divideScalar(src, s, result = new Vector3()) {
    result[0] = src[0] / s;
    result[1] = src[1] / s;
    result[2] = src[2] / s;
    return result;
  }
  static negate(src, result = new Vector3()) {
    result[0] = -src[0];
    result[1] = -src[1];
    result[2] = -src[2];
    return result;
  }
  static transformMat3(v3, mat3, result = new Vector3()) {
    const m = mat3.data;
    const x = v3[0], y = v3[1], z = v3[2];
    result[0] = x * m[0] + y * m[3] + z * m[6];
    result[1] = x * m[1] + y * m[4] + z * m[7];
    result[2] = x * m[2] + y * m[5] + z * m[8];
    return result;
  }
  static transformMat4(vec3, mat, result = new Vector3()) {
    const x = vec3[0], y = vec3[1], z = vec3[2];
    const e = mat.data;
    const w = 1 / (e[3] * x + e[7] * y + e[11] * z + e[15]);
    result[0] = (e[0] * x + e[4] * y + e[8] * z + e[12]) * w;
    result[1] = (e[1] * x + e[5] * y + e[9] * z + e[13]) * w;
    result[2] = (e[2] * x + e[6] * y + e[10] * z + e[14]) * w;
    return result;
  }
  static dot(left, right) {
    return left[0] * right[0] + left[1] * right[1] + left[2] * right[2];
  }
  static sizeSquare(vec3) {
    return vec3[0] * vec3[0] + vec3[1] * vec3[1] + vec3[2] * vec3[2];
  }
  static size(src) {
    return Math.sqrt(src[0] * src[0] + src[1] * src[1] + src[2] * src[2]);
  }
  static normalize(src, result = new Vector3()) {
    const s = Math.sqrt(src[0] * src[0] + src[1] * src[1] + src[2] * src[2]);
    result[0] = src[0] / s;
    result[1] = src[1] / s;
    result[2] = src[2] / s;
    return result;
  }
  static distanceToSquare(left, right) {
    var dx = left[0] - right[0], dy = left[1] - right[1], dz = left[2] - right[2];
    return dx * dx + dy * dy + dz * dz;
  }
  static distanceTo(left, right) {
    return Math.sqrt(Vector3.distanceToSquare(left, right));
  }
  /**
   * get a vector containing the minimum element of the input vectors.
   * @param left
   * @param right
   * @param result
   * @returns
   */
  static min(left, right, result = new Vector3()) {
    const x = Math.min(left[0], right[0]);
    const y = Math.min(left[1], right[1]);
    const z = Math.min(left[2], right[2]);
    result.set([x, y, z], 0);
    return result;
  }
  /**
   * get a vector containing the maximum element of the input vectors.
   * @param left
   * @param right
   * @param result
   * @returns
   */
  static max(left, right, result = new Vector3()) {
    const x = Math.max(left[0], right[0]);
    const y = Math.max(left[1], right[1]);
    const z = Math.max(left[2], right[2]);
    result.set([x, y, z], 0);
    return result;
  }
}
class Vector4 {
  constructor(x, y, z, w) {
    this._datas = new Float32Array(4);
    this._datas[0] = x ?? 0;
    this._datas[1] = y ?? 0;
    this._datas[2] = z ?? 0;
    this._datas[3] = w ?? 0;
  }
  get data() {
    return this._datas;
  }
  get 0() {
    return this._datas[0];
  }
  get 1() {
    return this._datas[1];
  }
  get 2() {
    return this._datas[2];
  }
  get 3() {
    return this._datas[3];
  }
  get x() {
    return this._datas[0];
  }
  get y() {
    return this._datas[1];
  }
  get z() {
    return this._datas[2];
  }
  get w() {
    return this._datas[3];
  }
  set 0(v) {
    this._datas[0] = v;
  }
  set 1(v) {
    this._datas[1] = v;
  }
  set 2(v) {
    this._datas[2] = v;
  }
  set 3(v) {
    this._datas[3] = v;
  }
  set x(v) {
    this._datas[0] = v;
  }
  set y(v) {
    this._datas[1] = v;
  }
  set z(v) {
    this._datas[2] = v;
  }
  set w(v) {
    this._datas[3] = v;
  }
  set(x, y, z, w) {
    this._datas[0] = x;
    this._datas[1] = y;
    this._datas[2] = z;
    this._datas[3] = w;
  }
  setByAnother(another) {
    this._datas[0] = another[0];
    this._datas[1] = another[1];
    this._datas[2] = another[2];
    this._datas[3] = another[3];
  }
  toString() {
    const data = this._datas;
    return [data[0], data[1], data[2], data[3]].toString();
  }
  toArray() {
    return [this._datas[0], this._datas[1], this._datas[2], this._datas[3]];
  }
  clone() {
    const data = this._datas;
    return new Vector4(data[0], data[1], data[2], data[3]);
  }
  /**-------------------------static functions---------------------------- */
  /**
   *
   * @param args the params are as x,y,z,w or as a array :[x,y,z,w] ,
   *             otherwise it'll return a Float32Array with [0,0,0,0].
   * @returns
   */
  // static create(...args):Vector4{
  //     if (args.length === 1 && args[0].length === 4 ) {
  //         const array = args[0]
  //         if (array) {
  //             return new Float32Array(array);
  //             }
  //             return new Float32Array(4)
  //     }else if(args.length === 4){
  //         return new Float32Array([args[0],args[1],args[2],args[3]]);
  //     }
  //     return new Float32Array([0,0,0,0]);
  // }
  static set(x, y, z, w, result) {
    result[0] = x;
    result[1] = y;
    result[2] = z;
    result[3] = w;
  }
  static add(left, right, result) {
    let ret = defaultValueOrNew(result, Vector4);
    ret[0] = left[0] + right[0];
    ret[1] = left[1] + right[1];
    ret[2] = left[2] + right[2];
    ret[3] = left[3] + right[3];
    return ret;
  }
  static addScalar(left, s, result) {
    let ret = defaultValueOrNew(result, Vector4);
    ret[0] = left[0] + s;
    ret[1] = left[1] + s;
    ret[2] = left[2] + s;
    ret[3] = left[3] + s;
    return ret;
  }
  static sub(left, right, result) {
    let ret = defaultValueOrNew(result, Vector4);
    ret[0] = left[0] - right[0];
    ret[1] = left[1] - right[1];
    ret[2] = left[2] - right[2];
    ret[3] = left[3] - right[3];
    return ret;
  }
  static subScalar(left, s, result) {
    let ret = defaultValueOrNew(result, Vector4);
    ret[0] = left[0] - s;
    ret[1] = left[1] - s;
    ret[2] = left[2] - s;
    ret[3] = left[3] - s;
    return ret;
  }
  static multiplyScalar(left, s, result) {
    let ret = defaultValueOrNew(result, Vector4);
    ret[0] = left[0] * s;
    ret[1] = left[1] * s;
    ret[2] = left[2] * s;
    ret[3] = left[3] * s;
    return ret;
  }
  static multiply(left, right, result) {
    let ret = defaultValueOrNew(result, Vector4);
    ret[0] = left[0] * right[0];
    ret[1] = left[1] * right[1];
    ret[2] = left[2] * right[2];
    ret[3] = left[3] * right[3];
    return ret;
  }
  static divideScalar(left, s, result) {
    let ret = defaultValueOrNew(result, Vector4);
    ret[0] = left[0] / s;
    ret[1] = left[1] / s;
    ret[2] = left[2] / s;
    ret[3] = left[3] / s;
    return ret;
  }
  static negate(vec4, result) {
    let ret = defaultValueOrNew(result, Vector4);
    ret[0] = -vec4[0];
    ret[1] = -vec4[1];
    ret[2] = -vec4[2];
    ret[3] = -vec4[3];
    return ret;
  }
  static dot(left, right) {
    return left[0] * right[0] + left[1] * right[1] + left[2] * right[2] + left[3] * right[3];
  }
  static sizeSquare(vec4) {
    return vec4[0] * vec4[0] + vec4[1] * vec4[1] + vec4[2] * vec4[2] + vec4[3] * vec4[3];
  }
  static size(vec4) {
    return Math.sqrt(
      vec4[0] * vec4[0] + vec4[1] * vec4[1] + vec4[2] * vec4[2] + vec4[3] * vec4[3]
    );
  }
  static normalize(vec4, result) {
    return Vector4.divideScalar(vec4, Vector4.size(vec4) || 1, result);
  }
  static distanceToSquare(left, right) {
    var dx = left[0] - right[0], dy = left[1] - right[1], dz = left[2] - right[2], dw = left[3] - right[3];
    return dx * dx + dy * dy + dz * dz + dw * dw;
  }
  static distanceTo(left, right) {
    return Math.sqrt(Vector4.distanceToSquare(left, right));
  }
  static fromArray(array, offset = 0, result) {
    let ret = defaultValueOrNew(result, Vector4);
    if (offset === void 0) offset = 0;
    ret[0] = array[offset];
    ret[1] = array[offset + 1];
    ret[2] = array[offset + 2];
    ret[3] = array[offset + 3];
    return ret;
  }
}
const C180_DIVIDE_PI = 180 / Math.PI;
const CPI_DIVIDE_180 = Math.PI / 180;
const EPSILON = 1e-6;
function create() {
  return new Vector4(0, 0, 0, 1);
}
function identity(src) {
  src[0] = 0;
  src[1] = 0;
  src[2] = 0;
  src[3] = 1;
  return src;
}
function copy(src, out) {
  let ret = out ?? create();
  ret[0] = src[0];
  ret[1] = src[1];
  ret[2] = src[2];
  ret[3] = src[3];
  return ret;
}
function getAngle(a, b) {
  const r = dot(a, b);
  return Math.acos(2 * r * r - 1);
}
function normalize(src, dst) {
  return Vector4.normalize(src, dst);
}
function rotateX(a, rad, out) {
  let ret = out ?? create();
  rad *= 0.5;
  const ax = a[0], ay = a[1], az = a[2], aw = a[3];
  const bx = Math.sin(rad), bw = Math.cos(rad);
  ret[0] = ax * bw + aw * bx;
  ret[1] = ay * bw + az * bx;
  ret[2] = az * bw - ay * bx;
  ret[3] = aw * bw - ax * bx;
  return ret;
}
function rotateY(a, rad, out) {
  let ret = out ?? create();
  rad *= 0.5;
  const ax = a[0], ay = a[1], az = a[2], aw = a[3];
  const by = Math.sin(rad), bw = Math.cos(rad);
  ret[0] = ax * bw - az * by;
  ret[1] = ay * bw + aw * by;
  ret[2] = az * bw + ax * by;
  ret[3] = aw * bw - ay * by;
  return ret;
}
function rotateZ(a, rad, out) {
  let ret = out ?? create();
  rad *= 0.5;
  const ax = a[0], ay = a[1], az = a[2], aw = a[3];
  const bz = Math.sin(rad), bw = Math.cos(rad);
  ret[0] = ax * bw + ay * bz;
  ret[1] = ay * bw - ax * bz;
  ret[2] = az * bw + aw * bz;
  ret[3] = aw * bw - az * bz;
  return ret;
}
function fromEulerAngles(x, y, z, out) {
  let ret = out ?? create();
  const halfX = x * 0.5;
  const halfY = y * 0.5;
  const halfZ = z * 0.5;
  const cx = Math.cos(halfX);
  const sx = Math.sin(halfX);
  const cy = Math.cos(halfY);
  const sy = Math.sin(halfY);
  const cz = Math.cos(halfZ);
  const sz = Math.sin(halfZ);
  const w = cz * cy * cx + sz * sy * sx;
  const i = cz * cy * sx - sz * sy * cx;
  const j = cz * sy * cx + sz * cy * sx;
  const k = sz * cy * cx - cz * sy * sx;
  ret.set(i, j, k, w);
  return ret;
}
function multiple(a, b, out) {
  let ret = out ?? create();
  const ax = a[0], ay = a[1], az = a[2], aw = a[3];
  const bx = b[0], by = b[1], bz = b[2], bw = b[3];
  ret[0] = ax * bw + aw * bx + ay * bz - az * by;
  ret[1] = ay * bw + aw * by + az * bx - ax * bz;
  ret[2] = az * bw + aw * bz + ax * by - ay * bx;
  ret[3] = aw * bw - ax * bx - ay * by - az * bz;
  return ret;
}
function dot(a, b) {
  return Vector4.dot(a, b);
}
function rotateByAxis(a, axis, rad, out) {
  let ret = out ?? create();
  rad *= 0.5;
  const len = Math.sqrt(
    axis[0] * axis[0] + axis[1] * axis[1] + axis[2] * axis[2]
  );
  if (Math.abs(len) < Number.EPSILON) {
    return a;
  }
  const s = Math.sin(rad) / len;
  const bx = s * axis[0];
  const by = s * axis[1];
  const bz = s * axis[2];
  const bw = Math.cos(rad);
  const ax = a[0], ay = a[1], az = a[2], aw = a[3];
  ret[0] = ax * bw + aw * bx + ay * bz - az * by;
  ret[1] = ay * bw + aw * by + az * bx - ax * bz;
  ret[2] = az * bw + aw * bz + ax * by - ay * bx;
  ret[3] = aw * bw - ax * bx - ay * by - az * bz;
  return ret;
}
function setFromRotationMatrix(m, out) {
  let ret = out ?? create();
  const te = m.data;
  const m11 = te[0], m12 = te[4], m13 = te[8];
  const m21 = te[1], m22 = te[5], m23 = te[9];
  const m31 = te[2], m32 = te[6], m33 = te[10];
  const trace = m11 + m22 + m33;
  let s;
  if (trace > 0) {
    s = 0.5 / Math.sqrt(trace + 1);
    ret[3] = 0.25 / s;
    ret[0] = (m32 - m23) * s;
    ret[1] = (m13 - m31) * s;
    ret[2] = (m21 - m12) * s;
  } else if (m11 > m22 && m11 > m33) {
    s = 2 * Math.sqrt(1 + m11 - m22 - m33);
    ret[3] = (m32 - m23) / s;
    ret[0] = 0.25 * s;
    ret[1] = (m12 + m21) / s;
    ret[2] = (m13 + m31) / s;
  } else if (m22 > m33) {
    s = 2 * Math.sqrt(1 + m22 - m11 - m33);
    ret[3] = (m13 - m31) / s;
    ret[0] = (m12 + m21) / s;
    ret[1] = 0.25 * s;
    ret[2] = (m23 + m32) / s;
  } else {
    s = 2 * Math.sqrt(1 + m33 - m11 - m22);
    ret[3] = (m21 - m12) / s;
    ret[0] = (m13 + m31) / s;
    ret[1] = (m23 + m32) / s;
    ret[2] = 0.25 * s;
  }
  return ret;
}
const quat = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  copy,
  create,
  dot,
  fromEulerAngles,
  getAngle,
  identity,
  multiple,
  normalize,
  rotateByAxis,
  rotateX,
  rotateY,
  rotateZ,
  setFromRotationMatrix
}, Symbol.toStringTag, { value: "Module" }));
class Matrix4 {
  constructor(c0r0, c0r1, c0r2, c0r3, c1r0, c1r1, c1r2, c1r3, c2r0, c2r1, c2r2, c2r3, c3r0, c3r1, c3r2, c3r3) {
    this._data = new Float32Array(16);
    const datas = this._data;
    datas[0] = defaultValue(c0r0, 1);
    datas[1] = defaultValue(c0r1, 0);
    datas[2] = defaultValue(c0r2, 0);
    datas[3] = defaultValue(c0r3, 0);
    datas[4] = defaultValue(c1r0, 0);
    datas[5] = defaultValue(c1r1, 1);
    datas[6] = defaultValue(c1r2, 0);
    datas[7] = defaultValue(c1r3, 0);
    datas[8] = defaultValue(c2r0, 0);
    datas[9] = defaultValue(c2r1, 0);
    datas[10] = defaultValue(c2r2, 1);
    datas[11] = defaultValue(c2r3, 0);
    datas[12] = defaultValue(c3r0, 0);
    datas[13] = defaultValue(c3r1, 0);
    datas[14] = defaultValue(c3r2, 0);
    datas[15] = defaultValue(c3r3, 1);
  }
  get data() {
    return this._data;
  }
  copy(other) {
    let m = this._data;
    let m2 = other._data;
    m.set(m2);
    return this;
  }
  clone() {
    let ret = new Matrix4();
    ret.copy(this);
    return ret;
  }
  toString() {
    return this._data.toString();
  }
  equals(matrix) {
    if (!matrix) {
      return false;
    }
    var te = this._data;
    var me = matrix._data;
    for (var i = 0; i < 16; i++) {
      if (te[i] !== me[i]) return false;
    }
    return true;
  }
  normalize() {
    let m = this._data;
    let scale = Math.sqrt(m[0] * m[0] + m[1] * m[1] + m[2] * m[2]);
    if (Math.abs(scale) < EPSILON) {
      m[0] = 1;
      m[1] = 0;
      m[2] = 0;
      m[3] = 0;
      m[4] = 0;
      m[5] = 1;
      m[6] = 0;
      m[7] = 0;
      m[8] = 0;
      m[9] = 0;
      m[10] = 1;
      m[11] = 0;
      m[12] = 0;
      m[13] = 0;
      m[14] = 0;
      m[15] = 1;
      return this;
    }
    for (let i = 0; i < 16; i++) {
      m[i] /= scale;
    }
    return this;
  }
  determinant() {
    let te = this._data;
    let n11 = te[0], n12 = te[4], n13 = te[8], n14 = te[12];
    let n21 = te[1], n22 = te[5], n23 = te[9], n24 = te[13];
    let n31 = te[2], n32 = te[6], n33 = te[10], n34 = te[14];
    let n41 = te[3], n42 = te[7], n43 = te[11], n44 = te[15];
    return n41 * (+n14 * n23 * n32 - n13 * n24 * n32 - n14 * n22 * n33 + n12 * n24 * n33 + n13 * n22 * n34 - n12 * n23 * n34) + n42 * (+n11 * n23 * n34 - n11 * n24 * n33 + n14 * n21 * n33 - n13 * n21 * n34 + n13 * n24 * n31 - n14 * n23 * n31) + n43 * (+n11 * n24 * n32 - n11 * n22 * n34 - n14 * n21 * n32 + n12 * n21 * n34 + n14 * n22 * n31 - n12 * n24 * n31) + n44 * (-n13 * n22 * n31 - n11 * n23 * n32 + n11 * n22 * n33 + n13 * n21 * n32 - n12 * n21 * n33 + n12 * n23 * n31);
  }
  compose(position, quaternion, scale) {
    let te = this._data;
    let x = quaternion[0], y = quaternion[1], z = quaternion[2], w = quaternion[3];
    let x2 = x + x, y2 = y + y, z2 = z + z;
    let xx = x * x2, xy = x * y2, xz = x * z2;
    let yy = y * y2, yz = y * z2, zz = z * z2;
    let wx = w * x2, wy = w * y2, wz = w * z2;
    let sx = scale[0], sy = scale[1], sz = scale[2];
    te[0] = (1 - (yy + zz)) * sx;
    te[1] = (xy + wz) * sx;
    te[2] = (xz - wy) * sx;
    te[3] = 0;
    te[4] = (xy - wz) * sy;
    te[5] = (1 - (xx + zz)) * sy;
    te[6] = (yz + wx) * sy;
    te[7] = 0;
    te[8] = (xz + wy) * sz;
    te[9] = (yz - wx) * sz;
    te[10] = (1 - (xx + yy)) * sz;
    te[11] = 0;
    te[12] = position[0];
    te[13] = position[1];
    te[14] = position[2];
    te[15] = 1;
    return this;
  }
  decompose(position, quaternion, scale) {
    let matrix = new Matrix4();
    let te = this._data;
    let sx = Vector3.size([te[0], te[1], te[2]]);
    let sy = Vector3.size([te[4], te[5], te[6]]);
    let sz = Vector3.size([te[8], te[9], te[10]]);
    let det = this.determinant();
    if (det < 0) sx = -sx;
    position[0] = te[12];
    position[1] = te[13];
    position[2] = te[14];
    matrix.copy(this);
    var invSX = 1 / sx;
    var invSY = 1 / sy;
    var invSZ = 1 / sz;
    matrix.data[0] *= invSX;
    matrix.data[1] *= invSX;
    matrix.data[2] *= invSX;
    matrix.data[4] *= invSY;
    matrix.data[5] *= invSY;
    matrix.data[6] *= invSY;
    matrix.data[8] *= invSZ;
    matrix.data[9] *= invSZ;
    matrix.data[10] *= invSZ;
    setFromRotationMatrix(matrix, quaternion);
    scale[0] = sx;
    scale[1] = sy;
    scale[2] = sz;
    return this;
  }
  /**
   * Get the translation vector from the matrix.
   * @returns Vector3 representing the translation component of the matrix.
   */
  getTranslation(result) {
    let ret = defaultValueOrNew(result, Vector3);
    ret[0] = this._data[12];
    ret[1] = this._data[13];
    ret[2] = this._data[14];
    return ret;
  }
  //IDENTITY
  //ZERO
  //abs
  //getRotation
  static fromArray(array, result) {
    let ret = defaultValueOrNew(result, Matrix4);
    ret._data = new Float32Array(array);
    return ret;
  }
  static multiply(a, b, out) {
    let aDatas = a._data;
    let left0 = aDatas[0];
    let left1 = aDatas[1];
    let left2 = aDatas[2];
    let left3 = aDatas[3];
    let left4 = aDatas[4];
    let left5 = aDatas[5];
    let left6 = aDatas[6];
    let left7 = aDatas[7];
    let left8 = aDatas[8];
    let left9 = aDatas[9];
    let left10 = aDatas[10];
    let left11 = aDatas[11];
    let left12 = aDatas[12];
    let left13 = aDatas[13];
    let left14 = aDatas[14];
    let left15 = aDatas[15];
    let bDatas = b._data;
    let right0 = bDatas[0];
    let right1 = bDatas[1];
    let right2 = bDatas[2];
    let right3 = bDatas[3];
    let right4 = bDatas[4];
    let right5 = bDatas[5];
    let right6 = bDatas[6];
    let right7 = bDatas[7];
    let right8 = bDatas[8];
    let right9 = bDatas[9];
    let right10 = bDatas[10];
    let right11 = bDatas[11];
    let right12 = bDatas[12];
    let right13 = bDatas[13];
    let right14 = bDatas[14];
    let right15 = bDatas[15];
    let col0Row0 = left0 * right0 + left4 * right1 + left8 * right2 + left12 * right3;
    let col0Row1 = left1 * right0 + left5 * right1 + left9 * right2 + left13 * right3;
    let col0Row2 = left2 * right0 + left6 * right1 + left10 * right2 + left14 * right3;
    let col0Row3 = left3 * right0 + left7 * right1 + left11 * right2 + left15 * right3;
    let col1Row0 = left0 * right4 + left4 * right5 + left8 * right6 + left12 * right7;
    let col1Row1 = left1 * right4 + left5 * right5 + left9 * right6 + left13 * right7;
    let col1Row2 = left2 * right4 + left6 * right5 + left10 * right6 + left14 * right7;
    let col1Row3 = left3 * right4 + left7 * right5 + left11 * right6 + left15 * right7;
    let col2Row0 = left0 * right8 + left4 * right9 + left8 * right10 + left12 * right11;
    let col2Row1 = left1 * right8 + left5 * right9 + left9 * right10 + left13 * right11;
    let col2Row2 = left2 * right8 + left6 * right9 + left10 * right10 + left14 * right11;
    let col2Row3 = left3 * right8 + left7 * right9 + left11 * right10 + left15 * right11;
    let col3Row0 = left0 * right12 + left4 * right13 + left8 * right14 + left12 * right15;
    let col3Row1 = left1 * right12 + left5 * right13 + left9 * right14 + left13 * right15;
    let col3Row2 = left2 * right12 + left6 * right13 + left10 * right14 + left14 * right15;
    let col3Row3 = left3 * right12 + left7 * right13 + left11 * right14 + left15 * right15;
    let result = defaultValueOrNew(out, Matrix4);
    let retDatas = result._data;
    retDatas[0] = col0Row0;
    retDatas[1] = col0Row1;
    retDatas[2] = col0Row2;
    retDatas[3] = col0Row3;
    retDatas[4] = col1Row0;
    retDatas[5] = col1Row1;
    retDatas[6] = col1Row2;
    retDatas[7] = col1Row3;
    retDatas[8] = col2Row0;
    retDatas[9] = col2Row1;
    retDatas[10] = col2Row2;
    retDatas[11] = col2Row3;
    retDatas[12] = col3Row0;
    retDatas[13] = col3Row1;
    retDatas[14] = col3Row2;
    retDatas[15] = col3Row3;
    return result;
  }
  static add(left, right, out) {
    let leftDatas = left._data;
    let rightDatas = right._data;
    let result = defaultValueOrNew(out, Matrix4);
    let retDatas = result._data;
    retDatas[0] = leftDatas[0] + rightDatas[0];
    retDatas[1] = leftDatas[1] + rightDatas[1];
    retDatas[2] = leftDatas[2] + rightDatas[2];
    retDatas[3] = leftDatas[3] + rightDatas[3];
    retDatas[4] = leftDatas[4] + rightDatas[4];
    retDatas[5] = leftDatas[5] + rightDatas[5];
    retDatas[6] = leftDatas[6] + rightDatas[6];
    retDatas[7] = leftDatas[7] + rightDatas[7];
    retDatas[8] = leftDatas[8] + rightDatas[8];
    retDatas[9] = leftDatas[9] + rightDatas[9];
    retDatas[10] = leftDatas[10] + rightDatas[10];
    retDatas[11] = leftDatas[11] + rightDatas[11];
    retDatas[12] = leftDatas[12] + rightDatas[12];
    retDatas[13] = leftDatas[13] + rightDatas[13];
    retDatas[14] = leftDatas[14] + rightDatas[14];
    retDatas[15] = leftDatas[15] + rightDatas[15];
    return result;
  }
  static subtract(left, right, out) {
    let leftDatas = left._data;
    let rightDatas = right._data;
    let result = defaultValueOrNew(out, Matrix4);
    let retDatas = result._data;
    retDatas[0] = leftDatas[0] - rightDatas[0];
    retDatas[1] = leftDatas[1] - rightDatas[1];
    retDatas[2] = leftDatas[2] - rightDatas[2];
    retDatas[3] = leftDatas[3] - rightDatas[3];
    retDatas[4] = leftDatas[4] - rightDatas[4];
    retDatas[5] = leftDatas[5] - rightDatas[5];
    retDatas[6] = leftDatas[6] - rightDatas[6];
    retDatas[7] = leftDatas[7] - rightDatas[7];
    retDatas[8] = leftDatas[8] - rightDatas[8];
    retDatas[9] = leftDatas[9] - rightDatas[9];
    retDatas[10] = leftDatas[10] - rightDatas[10];
    retDatas[11] = leftDatas[11] - rightDatas[11];
    retDatas[12] = leftDatas[12] - rightDatas[12];
    retDatas[13] = leftDatas[13] - rightDatas[13];
    retDatas[14] = leftDatas[14] - rightDatas[14];
    retDatas[15] = leftDatas[15] - rightDatas[15];
    return result;
  }
  static scale(left, scale, out) {
    let scaleX = scale[0];
    let scaleY = scale[1];
    let scaleZ = scale[2];
    let result = defaultValueOrNew(out, Matrix4);
    let retDatas = result._data;
    let leftDatas = left._data;
    retDatas[0] = scaleX * leftDatas[0];
    retDatas[1] = scaleX * leftDatas[1];
    retDatas[2] = scaleX * leftDatas[2];
    retDatas[3] = 0;
    retDatas[4] = scaleY * leftDatas[4];
    retDatas[5] = scaleY * leftDatas[5];
    retDatas[6] = scaleY * leftDatas[6];
    retDatas[7] = 0;
    retDatas[8] = scaleZ * leftDatas[8];
    retDatas[9] = scaleZ * leftDatas[9];
    retDatas[10] = scaleZ * leftDatas[10];
    retDatas[11] = 0;
    retDatas[12] = leftDatas[12];
    retDatas[13] = leftDatas[13];
    retDatas[14] = leftDatas[14];
    retDatas[15] = 1;
    return result;
  }
  static negate(matrix, out) {
    let result = defaultValueOrNew(out, Matrix4);
    let retDatas = result._data;
    let matrixDatas = matrix._data;
    retDatas[0] = -matrixDatas[0];
    retDatas[1] = -matrixDatas[1];
    retDatas[2] = -matrixDatas[2];
    retDatas[3] = -matrixDatas[3];
    retDatas[4] = -matrixDatas[4];
    retDatas[5] = -matrixDatas[5];
    retDatas[6] = -matrixDatas[6];
    retDatas[7] = -matrixDatas[7];
    retDatas[8] = -matrixDatas[8];
    retDatas[9] = -matrixDatas[9];
    retDatas[10] = -matrixDatas[10];
    retDatas[11] = -matrixDatas[11];
    retDatas[12] = -matrixDatas[12];
    retDatas[13] = -matrixDatas[13];
    retDatas[14] = -matrixDatas[14];
    retDatas[15] = -matrixDatas[15];
    return result;
  }
  static axisRotate(m, axis, angleInRadians, out) {
    const result = defaultValueOrNew(out, Matrix4);
    const data = result.data;
    const input = m.data;
    const axisData = axis;
    let x = axisData[0];
    let y = axisData[1];
    let z = axisData[2];
    const n = Math.sqrt(x * x + y * y + z * z);
    x /= n;
    y /= n;
    z /= n;
    const xx = x * x;
    const yy = y * y;
    const zz = z * z;
    const c = Math.cos(angleInRadians);
    const s = Math.sin(angleInRadians);
    const oneMinusCosine = 1 - c;
    const r00 = xx + (1 - xx) * c;
    const r01 = x * y * oneMinusCosine + z * s;
    const r02 = x * z * oneMinusCosine - y * s;
    const r10 = x * y * oneMinusCosine - z * s;
    const r11 = yy + (1 - yy) * c;
    const r12 = y * z * oneMinusCosine + x * s;
    const r20 = x * z * oneMinusCosine + y * s;
    const r21 = y * z * oneMinusCosine - x * s;
    const r22 = zz + (1 - zz) * c;
    const m00 = input[0];
    const m01 = input[1];
    const m02 = input[2];
    const m03 = input[3];
    const m10 = input[4];
    const m11 = input[5];
    const m12 = input[6];
    const m13 = input[7];
    const m20 = input[8];
    const m21 = input[9];
    const m22 = input[10];
    const m23 = input[11];
    data[0] = r00 * m00 + r01 * m10 + r02 * m20;
    data[1] = r00 * m01 + r01 * m11 + r02 * m21;
    data[2] = r00 * m02 + r01 * m12 + r02 * m22;
    data[3] = r00 * m03 + r01 * m13 + r02 * m23;
    data[4] = r10 * m00 + r11 * m10 + r12 * m20;
    data[5] = r10 * m01 + r11 * m11 + r12 * m21;
    data[6] = r10 * m02 + r11 * m12 + r12 * m22;
    data[7] = r10 * m03 + r11 * m13 + r12 * m23;
    data[8] = r20 * m00 + r21 * m10 + r22 * m20;
    data[9] = r20 * m01 + r21 * m11 + r22 * m21;
    data[10] = r20 * m02 + r21 * m12 + r22 * m22;
    data[11] = r20 * m03 + r21 * m13 + r22 * m23;
    if (m !== result) {
      data[12] = m[12];
      data[13] = m[13];
      data[14] = m[14];
      data[15] = m[15];
    }
    return result;
  }
  /**
   * reference to three.js
   * @param eye
   * @param target
   * @param up
   */
  static lookat(eye, target, up, out) {
    let vecZ = new Vector3();
    let vecX = new Vector3();
    let vecY = new Vector3();
    let result = defaultValueOrNew(out, Matrix4);
    let retDatas = result._data;
    Vector3.sub(eye, target, vecZ);
    if (Vector3.sizeSquare(vecZ) === 0) {
      vecZ[2] = 1;
    }
    Vector3.normalize(vecZ, vecZ);
    Vector3.cross(up, vecZ, vecX);
    if (Vector3.sizeSquare(vecX) === 0) {
      if (Math.abs(up[2]) === 1) {
        vecZ[0] += 1e-4;
      } else {
        vecZ[2] += 1e-4;
      }
      Vector3.normalize(vecZ, vecZ);
      Vector3.cross(up, vecZ, vecX);
    }
    Vector3.normalize(vecX, vecX);
    Vector3.cross(vecZ, vecX, vecY);
    retDatas[0] = vecX[0];
    retDatas[4] = vecY[0];
    retDatas[8] = vecZ[0];
    retDatas[1] = vecX[1];
    retDatas[5] = vecY[1];
    retDatas[9] = vecZ[1];
    retDatas[2] = vecX[2];
    retDatas[6] = vecY[2];
    retDatas[10] = vecZ[2];
    return result;
  }
  /**
   * reference to gl-matrix
   * @param eye
   * @param target
   * @param up
   * @param result
   */
  static lookAt2(eye, target, up, out) {
    let result = defaultValueOrNew(out, Matrix4);
    var x0, x1, x2, y0, y1, y2, z0, z1, z2, len;
    var eyex = eye[0];
    var eyey = eye[1];
    var eyez = eye[2];
    var upx = up[0];
    var upy = up[1];
    var upz = up[2];
    var centerx = target[0];
    var centery = target[1];
    var centerz = target[2];
    var EPSILON2 = 1e-6;
    if (Math.abs(eyex - centerx) < EPSILON2 && Math.abs(eyey - centery) < EPSILON2 && Math.abs(eyez - centerz) < EPSILON2) {
      return Matrix4.identity();
    }
    z0 = eyex - centerx;
    z1 = eyey - centery;
    z2 = eyez - centerz;
    len = 1 / Math.sqrt(z0 * z0 + z1 * z1 + z2 * z2);
    z0 *= len;
    z1 *= len;
    z2 *= len;
    x0 = upy * z2 - upz * z1;
    x1 = upz * z0 - upx * z2;
    x2 = upx * z1 - upy * z0;
    len = Math.sqrt(x0 * x0 + x1 * x1 + x2 * x2);
    if (!len) {
      x0 = 0;
      x1 = 0;
      x2 = 0;
    } else {
      len = 1 / len;
      x0 *= len;
      x1 *= len;
      x2 *= len;
    }
    y0 = z1 * x2 - z2 * x1;
    y1 = z2 * x0 - z0 * x2;
    y2 = z0 * x1 - z1 * x0;
    len = Math.sqrt(y0 * y0 + y1 * y1 + y2 * y2);
    if (!len) {
      y0 = 0;
      y1 = 0;
      y2 = 0;
    } else {
      len = 1 / len;
      y0 *= len;
      y1 *= len;
      y2 *= len;
    }
    let retDatas = result.data;
    retDatas[0] = x0;
    retDatas[1] = y0;
    retDatas[2] = z0;
    retDatas[3] = 0;
    retDatas[4] = x1;
    retDatas[5] = y1;
    retDatas[6] = z1;
    retDatas[7] = 0;
    retDatas[8] = x2;
    retDatas[9] = y2;
    retDatas[10] = z2;
    retDatas[11] = 0;
    retDatas[12] = -(x0 * eyex + x1 * eyey + x2 * eyez);
    retDatas[13] = -(y0 * eyex + y1 * eyey + y2 * eyez);
    retDatas[14] = -(z0 * eyex + z1 * eyey + z2 * eyez);
    retDatas[15] = 1;
    return result;
  }
  /**
   * Generates a perspective projection matrix
   * @param fovyrad  the radian of fov
   * @param aspect the aspect ratio of the frustum.(width/height)
   * @param near the distance to the nearer depth clipping plane
   * @param far the distance to the farther depth clipping plane
   */
  static perspective(fovyrad, aspect, near, far, out) {
    let result = defaultValueOrNew(out, Matrix4);
    let retDatas = result._data;
    var f = 1 / Math.tan(fovyrad / 2), nf;
    retDatas[0] = f / aspect;
    retDatas[1] = 0;
    retDatas[2] = 0;
    retDatas[3] = 0;
    retDatas[4] = 0;
    retDatas[5] = f;
    retDatas[6] = 0;
    retDatas[7] = 0;
    retDatas[8] = 0;
    retDatas[9] = 0;
    retDatas[11] = -1;
    retDatas[12] = 0;
    retDatas[13] = 0;
    retDatas[15] = 0;
    if (far != null && far !== Infinity) {
      nf = 1 / (near - far);
      retDatas[10] = (far + near) * nf;
      retDatas[14] = 2 * far * near * nf;
    } else {
      retDatas[10] = -1;
      retDatas[14] = -2 * near;
    }
    return result;
  }
  static orthographic(left, right, bottom, top, near, far, out) {
    let result = defaultValueOrNew(out, Matrix4);
    const rl = 1 / (right - left);
    const tb = 1 / (top - bottom);
    const nf = 1 / (near - far);
    Matrix4.fromArray(
      [
        2 * rl,
        0,
        0,
        0,
        0,
        2 * tb,
        0,
        0,
        0,
        0,
        2 * nf,
        0,
        -(left + right) * rl,
        -(top + bottom) * tb,
        (far + near) * nf,
        1
      ],
      result
    );
    return result;
  }
  static fromQuat(q, out) {
    let result = defaultValueOrNew(out, Matrix4);
    const { x, y, z, w } = q;
    const x2 = x + x;
    const y2 = y + y;
    const z2 = z + z;
    const xx = x * x2;
    const xy = x * y2;
    const xz = x * z2;
    const yy = y * y2;
    const yz = y * z2;
    const zz = z * z2;
    const wx = w * x2;
    const wy = w * y2;
    const wz = w * z2;
    result._data.set([
      1 - (yy + zz),
      xy + wz,
      xz - wy,
      0,
      xy - wz,
      1 - (xx + zz),
      yz + wx,
      0,
      xz + wy,
      yz - wx,
      1 - (xx + yy),
      0,
      0,
      0,
      0,
      1
    ]);
    return result;
  }
  /**
   * only for order "XYZ"
   * @param m
   * @returns
   */
  static toEulerAngles(mat) {
    let pitch, yaw, roll;
    const m = mat._data;
    if (m[10] < 0.998 && m[10] > -0.998) {
      pitch = Math.asin(-m[8]);
      yaw = Math.atan2(m[6], m[10]);
      roll = Math.atan2(m[4], m[0]);
    } else {
      pitch = Math.atan2(m[6], m[7]);
      yaw = Math.atan2(m[2], m[1]);
      roll = 0;
    }
    return [pitch, yaw, roll];
  }
  //inverse
  static invert(mat, out) {
    let result = defaultValueOrNew(out, Matrix4);
    let a = mat._data;
    let a00 = a[0], a01 = a[1], a02 = a[2], a03 = a[3];
    let a10 = a[4], a11 = a[5], a12 = a[6], a13 = a[7];
    let a20 = a[8], a21 = a[9], a22 = a[10], a23 = a[11];
    let a30 = a[12], a31 = a[13], a32 = a[14], a33 = a[15];
    let b00 = a00 * a11 - a01 * a10;
    let b01 = a00 * a12 - a02 * a10;
    let b02 = a00 * a13 - a03 * a10;
    let b03 = a01 * a12 - a02 * a11;
    let b04 = a01 * a13 - a03 * a11;
    let b05 = a02 * a13 - a03 * a12;
    let b06 = a20 * a31 - a21 * a30;
    let b07 = a20 * a32 - a22 * a30;
    let b08 = a20 * a33 - a23 * a30;
    let b09 = a21 * a32 - a22 * a31;
    let b10 = a21 * a33 - a23 * a31;
    let b11 = a22 * a33 - a23 * a32;
    let det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
    if (!det) {
      return null;
    }
    det = 1 / det;
    result._data.set([
      (a11 * b11 - a12 * b10 + a13 * b09) * det,
      (a02 * b10 - a01 * b11 - a03 * b09) * det,
      (a31 * b05 - a32 * b04 + a33 * b03) * det,
      (a22 * b04 - a21 * b05 - a23 * b03) * det,
      (a12 * b08 - a10 * b11 - a13 * b07) * det,
      (a00 * b11 - a02 * b08 + a03 * b07) * det,
      (a32 * b02 - a30 * b05 - a33 * b01) * det,
      (a20 * b05 - a22 * b02 + a23 * b01) * det,
      (a10 * b10 - a11 * b08 + a13 * b06) * det,
      (a01 * b08 - a00 * b10 - a03 * b06) * det,
      (a30 * b04 - a31 * b02 + a33 * b00) * det,
      (a21 * b02 - a20 * b04 - a23 * b00) * det,
      (a11 * b07 - a10 * b09 - a12 * b06) * det,
      (a00 * b09 - a01 * b07 + a02 * b06) * det,
      (a31 * b01 - a30 * b03 - a32 * b00) * det,
      (a20 * b03 - a21 * b01 + a22 * b00) * det
    ]);
    return result;
  }
  /**
   * make a matrix4 from a vec3 data of translation
   * @param val
   * @returns
   */
  static fromTranslation(val) {
    const mat = Matrix4.identity();
    mat._data[12] = val[0];
    mat._data[13] = val[1];
    mat._data[14] = val[2];
    return mat;
  }
  static identity(out) {
    let ret = out || new Matrix4();
    const m = ret._data;
    m[0] = 1;
    m[1] = 0;
    m[2] = 0;
    m[3] = 0;
    m[4] = 0;
    m[5] = 1;
    m[6] = 0;
    m[7] = 0;
    m[8] = 0;
    m[9] = 0;
    m[10] = 1;
    m[11] = 0;
    m[12] = 0;
    m[13] = 0;
    m[14] = 0;
    m[15] = 1;
    return ret;
  }
  static translate(m4, v3, out) {
    let ret = out || new Matrix4();
    const m = m4.data;
    const v = v3;
    const rd = ret.data;
    const v0 = v[0];
    const v1 = v[1];
    const v2 = v[2];
    const m00 = m[0];
    const m01 = m[1];
    const m02 = m[2];
    const m03 = m[3];
    const m10 = m[4];
    const m11 = m[5];
    const m12 = m[6];
    const m13 = m[7];
    const m20 = m[8];
    const m21 = m[9];
    const m22 = m[10];
    const m23 = m[11];
    const m30 = m[12];
    const m31 = m[13];
    const m32 = m[14];
    const m33 = m[15];
    if (m4 !== out) {
      rd[0] = m00;
      rd[1] = m01;
      rd[2] = m02;
      rd[3] = m03;
      rd[4] = m10;
      rd[5] = m11;
      rd[6] = m12;
      rd[7] = m13;
      rd[8] = m20;
      rd[9] = m21;
      rd[10] = m22;
      rd[11] = m23;
    }
    rd[12] = m00 * v0 + m10 * v1 + m20 * v2 + m30;
    rd[13] = m01 * v0 + m11 * v1 + m21 * v2 + m31;
    rd[14] = m02 * v0 + m12 * v1 + m22 * v2 + m32;
    rd[15] = m03 * v0 + m13 * v1 + m23 * v2 + m33;
    return ret;
  }
}
function radiansToDegrees(rad) {
  return rad * C180_DIVIDE_PI;
}
function degreesToRadians(deg) {
  return deg * CPI_DIVIDE_180;
}
function rand(min, max) {
  return min + Math.random() * (max - min);
}
function areRectanglesIntersecting(rect1, rect2, isYflip = false) {
  if (rect1.topRight[0] < rect2.bottomLeft[0] || rect2.topRight[0] < rect1.bottomLeft[0]) {
    return false;
  }
  if (isYflip) {
    if (rect1.topRight[1] > rect2.bottomLeft[1] || rect2.topRight[1] > rect1.bottomLeft[1]) {
      return false;
    }
  } else {
    if (rect1.topRight[1] < rect2.bottomLeft[1] || rect2.topRight[1] < rect1.bottomLeft[1]) {
      return false;
    }
  }
  return true;
}
const utils = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  areRectanglesIntersecting,
  degreesToRadians,
  radiansToDegrees,
  rand
}, Symbol.toStringTag, { value: "Module" }));
var LogLevel = /* @__PURE__ */ ((LogLevel2) => {
  LogLevel2[LogLevel2["DEBUG"] = 0] = "DEBUG";
  LogLevel2[LogLevel2["INFO"] = 1] = "INFO";
  LogLevel2[LogLevel2["WARN"] = 2] = "WARN";
  LogLevel2[LogLevel2["ERROR"] = 3] = "ERROR";
  return LogLevel2;
})(LogLevel || {});
class Logger {
  constructor(level) {
    this._level = level;
  }
  getCurrentTime() {
    return performance.now().toString();
  }
  print(level, message, ...args) {
    if (level >= this._level) {
      const timestamp = this.getCurrentTime().split(".")[0];
      const levelName = LogLevel[level];
      const stack = new Error().stack;
      const stackLine = stack.split("\n")[3];
      const trace = stackLine.match(/at\s(.*):(\d+):(\d+)/) || [];
      const fileInfo = trace[1] ? ` [${trace[1]}:${trace[2]}]` : "";
      if (args && args.length > 0) {
        console.log(
          `[Mirror:${timestamp}]${fileInfo} [${levelName}] ${message}`,
          ...args
        );
      } else {
        console.log(
          `[Mirror:${timestamp}]${fileInfo} [${levelName}] ${message}`
        );
      }
    }
  }
  get level() {
    return this._level;
  }
  set level(val) {
    this._level = val;
  }
  debug(message, ...args) {
    if (args && args.length > 0) {
      this.print(0, message, ...args);
    } else {
      this.print(0, message);
    }
  }
  log(message, ...args) {
    if (args && args.length > 0) {
      this.print(1, message, ...args);
    } else {
      this.print(1, message);
    }
  }
  warn(message, ...args) {
    if (args && args.length > 0) {
      this.print(2, message, ...args);
    } else {
      this.print(2, message);
    }
  }
  error(message, ...args) {
    throw Error(message);
  }
}
const logger = new Logger(
  1
  /* INFO */
);
class Utils {
  static calculateTimestamp(callback, flag) {
    let start = performance.now();
    callback();
    let diffMillSeconds = performance.now() - start;
    flag = flag || "";
    logger.debug(
      `${flag}: a task took ${diffMillSeconds}  milliseconds to execute. `
    );
  }
  /**
     * 示例 \
     * const uuid = generateUUID();\
       console.log(uuid); 
        >> 输出类似 "f47ac10b-58cc-4372-a567-0e02b2c3d479"
     * @returns 
     */
  static generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c === "x" ? r : r & 3 | 8;
      return v.toString(16);
    });
  }
  /**
   * Extracts the file name (including the extension) from the given file path.
   * If the path does not contain a file name, the original path is returned.
   *
   * @param filePath - The path string containing the file name.
   * @returns The extracted file name (including the extension), or the original path if no file name is present.
   */
  static getFileNameFromPath(filePath) {
    const match = filePath.match(/\/([^/]+)$/);
    if (match && match.length > 1) {
      return match[1];
    }
    return filePath;
  }
  static deepCopy(o) {
    if (o instanceof Array) {
      let n = [];
      for (let i = 0; i < o.length; ++i) {
        n[i] = Utils.deepCopy(o[i]);
      }
      return n;
    } else if (o instanceof Object) {
      let n = {};
      for (let i in o) {
        n[i] = Utils.deepCopy(o[i]);
      }
      return n;
    } else {
      return o;
    }
  }
}
class MObject {
  constructor() {
    this._name = "";
    this._className = "MObject";
    this._isModified = false;
    this._modifyCallbacks = [];
    this._id = Utils.generateUUID();
  }
  get name() {
    return this._name;
  }
  get className() {
    return this._className;
  }
  get id() {
    return this._id;
  }
  set name(v) {
    this._name = v;
  }
  set isModified(v) {
    this._isModified = v;
  }
  get isModified() {
    return this._isModified;
  }
  // onModified(callback:()=>void){
  //   this._modifyCallbacks.push(callback)
  // }
  // modified(){
  //   for (let index = 0,len = this._modifyCallbacks.length; index < len; index++) {
  //     const callback = this._modifyCallbacks[index];
  //     callback && callback()
  //   }
  // }
  // copyFrom(val){
  //   this._id = val.id();
  // }
}
class LoadingManager extends MObject {
  constructor(onStart, onLoad, onProgress, onError) {
    super();
    this.onStart = onStart;
    this.onLoad = onLoad;
    this.onProgress = onProgress;
    this.onError = onError;
    this.itemsTotal = 0;
    this.itemsLoaded = 0;
    this.isLoading = false;
    this._className = "LoadingManager";
  }
  load(url, onLoad, onProgress, onError) {
  }
  itemStart(url) {
    this.itemsTotal++;
    if (this.isLoading === false) {
      if (this.onStart) {
        this.onStart(url, this.itemsLoaded, this.itemsTotal);
      }
    }
    this.isLoading = true;
  }
  itemEnd(url) {
    this.itemsLoaded++;
    if (this.onProgress) {
      this.onProgress(url, this.itemsLoaded, this.itemsTotal);
    }
    if (this.itemsLoaded === this.itemsTotal) {
      this.isLoading = false;
      if (this.onLoad) {
        this.onLoad();
      }
    }
  }
  itemError(url) {
    if (this.onError) {
      this.onError(url);
    }
  }
}
async function fetchFile(url) {
  const resp = await fetch(url);
  const blob = await resp.blob();
  const ret = await blob.arrayBuffer();
  return ret;
}
async function fetchText(url) {
  const resp = await fetch(url);
  const ret = await resp.text();
  return ret;
}
async function fetchJson(url) {
  const resp = await fetch(url);
  const ret = await resp.json();
  return ret;
}
async function fetchScript(url) {
  const resp = await fetch(url);
  const scriptText = await resp.text();
  return scriptText;
}
async function loadImageElement(url) {
  return new Promise((resolve, rejected) => {
    const img = new Image();
    img.onload = (e) => {
      resolve(img);
    };
    img.onerror = (e) => {
      logger.warn("load Image false", e, url);
      rejected(e);
    };
    img.src = url;
  });
}
async function loadImageBitmap(url) {
  try {
    const res = await fetch(url);
    const blob = await res.blob();
    return createImageBitmap(blob, {
      colorSpaceConversion: "none",
      resizeQuality: "high"
    });
  } catch (err) {
    logger.warn(err);
  }
  return null;
}
const _floatView = new Float32Array(1);
const _int32View = new Int32Array(_floatView.buffer);
function toHalfFloat(val) {
  _floatView[0] = val;
  const x = _int32View[0];
  let bits = x >> 16 & 32768;
  let m = x >> 12 & 2047;
  const e = x >> 23 & 255;
  if (e < 103) return bits;
  if (e > 142) {
    bits |= 31744;
    bits |= (e == 255 ? 0 : 1) && x & 8388607;
    return bits;
  }
  if (e < 113) {
    m |= 2048;
    bits |= (m >> 114 - e) + (m >> 113 - e & 1);
    return bits;
  }
  bits |= e - 112 << 10 | m >> 1;
  bits += m & 1;
  return bits;
}
class RGBELoader extends LoadingManager {
  constructor() {
    super();
    this.type = 0;
  }
  parse(buffer) {
    var rgbe_read_error = 1, rgbe_write_error = 2, rgbe_format_error = 3, rgbe_memory_error = 4, rgbe_error = (rgbe_error_code, msg) => {
      switch (rgbe_error_code) {
        case rgbe_read_error:
          logger.error("RGBELoader Read Error: " + (msg || ""));
          break;
        case rgbe_write_error:
          logger.error("RGBELoader Write Error: " + (msg || ""));
          break;
        case rgbe_format_error:
          logger.error("RGBELoader Bad File Format: " + (msg || ""));
          break;
        default:
        case rgbe_memory_error:
          logger.error("RGBELoader: Error: " + (msg || ""));
      }
      return -1;
    }, RGBE_VALID_PROGRAMTYPE = 1, RGBE_VALID_FORMAT = 2, RGBE_VALID_DIMENSIONS = 4, NEWLINE = "\n";
    const fgets = (buffer2, lineLimit, consume) => {
      lineLimit = !lineLimit ? 1024 : lineLimit;
      var p = buffer2.pos, i = -1, len = 0, s = "", chunkSize = 128, chunk = String.fromCharCode.apply(null, new Uint16Array(buffer2.subarray(p, p + chunkSize)));
      while (0 > (i = chunk.indexOf(NEWLINE)) && len < lineLimit && p < buffer2.byteLength) {
        s += chunk;
        len += chunk.length;
        p += chunkSize;
        chunk += String.fromCharCode.apply(null, new Uint16Array(buffer2.subarray(p, p + chunkSize)));
      }
      if (-1 < i) {
        buffer2.pos += len + i + 1;
        return s + chunk.slice(0, i);
      }
      return null;
    };
    const RGBE_ReadHeader = (buffer2) => {
      var line, match, magic_token_re = /^#\?(\S+)/, gamma_re = /^\s*GAMMA\s*=\s*(\d+(\.\d+)?)\s*$/, exposure_re = /^\s*EXPOSURE\s*=\s*(\d+(\.\d+)?)\s*$/, format_re = /^\s*FORMAT=(\S+)\s*$/, dimensions_re = /^\s*\-Y\s+(\d+)\s+\+X\s+(\d+)\s*$/, header = {
        valid: 0,
        /* indicate which fields are valid */
        string: "",
        /* the actual header string */
        comments: "",
        /* comments found in header */
        programtype: "RGBE",
        /* listed at beginning of file to identify it after "#?". defaults to "RGBE" */
        format: "",
        /* RGBE format, default 32-bit_rle_rgbe */
        gamma: 1,
        /* image has already been gamma corrected with given gamma. defaults to 1.0 (no correction) */
        exposure: 1,
        /* a value of 1.0 in an image corresponds to <exposure> watts/steradian/m^2. defaults to 1.0 */
        width: 0,
        height: 0
        /* image dimensions, width/height */
      };
      if (buffer2.pos >= buffer2.byteLength || !(line = fgets(buffer2))) {
        return rgbe_error(rgbe_read_error, "no header found");
      }
      if (!(match = line.match(magic_token_re))) {
        return rgbe_error(rgbe_format_error, "bad initial token");
      }
      header.valid |= RGBE_VALID_PROGRAMTYPE;
      header.programtype = match[1];
      header.string += line + "\n";
      while (true) {
        line = fgets(buffer2);
        if (null === line) break;
        header.string += line + "\n";
        if ("#" === line.charAt(0)) {
          header.comments += line + "\n";
          continue;
        }
        if (match = line.match(gamma_re)) {
          header.gamma = parseFloat(match[1]);
        }
        if (match = line.match(exposure_re)) {
          header.exposure = parseFloat(match[1]);
        }
        if (match = line.match(format_re)) {
          header.valid |= RGBE_VALID_FORMAT;
          header.format = match[1];
        }
        if (match = line.match(dimensions_re)) {
          header.valid |= RGBE_VALID_DIMENSIONS;
          header.height = parseInt(match[1], 10);
          header.width = parseInt(match[2], 10);
        }
        if (header.valid & RGBE_VALID_FORMAT && header.valid & RGBE_VALID_DIMENSIONS) break;
      }
      if (!(header.valid & RGBE_VALID_FORMAT)) {
        return rgbe_error(rgbe_format_error, "missing format specifier");
      }
      if (!(header.valid & RGBE_VALID_DIMENSIONS)) {
        return rgbe_error(rgbe_format_error, "missing image size specifier");
      }
      return header;
    };
    const RGBE_ReadPixels_RLE = (buffer2, w, h) => {
      var data_rgba, offset, pos, count, byteValue, scanline_buffer, ptr, ptr_end, i, l, off, isEncodedRun, scanline_width = w, num_scanlines = h, rgbeStart;
      if (
        // run length encoding is not allowed so read flat
        scanline_width < 8 || scanline_width > 32767 || // this file is not run length encoded
        (2 !== buffer2[0] || 2 !== buffer2[1] || buffer2[2] & 128)
      ) {
        return new Uint8Array(buffer2);
      }
      if (scanline_width !== (buffer2[2] << 8 | buffer2[3])) {
        return rgbe_error(rgbe_format_error, "wrong scanline width");
      }
      data_rgba = new Uint8Array(4 * w * h);
      if (!data_rgba.length) {
        return rgbe_error(rgbe_memory_error, "unable to allocate buffer space");
      }
      offset = 0;
      pos = 0;
      ptr_end = 4 * scanline_width;
      rgbeStart = new Uint8Array(4);
      scanline_buffer = new Uint8Array(ptr_end);
      while (num_scanlines > 0 && pos < buffer2.byteLength) {
        if (pos + 4 > buffer2.byteLength) {
          return rgbe_error(rgbe_read_error);
        }
        rgbeStart[0] = buffer2[pos++];
        rgbeStart[1] = buffer2[pos++];
        rgbeStart[2] = buffer2[pos++];
        rgbeStart[3] = buffer2[pos++];
        if (2 != rgbeStart[0] || 2 != rgbeStart[1] || (rgbeStart[2] << 8 | rgbeStart[3]) != scanline_width) {
          return rgbe_error(rgbe_format_error, "bad rgbe scanline format");
        }
        ptr = 0;
        while (ptr < ptr_end && pos < buffer2.byteLength) {
          count = buffer2[pos++];
          isEncodedRun = count > 128;
          if (isEncodedRun) count -= 128;
          if (0 === count || ptr + count > ptr_end) {
            return rgbe_error(rgbe_format_error, "bad scanline data");
          }
          if (isEncodedRun) {
            byteValue = buffer2[pos++];
            for (i = 0; i < count; i++) {
              scanline_buffer[ptr++] = byteValue;
            }
          } else {
            scanline_buffer.set(buffer2.subarray(pos, pos + count), ptr);
            ptr += count;
            pos += count;
          }
        }
        l = scanline_width;
        for (i = 0; i < l; i++) {
          off = 0;
          data_rgba[offset] = scanline_buffer[i + off];
          off += scanline_width;
          data_rgba[offset + 1] = scanline_buffer[i + off];
          off += scanline_width;
          data_rgba[offset + 2] = scanline_buffer[i + off];
          off += scanline_width;
          data_rgba[offset + 3] = scanline_buffer[i + off];
          offset += 4;
        }
        num_scanlines--;
      }
      return data_rgba;
    };
    const RGBEByteToRGBFloat = (sourceArray, sourceOffset, destArray, destOffset) => {
      var e = sourceArray[sourceOffset + 3];
      var scale = Math.pow(2, e - 128) / 255;
      destArray[destOffset + 0] = sourceArray[sourceOffset + 0] * scale;
      destArray[destOffset + 1] = sourceArray[sourceOffset + 1] * scale;
      destArray[destOffset + 2] = sourceArray[sourceOffset + 2] * scale;
    };
    const RGBEByteToRGBHalf = (sourceArray, sourceOffset, destArray, destOffset) => {
      let e = sourceArray[sourceOffset + 3];
      let scale = Math.pow(2, e - 128) / 255;
      destArray[destOffset + 0] = toHalfFloat(sourceArray[sourceOffset + 0] * scale);
      destArray[destOffset + 1] = toHalfFloat(sourceArray[sourceOffset + 1] * scale);
      destArray[destOffset + 2] = toHalfFloat(sourceArray[sourceOffset + 2] * scale);
    };
    let byteArray = new Uint8Array(buffer);
    byteArray.pos = 0;
    let rgbe_header_info = RGBE_ReadHeader(byteArray);
    logger.debug(">>>readHeader:", rgbe_header_info);
    if (-1 !== rgbe_header_info) {
      let w = rgbe_header_info.width, h = rgbe_header_info.height, image_rgba_data = RGBE_ReadPixels_RLE(byteArray.subarray(byteArray.pos), w, h);
      if (-1 !== image_rgba_data) {
        let data;
        let format;
        const srcData = image_rgba_data;
        switch (this.type) {
          case 0:
            data = srcData;
            format = 0;
            break;
          case 1:
            var numElements = srcData.length / 4 * 3;
            var floatArray = new Float32Array(numElements);
            for (var j = 0; j < numElements; j++) {
              RGBEByteToRGBFloat(srcData, j * 4, floatArray, j * 3);
            }
            data = floatArray;
            format = 1;
            break;
          case 2:
            var numElements = srcData.length / 4 * 3;
            var halfArray = new Uint16Array(numElements);
            for (var j = 0; j < numElements; j++) {
              RGBEByteToRGBHalf(srcData, j * 4, halfArray, j * 3);
            }
            data = halfArray;
            format = 1;
            break;
          default:
            logger.error("RGBELoader: unsupported type: ", this.type);
            break;
        }
        return {
          width: w,
          height: h,
          data,
          header: rgbe_header_info.string,
          gamma: rgbe_header_info.gamma,
          exposure: rgbe_header_info.exposure,
          format,
          type: this.type
        };
      }
    }
    return null;
  }
  async load(url) {
    const rgbeArrayBuffer = await fetchFile(url);
    return this.parse(rgbeArrayBuffer);
  }
}
class MapCache {
  constructor() {
    this.objects = {};
  }
  get(key) {
    return this.objects[key];
  }
  add(key, object) {
    this.objects[key] = object;
  }
  remove(key) {
    delete this.objects[key];
  }
  clear() {
    this.objects = {};
  }
}
class ImageLoader extends LoadingManager {
  constructor() {
    super();
    this._cache = new MapCache();
  }
  static get singleton() {
    if (ImageLoader._singleton === void 0) {
      ImageLoader._singleton = new ImageLoader();
    }
    return ImageLoader._singleton;
  }
  load(url, onLoad, onProgress, onError) {
    let cached = this._cache.get(url);
    if (cached) {
      this.itemStart(url);
      setTimeout(() => {
        if (onLoad) onLoad(cached);
        this.itemEnd(url);
      }, 0);
      return cached;
    }
    let img = new Image();
    img.crossOrigin = "";
    img.src = url;
    if (img.complete) {
      this.itemStart(url);
      setTimeout(() => {
        if (onLoad) onLoad(img);
        this.itemEnd(url);
      }, 0);
      return;
    }
    img.onload = () => {
      if (onLoad) onLoad(img);
      this.itemEnd(url);
    };
  }
}
class FileLoader extends LoadingManager {
  constructor() {
    super();
    this._cache = new MapCache();
    this._loading = {};
  }
  static get singleton() {
    if (!this._instanse) {
      this._instanse = new FileLoader();
    }
    return this._instanse;
  }
  setPath(path) {
    this._path = path;
  }
  load(url, onLoad, onProgress, onError) {
    let loadUrl = url || "";
    if (this._path) {
      loadUrl = this._path + loadUrl;
    }
    let cached = this._cache.get(url);
    if (cached !== void 0) {
      this.itemStart(url);
      setTimeout(() => {
        if (onLoad) onLoad(cached);
        this.itemEnd(url);
      }, 0);
      return cached;
    }
    if (this._loading[url] != void 0) {
      this._loading[url].push({
        onLoad,
        onProgress,
        onErr: onError
      });
      return;
    }
    let dataUriRegex = /^data:(.*?)(;base64)?,(.*)$/;
    url.match(dataUriRegex);
    this._loading[url] = [];
    this._loading[url].push({
      onLoad,
      onProgress,
      onErr: onError
    });
    this._fetchResource(url).then((resp) => {
      let callbacks = this._loading[url];
      delete this._loading[url];
      if (resp.ok === true) {
        callbacks.forEach((callback) => {
          if (callback.onLoad) {
            callback.onLoad(resp);
          }
        });
        this.itemEnd(url);
      } else {
        this.itemError(url);
        this.itemEnd(url);
      }
    });
    this.itemStart(url);
  }
  /**
   * request a resource in the url by 'fetch'
   * @param url 
   */
  _fetchResource(url) {
    return fetch(url);
  }
}
const fileloader = FileLoader.singleton;
class LoaderUtils {
  static decodeText(array) {
    if (typeof TextDecoder !== "undefined") {
      return new TextDecoder().decode(array);
    }
    var s = "";
    for (var i = 0, il = array.length; i < il; i++) {
      s += String.fromCharCode(array[i]);
    }
    return decodeURIComponent(escape(s));
  }
  static extractUrlBase(url) {
    var index = url.lastIndexOf("/");
    if (index === -1) return "./";
    return url.substr(0, index + 1);
  }
}
var GLDrawType = /* @__PURE__ */ ((GLDrawType2) => {
  GLDrawType2[GLDrawType2["STATIC_DRAW"] = 0] = "STATIC_DRAW";
  GLDrawType2[GLDrawType2["DYNAMIC_DRAW"] = 1] = "DYNAMIC_DRAW";
  GLDrawType2[GLDrawType2["STREAM_DRAW"] = 2] = "STREAM_DRAW";
  return GLDrawType2;
})(GLDrawType || {});
var GLBingBufferTarget = /* @__PURE__ */ ((GLBingBufferTarget2) => {
  GLBingBufferTarget2[GLBingBufferTarget2["ARRAY_BUFFER"] = 0] = "ARRAY_BUFFER";
  GLBingBufferTarget2[GLBingBufferTarget2["ELEMENT_ARRAY_BUFFER"] = 1] = "ELEMENT_ARRAY_BUFFER";
  return GLBingBufferTarget2;
})(GLBingBufferTarget || {});
var EGLDataType = /* @__PURE__ */ ((EGLDataType2) => {
  EGLDataType2[EGLDataType2["BYTE"] = 5120] = "BYTE";
  EGLDataType2[EGLDataType2["UNSIGNED_BYTE"] = 5121] = "UNSIGNED_BYTE";
  EGLDataType2[EGLDataType2["SHORT"] = 5122] = "SHORT";
  EGLDataType2[EGLDataType2["UNSIGNED_SHORT"] = 5123] = "UNSIGNED_SHORT";
  EGLDataType2[EGLDataType2["INT"] = 5124] = "INT";
  EGLDataType2[EGLDataType2["UNSIGNED_INT"] = 5125] = "UNSIGNED_INT";
  EGLDataType2[EGLDataType2["FLOAT"] = 5126] = "FLOAT";
  EGLDataType2[EGLDataType2["UNSIGNED_SHORT_4_4_4_4"] = 32819] = "UNSIGNED_SHORT_4_4_4_4";
  EGLDataType2[EGLDataType2["UNSIGNED_SHORT_5_5_5_1"] = 32820] = "UNSIGNED_SHORT_5_5_5_1";
  EGLDataType2[EGLDataType2["UNSIGNED_SHORT_5_6_5"] = 33635] = "UNSIGNED_SHORT_5_6_5";
  EGLDataType2[EGLDataType2["HALF_FLOAT"] = 5131] = "HALF_FLOAT";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_2_10_10_10_REV"] = 33640] = "UNSIGNED_INT_2_10_10_10_REV";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_10F_11F_11F_REV"] = 35899] = "UNSIGNED_INT_10F_11F_11F_REV";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_5_9_9_9_REV"] = 35902] = "UNSIGNED_INT_5_9_9_9_REV";
  EGLDataType2[EGLDataType2["FLOAT_32_UNSIGNED_INT_24_8_REV"] = 36269] = "FLOAT_32_UNSIGNED_INT_24_8_REV";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_24_8"] = 34042] = "UNSIGNED_INT_24_8";
  EGLDataType2[EGLDataType2["FLOAT_VEC2"] = 35664] = "FLOAT_VEC2";
  EGLDataType2[EGLDataType2["FLOAT_VEC3"] = 35665] = "FLOAT_VEC3";
  EGLDataType2[EGLDataType2["FLOAT_VEC4"] = 35666] = "FLOAT_VEC4";
  EGLDataType2[EGLDataType2["INT_VEC2"] = 35667] = "INT_VEC2";
  EGLDataType2[EGLDataType2["INT_VEC3"] = 35668] = "INT_VEC3";
  EGLDataType2[EGLDataType2["INT_VEC4"] = 35669] = "INT_VEC4";
  EGLDataType2[EGLDataType2["BOOL"] = 35670] = "BOOL";
  EGLDataType2[EGLDataType2["BOOL_VEC2"] = 35671] = "BOOL_VEC2";
  EGLDataType2[EGLDataType2["BOOL_VEC3"] = 35672] = "BOOL_VEC3";
  EGLDataType2[EGLDataType2["BOOL_VEC4"] = 35673] = "BOOL_VEC4";
  EGLDataType2[EGLDataType2["FLOAT_MAT2"] = 35674] = "FLOAT_MAT2";
  EGLDataType2[EGLDataType2["FLOAT_MAT3"] = 35675] = "FLOAT_MAT3";
  EGLDataType2[EGLDataType2["FLOAT_MAT4"] = 35676] = "FLOAT_MAT4";
  EGLDataType2[EGLDataType2["SAMPLER_2D"] = 35678] = "SAMPLER_2D";
  EGLDataType2[EGLDataType2["SAMPLER_CUBE"] = 35680] = "SAMPLER_CUBE";
  EGLDataType2[EGLDataType2["SAMPLER_3D"] = 35679] = "SAMPLER_3D";
  EGLDataType2[EGLDataType2["SAMPLER_2D_SHADOW"] = 35682] = "SAMPLER_2D_SHADOW";
  EGLDataType2[EGLDataType2["FLOAT_MAT2x3"] = 35685] = "FLOAT_MAT2x3";
  EGLDataType2[EGLDataType2["FLOAT_MAT2x4"] = 35686] = "FLOAT_MAT2x4";
  EGLDataType2[EGLDataType2["FLOAT_MAT3x2"] = 35687] = "FLOAT_MAT3x2";
  EGLDataType2[EGLDataType2["FLOAT_MAT3x4"] = 35688] = "FLOAT_MAT3x4";
  EGLDataType2[EGLDataType2["FLOAT_MAT4x2"] = 35689] = "FLOAT_MAT4x2";
  EGLDataType2[EGLDataType2["FLOAT_MAT4x3"] = 35690] = "FLOAT_MAT4x3";
  EGLDataType2[EGLDataType2["SAMPLER_2D_ARRAY"] = 36289] = "SAMPLER_2D_ARRAY";
  EGLDataType2[EGLDataType2["SAMPLER_2D_ARRAY_SHADOW"] = 36292] = "SAMPLER_2D_ARRAY_SHADOW";
  EGLDataType2[EGLDataType2["SAMPLER_CUBE_SHADOW"] = 36293] = "SAMPLER_CUBE_SHADOW";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_VEC2"] = 36294] = "UNSIGNED_INT_VEC2";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_VEC3"] = 36295] = "UNSIGNED_INT_VEC3";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_VEC4"] = 36296] = "UNSIGNED_INT_VEC4";
  EGLDataType2[EGLDataType2["INT_SAMPLER_2D"] = 36298] = "INT_SAMPLER_2D";
  EGLDataType2[EGLDataType2["INT_SAMPLER_3D"] = 36299] = "INT_SAMPLER_3D";
  EGLDataType2[EGLDataType2["INT_SAMPLER_CUBE"] = 36300] = "INT_SAMPLER_CUBE";
  EGLDataType2[EGLDataType2["INT_SAMPLER_2D_ARRAY"] = 36303] = "INT_SAMPLER_2D_ARRAY";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_SAMPLER_2D"] = 36306] = "UNSIGNED_INT_SAMPLER_2D";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_SAMPLER_3D"] = 36307] = "UNSIGNED_INT_SAMPLER_3D";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_SAMPLER_CUBE"] = 36308] = "UNSIGNED_INT_SAMPLER_CUBE";
  EGLDataType2[EGLDataType2["UNSIGNED_INT_SAMPLER_2D_ARRAY"] = 36311] = "UNSIGNED_INT_SAMPLER_2D_ARRAY";
  EGLDataType2[EGLDataType2["TEXTURE_2D"] = 3553] = "TEXTURE_2D";
  EGLDataType2[EGLDataType2["TEXTURE_CUBE_MAP"] = 34067] = "TEXTURE_CUBE_MAP";
  EGLDataType2[EGLDataType2["TEXTURE_3D"] = 32879] = "TEXTURE_3D";
  EGLDataType2[EGLDataType2["TEXTURE_2D_ARRAY"] = 35866] = "TEXTURE_2D_ARRAY";
  return EGLDataType2;
})(EGLDataType || {});
class GLHelper {
  /**
   * Bind glbuffer
   * @param glTarget 
   * @param buff 
   * @param arrayData 
   * @param drawtype 
   */
  static glBindBuffer(gl, glTarget, glBuffer, typedArray, drawtype) {
    let bufferType = glTarget === GLBingBufferTarget.ARRAY_BUFFER ? gl.ARRAY_BUFFER : gl.ELEMENT_ARRAY_BUFFER;
    gl.bindBuffer(bufferType, glBuffer);
    gl.bufferData(bufferType, typedArray, drawtype);
  }
  /**
   * create glBuffer from TypeArray
   * @param type          ARRAY_BUFFER | ELEMENT_ARRAY_BUFFER
   * @param typedArray    TypedArray
   * @param drawtype      (optional)GLDrawType
   */
  static createBufferFromTypedArray(gl, type2, typedArray, drawtype) {
    const glBuffer = gl.createBuffer();
    GLHelper.bindBuffer(gl, glBuffer, type2, typedArray, drawtype);
    return glBuffer;
  }
  static bindBuffer(gl, glBuffer, type2, typedArray, drawtype) {
    let glDrawType = gl.STATIC_DRAW;
    if (drawtype === GLDrawType.DYNAMIC_DRAW) {
      glDrawType = gl.DYNAMIC_DRAW;
    }
    if (drawtype === GLDrawType.STREAM_DRAW) {
      glDrawType = gl.STREAM_DRAW;
    }
    GLHelper.glBindBuffer(gl, type2, glBuffer, typedArray, glDrawType);
  }
  /**
   * createWebGLBuffersFromVertexInfo
   * @param vertexInfo 
   */
  static bindIBOFromVertexInfo(gl, drawObj) {
    let attrCache = drawObj.vertexAttrBufferCache;
    if (attrCache.hasOwnProperty("indices")) {
      let indicesProperty = attrCache["indices"];
      let attrInfo = indicesProperty.attrInfo;
      if (!indicesProperty.glBuffer) {
        indicesProperty.glBuffer = GLHelper.createBufferFromTypedArray(gl, gl.ELEMENT_ARRAY_BUFFER, attrInfo.buffer);
      } else {
        GLHelper.bindBuffer(gl, indicesProperty.glBuffer, gl.ELEMENT_ARRAY_BUFFER, attrInfo.buffer, gl.STATIC_DRAW);
      }
    }
  }
}
var EEnabledCapability = /* @__PURE__ */ ((EEnabledCapability2) => {
  EEnabledCapability2[EEnabledCapability2["BLEND"] = 0] = "BLEND";
  EEnabledCapability2[EEnabledCapability2["CULL_FACE"] = 1] = "CULL_FACE";
  EEnabledCapability2[EEnabledCapability2["DEPTH_TEST"] = 2] = "DEPTH_TEST";
  EEnabledCapability2[EEnabledCapability2["DITHER"] = 3] = "DITHER";
  EEnabledCapability2[EEnabledCapability2["POLYGON_OFFSET_FILL"] = 4] = "POLYGON_OFFSET_FILL";
  EEnabledCapability2[EEnabledCapability2["SAMPLE_ALPHA_TO_COVERAGE"] = 5] = "SAMPLE_ALPHA_TO_COVERAGE";
  EEnabledCapability2[EEnabledCapability2["SAMPLE_COVERAGE"] = 6] = "SAMPLE_COVERAGE";
  EEnabledCapability2[EEnabledCapability2["SCISSOR_TEST"] = 7] = "SCISSOR_TEST";
  EEnabledCapability2[EEnabledCapability2["STENCIL_TEST"] = 8] = "STENCIL_TEST";
  return EEnabledCapability2;
})(EEnabledCapability || {});
class State {
  constructor() {
    this._enabledCapabilities = {};
  }
  get capabilities() {
    return this._enabledCapabilities;
  }
  isCapabilityEnabled(capability) {
    return this._enabledCapabilities[capability] ? this._enabledCapabilities[capability] : false;
  }
  getStateFlags() {
    const keys = Object.keys(this._enabledCapabilities);
    let keyNums = keys.map((val) => {
      return parseInt(val, 10);
    });
    return keyNums;
  }
  // setAsTarget(other:State){
  //     this.disableAll();
  //     const otherCapabilities = other.getStateFlags();
  //     for (let index = 0,len = otherCapabilities.length; index < len; index++) {
  //         const key = otherCapabilities[index];
  //         this.enable(key);
  //     }
  // }
  disableAll() {
    const emableFlags = this.getStateFlags();
    for (let index = 0, len = emableFlags.length; index < len; index++) {
      const key = emableFlags[index];
      this.disable(key);
    }
  }
  enable(id) {
    if (this._enabledCapabilities[id] !== true) {
      this._enabledCapabilities[id] = true;
    }
  }
  disable(id) {
    if (this._enabledCapabilities[id]) {
      this._enabledCapabilities[id] = false;
    }
  }
}
var Mirror;
((Mirror2) => {
  function defined(value) {
    return value !== void 0 && value !== null;
  }
  Mirror2.defined = defined;
  function debounce(func, delay, immediate) {
    let timer = null;
    return function(...args) {
      const callNow = immediate && !timer;
      const context2 = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        if (!immediate) func.apply(context2, args);
      }, delay);
      if (callNow) func.apply(context2, args);
    };
  }
  Mirror2.debounce = debounce;
})(Mirror || (Mirror = {}));
var ETextureFormat = /* @__PURE__ */ ((ETextureFormat2) => {
  ETextureFormat2["RGB"] = "RGB";
  ETextureFormat2["RGBA"] = "RGBA";
  ETextureFormat2["ALPHA"] = "ALPHA";
  ETextureFormat2["LUMINANCE"] = "LUMINANCE";
  ETextureFormat2["LUMINANCE_ALPHA"] = "LUMINANCE_ALPHA";
  ETextureFormat2["DEPTH_COMPONENT"] = "DEPTH_COMPONENT";
  ETextureFormat2["DEPTH_STENCIL"] = "DEPTH_STENCIL";
  ETextureFormat2["SRGB_EXT"] = "SRGB_EXT";
  ETextureFormat2["SRGB_ALPHA_EXT"] = "SRGB_ALPHA_EXT";
  return ETextureFormat2;
})(ETextureFormat || {});
var ETextureDataType = /* @__PURE__ */ ((ETextureDataType2) => {
  ETextureDataType2["UNSIGNED_BYTE"] = "UNSIGNED_BYTE";
  ETextureDataType2["UNSIGNED_SHORT_4_4_4_4"] = "UNSIGNED_SHORT_4_4_4_4";
  ETextureDataType2["UNSIGNED_SHORT_5_5_5_1"] = "UNSIGNED_SHORT_5_5_5_1";
  ETextureDataType2["UNSIGNED_SHORT_5_6_5"] = "UNSIGNED_SHORT_5_6_5";
  ETextureDataType2["UNSIGNED_SHORT"] = "UNSIGNED_SHORT";
  ETextureDataType2["UNSIGNED_INT"] = "UNSIGNED_INT";
  ETextureDataType2["FLOAT"] = "FLOAT";
  ETextureDataType2["HALF_FLOAT_OES"] = "HALF_FLOAT_OES";
  return ETextureDataType2;
})(ETextureDataType || {});
var ETextureMagFilterType = /* @__PURE__ */ ((ETextureMagFilterType2) => {
  ETextureMagFilterType2["NEAREST"] = "NEAREST";
  ETextureMagFilterType2["LINEAR"] = "LINEAR";
  return ETextureMagFilterType2;
})(ETextureMagFilterType || {});
var ETextureMinFilterType = /* @__PURE__ */ ((ETextureMinFilterType2) => {
  ETextureMinFilterType2["NEAREST"] = "NEAREST";
  ETextureMinFilterType2["LINEAR"] = "LINEAR";
  ETextureMinFilterType2["NEAREST_MIPMAP_NEAREST"] = "NEAREST_MIPMAP_NEAREST";
  ETextureMinFilterType2["LINEAR_MIPMAP_NEAREST"] = "LINEAR_MIPMAP_NEAREST";
  ETextureMinFilterType2["NEAREST_MIPMAP_LINEAR"] = "NEAREST_MIPMAP_LINEAR";
  ETextureMinFilterType2["LINEAR_MIPMAP_LINEAR"] = "LINEAR_MIPMAP_LINEAR";
  return ETextureMinFilterType2;
})(ETextureMinFilterType || {});
var ETextureWrapType = /* @__PURE__ */ ((ETextureWrapType2) => {
  ETextureWrapType2["REPEAT"] = "REPEAT";
  ETextureWrapType2["CLAMP_TO_EDGE"] = "CLAMP_TO_EDGE";
  ETextureWrapType2["MIRRORED_REPEAT"] = "MIRRORED_REPEAT";
  return ETextureWrapType2;
})(ETextureWrapType || {});
const glconstant = {
  texFilter: void 0,
  texWrapType: void 0,
  glType: void 0,
  glFormat: void 0
};
const defaultOptions$1 = {
  magFilterType: "LINEAR",
  minFilterType: "LINEAR",
  wrapSType: "REPEAT",
  wrapTType: "REPEAT",
  format: "RGBA",
  type: "UNSIGNED_BYTE",
  isYFlip: false,
  isGenerateMipmap: true
};
class Texture {
  constructor(options) {
    this._param = Object.assign({
      width: options.data.width,
      height: options.data.height
    }, defaultOptions$1, options);
  }
  get texture() {
    return this._texture;
  }
  get width() {
    return this._param.width;
  }
  get height() {
    return this._param.height;
  }
  /**
   * Returns true if value is power of 2
   * @param {number} value number to check.
   * @return true if value is power of 2
   */
  _isPowerOf2(value) {
    return (value & value - 1) === 0;
  }
  getFilterType(gl) {
    if (!glconstant.texFilter) {
      glconstant.texFilter = {
        NEAREST: gl.NEAREST,
        LINEAR: gl.LINEAR,
        NEAREST_MIPMAP_NEAREST: gl.NEAREST_MIPMAP_NEAREST,
        LINEAR_MIPMAP_NEAREST: gl.LINEAR_MIPMAP_NEAREST,
        NEAREST_MIPMAP_LINEAR: gl.NEAREST_MIPMAP_LINEAR,
        LINEAR_MIPMAP_LINEAR: gl.LINEAR_MIPMAP_LINEAR
      };
    }
    if (!glconstant.texWrapType) {
      glconstant.texWrapType = {
        REPEAT: gl.REPEAT,
        CLAMP_TO_EDGE: gl.CLAMP_TO_EDGE,
        MIRRORED_REPEAT: gl.MIRRORED_REPEAT
      };
    }
    if (!glconstant.glType) {
      glconstant.glType = {
        UNSIGNED_BYTE: gl.UNSIGNED_BYTE,
        UNSIGNED_SHORT: gl.UNSIGNED_SHORT,
        UNSIGNED_INT: gl.UNSIGNED_INT,
        FLOAT: gl.FLOAT
      };
    }
    if (!glconstant.glFormat) {
      glconstant.glFormat = {
        RGB: gl.RGB,
        RGBA: gl.RGBA,
        ALPHA: gl.ALPHA,
        LUMINANCE: gl.LUMINANCE,
        LUMINANCE_ALPHA: gl.LUMINANCE_ALPHA,
        // extern WEBGL_depth_texture
        DEPTH_COMPONENT: gl.DEPTH_COMPONENT,
        DEPTH_STENCIL: gl.DEPTH_STENCIL
        // extern EXT_sRGB
        // ext.SRGB_EXT,
        // ext.SRGB_ALPHA_EXT,
      };
    }
  }
  _isArrayBufferData(textureData) {
    return typeof textureData.buffer !== "undefined";
  }
  _isBrowserData(textureData) {
    return typeof HTMLCanvasElement !== "undefined" && textureData instanceof HTMLCanvasElement || typeof HTMLImageElement !== "undefined" && textureData instanceof HTMLImageElement || typeof HTMLVideoElement !== "undefined" && textureData instanceof HTMLVideoElement || typeof ImageBitmap !== "undefined" && textureData instanceof ImageBitmap;
  }
  createGlTexture(gl) {
    if (!this._param) {
      return;
    }
    logger.debug("createglTexture::>>", this._param);
    this.getFilterType(gl);
    this._texture = gl.createTexture();
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, this._texture);
    if (this._param.isYFlip) {
      gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
    }
    const minFilterType = this._param.minFilterType;
    if (minFilterType) {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, glconstant.texFilter[minFilterType]);
    }
    const magFilterType = this._param.magFilterType;
    if (magFilterType) {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, glconstant.texFilter[magFilterType]);
    }
    const wrapSType = this._param.wrapSType;
    if (wrapSType) {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, glconstant.texWrapType[wrapSType]);
    }
    const wrapTType = this._param.wrapTType;
    if (wrapTType) {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, glconstant.texWrapType[wrapTType]);
    }
    const data = this._param.data;
    const format = glconstant.glFormat[this._param.format];
    const type2 = glconstant.glType[this._param.type];
    const width = this._param.width;
    const height = this._param.height;
    if (data) {
      if (this._isArrayBufferData(this._param.data)) {
        gl.texImage2D(gl.TEXTURE_2D, 0, format, width, height, 0, format, type2, data);
      } else if (this._isBrowserData(this._param.data)) {
        gl.texImage2D(gl.TEXTURE_2D, 0, format, format, type2, data);
      }
    } else {
      gl.texImage2D(gl.TEXTURE_2D, 0, format, width, height, 0, format, type2, null);
    }
    if (this._param.isGenerateMipmap) {
      gl.generateMipmap(gl.TEXTURE_2D);
    }
    gl.bindTexture(gl.TEXTURE_2D, null);
    return this._texture;
  }
  /**
   * dispose release
   * @param gl 
   */
  dispose(gl) {
    if (this._texture) {
      gl.deleteTexture(this._texture);
    }
  }
  // static createTexture(gl:WebGLRenderingContext,image:any){
  //     let texture = gl.createTexture();// 创建纹理
  //     gl.activeTexture(gl.TEXTURE0);// 激活0号纹理单元（0号是默认激活的纹理单元）
  //     gl.bindTexture(gl.TEXTURE_2D, texture);// 绑定纹理对象到激活的纹理单元
  //     //gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);// 图像坐标与纹理坐标Y轴方向相反，需进行Y轴反转
  //     // config texParameteri
  //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);// 纹理放大方式
  //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);// 纹理缩小方式
  //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);// 纹理水平填充方式
  //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);// 纹理垂直填充方式
  //     gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, gl.RGB, gl.UNSIGNED_BYTE, image);// 配置纹理图像
  //     gl.bindTexture(gl.TEXTURE_2D, null);
  //     return new Texture(texture);
  // }
  // static createEmptyTexture(width:number,height:number,border:number=0){
  //     let gl = GLContext.gl;
  //     let texture = gl.createTexture();// 创建纹理
  //     gl.activeTexture(gl.TEXTURE0);// 激活0号纹理单元（0号是默认激活的纹理单元）
  //     gl.bindTexture(gl.TEXTURE_2D, texture);// 绑定纹理对象到激活的纹理单元
  //     //gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);// 图像坐标与纹理坐标Y轴方向相反，需进行Y轴反转
  //     // config texParameteri
  //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);// 纹理放大方式
  //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);// 纹理缩小方式
  //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);// 纹理水平填充方式
  //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);// 纹理垂直填充方式
  //     gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB,width,height,border, gl.RGB, gl.UNSIGNED_BYTE, null);// 配置纹理图像
  //     gl.bindTexture(gl.TEXTURE_2D, null);
  //     return new Texture(texture);
  // }
}
const colorVS = "// Attributes\nattribute vec4 aPosition;\n// Uniforms\nuniform mat4 uMvpMatrix;\n\nvoid main(){\n    gl_Position = uMvpMatrix * aPosition;//position;//\n}";
const colorFS = "precision mediump float;\r\nuniform vec4 color;\r\n\r\nvoid main(void) {\r\n	gl_FragColor = color;\r\n}";
const transformDef = (
  /* glsl */
  `
in vec3 aPosition;
in vec3 aNormal;
in vec3 aTangent;

uniform mat4 uModelMatrix;
uniform mat4 uVpMatrix;

out vec3 oNormal;
out vec3 oPos;
`
);
const BEGIN = "void main(){ \n";
const END = "} \n";
const getTBNMatrix_vert = `
    out mat3 oTBN;

    mat3 getTBNMatrix(){
        //vec3 T =  normalize(vec3((transpose(inverse(uModelMatrix))) * vec4(aTangent,0.0)));
        //vec3 N = normalize(vec3((transpose(inverse(uModelMatrix))) * vec4(aNormal,0.0)));
        vec3 T = normalize(aTangent);
        vec3 N = normalize(aNormal);
        T = normalize(T - dot(T, N) * N);
        vec3 B = cross(N,T);

        // mat3 TBN = mat3(T,B,N);
        mat4 TBNmat4 = mat4(vec4(T,0.0),vec4(B,0.0),vec4(N,0.0), vec4(0.0,0.0,0.0,1.0));
        mat3 TBN = mat3 ((transpose(inverse(uModelMatrix))) * TBNmat4);
        return TBN;
    }
`;
const getNormal_frag = (material) => {
  let out = "";
  const attributes = material.getAttributes();
  if (attributes.normalMap) {
    out += `
            vec3 normal = texture(uNormalMap, vUv0).rgb;
            normal = normal * 2.0 - 1.0;   
            normal =  normalize(oTBN * normal);
        `;
  } else {
    out += `vec3 normal = normalize(oNormal);`;
  }
  return out;
};
const getTBNMatrix_frag = `
    in mat3 oTBN;
`;
const getNormalMap = `
    uniform sampler2D uNormalMap;

`;
function generateShaderCode(shaderOptions) {
  const { options, material } = shaderOptions;
  let codeHead = "";
  codeHead += transformDef;
  codeHead += getTBNMatrix_vert;
  const attributes = material.getAttributes();
  if (attributes.diffuseMap) {
    codeHead += `
        in vec2 aTexcoord_0;
        out vec2 vUv0;
        `;
  }
  let codeBody = "";
  codeBody += BEGIN;
  codeBody += `
        vec4 v4Position = vec4(aPosition,1.0);
        gl_Position = (uVpMatrix * (uModelMatrix * v4Position));
        oNormal = mat3(transpose(inverse(uModelMatrix))) * aNormal; 
        oTBN = getTBNMatrix();
        oPos = vec3(uModelMatrix*v4Position);
    `;
  if (attributes.diffuseMap) {
    codeBody += "    vUv0 = aTexcoord_0;\n";
  }
  codeBody += END;
  let code = "#version 300 es";
  code += codeHead;
  code += codeBody;
  const vshaderCode = code;
  codeHead = `
        precision highp float;
        uniform vec3 uAmbientColor;
        uniform float uDiffuseIntensity;
        uniform float uSpecularIntensity;
        uniform float uOpacity;
        uniform vec3 uViewPos;
        out vec4 outColor; `;
  for (let i = 0, len = options.lights.length; i < len; i++) {
    codeHead += `
        uniform vec3 uLightPos_${i};  
        uniform float uLightIntensity_${i}; 
        uniform vec3 uLightColor_${i}; 
        uniform vec3 uLightDirection_${i};
        uniform float uLightType_${i};  `;
  }
  codeHead += `
        in vec3 oNormal; 
        in vec3 oPos;
    `;
  codeHead += getNormalMap;
  codeHead += getTBNMatrix_frag;
  if (attributes.diffuseMap) {
    codeHead += `
            in vec2 vUv0;
            uniform sampler2D uDiffuseMap;
        `;
  }
  codeBody = "";
  codeBody += BEGIN;
  codeBody += getNormal_frag(material);
  if (attributes.isRenderBothSide) {
    codeBody += `  
    if (!gl_FrontFacing) {
      normal = -normal; 
    }`;
  }
  codeBody += `vec3 color = uAmbientColor;
    `;
  if (attributes.diffuseMap) {
    codeBody += `
        color = texture(uDiffuseMap, vUv0).rgb *0.5; 
        `;
  }
  for (let i = 0, len = options.lights.length; i < len; i++) {
    codeBody += `
        float lightType_${i} = uLightType_${i};
        vec3 lightDirInverse_${i} = vec3(1.0,1.0,1.0);
        if(lightType_${i} == 1.0){
            lightDirInverse_${i} = -normalize(uLightDirection_${i});
        }else if(lightType_${i} == 2.0){
            lightDirInverse_${i} = normalize(uLightPos_${i} - oPos);
        }
        float diffDegree_${i} = max(dot(normal,lightDirInverse_${i}),0.0);
        vec3 diffuse_${i} = diffDegree_${i} * uLightColor_${i};
        vec3 viewDir_${i} = normalize(  uViewPos - oPos);
        vec3 reflectDir_${i} = reflect(-lightDirInverse_${i}, normal);
        float spec_${i} = pow(max(dot(viewDir_${i}, reflectDir_${i}), 0.0), 32.0);
        vec3 specular_${i} = spec_${i} * uLightColor_${i};
        color +=   diffuse_${i} * uDiffuseIntensity *  uLightIntensity_${i}  + specular_${i} * uSpecularIntensity *  uLightIntensity_${i};
        `;
  }
  codeBody += `outColor = vec4(color, 1.0); `;
  codeBody += END;
  code = "#version 300 es";
  code += codeHead;
  code += codeBody;
  const fshaderCode = code;
  const output = {
    vs: vshaderCode,
    fs: fshaderCode
  };
  return output;
}
class ProgramInfo {
  constructor(vsSrc, fsSrc) {
    this.vertexShaderSource = vsSrc;
    this.fragmentShaderSource = fsSrc;
  }
}
const _Program = class _Program {
  constructor(gl, id, name2, program, vertexShader, fragmentShader) {
    this.usedTimes = 0;
    this.id = id;
    this.name = name2;
    this.glProgram = program;
    this.glVertexShader = vertexShader;
    this.glFragmentShader = fragmentShader;
    this.uniforms = new Uniforms(gl, program);
    this.attributes = new Attributes(gl, program);
  }
  static createProgram(gl, programInfo) {
    let vertexShader = _Program._createShader(
      gl,
      0,
      programInfo.vertexShaderSource
    );
    let fragmentShader = _Program._createShader(
      gl,
      1,
      programInfo.fragmentShaderSource
    );
    let program = _Program._createProgram(gl, [
      vertexShader,
      fragmentShader
    ]);
    let ret = new _Program(gl, "", "", program, vertexShader, fragmentShader);
    programInfo.program = ret;
    return ret;
  }
  static _createShader(gl, type2, source) {
    let shader;
    if (type2 === 0) {
      shader = gl.createShader(gl.VERTEX_SHADER);
    } else if (type2 === 1) {
      shader = gl.createShader(gl.FRAGMENT_SHADER);
    }
    if (!shader) {
      logger.error("Create shader false with invalid shader type ! ");
      return null;
    }
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      logger.log("parseShader:", source);
      logger.error(
        "error occured compiling the shaders:" + gl.getShaderInfoLog(shader)
      );
      gl.deleteShader(shader);
      return null;
    }
    return shader;
  }
  static _createProgram(gl, shaders) {
    const program = gl.createProgram();
    shaders.forEach((shader) => {
      gl.attachShader(program, shader);
    });
    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      const lastError = gl.getProgramInfoLog(program);
      logger.error("Error in program linking:" + lastError);
      gl.deleteProgram(program);
      return null;
    }
    return program;
  }
};
_Program.programIDCount = 0;
let Program = _Program;
var ESetterType = /* @__PURE__ */ ((ESetterType2) => {
  ESetterType2[ESetterType2["NORMALSETTER"] = 0] = "NORMALSETTER";
  ESetterType2[ESetterType2["SAMPLERSETTER"] = 1] = "SAMPLERSETTER";
  return ESetterType2;
})(ESetterType || {});
class Setter {
  constructor() {
    this.type = 0;
  }
}
class Setter1f extends Setter {
  setValue(gl, addr, value) {
    gl.uniform1f(addr, value);
  }
}
class Setter1fv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform1fv(addr, value);
  }
}
class Setter2fv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform2fv(addr, value);
  }
}
class Setter3fv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform3fv(addr, value);
  }
}
class Setter4fv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform4fv(addr, value);
  }
}
class Setter1i extends Setter {
  setValue(gl, addr, value) {
    gl.uniform1i(addr, value);
  }
}
class Setter1iv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform1iv(addr, value);
  }
}
class Setter2iv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform2iv(addr, value);
  }
}
class Setter3iv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform3iv(addr, value);
  }
}
class Setter4iv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform4iv(addr, value);
  }
}
class Setter1ui extends Setter {
  setValue(gl, addr, value) {
    gl.uniform1ui(addr, value);
  }
}
class Setter1uiv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform1uiv(addr, value);
  }
}
class Setter2uiv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform2uiv(addr, value);
  }
}
class Setter3uiv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform3uiv(addr, value);
  }
}
class Setter4uiv extends Setter {
  setValue(gl, addr, value) {
    gl.uniform4uiv(addr, value);
  }
}
class SetterMatrix2fv extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix2fv(addr, false, value);
  }
}
class SetterMatrix3fv extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix3fv(addr, false, value);
  }
}
class SetterMatrix4fv extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix4fv(addr, false, value);
  }
}
class SetterMatrix23f extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix2x3fv(addr, false, value);
  }
}
class SetterMatrix32f extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix3x2fv(addr, false, value);
  }
}
class SetterMatrix24f extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix3x2fv(addr, false, value);
  }
}
class SetterMatrix42f extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix4x2fv(addr, false, value);
  }
}
class SetterMatrix34f extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix3x4fv(addr, false, value);
  }
}
class SetterMatrix43f extends Setter {
  setValue(gl, addr, value) {
    gl.uniformMatrix4x3fv(addr, false, value);
  }
}
class SetterSampler extends Setter {
  constructor(bindPoint) {
    super();
    this.bindPoint = bindPoint;
    this.type = 1;
  }
  setValue(gl, addr, value, param) {
    let textureUnit = param || 0;
    gl.activeTexture(gl.TEXTURE0 + textureUnit);
    gl.bindTexture(this.bindPoint, value);
    gl.uniform1i(addr, textureUnit);
  }
}
const _Uniforms = class _Uniforms {
  constructor(gl, program) {
    this.map = {};
    this.seq = [];
    this._textureCount = 0;
    let n = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
    for (let i = 0; i < n; ++i) {
      let info = gl.getActiveUniform(program, i);
      let addr = gl.getUniformLocation(
        program,
        info.name
      );
      this._parseUniform(info, addr);
    }
  }
  _parseUniform(info, addr) {
    var id = info.name | 0;
    this._addUniform(id, info.name, addr, info.type);
    logger.debug("add uniform:" + info.name + " | " + info.type);
  }
  _addUniform(id, name2, addr, uniformType) {
    const isArray = name2.endsWith("[0]");
    let setter;
    if (isArray) {
      name2 = name2.replace("[0]", "");
      setter = _Uniforms.arrayTypeMap[uniformType];
    } else {
      setter = _Uniforms.typeMap[uniformType];
    }
    let uniform = new Uniform(id, addr, setter, isArray);
    if (uniform.setter.type === 1) {
      uniform.textureUnit = this._textureCount;
      this._textureCount++;
    }
    this.map[name2] = uniform;
    this.seq.push(uniform);
  }
};
_Uniforms.arrayTypeMap = {
  [EGLDataType.FLOAT]: new Setter1fv(),
  [EGLDataType.INT]: new Setter1iv(),
  [EGLDataType.UNSIGNED_INT]: new Setter1uiv(),
  [EGLDataType.BOOL]: new Setter1uiv()
};
_Uniforms.typeMap = {
  [EGLDataType.FLOAT]: new Setter1f(),
  [EGLDataType.FLOAT_VEC2]: new Setter2fv(),
  [EGLDataType.FLOAT_VEC3]: new Setter3fv(),
  [EGLDataType.FLOAT_VEC4]: new Setter4fv(),
  [EGLDataType.INT]: new Setter1i(),
  [EGLDataType.INT_VEC2]: new Setter2iv(),
  [EGLDataType.INT_VEC3]: new Setter3iv(),
  [EGLDataType.INT_VEC4]: new Setter4iv(),
  [EGLDataType.UNSIGNED_INT]: new Setter1ui(),
  [EGLDataType.UNSIGNED_INT_VEC2]: new Setter2uiv(),
  [EGLDataType.UNSIGNED_INT_VEC3]: new Setter3uiv(),
  [EGLDataType.UNSIGNED_INT_VEC4]: new Setter4uiv(),
  [EGLDataType.BOOL]: new Setter1ui(),
  [EGLDataType.BOOL_VEC2]: new Setter2uiv(),
  [EGLDataType.BOOL_VEC3]: new Setter3uiv(),
  [EGLDataType.BOOL_VEC4]: new Setter4uiv(),
  [EGLDataType.FLOAT_MAT2]: new SetterMatrix2fv(),
  [EGLDataType.FLOAT_MAT3]: new SetterMatrix3fv(),
  [EGLDataType.FLOAT_MAT4]: new SetterMatrix4fv(),
  [EGLDataType.FLOAT_MAT2x3]: new SetterMatrix23f(),
  [EGLDataType.FLOAT_MAT2x4]: new SetterMatrix24f(),
  [EGLDataType.FLOAT_MAT3x2]: new SetterMatrix32f(),
  [EGLDataType.FLOAT_MAT3x4]: new SetterMatrix34f(),
  [EGLDataType.FLOAT_MAT4x2]: new SetterMatrix42f(),
  [EGLDataType.FLOAT_MAT4x3]: new SetterMatrix43f(),
  [EGLDataType.SAMPLER_2D]: new SetterSampler(EGLDataType.TEXTURE_2D),
  [EGLDataType.SAMPLER_CUBE]: new SetterSampler(EGLDataType.TEXTURE_CUBE_MAP),
  [EGLDataType.SAMPLER_3D]: new SetterSampler(EGLDataType.TEXTURE_3D),
  [EGLDataType.SAMPLER_2D_SHADOW]: new SetterSampler(EGLDataType.TEXTURE_2D),
  [EGLDataType.SAMPLER_2D_ARRAY]: new SetterSampler(
    EGLDataType.TEXTURE_2D_ARRAY
  ),
  [EGLDataType.SAMPLER_2D_ARRAY_SHADOW]: new SetterSampler(
    EGLDataType.TEXTURE_2D_ARRAY
  ),
  [EGLDataType.INT_SAMPLER_2D]: new SetterSampler(EGLDataType.TEXTURE_2D),
  [EGLDataType.INT_SAMPLER_3D]: new SetterSampler(EGLDataType.TEXTURE_3D),
  [EGLDataType.INT_SAMPLER_CUBE]: new SetterSampler(
    EGLDataType.TEXTURE_CUBE_MAP
  ),
  [EGLDataType.INT_SAMPLER_2D_ARRAY]: new SetterSampler(
    EGLDataType.TEXTURE_2D_ARRAY
  ),
  [EGLDataType.UNSIGNED_INT_SAMPLER_2D]: new SetterSampler(
    EGLDataType.TEXTURE_2D
  ),
  [EGLDataType.UNSIGNED_INT_SAMPLER_3D]: new SetterSampler(
    EGLDataType.TEXTURE_3D
  ),
  [EGLDataType.UNSIGNED_INT_SAMPLER_CUBE]: new SetterSampler(
    EGLDataType.TEXTURE_CUBE_MAP
  ),
  [EGLDataType.UNSIGNED_INT_SAMPLER_2D_ARRAY]: new SetterSampler(
    EGLDataType.TEXTURE_2D_ARRAY
  )
};
let Uniforms = _Uniforms;
class Uniform {
  constructor(id, addr, setter, isArray) {
    this.id = id;
    this.addr = addr;
    this.setter = setter;
    this.isArray = isArray;
  }
}
class AttribFloatSetter extends Setter {
  setValue(gl, addr, value) {
    let attrib = value.attrInfo;
    if (attrib.isConstantToShader === true) {
      gl.disableVertexAttribArray(addr);
      let setValue = attrib.buffer;
      switch (setValue.length) {
        case 4:
          gl.vertexAttrib4fv(addr, setValue.buffer);
          break;
        case 3:
          gl.vertexAttrib3fv(addr, setValue.buffer);
          break;
        case 2:
          gl.vertexAttrib2fv(addr, setValue.buffer);
          break;
        case 1:
          gl.vertexAttrib1fv(addr, setValue.buffer);
          break;
        default:
          throw new Error(
            "the length of a float constant value must be between 1 and 4!"
          );
      }
    } else {
      if (!value.glBuffer) {
        logger.debug("no glbuffer:" + attrib.buffer.length);
        value.glBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, value.glBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, attrib.buffer, gl.STATIC_DRAW);
      } else {
        gl.bindBuffer(gl.ARRAY_BUFFER, value.glBuffer);
      }
      gl.enableVertexAttribArray(addr);
      gl.vertexAttribPointer(
        addr,
        attrib.numComponents,
        gl.FLOAT,
        attrib.normalized || false,
        attrib.sizeofStride || 0,
        attrib.offsetofStride || 0
      );
      gl.bindBuffer(gl.ARRAY_BUFFER, null);
    }
  }
}
class AttribIntSetter extends Setter {
  setValue(gl, addr, value) {
    let attrib = value.attrInfo;
    if (attrib.isConstantToShader !== true) {
      if (!value.glBuffer) {
        value.glBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, value.glBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, attrib.buffer, gl.STATIC_DRAW);
      } else {
        gl.bindBuffer(gl.ARRAY_BUFFER, value.glBuffer);
      }
      gl.enableVertexAttribArray(addr);
      gl.vertexAttribIPointer(
        addr,
        attrib.numComponents,
        attrib.glType || gl.INT,
        attrib.sizeofStride || 0,
        attrib.offsetofStride || 0
      );
      gl.bindBuffer(gl.ARRAY_BUFFER, null);
    }
  }
}
class AttribUIntSetter extends Setter {
  setValue(gl, addr, value) {
    let attrib = value.attrInfo;
    if (attrib.isConstantToShader !== true) {
      if (!value.glBuffer) {
        value.glBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, value.glBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, attrib.buffer, gl.STATIC_DRAW);
      } else {
        gl.bindBuffer(gl.ARRAY_BUFFER, value.glBuffer);
      }
      gl.enableVertexAttribArray(addr);
      gl.vertexAttribIPointer(
        addr,
        attrib.numComponents,
        attrib.glType || gl.UNSIGNED_INT,
        attrib.sizeofStride || 0,
        attrib.offsetofStride || 0
      );
      gl.bindBuffer(gl.ARRAY_BUFFER, null);
    }
  }
}
class Attribute {
  constructor(id, addr, type2) {
    this.id = id;
    this.addr = addr;
    this.setter = Attributes.typeMap[type2];
    this.type = type2;
  }
}
const _Attributes = class _Attributes {
  constructor(gl, program) {
    this.map = {};
    this.seq = [];
    const numAttribs = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);
    for (let i = 0; i < numAttribs; ++i) {
      const attribInfo = gl.getActiveAttrib(program, i);
      if (this._isBuiltIn(attribInfo)) {
        continue;
      }
      const index = gl.getAttribLocation(program, attribInfo.name);
      const id = attribInfo.name | 0;
      const attribute = new Attribute(id, index, attribInfo.type);
      this.map[attribInfo.name] = attribute;
      this.seq.push(attribute);
      logger.debug("parse attribute:" + attribInfo.name + "|" + index);
    }
  }
  /**
   * check is BuiltIn variables return boolean
   * @param info
   * @returns boolean
   */
  _isBuiltIn(info) {
    const name2 = info.name;
    return name2.startsWith("gl_") || name2.startsWith("webgl_");
  }
};
_Attributes.typeMap = {
  [EGLDataType.FLOAT]: new AttribFloatSetter(),
  [EGLDataType.FLOAT_VEC2]: new AttribFloatSetter(),
  [EGLDataType.FLOAT_VEC3]: new AttribFloatSetter(),
  [EGLDataType.FLOAT_VEC4]: new AttribFloatSetter(),
  [EGLDataType.INT]: new AttribIntSetter(),
  [EGLDataType.INT_VEC2]: new AttribIntSetter(),
  [EGLDataType.INT_VEC3]: new AttribIntSetter(),
  [EGLDataType.INT_VEC4]: new AttribIntSetter(),
  [EGLDataType.UNSIGNED_INT]: new AttribUIntSetter(),
  [EGLDataType.UNSIGNED_INT_VEC2]: new AttribUIntSetter(),
  [EGLDataType.UNSIGNED_INT_VEC3]: new AttribUIntSetter(),
  [EGLDataType.UNSIGNED_INT_VEC4]: new AttribUIntSetter(),
  [EGLDataType.BOOL]: new AttribIntSetter(),
  [EGLDataType.BOOL_VEC2]: new AttribIntSetter(),
  [EGLDataType.BOOL_VEC3]: new AttribIntSetter(),
  [EGLDataType.BOOL_VEC4]: new AttribIntSetter()
  // [GLDataType.FLOAT_MAT2]:new Setter1f(),
  // [GLDataType.FLOAT_MAT3]:new Setter1f(),
  // [GLDataType.FLOAT_MAT4]:new Setter1f(),
};
let Attributes = _Attributes;
var ECullType = /* @__PURE__ */ ((ECullType2) => {
  ECullType2[ECullType2["CULLFACE_NONE"] = 0] = "CULLFACE_NONE";
  ECullType2[ECullType2["CULLFACE_BACK"] = 1] = "CULLFACE_BACK";
  ECullType2[ECullType2["CULLFACE_FRONT"] = 2] = "CULLFACE_FRONT";
  ECullType2[ECullType2["CULLFACE_FRONTANDBACK"] = 3] = "CULLFACE_FRONTANDBACK";
  return ECullType2;
})(ECullType || {});
var Layer = /* @__PURE__ */ ((Layer32) => {
  Layer32[Layer32["Layer0"] = 1] = "Layer0";
  Layer32[Layer32["Layer1"] = 2] = "Layer1";
  Layer32[Layer32["Layer2"] = 4] = "Layer2";
  Layer32[Layer32["Layer3"] = 8] = "Layer3";
  Layer32[Layer32["Layer4"] = 16] = "Layer4";
  Layer32[Layer32["Layer5"] = 32] = "Layer5";
  Layer32[Layer32["Layer6"] = 64] = "Layer6";
  Layer32[Layer32["Layer7"] = 128] = "Layer7";
  Layer32[Layer32["Layer8"] = 256] = "Layer8";
  Layer32[Layer32["Layer9"] = 512] = "Layer9";
  Layer32[Layer32["Layer10"] = 1024] = "Layer10";
  Layer32[Layer32["Layer11"] = 2048] = "Layer11";
  Layer32[Layer32["Layer12"] = 4096] = "Layer12";
  Layer32[Layer32["Layer13"] = 8192] = "Layer13";
  Layer32[Layer32["Layer14"] = 16384] = "Layer14";
  Layer32[Layer32["Layer15"] = 32768] = "Layer15";
  Layer32[Layer32["Layer16"] = 65536] = "Layer16";
  Layer32[Layer32["Layer17"] = 131072] = "Layer17";
  Layer32[Layer32["Layer18"] = 262144] = "Layer18";
  Layer32[Layer32["Layer19"] = 524288] = "Layer19";
  Layer32[Layer32["Layer20"] = 1048576] = "Layer20";
  Layer32[Layer32["Layer21"] = 2097152] = "Layer21";
  Layer32[Layer32["Layer22"] = 4194304] = "Layer22";
  Layer32[Layer32["Layer23"] = 8388608] = "Layer23";
  Layer32[Layer32["Layer24"] = 16777216] = "Layer24";
  Layer32[Layer32["Layer25"] = 33554432] = "Layer25";
  Layer32[Layer32["Layer26"] = 67108864] = "Layer26";
  Layer32[Layer32["Layer27"] = 134217728] = "Layer27";
  Layer32[Layer32["Layer28"] = 268435456] = "Layer28";
  Layer32[Layer32["Layer29"] = 536870912] = "Layer29";
  Layer32[Layer32["Layer30"] = 1073741824] = "Layer30";
  Layer32[Layer32["Layer31"] = 2147483648] = "Layer31";
  Layer32[Layer32["Everything"] = 4294967295] = "Everything";
  Layer32[Layer32["Nothing"] = 0] = "Nothing";
  return Layer32;
})(Layer || {});
function getLayerEnumByIndex(index) {
  if (index < 0 || index > 31) {
    console.error("Invalid layer index specified.");
    return void 0;
  }
  const enumName = `Layer${index}`;
  return Layer[enumName];
}
const _LayerManager = class _LayerManager {
  // 初始化默认Layer别名
  static initializeDefaultLayerNames() {
    this._defaultLayerNames[0] = "DEFAULT";
    this._defaultLayerNames[1] = "GIZMOS";
    this._defaultLayerNames[2] = "EDITOR";
    this._defaultLayerNames[5] = "UI";
    this._modifiableLayerNames = [...this._defaultLayerNames];
  }
  // 通过Layer序号获取别名
  static getAliasByLayer(layer) {
    if (layer < 0 || layer > 31) {
      console.error("Invalid layer specified.");
      return null;
    }
    return this._modifiableLayerNames[layer] || null;
  }
  // 设置Layer的别名
  static setAlias(layer, alias) {
    if (layer < 0 || layer > 31) {
      console.error("Invalid layer specified.");
      return;
    }
    if (!this._modifiableLayerNames[layer]) {
      this._modifiableLayerNames[layer] = alias;
    } else {
      console.log(`Layer ${layer} already has an alias and cannot be modified.`);
    }
  }
  // 通过别名获取Layer
  static getLayerByAlias(alias) {
    for (let i = 0; i < this._modifiableLayerNames.length; i++) {
      if (this._modifiableLayerNames[i] === alias) {
        return getLayerEnumByIndex(i);
      }
    }
    return null;
  }
};
_LayerManager._defaultLayerNames = new Array(32).fill(null);
_LayerManager._modifiableLayerNames = [];
let LayerManager = _LayerManager;
class Matrix3 {
  constructor(c0r0, c0r1, c0r2, c1r0, c1r1, c1r2, c2r0, c2r1, c2r2) {
    this._data = new Float32Array(9);
    const datas = this._data;
    datas[0] = defaultValue(c0r0, 1);
    datas[1] = defaultValue(c0r1, 0);
    datas[2] = defaultValue(c0r2, 0);
    datas[4] = defaultValue(c1r0, 0);
    datas[5] = defaultValue(c1r1, 1);
    datas[6] = defaultValue(c1r2, 0);
    datas[8] = defaultValue(c2r0, 0);
    datas[9] = defaultValue(c2r1, 0);
    datas[10] = defaultValue(c2r2, 1);
  }
  get data() {
    return this._data;
  }
  clone(a) {
    let m = this._data;
    m[0] = a[0];
    m[1] = a[1];
    m[2] = a[2];
    m[3] = a[3];
    m[4] = a[4];
    m[5] = a[5];
    m[6] = a[6];
    m[7] = a[7];
    m[8] = a[8];
    m[9] = a[9];
    m[10] = a[10];
    m[11] = a[11];
    return this;
  }
  set(m00, m01, m02, m10, m11, m12, m20, m21, m22) {
    let m = this._data;
    m[0] = m00;
    m[1] = m01;
    m[2] = m02;
    m[3] = m10;
    m[4] = m11;
    m[5] = m12;
    m[6] = m20;
    m[7] = m21;
    m[8] = m22;
  }
  identity() {
    let m = this._data;
    m[0] = 1;
    m[1] = 0;
    m[2] = 0;
    m[3] = 0;
    m[4] = 1;
    m[5] = 0;
    m[6] = 0;
    m[7] = 0;
    m[8] = 1;
  }
  // equals 方法，用于比较两个矩阵是否相等
  static equals(a, b) {
    let ma = a._data;
    let mb = b._data;
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (Math.abs(ma[i][j] - mb[i][j]) <= EPSILON) {
          return false;
        }
      }
    }
    return true;
  }
  static fromArray(input, out) {
    let ret = defaultValueOrNew(out, Matrix3);
    let m = ret._data;
    m[0] = input[0];
    m[1] = input[1];
    m[2] = input[2];
    m[3] = input[4];
    m[4] = input[5];
    m[5] = input[6];
    m[6] = input[8];
    m[7] = input[9];
    m[8] = input[10];
    return ret;
  }
  static fromMat4(input, out) {
    let ret = defaultValueOrNew(out, Matrix3);
    let m = ret._data;
    let a = input.data;
    m[0] = a[0];
    m[1] = a[1];
    m[2] = a[2];
    m[3] = a[4];
    m[4] = a[5];
    m[5] = a[6];
    m[6] = a[8];
    m[7] = a[9];
    m[8] = a[10];
    return ret;
  }
  /**
   * get a 3x3 matrix of rotation from a 4x4 matrix,the result has been normalized.
   * @param input
   * @param out
   * @returns
   */
  static getPureRotationFromMat4(input, out) {
    let ret = defaultValueOrNew(out, Matrix3);
    let m = input.data;
    let o = ret._data;
    const temp = new Vector3();
    Vector3.normalize([m[0], m[1], m[2]], temp);
    o[0] = temp[0];
    o[1] = temp[1];
    o[2] = temp[2];
    Vector3.normalize([m[4], m[5], m[6]], temp);
    o[3] = temp[0];
    o[4] = temp[1];
    o[5] = temp[2];
    Vector3.normalize([m[8], m[9], m[10]], temp);
    o[6] = temp[0];
    o[7] = temp[1];
    o[8] = temp[2];
    return ret;
  }
  static multiply(a, b, out) {
    let ret = defaultValueOrNew(out, Matrix3);
    let m = ret._data;
    const a00 = a[0], a01 = a[1], a02 = a[2];
    const a10 = a[3], a11 = a[4], a12 = a[5];
    const a20 = a[6], a21 = a[7], a22 = a[8];
    const b00 = b[0], b01 = b[1], b02 = b[2];
    const b10 = b[3], b11 = b[4], b12 = b[5];
    const b20 = b[6], b21 = b[7], b22 = b[8];
    m[0] = b00 * a00 + b01 * a10 + b02 * a20;
    m[1] = b00 * a01 + b01 * a11 + b02 * a21;
    m[2] = b00 * a02 + b01 * a12 + b02 * a22;
    m[3] = b10 * a00 + b11 * a10 + b12 * a20;
    m[4] = b10 * a01 + b11 * a11 + b12 * a21;
    m[5] = b10 * a02 + b11 * a12 + b12 * a22;
    m[6] = b20 * a00 + b21 * a10 + b22 * a20;
    m[7] = b20 * a01 + b21 * a11 + b22 * a21;
    m[8] = b20 * a02 + b21 * a12 + b22 * a22;
    return ret;
  }
  static subtract(a, b, out) {
    let ret = defaultValueOrNew(out, Matrix3);
    let m = ret._data;
    m[0] = a[0] - b[0];
    m[1] = a[1] - b[1];
    m[2] = a[2] - b[2];
    m[3] = a[3] - b[3];
    m[4] = a[4] - b[4];
    m[5] = a[5] - b[5];
    m[6] = a[6] - b[6];
    m[7] = a[7] - b[7];
    m[8] = a[8] - b[8];
    return ret;
  }
  static add(a, b, out) {
    let ret = defaultValueOrNew(out, Matrix3);
    let m = ret._data;
    m[0] = a[0] + b[0];
    m[1] = a[1] + b[1];
    m[2] = a[2] + b[2];
    m[3] = a[3] + b[3];
    m[4] = a[4] + b[4];
    m[5] = a[5] + b[5];
    m[6] = a[6] + b[6];
    m[7] = a[7] + b[7];
    m[8] = a[8] + b[8];
    return ret;
  }
  static multiplyScalar(a, b, out) {
    let ret = defaultValueOrNew(out, Matrix3);
    let m = ret._data;
    m[0] = a[0] * b;
    m[1] = a[1] * b;
    m[2] = a[2] * b;
    m[3] = a[3] * b;
    m[4] = a[4] * b;
    m[5] = a[5] * b;
    m[6] = a[6] * b;
    m[7] = a[7] * b;
    m[8] = a[8] * b;
    return ret;
  }
  toString() {
    return this._data.toString();
  }
}
class SceneNode extends MObject {
  constructor(parent) {
    super();
    this._position = new Vector3(0, 0, 0);
    this._rotation = new Vector3();
    this._quaternion = new Vector4();
    this._scale = new Vector3(1, 1, 1);
    this._localMatrix = Matrix4.identity();
    this._worldMatrix = Matrix4.identity();
    this._worldPosition = new Vector3(0, 0, 0);
    this._worldRotation = new Vector3(0, 0, 0);
    this._worldScale = new Vector3(0, 0, 0);
    this._worldQuaternion = new Vector4();
    this._target = null;
    this._layer = Layer.Layer0;
    this._children = [];
    this._onChange = [];
    this._updateListenerArray = [];
    this._parent = parent;
  }
  get layer() {
    return this._layer;
  }
  set layer(val) {
    if (val < Layer.Nothing && val > Layer.Everything) {
      logger.warn("this layer val is invalid:", val);
      return;
    }
    this._layer = val;
  }
  // worldMatrix use inside for calculate current node world matrix
  get worldMatrix() {
    return this._worldMatrix;
  }
  get parent() {
    return this._parent;
  }
  get children() {
    return this._children;
  }
  set root(rootNode) {
    this._root = rootNode;
  }
  get root() {
    return this._root;
  }
  get rotation() {
    return this._rotation;
  }
  set rotation(xyzDegrees) {
    this._rotation = xyzDegrees.clone();
    const { x, y, z } = xyzDegrees;
    const radX = degreesToRadians(x);
    const radY = degreesToRadians(y);
    const radZ = degreesToRadians(z);
    this._quaternion = fromEulerAngles(radX, radY, radZ);
    this.setTransformModifyFlag();
  }
  get position() {
    return this._position;
  }
  set position(value) {
    this._position = value.clone();
    this.setTransformModifyFlag();
  }
  get quaternion() {
    return this._quaternion;
  }
  get worldPosition() {
    return this._worldPosition;
  }
  get worldQuat() {
    return this._worldQuaternion;
  }
  get worldScale() {
    return this._worldScale;
  }
  set quaternion(value) {
    this._quaternion = value;
    const mat = Matrix4.fromQuat(value);
    const rotations = Matrix4.toEulerAngles(mat);
    this._rotation.set([
      radiansToDegrees(rotations[0]),
      radiansToDegrees(rotations[1]),
      radiansToDegrees(rotations[2])
    ]);
    this.setTransformModifyFlag();
  }
  get scale() {
    return this._scale;
  }
  set scale(value) {
    this._scale = value.clone();
    this.setTransformModifyFlag();
  }
  set target(val) {
    this._target = val;
  }
  getFront() {
    return new Vector3(0, 0, -1);
  }
  getWorldFront() {
    const ret = new Vector3(0, 0, -1);
    const rotateMatrix = Matrix3.getPureRotationFromMat4(this._worldMatrix);
    Vector3.transformMat3(ret, rotateMatrix, ret);
    return ret;
  }
  getWorldUp() {
    const worldMatrix = this._worldMatrix.data;
    const ret = new Vector3(worldMatrix[4], worldMatrix[5], worldMatrix[6]);
    return Vector3.normalize(ret, ret);
  }
  getWorldRight() {
    const worldMatrix = this._worldMatrix.data;
    const ret = new Vector3(worldMatrix[0], worldMatrix[1], worldMatrix[2]);
    return Vector3.normalize(ret, ret);
  }
  getUp() {
    const localMatrix = this._localMatrix.data;
    const ret = new Vector3(localMatrix[4], localMatrix[5], localMatrix[6]);
    return Vector3.normalize(ret, ret);
  }
  getRight() {
    const localMatrix = this._localMatrix.data;
    const ret = new Vector3(localMatrix[0], localMatrix[1], localMatrix[2]);
    return Vector3.normalize(ret, ret);
  }
  setTransform(opts) {
    if (opts.position) this._position = opts.position;
    if (opts.quaternion) this._quaternion = opts.quaternion;
    if (opts.scale) this._scale = opts.scale;
    this.setTransformModifyFlag();
  }
  /**
   * add a listener in update propress
   */
  addUpdateListener(listener) {
    if (listener) {
      this._updateListenerArray.push(listener);
    }
  }
  /**
   * update entity property preframe
   */
  update() {
    this._updateListenerArray.forEach((listener) => {
      listener(this);
    });
    let children = this.children;
    if (children && children.length > 0) {
      children.forEach((node) => {
        node.update();
      });
    }
  }
  /**
   * do a callback in traversing all node of tree
   * @param callback
   */
  traverse(callback) {
    if (callback) {
      callback(this);
    }
    let children = this.children;
    if (children && children.length > 0) {
      children.forEach((node) => {
        node.traverse(callback);
      });
    }
  }
  /**
   * add a child
   */
  addNode(node) {
    if (!node) {
      logger.error("node isn't a scenenode", node);
      return;
    }
    this.children.push(node);
    node._parent = this;
    if (this.className === "Scene") {
      node.root = this;
    } else {
      node.root = this._root;
    }
    if (this._onChange) {
      this._onChange.forEach((listener) => {
        listener(this);
      });
    }
    node.isModified = true;
    let currentnode = this;
    while (currentnode) {
      currentnode.isModified = true;
      currentnode = currentnode.parent;
    }
  }
  /**
   * If this transformation is modified,the modification flag of the relative node is set to true.
   */
  setTransformModifyFlag() {
    this.traverse((item) => {
      item.isModified = true;
    });
    let parent = this.parent;
    while (parent) {
      parent.isModified = true;
      parent = parent.parent;
    }
  }
  removeNode(node) {
    const index = this.children.findIndex((item) => {
      return item === node;
    });
    if (index !== -1) {
      this.children.splice(index, 1);
    }
  }
  removeAll() {
    this._children = [];
  }
  /**
   * add a onChange listener
   * @param callback
   */
  addOnChangeListener(callback) {
    let index = this._onChange.length;
    this._onChange.push(callback);
    return index;
  }
  /**
   * remove a onChange Listener
   * @param index
   */
  removeOnChangeListener(index) {
    this._onChange.slice(index, 1);
  }
  /**
   * update transform if there is a target to lookat
   */
  updateTransformByTarget() {
    if (this._target) {
      const targetPosition = this._target.worldPosition.clone();
      Vector3.transformMat4(targetPosition, this._worldMatrix, targetPosition);
      const newMatrix = Matrix4.lookAt2(
        this.position,
        targetPosition,
        this.getUp()
      );
      Matrix4.multiply(newMatrix, this._localMatrix, this._localMatrix);
      this.applyMatrix(this._localMatrix);
    }
  }
  /**
   * the changes of position , queternion and scale lead to matrix updating and then update transform.
   * update local and world matrix ,and update it's children
   */
  _updateTransform() {
    if (!this.isModified) {
      return;
    }
    let parent = this.parent;
    this._localMatrix.compose(this._position, this._quaternion, this._scale);
    this._worldMatrix = this._localMatrix;
    while (parent) {
      this._worldMatrix = Matrix4.multiply(
        parent._localMatrix,
        this._worldMatrix
      );
      this._worldMatrix.decompose(
        this._worldPosition,
        this._worldQuaternion,
        this._worldScale
      );
      parent = parent.parent;
    }
    for (let index = 0, len = this.children.length; index < len; index++) {
      const childNode = this.children[index];
      childNode._updateTransform();
    }
    this.isModified = false;
  }
  /**
   *  set local matrix dirtly to this node ,also it update the position ,queternion and scale.
   * finally update transform.
   * @param matrix
   */
  applyMatrix(matrix) {
    this._localMatrix = matrix;
    this._localMatrix.decompose(this._position, this._quaternion, this._scale);
    this.setTransformModifyFlag();
  }
}
class Light extends SceneNode {
  constructor(parent) {
    super(parent);
    this._className = "Light";
    this._color = new Vector3(1, 1, 1);
    this._intensity = 1;
  }
  get lightType() {
    return this._lightType;
  }
  get intensity() {
    return this._intensity;
  }
  get color() {
    return this._color;
  }
  /**
   * Sets the intensity of the light.
   *
   * @param val - The intensity value to be set for the light. This value should be a number between (0 - 1).
   */
  set intensity(val) {
    this._intensity = val;
  }
  /**
   * Sets the color of the light.
   *
   * @param color - The color value to be set for the light. This value should be a Vector3 object.
   */
  set color(color) {
    this._color = color;
  }
}
class DirectionLight extends Light {
  constructor(parent) {
    super(parent);
    this._className = "DirectionLight";
    this._direction = new Vector3(0, 0, 1);
    this._lightType = 0;
  }
  set direction(dir) {
    this._direction = dir;
  }
  get direction() {
    return this._direction;
  }
}
class PointLight extends Light {
  // 1-300 越大光斑以及范围越小
  constructor(parent) {
    super(parent);
    this._className = "PointLight";
    this._shininess = 16;
    this._lightType = 1;
  }
  get shininess() {
    return this._shininess;
  }
}
class SpotLight extends Light {
  constructor(parent) {
    super(parent);
    this._className = "SpotLight";
    this._lightType = 2;
  }
}
const defaultVsCode = (
  /* glsl */
  `
attribute vec4 aPosition;
uniform mat4 uMvpMatrix;
void main() {
    gl_Position = uMvpMatrix * aPosition;
}
`
);
const defaultFsCode = (
  /* glsl */
  `
precision mediump float;
void main() {
    gl_FragColor = vec4(1.0,1.0,1.0,1.0);  // green
}
`
);
const IDENTITY_MATRIX = Matrix4.identity();
var BlendFactor = ((BlendFactor2) => {
  BlendFactor2[BlendFactor2["Zero"] = WebGLRenderingContext.ZERO] = "Zero";
  BlendFactor2[BlendFactor2["One"] = WebGLRenderingContext.ONE] = "One";
  BlendFactor2[BlendFactor2["SrcColor"] = WebGLRenderingContext.SRC_COLOR] = "SrcColor";
  BlendFactor2[BlendFactor2["OneMinusSrcColor"] = WebGLRenderingContext.ONE_MINUS_SRC_COLOR] = "OneMinusSrcColor";
  BlendFactor2[BlendFactor2["DstColor"] = WebGLRenderingContext.DST_COLOR] = "DstColor";
  BlendFactor2[BlendFactor2["OneMinusDstColor"] = WebGLRenderingContext.ONE_MINUS_DST_COLOR] = "OneMinusDstColor";
  BlendFactor2[BlendFactor2["SrcAlpha"] = WebGLRenderingContext.SRC_ALPHA] = "SrcAlpha";
  BlendFactor2[BlendFactor2["OneMinusSrcAlpha"] = WebGLRenderingContext.ONE_MINUS_SRC_ALPHA] = "OneMinusSrcAlpha";
  BlendFactor2[BlendFactor2["DstAlpha"] = WebGLRenderingContext.DST_ALPHA] = "DstAlpha";
  BlendFactor2[BlendFactor2["OneMinusDstAlpha"] = WebGLRenderingContext.ONE_MINUS_DST_ALPHA] = "OneMinusDstAlpha";
  return BlendFactor2;
})(BlendFactor || {});
class Renderer {
  constructor(canvas) {
    this._state = new State();
    this._programCache = /* @__PURE__ */ new Map();
    if (!canvas) {
      throw new Error("canvas is null when create renderer");
    }
    this.canvas = canvas;
    if (!this.gl) {
      this.gl = canvas.getContext("webgl2", {
        preserverDrawingBuffer: false,
        premultipliedalpha: false
        // alpha: false
      });
      logger.log("webgl version: webgl2");
      this.initExtentions(this.gl);
      canvas.addEventListener(
        "webglcontextlost",
        function(event) {
          event.preventDefault();
          logger.warn("WebGL context lost!");
        },
        false
      );
    }
    this._initEnabledCapabilities();
  }
  /**
   * the alpha is not used
   * @param bgColor
   */
  clearWithSolidColor(bgColor) {
    let gl = this.gl;
    gl.blendFuncSeparate(
      gl.SRC_ALPHA,
      gl.ONE_MINUS_SRC_ALPHA,
      gl.ONE,
      gl.ONE_MINUS_SRC_ALPHA
    );
    gl.colorMask(true, true, true, false);
    gl.clearColor(bgColor[0], bgColor[1], bgColor[2], 1);
    gl.clearDepth(1);
    gl.clear(
      WebGLRenderingContext.COLOR_BUFFER_BIT | WebGLRenderingContext.DEPTH_BUFFER_BIT
    );
    gl.colorMask(true, true, true, true);
  }
  clearDepthOnly() {
    let gl = this.gl;
    gl.clearDepth(1);
    gl.clear(WebGLRenderingContext.DEPTH_BUFFER_BIT);
  }
  clear(bgColor = [0, 0, 0, 0]) {
    let gl = this.gl;
    gl.enable(gl.BLEND);
    gl.blendFuncSeparate(
      gl.SRC_ALPHA,
      gl.ONE_MINUS_SRC_ALPHA,
      gl.ONE,
      gl.ONE_MINUS_SRC_ALPHA
    );
    gl.clearColor(bgColor[0], bgColor[1], bgColor[2], bgColor[3]);
    gl.clearDepth(1);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
  }
  scissor(left, bottom, width, height) {
    this.gl.scissor(left, bottom, width, height);
  }
  /**
   * setviewport:
   * the coordinate system is from left-bottom to right-top
   */
  viewport(vp) {
    this.gl.viewport(vp[0], vp[1], vp[2], vp[3]);
    this._viewport = new Vector4(vp[0], vp[1], vp[2], vp[3]);
    if (this._state.isCapabilityEnabled(EEnabledCapability.SCISSOR_TEST)) {
      this.scissor(vp[0], vp[1], vp[2], vp[3]);
    }
  }
  _initEnabledCapabilities() {
    if (this._enabledCapabilitieNameMap) {
      return;
    }
    this._enabledCapabilitieNameMap = {};
    let keys = Object.keys(EEnabledCapability);
    keys.forEach((key) => {
      let keynum = EEnabledCapability[key];
      switch (keynum) {
        case EEnabledCapability.BLEND:
          this._enabledCapabilitieNameMap[keynum] = this.gl.BLEND;
          break;
        case EEnabledCapability.CULL_FACE:
          this._enabledCapabilitieNameMap[keynum] = this.gl.CULL_FACE;
          break;
        case EEnabledCapability.DEPTH_TEST:
          this._enabledCapabilitieNameMap[keynum] = this.gl.DEPTH_TEST;
          break;
        case EEnabledCapability.DITHER:
          this._enabledCapabilitieNameMap[keynum] = this.gl.DITHER;
          break;
        case EEnabledCapability.POLYGON_OFFSET_FILL:
          this._enabledCapabilitieNameMap[keynum] = this.gl.POLYGON_OFFSET_FILL;
          break;
        case EEnabledCapability.SAMPLE_ALPHA_TO_COVERAGE:
          this._enabledCapabilitieNameMap[keynum] = this.gl.SAMPLE_ALPHA_TO_COVERAGE;
          break;
        case EEnabledCapability.SAMPLE_COVERAGE:
          this._enabledCapabilitieNameMap[keynum] = this.gl.SAMPLE_COVERAGE;
          break;
        case EEnabledCapability.SCISSOR_TEST:
          this._enabledCapabilitieNameMap[keynum] = this.gl.SCISSOR_TEST;
          break;
        case EEnabledCapability.STENCIL_TEST:
          this._enabledCapabilitieNameMap[keynum] = this.gl.STENCIL_TEST;
          break;
      }
    });
  }
  /**
   * print current renderer state
   */
  printAllState() {
    for (let index = 0; index < 9; index++) {
      const isEnabled = this.gl.getParameter(
        this._enabledCapabilitieNameMap[index]
      );
      logger.debug(`${EEnabledCapability[index]}::${index}`, isEnabled);
    }
  }
  /**
   * Some state is not follow the drawobject, they are more like the global setting. so this interface is for that.
   * @param stateId
   * @param isEnabled
   */
  setState(stateId, isEnabled) {
    logger.debug("enable state::", EEnabledCapability[stateId], isEnabled);
    const glKey = this._enabledCapabilitieNameMap[stateId];
    if (isEnabled) {
      this.gl.enable(glKey);
      this._state.enable(stateId);
    } else {
      this.gl.disable(glKey);
      this._state.disable(stateId);
    }
  }
  /**
   * 恢复之前记录的差异状态
   */
  restoreState() {
    if (!this._stateDiff) {
      return;
    }
    const diffKeys = Object.keys(this._stateDiff);
    for (let index = 0; index < diffKeys.length; index++) {
      const key = parseInt(diffKeys[index], 10);
      const isEnabled = this._stateDiff[key];
      const glKey = this._enabledCapabilitieNameMap[key];
      if (isEnabled) {
        logger.debug("[RestoreEnableState::]", key);
        this.gl.enable(glKey);
        this._state.enable(key);
      } else {
        logger.debug("[RestoreDisableState::]", key);
        this.gl.disable(glKey);
        this._state.disable(key);
      }
    }
    this._stateDiff = {};
  }
  /**
   * 适配DrawObject的状态,应用到当前渲染器状态中,
   * 记录状态差异并设置差异状态，当前有目标没有的状态不处理
   * @param state
   */
  applyState(state) {
    let currentStates = this._state.capabilities;
    let targetStates = state.capabilities;
    this._stateDiff = {};
    const targetKeys = Object.keys(targetStates);
    for (let index = 0; index < targetKeys.length; index++) {
      const key = parseInt(targetKeys[index], 10);
      const targetValue = targetStates[key];
      const currentValue = currentStates[key];
      if (targetValue !== currentValue) {
        this._stateDiff[key] = currentValue;
        const glKey = this._enabledCapabilitieNameMap[key];
        if (targetValue) {
          logger.debug("[EnableState::]", key);
          this.gl.enable(glKey);
          this._state.enable(key);
        } else {
          logger.debug("[DisableState::]", key);
          this.gl.disable(glKey);
          this._state.disable(key);
        }
      }
    }
    logger.debug("[Applystate_end::]", this._state.capabilities);
  }
  resetCallDraw() {
    this._callDrawCount = 0;
  }
  getProfileOfRendering() {
    return this._callDrawCount;
  }
  getGLContext() {
    return this.gl;
  }
  initExtentions(gl) {
    const supportedExtensions = {};
    gl.getSupportedExtensions().forEach((e) => {
      supportedExtensions[e] = true;
    });
    const getExtension = (...args) => {
      for (let i = 0; i < args.length; i++) {
        if (supportedExtensions.hasOwnProperty(args[i])) {
          return gl.getExtension(args[i]);
        }
      }
      return null;
    };
    this.extTextureFloat = getExtension("OES_texture_float");
    this.extTextureHalfFloat = getExtension("OES_texture_half_float");
    this.extTextureHalfFloatLinear = getExtension(
      "OES_texture_half_float_linear"
    );
    this.extLoseContext = getExtension("WEBGL_lose_context");
    if (!this.extLoseContext) {
      logger.warn("[Extension]:WEBGL_lose_context is not supported!");
    }
  }
  /**
   * setup lights
   * @param drawObj
   * @param renderOptions
   * @returns
   */
  _setupLights(drawObj, renderOptions) {
    if (renderOptions.lights.length === 0) {
      return;
    }
    const lights = renderOptions.lights;
    for (let i = 0, len = lights.length; i < len; i++) {
      if (lights[i] instanceof DirectionLight) {
        const light = lights[i];
        drawObj.uniforms[`uLightPos_${i}`] = light.worldPosition;
        drawObj.uniforms[`uLightIntensity_${i}`] = light.intensity;
        drawObj.uniforms[`uLightColor_${i}`] = light.color;
        drawObj.uniforms[`uLightDirection_${i}`] = light.direction;
        drawObj.uniforms[`uLightType_${i}`] = 1;
      } else if (lights[i] instanceof PointLight) {
        const light = lights[i];
        drawObj.uniforms[`uLightPos_${i}`] = light.worldPosition;
        drawObj.uniforms[`uLightIntensity_${i}`] = light.intensity;
        drawObj.uniforms[`uLightColor_${i}`] = light.color;
        drawObj.uniforms[`uLightType_${i}`] = 2;
      } else if (lights[i] instanceof SpotLight) {
        const light = lights[i];
        drawObj.uniforms[`uLightPos_${i}`] = light.worldPosition;
        drawObj.uniforms[`uLightType_${i}`] = 3;
      }
    }
  }
  _setupUniforms(drawObj) {
    if (drawObj.isDrawWithoutVPMatrix) {
      drawObj.uniforms["uViewPos"] = IDENTITY_MATRIX.data;
      drawObj.uniforms["uMvpMatrix"] = IDENTITY_MATRIX.data;
      drawObj.uniforms["uVpMatrix"] = IDENTITY_MATRIX.data;
      drawObj.uniforms["uModelMatrix"] = IDENTITY_MATRIX.data;
    }
  }
  _createNeedTexture(drawObj) {
    const keys = Object.keys(drawObj.uniforms);
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const uniform = drawObj.uniforms[key];
      if (uniform instanceof Texture) {
        if (!uniform.texture) {
          uniform.createGlTexture(this.gl);
        }
        drawObj.uniforms[key] = uniform.texture;
      }
    }
  }
  createOrGetProgram(drawObj, renderOptions) {
    const material = drawObj.material;
    if (!this._programCache.has(material)) {
      let programInfo;
      if (drawObj.shaderGenerator) {
        const shaderCode = drawObj.shaderGenerator({
          material: drawObj.material,
          options: renderOptions
        });
        logger.debug("create programInfo from shaderGenerator:", shaderCode);
        programInfo = new ProgramInfo(shaderCode.vs, shaderCode.fs);
      } else {
        programInfo = new ProgramInfo(defaultVsCode, defaultFsCode);
        logger.debug("new program!!!");
      }
      logger.debug("create program");
      const newProgram = Program.createProgram(this.gl, programInfo);
      this._programCache.set(material, {
        proInfo: programInfo,
        program: newProgram
      });
    }
    return this._programCache.get(material);
  }
  drawObject(drawObj, renderOptions) {
    this._callDrawCount++;
    logger.debug("drawObj:", drawObj);
    if (!drawObj.vertexAttrBufferCache) {
      drawObj.vertexAttrBufferCache = {};
      const keys = Object.keys(drawObj.vertexInfo);
      for (const key of keys) {
        drawObj.vertexAttrBufferCache[key] = {
          // buffer:drawObj.vertexInfo[key].buffer,
          attrInfo: drawObj.vertexInfo[key]
        };
      }
    }
    this._setupUniforms(drawObj);
    this._setupLights(drawObj, renderOptions);
    if (!drawObj.vertexInfo && !drawObj.programInfo) {
      throw new Error(
        "there is invalid vertexInfo or programInfo in vertexInfo"
      );
    }
    drawObj.bindIBO(this.gl);
    logger.debug("[programInfo]", drawObj);
    if (!drawObj.programInfo) {
      const program2 = this.createOrGetProgram(drawObj, renderOptions);
      if (program2) {
        drawObj.programInfo = program2.proInfo;
      }
    }
    if (drawObj.programInfo && this.lastUsedProgramInfo !== drawObj.programInfo) {
      this.lastUsedProgramInfo = drawObj.programInfo;
      if (drawObj.programInfo.program) {
        this.gl.useProgram(drawObj.programInfo.program.glProgram);
      }
    }
    if (drawObj.material) {
      const newStatus = drawObj.material.renderState;
      this.applyState(newStatus);
      drawObj.material.updateUniformsToDrawObj(drawObj);
    }
    this._createNeedTexture(drawObj);
    let vertexInfo = drawObj.vertexInfo;
    let program = drawObj.programInfo.program;
    for (const key in program.attributes.map) {
      if (program.attributes.map.hasOwnProperty(key)) {
        const attrName = key.slice(1).toLowerCase();
        if (vertexInfo.hasOwnProperty(attrName)) {
          const glbufferCache = drawObj.vertexAttrBufferCache[attrName];
          const attribute = program.attributes.map[key];
          attribute.setter.setValue(this.gl, attribute.addr, glbufferCache);
        } else {
          logger.debug("vertexInfo isn't has property:" + attrName);
        }
      }
    }
    let uniforms = drawObj.uniforms;
    for (const key in program.uniforms.map) {
      if (program.uniforms.map.hasOwnProperty(key)) {
        if (uniforms && uniforms.hasOwnProperty(key)) {
          const uniformValue = uniforms[key];
          const uniform = program.uniforms.map[key];
          if (uniform.setter.type === ESetterType.SAMPLERSETTER) {
            uniform.setter.setValue(
              this.gl,
              uniform.addr,
              uniformValue,
              uniform.textureUnit
            );
          } else {
            uniform.setter.setValue(this.gl, uniform.addr, uniformValue);
          }
        } else {
          logger.debug("4 'uniforms' " + key, program.uniforms.map);
        }
      }
    }
    let glDrawType = this.gl.TRIANGLES;
    if (drawObj.drawType === EDrawType.POINTS) {
      glDrawType = this.gl.POINTS;
    } else if (drawObj.drawType === EDrawType.LINES) {
      glDrawType = this.gl.LINES;
    }
    if (!drawObj.vertexInfo.indices) {
      this.gl.drawArrays(
        glDrawType,
        drawObj.drawArrayOffset,
        drawObj.drawArrayCount
      );
    } else {
      this.gl.drawElements(
        glDrawType,
        drawObj.drawArrayCount,
        drawObj.vertexInfo.indices.glType | this.gl.UNSIGNED_BYTE,
        drawObj.drawArrayOffset
      );
    }
    this.restoreState();
  }
}
class RenderList {
  constructor() {
    this.renderObjs = [];
  }
}
var ERenderGroupType = /* @__PURE__ */ ((ERenderGroupType2) => {
  ERenderGroupType2[ERenderGroupType2["BACKGROUND"] = 0] = "BACKGROUND";
  ERenderGroupType2[ERenderGroupType2["OPAQUE"] = 1] = "OPAQUE";
  ERenderGroupType2[ERenderGroupType2["TRANSPARENCE"] = 2] = "TRANSPARENCE";
  ERenderGroupType2[ERenderGroupType2["UILAYOUT"] = 3] = "UILAYOUT";
  return ERenderGroupType2;
})(ERenderGroupType || {});
class RenderGroup {
  clear() {
    this.renderListArray = [];
  }
  constructor(id, priority) {
    this.id = id;
    this.priority = priority;
    this.renderListArray = [];
  }
}
class RenderGroups {
  constructor() {
    this._setupRenderGroups();
  }
  get renderGroupList() {
    return this._renderGroupList;
  }
  /**
   * add a render obj to the render group
   * @param drawObj 
   * @param renderType 
   */
  addDrawAbleObj(drawObj, renderType) {
    if (this._renderGroupList.hasOwnProperty(renderType)) {
      const renderGroup = this._renderGroupList[renderType];
      if (renderGroup.renderListArray.length === 0) {
        renderGroup.renderListArray.push(new RenderList());
      }
      renderGroup.renderListArray[0].renderObjs.push(drawObj);
    }
  }
  clearRenderGroup() {
    for (const key in this._renderGroupList) {
      if (this._renderGroupList.hasOwnProperty(key)) {
        this._renderGroupList[key].clear();
      }
    }
  }
  /**
   * setup the default rendergroups
   */
  _setupRenderGroups() {
    this._renderGroupList = {};
    this._renderGroupList[
      0
      /* BACKGROUND */
    ] = new RenderGroup(0, 500);
    this._renderGroupList[
      1
      /* OPAQUE */
    ] = new RenderGroup(1, 400);
    this._renderGroupList[
      2
      /* TRANSPARENCE */
    ] = new RenderGroup(2, 300);
    this._renderGroupList[
      3
      /* UILAYOUT */
    ] = new RenderGroup(3, 100);
  }
}
class Material extends MObject {
  constructor() {
    super();
    this.isModified = true;
    this._className = "Material";
  }
  clone() {
    let ret = {};
    ret.id = this.id;
    ret.name = this.name;
    ret.alphaTest = this.alphaTest;
    ret.depthTest = this.depthTest;
    ret.depthWrite = this.depthWrite;
    ret.cullMode = this.cullMode;
    ret.isModified = true;
    return ret;
  }
  get renderState() {
    if (!this._renderStatus) {
      this._renderStatus = new State();
      this._renderStatus.enable(EEnabledCapability.DEPTH_TEST);
      this._renderStatus.enable(EEnabledCapability.CULL_FACE);
    }
    return this._renderStatus;
  }
  /**
   *  attach the material to a drawobj
   * @param drawObj
   */
  attachToDrawObj(drawObj) {
    drawObj.material = this;
  }
  /**
   * update uniforms To DrawObj in rendering
   */
  updateUniformsToDrawObj(drawObj) {
  }
}
const _CustomMaterial = class _CustomMaterial extends Material {
  constructor(vs, fs, uniforms) {
    super();
    this.vs = vs;
    this.fs = fs;
    this.uniforms = uniforms;
    this._className = "CustomMaterial";
  }
  updateUniformsToDrawObj(drawObj) {
    const uniformKeys = Object.keys(this.uniforms);
    for (let index = 0; index < uniformKeys.length; index++) {
      const key = uniformKeys[index];
      const val = this.uniforms[key];
      drawObj.uniforms[key] = val;
    }
  }
  attachToDrawObj(drawObj) {
    super.attachToDrawObj(drawObj);
    drawObj.shaderGenerator = _CustomMaterial.shaderGenerator;
  }
};
_CustomMaterial.shaderGenerator = (shaderOptions) => {
  return {
    vs: shaderOptions.material.vs,
    fs: shaderOptions.material.fs
  };
};
let CustomMaterial = _CustomMaterial;
class ColorMaterial extends Material {
  constructor(option) {
    super();
    this.color = new Vector4(1, 1, 1, 1);
    this._className = "ColorMaterial";
    if (option && option.color) {
      this.color = option.color;
    }
  }
  updateUniformsToDrawObj(drawObj) {
    const color = this.color;
    drawObj.uniforms["color"] = [color[0], color[1], color[2], color[3]];
  }
  /**
   *  attach the color material to a drawobj
   * @param drawObj
   */
  attachToDrawObj(drawObj) {
    if (!drawObj) {
      return;
    }
    super.attachToDrawObj(drawObj);
    const color = this.color;
    drawObj.uniforms["color"] = [color[0], color[1], color[2], color[3]];
    drawObj.uniforms["uMvpMatrix"] = Matrix4.identity().data;
    drawObj.shaderGenerator = () => {
      return {
        vs: colorVS,
        fs: colorFS
      };
    };
  }
}
class PhoneMaterial extends Material {
  constructor() {
    super();
    this._className = "PhoneMaterial";
    this._attributes = {
      emissiveColor: new Vector3(0.1, 0.1, 0.1),
      emissiveIntensity: 0.5,
      specularIntensity: 0.5,
      diffuseIntensity: 0.5,
      diffuseColor: new Vector3(0, 0, 0),
      ambientColor: new Vector3(0.4, 0.4, 0.4),
      isRenderBothSide: false
    };
  }
  setAttribute(atts) {
    this._attributes = { ...this._attributes, ...atts };
    this.isModified = true;
  }
  getAttributes() {
    return this._attributes;
  }
  updateUniformsToDrawObj(drawObj) {
    const attributes = this._attributes;
    drawObj.uniforms["uSpecularIntensity"] = attributes.specularIntensity;
    drawObj.uniforms["uDiffuseIntensity"] = attributes.diffuseIntensity;
    drawObj.uniforms["uAmbientColor"] = attributes.ambientColor;
    if (attributes.diffuseMap && !drawObj.uniforms["uDiffuseMap"]) {
      drawObj.uniforms["uDiffuseMap"] = attributes.diffuseMap;
    }
    if (attributes.normalMap && !drawObj.uniforms["uNormalMap"]) {
      drawObj.uniforms["uNormalMap"] = attributes.normalMap;
    }
  }
  attachToDrawObj(drawObj) {
    if (!drawObj) {
      return;
    }
    super.attachToDrawObj(drawObj);
    drawObj.shaderGenerator = generateShaderCode;
  }
}
var ECameraType = /* @__PURE__ */ ((ECameraType2) => {
  ECameraType2[ECameraType2["Perspective"] = 0] = "Perspective";
  ECameraType2[ECameraType2["Orthographic"] = 1] = "Orthographic";
  return ECameraType2;
})(ECameraType || {});
var ECameraClearFlagType = /* @__PURE__ */ ((ECameraClearFlagType2) => {
  ECameraClearFlagType2[ECameraClearFlagType2["SkyBox"] = 0] = "SkyBox";
  ECameraClearFlagType2[ECameraClearFlagType2["SolidColor"] = 1] = "SolidColor";
  ECameraClearFlagType2[ECameraClearFlagType2["DepthOnly"] = 2] = "DepthOnly";
  ECameraClearFlagType2[ECameraClearFlagType2["DontClear"] = 3] = "DontClear";
  return ECameraClearFlagType2;
})(ECameraClearFlagType || {});
const _Camera = class _Camera extends SceneNode {
  constructor(opts = {}) {
    super();
    this._viewMatrix = Matrix4.identity();
    this._projectionMatrix = Matrix4.identity();
    this._vpMatrix = Matrix4.identity();
    this._type = 0;
    this._upVector = new Vector3(0, 1, 0);
    this._lookAt = null;
    this._viewport = new Vector4(0, 0, 1, 1);
    this._viewportWidth = 0;
    this._viewportHeight = 0;
    this._background = new Vector4(0.1, 0.1, 0.1, 1);
    this._clearFlag = 3;
    this._fovy = 60;
    this._near = 0.1;
    this._far = 1e3;
    this._aspect = 16 / 9;
    this._cullingMask = Layer.Everything;
    this._left = -100;
    this._right = 100;
    this._bottom = -100;
    this._top = 100;
    this._orthoHalfHeight = 100;
    this._className = "Camera";
    this._fovy = opts.fovy || this._fovy;
    this._aspect = opts.aspect || this._aspect;
    this._near = opts.near || this._near;
    this._far = opts.far || this._far;
    this._upVector = opts.upVector || this._upVector;
    this.orthoHalfHeight = opts.orthoHalfHeight || this._orthoHalfHeight;
    this._name = `Camera_${_Camera._instanceCount++}`;
    this._clearFlag = opts.clearFlag || this._clearFlag;
  }
  set cullingMask(val) {
    if (val < Layer.Nothing && val > Layer.Everything) {
      logger.warn("this layer val is invalid:", val);
      return;
    }
    this._cullingMask = val;
  }
  get cullingMask() {
    return this._cullingMask;
  }
  get clearFlag() {
    return this._clearFlag;
  }
  get background() {
    return this._background;
  }
  get aspect() {
    return this._aspect;
  }
  get upVector() {
    return this._upVector;
  }
  get viewportRect() {
    return this._viewport;
  }
  get viewportWidth() {
    return this._viewportWidth;
  }
  get viewportHeight() {
    return this._viewportHeight;
  }
  get lookAt() {
    return this._lookAt;
  }
  get far() {
    return this._far;
  }
  get near() {
    return this._near;
  }
  get fovy() {
    return this._fovy;
  }
  get orthoHalfHeight() {
    return this._orthoHalfHeight;
  }
  get type() {
    return this._type;
  }
  get viewMatrix() {
    return this._viewMatrix;
  }
  get projectionMatrix() {
    return this._projectionMatrix;
  }
  get vpMatrix() {
    return this._vpMatrix;
  }
  /**
   * [startposX,startposY,Width,Height] \
   * the left bottom point is (0,0),right top point is (1,1). \
   * max width and height is 1. \
   * default value is [0,0,1,1].
   */
  set viewportRect(val) {
    this._viewport.setByAnother(val);
  }
  set aspect(val) {
    this._aspect = val;
  }
  set orthoHalfHeight(val) {
    this._orthoHalfHeight = val;
    this._updateOrthographicParameters();
  }
  set viewportWidth(val) {
    this._viewportWidth = val;
  }
  set viewportHeight(val) {
    this._viewportHeight = val;
  }
  set type(val) {
    this._type = val;
  }
  set background(val) {
    this._background.setByAnother(val);
  }
  set upVector(val) {
    this._upVector = val;
  }
  set lookAt(val) {
    this._lookAt = val;
  }
  set fovy(val) {
    this._fovy = val;
  }
  set far(val) {
    this._far = val;
  }
  set near(val) {
    this._near = val;
  }
  set clearFlag(val) {
    this._clearFlag = val;
  }
  _updateOrthographicParameters() {
    const halfHeight = this._orthoHalfHeight;
    const halfWidth = halfHeight * this._aspect;
    this._bottom = -halfHeight;
    this._top = halfHeight;
    this._left = -halfWidth;
    this._right = halfWidth;
  }
  toString() {
    return JSON.stringify(this);
  }
  _updateViewMatrix() {
    if (this._upVector) {
      if (!this._lookAt) {
        const front = this.getWorldFront();
        const target = Vector3.add(this.worldPosition, front);
        this._viewMatrix = Matrix4.lookAt2(
          this.position,
          target,
          this._upVector
        );
      } else {
        const target = this._lookAt;
        this._viewMatrix = Matrix4.lookAt2(
          this.position,
          target,
          this._upVector
        );
      }
    }
    if (this._type === 0) {
      this._projectionMatrix = Matrix4.perspective(
        degreesToRadians(this._fovy),
        this._aspect,
        this._near,
        this._far
      );
    } else {
      this._projectionMatrix = Matrix4.orthographic(
        this._left,
        this._right,
        this._bottom,
        this._top,
        this._near,
        this._far
      );
    }
    this._vpMatrix = Matrix4.multiply(this._projectionMatrix, this._viewMatrix);
  }
  updateCamera(option) {
    Object.assign(this, option);
    this._updateOrthographicParameters();
    this._updateViewMatrix();
  }
  worldspaceToScreenspace(worldPos, result) {
    let ret = result ?? new Vector2();
    const ndcPos = Vector3.transformMat4(worldPos, this.vpMatrix);
    ret[0] = (ndcPos[0] + 1) * this._viewportWidth / 2;
    ret[1] = (ndcPos[1] + 1) * this._viewportHeight / 2;
    return ret;
  }
};
_Camera._instanceCount = 0;
let Camera = _Camera;
class Scene extends SceneNode {
  constructor() {
    super();
    this._lights = [];
    this._renderGroups = new RenderGroups();
    this._className = "Scene";
  }
  get renderGroups() {
    return this._renderGroups;
  }
  getLights() {
    const ret = [];
    this.traverse((node) => {
      if (node instanceof Light) {
        ret.push(node);
      }
    });
    return ret;
  }
  addLight(light) {
    this._lights.push(light);
  }
  destory() {
    this._lights = [];
  }
  /**
   * Get all cameras in the order they were rendered
   * @returns 
   */
  getCamerasByRenderOrder() {
    const ret = [];
    this.traverse((node) => {
      if (node.className === "Camera") {
        ret.push(node);
      }
    });
    return ret;
  }
}
class Event {
  constructor() {
    this.events = /* @__PURE__ */ new Set();
  }
  onFire(callback) {
    if (!this.events.has(callback)) {
      this.events.add(callback);
    }
  }
  onFireOnce(callback) {
    const oneCall = async (a) => {
      await callback(a);
      this.off(oneCall);
    };
    this.onFire(oneCall);
  }
  fire(arg) {
    this.events.forEach((cb) => {
      cb(arg);
    });
  }
  off(callback) {
    if (this.events.has(callback)) {
      this.events.delete(callback);
    }
  }
  hasEvent(callback) {
    return this.events.has(callback);
  }
}
class ComponentSystem extends MObject {
  constructor() {
    super();
    this._entityCompMap = /* @__PURE__ */ new Map();
    this._bindComponents = [];
    this._className = "ComponentSystem";
  }
  get entities() {
    return this._entities;
  }
  bindComponent(component) {
    this._bindComponents.push(component);
  }
  /**
   * find which entities are related for this system
   * @param scene
   * @returns
   */
  updateEntitiesFromScene(entities) {
    this._entities = [];
    this._entityCompMap.clear();
    for (let i = 0; i < entities.length; i++) {
      const ent = entities[i];
      if (!(ent instanceof Entity)) {
        continue;
      }
      for (let n = 0, len = ent.components.length; n < len; n++) {
        const comp = ent.components[n];
        if (this._bindComponents.includes(comp.constructor)) {
          this._entities.push(ent);
          this._entityCompMap.set(ent, comp);
          break;
        }
      }
    }
  }
  /**
   * get component by it's bingding entity
   * @param ent
   * @returns
   */
  getCompByEntity(ent) {
    return this._entityCompMap.get(ent);
  }
  update(deltaTime) {
  }
}
class Component extends MObject {
  get entity() {
    return this._entity;
  }
  bindEntity(entity) {
    this._entity = entity;
  }
  enabled() {
  }
  disabled() {
  }
}
class ComponentList {
  constructor() {
    this._components = /* @__PURE__ */ new Map();
  }
  getComponentList() {
    return Array.from(this._components.keys());
  }
  addComponent(component) {
    this._components.set(component.constructor, component);
  }
  removeComponent(component) {
    const key = component.constructor;
    if (this._components.has(key)) {
      this._components.delete(key);
    }
  }
  getComponent(componentType) {
    return this._components.get(componentType);
  }
}
class ComMeshRenderer extends Component {
  constructor() {
    super(...arguments);
    this.isDrawWithoutVPMatrix = false;
  }
  // get name() {
  //   return "MeshRenderer";
  // }
  data() {
    return this._data;
  }
}
class ComMeshRendererSystem extends ComponentSystem {
  constructor() {
    super();
    this._drawObjMap = /* @__PURE__ */ new Map();
    this._drawObjCache = /* @__PURE__ */ new Map();
    this._drawDirectlyObjs = [];
    this.count = 0;
    this._className = "ComMeshRendererSystem";
    this.bindComponent(ComMeshRenderer);
  }
  static getSingleton() {
    if (!this._singleton) {
      this._singleton = new ComMeshRendererSystem();
    }
    return this._singleton;
  }
  /**
   * draw a Drawobj directly, skirt the scene entity
   * @param drawObjs
   */
  addDrawObjsDirectly(...drawObjs) {
    this._drawDirectlyObjs.push(...drawObjs);
  }
  clear() {
    this._drawObjMap.clear();
  }
  /**
   * 每次执行这个方法由场景更新渲染队列。
   * find which entities are related for this system
   * @param scene
   * @returns
   */
  updateEntitiesFromScene(entities) {
    super.updateEntitiesFromScene(entities);
    const entitis = this._entities;
    this._drawObjMap.clear();
    for (let i = 0; i < entitis.length; i++) {
      const ent = entitis[i];
      const drawObjs = this._transformEntityToADrawObj(entitis[i]);
      this._drawObjMap.set(ent, drawObjs);
    }
  }
  /**
   * 把entity转换成对应DrawObj,注意这里相同的entity对应的DrawObj有缓存，
   * 以免重复创建大量DrawObj
   * @param ent
   */
  _transformEntityToADrawObj(ent) {
    if (!this._drawObjCache.has(ent)) {
      logger.debug("trans drawobj**:::", this.id, this._drawObjCache.size, ent);
      this._drawObjCache.set(ent, []);
      const drawObjs = this._drawObjCache.get(ent);
      const comp = this.getCompByEntity(ent);
      if (comp) {
        const primitives = comp.mesh.primitives;
        for (let i = 0; i < primitives.length; i++) {
          const primitive = primitives[i];
          const geometry = primitive.geometry;
          if (geometry) {
            const drawObj = new DrawableObj();
            drawObj.vertexInfo = geometry.vertexInfo;
            drawObj.drawArrayOffset = geometry.drawOffset;
            drawObj.drawArrayCount = geometry.drawCount;
            drawObj.drawType = geometry.drawType;
            if (primitive.material) {
              primitive.material.attachToDrawObj(drawObj);
            }
            drawObj.isDrawWithoutVPMatrix = comp.isDrawWithoutVPMatrix;
            drawObjs.push(drawObj);
          }
        }
      }
    }
    return this._drawObjCache.get(ent);
  }
  render(renderer, scene, camera) {
    this.count = 1;
    const lights = scene.getLights();
    for (let drawobj of this._drawObjMap) {
      const ent = drawobj[0];
      const drawObjList = drawobj[1];
      for (let n = 0, objcount = drawObjList.length; n < objcount; n++) {
        const drawObj = drawObjList[n];
        this._drawAObject(drawObj, renderer, { lights }, ent, camera);
        this.count++;
      }
    }
    for (let drawObj of this._drawDirectlyObjs) {
      this._drawAObject(drawObj, renderer, { lights });
      this.count++;
    }
    logger.debug("draw count::", this.count);
  }
  _drawAObject(drawObj, renderer, renderOptions, entity, camera) {
    if (entity && camera) {
      const vpMatrix = camera.vpMatrix;
      let mvpMatrix = Matrix4.multiply(vpMatrix, entity.worldMatrix);
      drawObj.uniforms["uViewPos"] = camera.position;
      drawObj.uniforms["uMvpMatrix"] = mvpMatrix.data;
      drawObj.uniforms["uVpMatrix"] = vpMatrix.data;
      drawObj.uniforms["uModelMatrix"] = entity.worldMatrix.data;
      drawObj.uniforms["uNear"] = camera.near;
      drawObj.uniforms["uFar"] = camera.far;
    }
    renderer.drawObject(drawObj, renderOptions);
  }
  // const IDENTITY_MATRIX = Matrix4.identity();
  // updatePositionTransform(camera: Camera) {
  //   const entities = this.entities;
  //   for (let i = 0, len = entities.length; i < len; i++) {
  //     const ent = entities[i];
  //     const comp = ent.getComponent(ComMeshRenderer) as ComMeshRenderer;
  //     const { viewMatrix, projectionMatrix } = camera;
  //     let vpMatrix = Matrix4.multiply(projectionMatrix, viewMatrix);
  //     let mvpMatrix = Matrix4.multiply(vpMatrix, ent.worldMatrix);
  //     const drawObjs = this._drawObjMap.get(ent);
  //     drawObjs.forEach((drawObj) => {
  //       if (drawObj.isDrawWithoutVPMatrix) {
  //         drawObj.uniforms["uViewPos"] = IDENTITY_MATRIX.data;
  //         drawObj.uniforms["uMvpMatrix"] = IDENTITY_MATRIX.data;
  //         drawObj.uniforms["uVpMatrix"] = IDENTITY_MATRIX.data;
  //         drawObj.uniforms["uModelMatrix"] = IDENTITY_MATRIX.data;
  //       } else {
  //         drawObj.uniforms["uViewPos"] = camera.position;
  //         drawObj.uniforms["uMvpMatrix"] = mvpMatrix.data;
  //         drawObj.uniforms["uVpMatrix"] = vpMatrix.data;
  //         drawObj.uniforms["uModelMatrix"] = ent.worldMatrix.data;
  //         drawObj.uniforms["uNear"] = camera.near;
  //         drawObj.uniforms["uFar"] = camera.far;
  //       }
  //     });
  //     // }
  //   }
  // }
}
const _FntTextMaterial = class _FntTextMaterial extends Material {
  constructor(fontTexture, fontSize) {
    super();
    this.fontSize = 1;
    this.fontTexture = fontTexture;
    this.fontSize = fontSize ?? 1;
  }
  /**
   *  attach the color material to a drawobj
   * @param drawObj
   */
  attachToDrawObj(drawObj) {
    if (!drawObj) {
      return;
    }
    if (this.fontTexture) {
      drawObj.uniforms["uTexture"] = this.fontTexture;
    }
    drawObj.shaderGenerator = () => {
      return {
        vs: _FntTextMaterial.vs,
        fs: _FntTextMaterial.fs
      };
    };
  }
};
_FntTextMaterial.vs = `#version 300 es
    in vec4 aPosition;
    in vec2 aTexcoord;

    uniform mat4 uMvpMatrix;
    uniform mat4 uProjectMatrix;
    out vec2 vTexcoord;

    void main() {
      // Multiply the position by the matrix.
      // gl_Position = aPosition + vec4(uMvpMatrix[3].x,uMvpMatrix[3].y,uMvpMatrix[3].z,0.0);
      gl_Position = uMvpMatrix * aPosition ;// + vec4(0.0,0.0,0.0,0.0);
      // Pass the texcoord to the fragment shader.
      vTexcoord = aTexcoord;
    }
    `;
_FntTextMaterial.fs = `#version 300 es
  precision highp float;

  // Passed in from the vertex shader.
  in vec2 vTexcoord;

  uniform sampler2D uTexture;

  out vec4 outColor;

  void main() {
    outColor = texture(uTexture, vTexcoord);
  }
`;
let FntTextMaterial = _FntTextMaterial;
class Res_FNT {
  constructor(fntFilePath) {
    this.fntFilePath = fntFilePath;
  }
  get fontInfo() {
    return this._fontInfo;
  }
  get fontMaterial() {
    return this._fontMaterial;
  }
  async load() {
    try {
      const postfix = this.fntFilePath.substring(
        this.fntFilePath.length - 4,
        this.fntFilePath.length
      );
      if (postfix !== ".fnt") {
        console.warn("We need a file which postfix is '.fnt'");
        return null;
      }
      const pngPath = this.fntFilePath.substring(0, this.fntFilePath.length - 4) + "_0.png";
      const fntText = await fetchText(this.fntFilePath);
      const ret = this._parseFntFile(fntText);
      this._fontInfo = {
        spacing: 1,
        spaceWidth: 8,
        textureWidth: 256,
        textureHeight: 256,
        glyphInfos: {}
      };
      const keys = Object.keys(ret.charMap);
      for (let i = 0; i < keys.length; i++) {
        const key = Number.parseInt(keys[i]);
        const letterInfo = ret.charMap[key];
        this._fontInfo.glyphInfos[key] = {
          width: letterInfo.width,
          height: letterInfo.height,
          x: letterInfo.x,
          y: letterInfo.y
        };
      }
      const imgData = await loadImageElement(pngPath);
      this._fontTexture = new Texture({
        format: ETextureFormat.RGBA,
        type: ETextureDataType.UNSIGNED_BYTE,
        minFilterType: ETextureMinFilterType.LINEAR_MIPMAP_LINEAR,
        magFilterType: ETextureMagFilterType.LINEAR,
        data: imgData
      });
      this._fontMaterial = new FntTextMaterial(this._fontTexture, 1);
      return this._fontInfo;
    } catch (excp) {
      console.warn("load fnt resource false::", excp);
      return null;
    }
  }
  /**
   * create geometry though FNT fontInfo
   * @param {*} text
   * @param {*} fontInfo
   * @returns
   */
  static createTextGeomertyFromString(text, fontInfo, fontsize) {
    let textLen = text.length;
    let numVertices = textLen * 6;
    let positions = new Float32Array(numVertices * 2);
    let texcoords = new Float32Array(numVertices * 2);
    let x = 0;
    let offset = 0;
    let maxX = fontInfo.textureWidth;
    let maxY = fontInfo.textureHeight;
    for (let i = 0; i < textLen; ++i) {
      const letter = text[i];
      var code = letter.charCodeAt(0);
      const glyphInfo = fontInfo.glyphInfos[code];
      if (glyphInfo) {
        const scaledWidth = glyphInfo.width * fontsize;
        const scaledHeight = glyphInfo.height * fontsize;
        let x2 = x + scaledWidth;
        positions[offset + 0] = x;
        positions[offset + 1] = 0;
        positions[offset + 2] = x2;
        positions[offset + 3] = 0;
        positions[offset + 4] = x;
        positions[offset + 5] = scaledHeight;
        positions[offset + 6] = x;
        positions[offset + 7] = scaledHeight;
        positions[offset + 8] = x2;
        positions[offset + 9] = 0;
        positions[offset + 10] = x2;
        positions[offset + 11] = scaledHeight;
        let u1 = glyphInfo.x / maxX;
        let v1 = (glyphInfo.y + glyphInfo.height - 1) / maxY;
        let u2 = (glyphInfo.x + glyphInfo.width - 1) / maxX;
        let v2 = glyphInfo.y / maxY;
        texcoords[offset + 0] = u1;
        texcoords[offset + 1] = v1;
        texcoords[offset + 2] = u2;
        texcoords[offset + 3] = v1;
        texcoords[offset + 4] = u1;
        texcoords[offset + 5] = v2;
        texcoords[offset + 6] = u1;
        texcoords[offset + 7] = v2;
        texcoords[offset + 8] = u2;
        texcoords[offset + 9] = v1;
        texcoords[offset + 10] = u2;
        texcoords[offset + 11] = v2;
        x += (glyphInfo.width + fontInfo.spacing) * fontsize;
        offset += 12;
      } else {
        x += fontInfo.spaceWidth * fontsize;
      }
    }
    let geometry = new Geometry();
    geometry.setVertexAttribute("position", {
      buffer: positions,
      numComponents: 2,
      sizeofStride: 8,
      offsetofStride: 0
    });
    geometry.setVertexAttribute("texcoord", {
      buffer: texcoords,
      numComponents: 2,
      sizeofStride: 8,
      offsetofStride: 0
    });
    geometry.drawCount = textLen * 6;
    return geometry;
  }
  /**
   * 解析 xml格式的 BTFont  fnt文件
   * @param {*} xmlString
   * @returns
   */
  _parseFntFile(xmlString) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    const common = xmlDoc.getElementsByTagName("common")[0];
    const scaleW = parseInt(common.getAttribute("scaleW"), 10);
    const scaleH = parseInt(common.getAttribute("scaleH"), 10);
    const chars = xmlDoc.getElementsByTagName("char");
    const charMap = {};
    for (let i = 0; i < chars.length; i++) {
      const charElem = chars[i];
      const id = parseInt(charElem.getAttribute("id"), 10);
      charMap[id] = {
        x: parseInt(charElem.getAttribute("x"), 10),
        y: parseInt(charElem.getAttribute("y"), 10),
        width: parseInt(charElem.getAttribute("width"), 10),
        height: parseInt(charElem.getAttribute("height"), 10),
        xoffset: parseInt(charElem.getAttribute("xoffset"), 10),
        yoffset: parseInt(charElem.getAttribute("yoffset"), 10),
        xadvance: parseInt(charElem.getAttribute("xadvance"), 10)
      };
    }
    const kerningsElem = xmlDoc.getElementsByTagName("kernings")[0];
    const kerningMap = {};
    if (kerningsElem) {
      const kernings = kerningsElem.getElementsByTagName("kerning");
      for (let i = 0; i < kernings.length; i++) {
        const kerningElem = kernings[i];
        const first = parseInt(kerningElem.getAttribute("first"), 10);
        const second = parseInt(kerningElem.getAttribute("second"), 10);
        const amount = parseInt(kerningElem.getAttribute("amount"), 10);
        kerningMap[`${first},${second}`] = amount;
      }
    }
    return { scaleW, scaleH, charMap, kerningMap };
  }
}
class ComTextRenderer extends Component {
}
class ComTextRendererSystem extends ComponentSystem {
  constructor() {
    super();
    this._drawObjMap = /* @__PURE__ */ new Map();
    this.count = 0;
    this.bindComponent(ComTextRenderer);
  }
  /**
   * find which entities are related for this system
   * @param entities
   * @returns
   */
  updateEntitiesFromScene(entities) {
    super.updateEntitiesFromScene(entities);
    const entitis = this._entities;
    for (let index = 0; index < entitis.length; index++) {
      const ent = entitis[index];
      this._transformEntityToADrawObj(ent);
    }
  }
  _transformEntityToADrawObj(ent) {
    if (!this._drawObjMap.has(ent)) {
      this._drawObjMap.set(ent, []);
    }
    const drawObjs = this._drawObjMap.get(ent);
    const comp = this.getCompByEntity(ent);
    if (comp.data.fontType === "FNT") {
      if (comp.data.fntReasource && comp.data.fntReasource.fontInfo) {
        if (!comp.data.geometry) {
          comp.data.geometry = Res_FNT.createTextGeomertyFromString(
            comp.data.text,
            comp.data.fntReasource.fontInfo,
            1
          );
          comp.data.material = comp.data.fntReasource.fontMaterial;
          const geometry = comp.data.geometry;
          if (geometry) {
            const drawObj = new DrawableObj();
            drawObj.vertexInfo = geometry.vertexInfo;
            drawObj.drawArrayOffset = geometry.drawOffset;
            drawObj.drawArrayCount = geometry.drawCount;
            drawObj.drawType = geometry.drawType;
            if (comp.data.material) {
              comp.data.material.attachToDrawObj(drawObj);
            }
            drawObjs.push(drawObj);
          }
        }
      }
    }
  }
  render(renderer, scene, camera) {
    this.count = 0;
    const lights = scene.getLights();
    for (let drawobj of this._drawObjMap) {
      const ent = drawobj[0];
      const drawObjList = drawobj[1];
      for (let n = 0, objcount = drawObjList.length; n < objcount; n++) {
        const drawObj = drawObjList[n];
        this._drawAObject(drawObj, ent, camera, renderer, { lights });
        this.count++;
      }
    }
  }
  _drawAObject(drawObj, entity, camera, renderer, renderOptions) {
    const projectMatrix = camera.projectionMatrix;
    const viewMatrix = camera.viewMatrix;
    let viewPos = Matrix4.multiply(viewMatrix, entity.worldMatrix);
    let newMatrix = Matrix4.identity();
    let data = newMatrix.data;
    data[12] = viewPos.data[12];
    data[13] = viewPos.data[13];
    data[14] = viewPos.data[14];
    Matrix4.multiply(projectMatrix, newMatrix, newMatrix);
    Matrix4.scale(newMatrix, [0.3, 0.3, 0.3], newMatrix);
    drawObj.uniforms["uViewPos"] = camera.position;
    drawObj.uniforms["uMvpMatrix"] = newMatrix.data;
    drawObj.uniforms["uModelMatrix"] = entity.worldMatrix.data;
    drawObj.uniforms["uNear"] = camera.near;
    drawObj.uniforms["uFar"] = camera.far;
    renderer.drawObject(drawObj, renderOptions);
  }
}
function isScriptResource(obj) {
  return obj && typeof obj === "object" && "data" in obj && "meta" in obj && typeof obj.meta === "object" && "id" in obj.meta && "path" in obj.meta && "size" in obj.meta && "type" in obj.meta && obj.meta.type === "script" && "language" in obj.meta;
}
class ComScript extends Component {
  setScript(scriptFunc) {
    if (!isScriptResource(scriptFunc)) {
      console.error("The script resource is invalid.", scriptFunc);
      return;
    }
    this._scriptRes = scriptFunc;
  }
  /**
   * Invoke once when this component mounted to a entity and enabled.
   */
  onEnabled() {
  }
  /**
   * Invoke when this script is disabled. it also invoke when destroy
   */
  onDisabled() {
  }
  /**
   * Invoke once when this script is activing and working.
   */
  onStart() {
    var _a;
    (_a = this._scriptRes) == null ? void 0 : _a.data.onStart();
  }
  /**
   * Invoke when the scenenode is destroyed;
   */
  onDestroy() {
    var _a;
    (_a = this._scriptRes) == null ? void 0 : _a.data.onDestroy();
  }
  /**
   * Invoke before rendering,frame by frame.
   */
  onUpdate(deltaTime) {
    var _a;
    (_a = this._scriptRes) == null ? void 0 : _a.data.onUpdate(deltaTime);
  }
  /**
   * Invoke after update but before rendering,frame by frame.
   */
  onLateUpdate() {
  }
  /**
   * Invoke after update ,frame by frame.
   */
  onPreRender() {
  }
  /**
   * Invoke after PreRender ,frame by frame.
   */
  onPostRender() {
  }
  /**-----------------input interaction----------------- */
  onPointerUp() {
  }
  onPointerDown() {
  }
  onPointerClicked() {
  }
  onPointerEnter() {
  }
  onPointerExit() {
  }
  /**---------------collision interaction----------------- */
  /**
   * Called when a node begins to come into contact with another collider.
   */
  onCollisionEnter() {
  }
  /**
   * It is called every frame when the node is in continuous contact with another collider.
   */
  onCollisionStay() {
  }
  /**
   * Called when a node stops contact with another collider.
   */
  onCollisionExit() {
  }
}
class ComScriptSystem extends ComponentSystem {
  constructor() {
    super();
    this._scripts = {};
    this.bindComponent(ComScript);
  }
  static getSingleton() {
    if (!this._singleton) {
      this._singleton = new ComScriptSystem();
    }
    return this._singleton;
  }
  _exeScriptInSequence(func) {
    for (let i = 0, len = this._entities.length; i < len; i++) {
      const ent = this._entities[i];
      for (let n = 0, scriptsLen = ent.scripts.length; n < scriptsLen; n++) {
        const script = ent.scripts[n];
        func(script);
      }
    }
  }
  /**-----------------component life cycle----------------- */
  update(deltaTime) {
    this._exeScriptInSequence((scipt) => {
      scipt.onUpdate(deltaTime);
    });
  }
  lateUpdate() {
    this._exeScriptInSequence((scipt) => {
      scipt.onLateUpdate();
    });
  }
  preRender() {
    this._exeScriptInSequence((scipt) => {
      scipt.onPreRender();
    });
  }
  /**
   * Invoke after PreRender ,frame by frame.
   */
  postRender() {
    this._exeScriptInSequence((scipt) => {
      scipt.onPostRender();
    });
  }
  /**-----------------input interaction----------------- */
  onPointerUp() {
    this._exeScriptInSequence((scipt) => {
      scipt.onPointerUp();
    });
  }
  onPointerDown() {
    this._exeScriptInSequence((scipt) => {
      scipt.onPointerDown();
    });
  }
  onPointerClicked() {
    this._exeScriptInSequence((scipt) => {
      scipt.onPointerClicked();
    });
  }
  onPointerEnter() {
    this._exeScriptInSequence((scipt) => {
      scipt.onPointerEnter();
    });
  }
  onPointerExit() {
    this._exeScriptInSequence((scipt) => {
      scipt.onPointerExit();
    });
  }
}
class ECSSystem {
  constructor() {
    this._systemMap = /* @__PURE__ */ new Map();
  }
  getSystem(sysConstructor) {
    if (!this._systemMap.has(sysConstructor)) {
      this._systemMap.set(sysConstructor, new sysConstructor());
    }
    const ret = this._systemMap.get(sysConstructor);
    return ret;
  }
  // static update(){
  //   const systems = ComponentSystemManager._systems;
  //   for (let i = 0,len = systems.length; i < len; i++) {
  //     const sys = systems[i];
  //     sys.update();
  //   }
  // }
}
let defaultOptions = {};
const _Viewport = class _Viewport {
  constructor(scene, viewportElem, backgroundColor) {
    this.scene = scene;
    this.viewportElem = viewportElem;
    this.backgroundColor = backgroundColor;
  }
  getEcsSystem(scene) {
    if (!_Viewport.sceneEcsPairMap.has(scene)) {
      _Viewport.sceneEcsPairMap.set(scene, new ECSSystem());
    }
    return _Viewport.sceneEcsPairMap.get(scene);
  }
  addDrawObjsDirectly(...drawObj) {
    if (drawObj.length) {
      const ecs = this.getEcsSystem(this.scene);
      const meshRendererSystem = ecs.getSystem(ComMeshRendererSystem);
      meshRendererSystem.addDrawObjsDirectly(...drawObj);
    }
  }
};
_Viewport.sceneEcsPairMap = /* @__PURE__ */ new Map();
let Viewport = _Viewport;
class SceneECSPair {
  constructor(scene, ecsSystemInstance) {
    this.scene = scene;
    this.ecsSystemInstance = ecsSystemInstance;
  }
}
const _Viewer = class _Viewer {
  constructor(options) {
    this._viewports = [];
    this._calldraw = 0;
    this._renderTarget = void 0;
    this.eventBeforeRender = new Event();
    this.eventAfterRender = new Event();
    this.eventPointerMove = new Event();
    this.eventPointerUp = new Event();
    this.eventPointerDown = new Event();
    this.eventPointerEnter = new Event();
    this.eventPointerLeave = new Event();
    this.eventWheel = new Event();
    this.eventKeydown = new Event();
    this.eventKeyup = new Event();
    this.eventKeypress = new Event();
    this._lastFrameTime = 0;
    let opt = {};
    Object.assign(opt, defaultOptions, options);
    this.name = "Viewer" + _Viewer.gViewerCount++;
    if (opt.container) {
      opt.width = opt.width || opt.container.clientWidth;
      opt.height = opt.height || opt.container.clientHeight;
    }
    this._canvas = opt.canvas;
    this.renderer = opt.renderer;
    this.bindContainer(opt.container);
    this._viewRect = this._container.getBoundingClientRect();
    logger.log("[create view Rect::]", this._viewRect);
    const newScene = new Scene();
    const newCamera = options.camera ?? new Camera({
      fovy: 60,
      aspect: opt.width / opt.height,
      near: 1,
      far: 1e4
    });
    newScene.addNode(newCamera);
    const viewports = [];
    viewports.push(
      new Viewport(newScene, opt.container, new Vector4(0, 0, 0, 0))
    );
    this.setViewports(viewports);
    this._registerAllEvents();
    this._gl = this.renderer.getGLContext();
    this.eventBeforeRender.onFire(() => {
      if (this._renderTarget) {
        this._renderTarget.beginRenderToTarget(this._gl);
      }
    });
    this.eventAfterRender.onFire(() => {
      if (this._renderTarget) {
        this._renderTarget.endRenderToTarget(this._gl);
      }
    });
    this.renderer.setState(EEnabledCapability.CULL_FACE, true);
    this.renderer.setState(EEnabledCapability.DEPTH_TEST, true);
    this.renderer.setState(EEnabledCapability.DITHER, true);
  }
  alwaysEnableScissorTest() {
    this.renderer.setState(EEnabledCapability.SCISSOR_TEST, true);
  }
  clear() {
    var _a;
    (_a = this.renderer.extLoseContext) == null ? void 0 : _a.loseContext();
  }
  /**
   * 清理canvas各种缓冲区
   */
  clearCanvas() {
    this.renderer.viewport([
      0,
      0,
      this._canvas.clientWidth,
      this._canvas.clientHeight
    ]);
    this.renderer.clear();
  }
  get viewRect() {
    return this._viewRect;
  }
  get viewports() {
    return this._viewports;
  }
  /**
   * get a viewport by index
   * @param index default there is one viewport and the index is 0.
   * @returns
   */
  getViewport(index = 0) {
    return this.viewports[index];
  }
  /**
   * get a scene of input viewport index
   * @param index default there is one viewport and the index is 0.
   * @returns
   */
  getScene(index = 0) {
    return this.viewports[index].scene;
  }
  /**
   * get a default camera of a scene
   * @param index the viewport index ,default there is one viewport and the index is 0.
   * @returns
   */
  getCamera(index = 0) {
    const scene = this.viewports[index].scene;
    const cameras = scene.getCamerasByRenderOrder();
    return cameras[0];
  }
  getContainer() {
    return this._container;
  }
  getCanvas() {
    return this.renderer.canvas;
  }
  setViewports(viewports) {
    this._viewports = viewports;
    this._bindSceneChangeToRender();
    if (viewports.length > 1) {
      this.renderer.setState(EEnabledCapability.SCISSOR_TEST, true);
    } else {
      this.renderer.setState(EEnabledCapability.SCISSOR_TEST, false);
    }
  }
  /**
   * resize had binded to the resize event of window when the canvas creating.
   * so you can invoke resize by use 'window.dispatchEvent(new Event('resize'))'.
   */
  resize() {
    logger.debug("resize.....");
    this._canvas.width = this._container.clientWidth;
    this._canvas.height = this._container.clientHeight;
    this.render();
  }
  get renderTarget() {
    return this._renderTarget;
  }
  set renderTarget(value) {
    this._renderTarget = value;
  }
  setRenderTarget(value) {
    this.renderTarget = value;
  }
  // ----------------- pointer event handler ------------------
  bindContainer(container) {
    if (this._container) {
      this._removeAllEvents();
    }
    this._container = container;
  }
  _pointerEnterHandler(e) {
    this.eventPointerEnter.fire(e);
  }
  _pointerLeaveHandler(e) {
    this.eventPointerLeave.fire(e);
  }
  _mouseupHandler(e) {
    this.eventPointerUp.fire(e);
  }
  _pointerdownHandler(e) {
    this.eventPointerDown.fire(e);
  }
  _pointermoveHandler(e) {
    this.eventPointerMove.fire(e);
  }
  _wheelHandler(e) {
    this.eventWheel.fire(e);
  }
  _keydownHandler(e) {
    this.eventKeydown.fire(e);
  }
  _keyupHandler(e) {
    this.eventKeyup.fire(e);
  }
  _keypressHandler(e) {
    this.eventKeypress.fire(e);
  }
  _registerAllEvents() {
    this._eventKeydownHandleRef = this._keydownHandler.bind(this);
    this._eventKeyupHandleRef = this._keyupHandler.bind(this);
    this._eventKeypressHandleRef = this._keypressHandler.bind(this);
    this._eventPointerMoveHandleRef = this._pointermoveHandler.bind(this);
    this._eventPointerUpHandleRef = this._mouseupHandler.bind(this);
    this._eventPointerDownHandleRef = this._pointerdownHandler.bind(this);
    this._eventWheelHandleRef = this._wheelHandler.bind(this);
    this._eventResizeHandleRef = this.resize.bind(this);
    this._eventPointerEnterRef = this._pointerEnterHandler.bind(this);
    this._eventPointerLeaveRef = this._pointerLeaveHandler.bind(this);
    this._container.addEventListener("keypress", this._eventKeypressHandleRef);
    this._container.addEventListener("keydown", this._eventKeydownHandleRef);
    this._container.addEventListener("keyup", this._eventKeyupHandleRef);
    this._container.addEventListener("keypress", this._eventKeydownHandleRef);
    this._container.addEventListener(
      "pointerup",
      this._eventPointerUpHandleRef
    );
    this._container.addEventListener(
      "pointerdown",
      this._eventPointerDownHandleRef
    );
    this._container.addEventListener(
      "pointermove",
      this._eventPointerMoveHandleRef
    );
    this._container.addEventListener("wheel", this._eventWheelHandleRef);
    window.addEventListener("resize", this._eventResizeHandleRef);
    this._container.addEventListener(
      "pointerenter",
      this._eventPointerEnterRef
    );
    this._container.addEventListener(
      "pointerleave",
      this._eventPointerLeaveRef
    );
  }
  _removeAllEvents() {
    this._container.removeEventListener(
      "pointerup",
      this._eventPointerUpHandleRef
    );
    this._container.removeEventListener(
      "pointerdown",
      this._eventPointerDownHandleRef
    );
    this._container.removeEventListener(
      "pointermove",
      this._eventPointerMoveHandleRef
    );
    this._container.removeEventListener("wheel", this._eventWheelHandleRef);
  }
  _bindSceneChangeToRender() {
    logger.debug(
      "[bindscenelistener]",
      this._viewports.length,
      this._viewports
    );
    for (let index = 0, len = this._viewports.length; index < len; index++) {
      const viewport = this._viewports[index];
      viewport.scene.addOnChangeListener((scene) => {
        this._updateSceneToRenderer(scene);
      });
      this._updateSceneToRenderer(viewport.scene);
    }
  }
  _updateSceneToRenderer(scene) {
  }
  calDeltaTime() {
    let now2 = performance.now();
    let deltaTime = now2 - this._lastFrameTime;
    this._lastFrameTime = now2;
    return deltaTime;
  }
  calFps(callback) {
    let start = performance.now();
    if (callback) {
      callback();
    }
    let diffMillSeconds = performance.now() - start;
    let fps = 1e3 / diffMillSeconds;
    logger.debug(
      `'fps:${fps} a frame took ${diffMillSeconds}  milliseconds to execute. `
    );
  }
  render() {
    const deltaTime = this.calDeltaTime();
    this.renderer.resetCallDraw();
    this._calldraw = 0;
    this._viewRect = this._container.getBoundingClientRect();
    for (let index = 0, len = this._viewports.length; index < len; index++) {
      const vp = this._viewports[index];
      this.renderAViewport(vp, deltaTime);
    }
  }
  renderAViewport(vp, deltaTime = 0) {
    const rect = vp.viewportElem.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;
    const left = rect.left - this._viewRect.left;
    const bottom = this._viewRect.bottom - rect.bottom;
    const isNeedRender = areRectanglesIntersecting(
      {
        bottomLeft: [rect.left, rect.bottom],
        topRight: [rect.right, rect.top]
      },
      {
        bottomLeft: [this._viewRect.left, this._viewRect.bottom],
        topRight: [this._viewRect.right, this._viewRect.top]
      },
      true
    );
    logger.debug("isNeedRender::", isNeedRender);
    if (!isNeedRender) {
      return;
    }
    logger.debug(
      "viewport::",
      [left, bottom, width, height],
      vp.backgroundColor
    );
    this.renderer.viewport([left, bottom, width, height]);
    this.renderer.clear(vp.backgroundColor);
    this._gl.canvas.style.transform = `translateY(${this._container.scrollTop}px)`;
    const scene = vp.scene;
    const ecsSystem = vp.getEcsSystem(scene);
    const meshRendererSystem = ecsSystem == null ? void 0 : ecsSystem.getSystem(ComMeshRendererSystem);
    const textRendererSystem = ecsSystem == null ? void 0 : ecsSystem.getSystem(ComTextRendererSystem);
    const scriptSystem = ecsSystem == null ? void 0 : ecsSystem.getSystem(ComScriptSystem);
    this.eventBeforeRender.fire();
    scriptSystem == null ? void 0 : scriptSystem.updateEntitiesFromScene(scene.children);
    scriptSystem == null ? void 0 : scriptSystem.update(deltaTime);
    scene.update();
    scene._updateTransform();
    scriptSystem == null ? void 0 : scriptSystem.lateUpdate();
    const cameras = scene.getCamerasByRenderOrder();
    for (let n = 0, len = cameras.length; n < len; n++) {
      const camera = cameras[n];
      const newaspect = rect.width / rect.height;
      camera.updateCamera({
        viewportHeight: rect.height,
        viewportWidth: rect.width,
        aspect: newaspect
      });
      const cameravp = camera.viewportRect;
      let cameraVpL = cameravp[0];
      let cameraVpB = cameravp[1];
      let cameraVpW = cameravp[2];
      let cameraVpH = cameravp[3];
      if (!(cameraVpL === 0 && cameraVpB === 0 && cameraVpW === 1 && cameraVpH === 1)) {
        cameraVpL = left + width * cameraVpL;
        cameraVpB = bottom + height * cameraVpB;
        cameraVpW = width * cameraVpW;
        cameraVpH = height * cameraVpH;
        this.renderer.setState(EEnabledCapability.SCISSOR_TEST, true);
        this.renderer.viewport([cameraVpL, cameraVpB, cameraVpW, cameraVpH]);
        if (camera.clearFlag === ECameraClearFlagType.SolidColor) {
          this.renderer.clearWithSolidColor(camera.background);
        } else if (camera.clearFlag === ECameraClearFlagType.DepthOnly) {
          this.renderer.clearDepthOnly();
        }
        logger.debug("background::", camera.background);
      }
      scriptSystem == null ? void 0 : scriptSystem.preRender();
      const visibleNodes = [];
      scene.traverse((sceneNode) => {
        if (sceneNode.className === "Entity") {
          if (camera.cullingMask & sceneNode.layer) {
            visibleNodes.push(sceneNode);
          }
        }
      });
      logger.debug("update entities from scene", visibleNodes);
      meshRendererSystem == null ? void 0 : meshRendererSystem.updateEntitiesFromScene(visibleNodes);
      textRendererSystem == null ? void 0 : textRendererSystem.updateEntitiesFromScene(visibleNodes);
      scriptSystem == null ? void 0 : scriptSystem.postRender();
      Utils.calculateTimestamp(() => {
        meshRendererSystem == null ? void 0 : meshRendererSystem.render(this.renderer, scene, camera);
        textRendererSystem == null ? void 0 : textRendererSystem.render(this.renderer, scene, camera);
      }, "rendercost::");
    }
    this.eventAfterRender.fire();
  }
};
_Viewer.gViewerCount = 0;
let Viewer = _Viewer;
var Stats = function() {
  function h(a2) {
    c.appendChild(a2.dom);
    return a2;
  }
  function k(a2) {
    for (var d = 0; d < c.children.length; d++) c.children[d].style.display = d === a2 ? "block" : "none";
    l = a2;
  }
  var l = 0, c = document.createElement("div");
  c.style.cssText = "position:fixed;top:0;left:0;cursor:pointer;opacity:0.9;z-index:10000";
  c.addEventListener("click", function(a2) {
    a2.preventDefault();
    k(++l % c.children.length);
  }, false);
  var g = (performance || Date).now(), e = g, a = 0, r = h(new Stats.Panel("FPS", "#0ff", "#002")), f = h(new Stats.Panel("MS", "#0f0", "#020"));
  if (self.performance && self.performance.memory) var t = h(new Stats.Panel("MB", "#f08", "#201"));
  k(0);
  return {
    REVISION: 16,
    dom: c,
    addPanel: h,
    showPanel: k,
    begin: function() {
      g = (performance || Date).now();
    },
    end: function() {
      a++;
      var c2 = (performance || Date).now();
      f.update(c2 - g, 200);
      if (c2 > e + 1e3 && (r.update(1e3 * a / (c2 - e), 100), e = c2, a = 0, t)) {
        var d = performance.memory;
        t.update(d.usedJSHeapSize / 1048576, d.jsHeapSizeLimit / 1048576);
      }
      return c2;
    },
    update: function() {
      g = this.end();
    },
    domElement: c,
    setMode: k
  };
};
Stats.Panel = function(h, k, l) {
  var c = Infinity, g = 0, e = Math.round, a = e(window.devicePixelRatio || 1), r = 80 * a, f = 48 * a, t = 3 * a, u = 2 * a, d = 3 * a, m = 15 * a, n = 74 * a, p = 30 * a, q = document.createElement("canvas");
  q.width = r;
  q.height = f;
  q.style.cssText = "width:80px;height:48px";
  var b = q.getContext("2d");
  b.font = "bold " + 9 * a + "px Helvetica,Arial,sans-serif";
  b.textBaseline = "top";
  b.fillStyle = l;
  b.fillRect(0, 0, r, f);
  b.fillStyle = k;
  b.fillText(h, t, u);
  b.fillRect(d, m, n, p);
  b.fillStyle = l;
  b.globalAlpha = 0.9;
  b.fillRect(d, m, n, p);
  return {
    dom: q,
    update: (f2, v) => {
      c = Math.min(c, f2);
      g = Math.max(g, f2);
      b.fillStyle = l;
      b.globalAlpha = 1;
      b.fillRect(0, 0, r, m);
      b.fillStyle = k;
      b.fillText(e(f2) + " " + h + " (" + e(c) + "-" + e(g) + ")", t, u);
      b.drawImage(q, d + a, m, n - a, p, d, m, n - a, p);
      b.fillRect(d + n - a, m, a, p);
      b.fillStyle = l;
      b.globalAlpha = 0.9;
      b.fillRect(d + n - a, m, a, e((1 - f2 / v) * p));
    }
  };
};
var Easing = Object.freeze({
  Linear: Object.freeze({
    None: function(amount) {
      return amount;
    },
    In: function(amount) {
      return this.None(amount);
    },
    Out: function(amount) {
      return this.None(amount);
    },
    InOut: function(amount) {
      return this.None(amount);
    }
  }),
  Quadratic: Object.freeze({
    In: function(amount) {
      return amount * amount;
    },
    Out: function(amount) {
      return amount * (2 - amount);
    },
    InOut: function(amount) {
      if ((amount *= 2) < 1) {
        return 0.5 * amount * amount;
      }
      return -0.5 * (--amount * (amount - 2) - 1);
    }
  }),
  Cubic: Object.freeze({
    In: function(amount) {
      return amount * amount * amount;
    },
    Out: function(amount) {
      return --amount * amount * amount + 1;
    },
    InOut: function(amount) {
      if ((amount *= 2) < 1) {
        return 0.5 * amount * amount * amount;
      }
      return 0.5 * ((amount -= 2) * amount * amount + 2);
    }
  }),
  Quartic: Object.freeze({
    In: function(amount) {
      return amount * amount * amount * amount;
    },
    Out: function(amount) {
      return 1 - --amount * amount * amount * amount;
    },
    InOut: function(amount) {
      if ((amount *= 2) < 1) {
        return 0.5 * amount * amount * amount * amount;
      }
      return -0.5 * ((amount -= 2) * amount * amount * amount - 2);
    }
  }),
  Quintic: Object.freeze({
    In: function(amount) {
      return amount * amount * amount * amount * amount;
    },
    Out: function(amount) {
      return --amount * amount * amount * amount * amount + 1;
    },
    InOut: function(amount) {
      if ((amount *= 2) < 1) {
        return 0.5 * amount * amount * amount * amount * amount;
      }
      return 0.5 * ((amount -= 2) * amount * amount * amount * amount + 2);
    }
  }),
  Sinusoidal: Object.freeze({
    In: function(amount) {
      return 1 - Math.sin((1 - amount) * Math.PI / 2);
    },
    Out: function(amount) {
      return Math.sin(amount * Math.PI / 2);
    },
    InOut: function(amount) {
      return 0.5 * (1 - Math.sin(Math.PI * (0.5 - amount)));
    }
  }),
  Exponential: Object.freeze({
    In: function(amount) {
      return amount === 0 ? 0 : Math.pow(1024, amount - 1);
    },
    Out: function(amount) {
      return amount === 1 ? 1 : 1 - Math.pow(2, -10 * amount);
    },
    InOut: function(amount) {
      if (amount === 0) {
        return 0;
      }
      if (amount === 1) {
        return 1;
      }
      if ((amount *= 2) < 1) {
        return 0.5 * Math.pow(1024, amount - 1);
      }
      return 0.5 * (-Math.pow(2, -10 * (amount - 1)) + 2);
    }
  }),
  Circular: Object.freeze({
    In: function(amount) {
      return 1 - Math.sqrt(1 - amount * amount);
    },
    Out: function(amount) {
      return Math.sqrt(1 - --amount * amount);
    },
    InOut: function(amount) {
      if ((amount *= 2) < 1) {
        return -0.5 * (Math.sqrt(1 - amount * amount) - 1);
      }
      return 0.5 * (Math.sqrt(1 - (amount -= 2) * amount) + 1);
    }
  }),
  Elastic: Object.freeze({
    In: function(amount) {
      if (amount === 0) {
        return 0;
      }
      if (amount === 1) {
        return 1;
      }
      return -Math.pow(2, 10 * (amount - 1)) * Math.sin((amount - 1.1) * 5 * Math.PI);
    },
    Out: function(amount) {
      if (amount === 0) {
        return 0;
      }
      if (amount === 1) {
        return 1;
      }
      return Math.pow(2, -10 * amount) * Math.sin((amount - 0.1) * 5 * Math.PI) + 1;
    },
    InOut: function(amount) {
      if (amount === 0) {
        return 0;
      }
      if (amount === 1) {
        return 1;
      }
      amount *= 2;
      if (amount < 1) {
        return -0.5 * Math.pow(2, 10 * (amount - 1)) * Math.sin((amount - 1.1) * 5 * Math.PI);
      }
      return 0.5 * Math.pow(2, -10 * (amount - 1)) * Math.sin((amount - 1.1) * 5 * Math.PI) + 1;
    }
  }),
  Back: Object.freeze({
    In: function(amount) {
      var s = 1.70158;
      return amount === 1 ? 1 : amount * amount * ((s + 1) * amount - s);
    },
    Out: function(amount) {
      var s = 1.70158;
      return amount === 0 ? 0 : --amount * amount * ((s + 1) * amount + s) + 1;
    },
    InOut: function(amount) {
      var s = 1.70158 * 1.525;
      if ((amount *= 2) < 1) {
        return 0.5 * (amount * amount * ((s + 1) * amount - s));
      }
      return 0.5 * ((amount -= 2) * amount * ((s + 1) * amount + s) + 2);
    }
  }),
  Bounce: Object.freeze({
    In: function(amount) {
      return 1 - Easing.Bounce.Out(1 - amount);
    },
    Out: function(amount) {
      if (amount < 1 / 2.75) {
        return 7.5625 * amount * amount;
      } else if (amount < 2 / 2.75) {
        return 7.5625 * (amount -= 1.5 / 2.75) * amount + 0.75;
      } else if (amount < 2.5 / 2.75) {
        return 7.5625 * (amount -= 2.25 / 2.75) * amount + 0.9375;
      } else {
        return 7.5625 * (amount -= 2.625 / 2.75) * amount + 0.984375;
      }
    },
    InOut: function(amount) {
      if (amount < 0.5) {
        return Easing.Bounce.In(amount * 2) * 0.5;
      }
      return Easing.Bounce.Out(amount * 2 - 1) * 0.5 + 0.5;
    }
  }),
  generatePow: function(power) {
    if (power === void 0) {
      power = 4;
    }
    power = power < Number.EPSILON ? Number.EPSILON : power;
    power = power > 1e4 ? 1e4 : power;
    return {
      In: function(amount) {
        return Math.pow(amount, power);
      },
      Out: function(amount) {
        return 1 - Math.pow(1 - amount, power);
      },
      InOut: function(amount) {
        if (amount < 0.5) {
          return Math.pow(amount * 2, power) / 2;
        }
        return (1 - Math.pow(2 - amount * 2, power)) / 2 + 0.5;
      }
    };
  }
});
var now = function() {
  return performance.now();
};
var Group = (
  /** @class */
  function() {
    function Group2() {
      this._tweens = {};
      this._tweensAddedDuringUpdate = {};
    }
    Group2.prototype.getAll = function() {
      var _this = this;
      return Object.keys(this._tweens).map(function(tweenId) {
        return _this._tweens[tweenId];
      });
    };
    Group2.prototype.removeAll = function() {
      this._tweens = {};
    };
    Group2.prototype.add = function(tween) {
      this._tweens[tween.getId()] = tween;
      this._tweensAddedDuringUpdate[tween.getId()] = tween;
    };
    Group2.prototype.remove = function(tween) {
      delete this._tweens[tween.getId()];
      delete this._tweensAddedDuringUpdate[tween.getId()];
    };
    Group2.prototype.update = function(time, preserve) {
      if (time === void 0) {
        time = now();
      }
      if (preserve === void 0) {
        preserve = false;
      }
      var tweenIds = Object.keys(this._tweens);
      if (tweenIds.length === 0) {
        return false;
      }
      while (tweenIds.length > 0) {
        this._tweensAddedDuringUpdate = {};
        for (var i = 0; i < tweenIds.length; i++) {
          var tween = this._tweens[tweenIds[i]];
          var autoStart = !preserve;
          if (tween && tween.update(time, autoStart) === false && !preserve) {
            delete this._tweens[tweenIds[i]];
          }
        }
        tweenIds = Object.keys(this._tweensAddedDuringUpdate);
      }
      return true;
    };
    return Group2;
  }()
);
var Interpolation = {
  Linear: function(v, k) {
    var m = v.length - 1;
    var f = m * k;
    var i = Math.floor(f);
    var fn = Interpolation.Utils.Linear;
    if (k < 0) {
      return fn(v[0], v[1], f);
    }
    if (k > 1) {
      return fn(v[m], v[m - 1], m - f);
    }
    return fn(v[i], v[i + 1 > m ? m : i + 1], f - i);
  },
  Bezier: function(v, k) {
    var b = 0;
    var n = v.length - 1;
    var pw = Math.pow;
    var bn = Interpolation.Utils.Bernstein;
    for (var i = 0; i <= n; i++) {
      b += pw(1 - k, n - i) * pw(k, i) * v[i] * bn(n, i);
    }
    return b;
  },
  CatmullRom: function(v, k) {
    var m = v.length - 1;
    var f = m * k;
    var i = Math.floor(f);
    var fn = Interpolation.Utils.CatmullRom;
    if (v[0] === v[m]) {
      if (k < 0) {
        i = Math.floor(f = m * (1 + k));
      }
      return fn(v[(i - 1 + m) % m], v[i], v[(i + 1) % m], v[(i + 2) % m], f - i);
    } else {
      if (k < 0) {
        return v[0] - (fn(v[0], v[0], v[1], v[1], -f) - v[0]);
      }
      if (k > 1) {
        return v[m] - (fn(v[m], v[m], v[m - 1], v[m - 1], f - m) - v[m]);
      }
      return fn(v[i ? i - 1 : 0], v[i], v[m < i + 1 ? m : i + 1], v[m < i + 2 ? m : i + 2], f - i);
    }
  },
  Utils: {
    Linear: function(p0, p1, t) {
      return (p1 - p0) * t + p0;
    },
    Bernstein: function(n, i) {
      var fc = Interpolation.Utils.Factorial;
      return fc(n) / fc(i) / fc(n - i);
    },
    Factorial: /* @__PURE__ */ function() {
      var a = [1];
      return function(n) {
        var s = 1;
        if (a[n]) {
          return a[n];
        }
        for (var i = n; i > 1; i--) {
          s *= i;
        }
        a[n] = s;
        return s;
      };
    }(),
    CatmullRom: function(p0, p1, p2, p3, t) {
      var v0 = (p2 - p0) * 0.5;
      var v1 = (p3 - p1) * 0.5;
      var t2 = t * t;
      var t3 = t * t2;
      return (2 * p1 - 2 * p2 + v0 + v1) * t3 + (-3 * p1 + 3 * p2 - 2 * v0 - v1) * t2 + v0 * t + p1;
    }
  }
};
var Sequence = (
  /** @class */
  function() {
    function Sequence2() {
    }
    Sequence2.nextId = function() {
      return Sequence2._nextId++;
    };
    Sequence2._nextId = 0;
    return Sequence2;
  }()
);
var mainGroup = new Group();
var Tween$1 = (
  /** @class */
  function() {
    function Tween2(_object, _group) {
      if (_group === void 0) {
        _group = mainGroup;
      }
      this._object = _object;
      this._group = _group;
      this._isPaused = false;
      this._pauseStart = 0;
      this._valuesStart = {};
      this._valuesEnd = {};
      this._valuesStartRepeat = {};
      this._duration = 1e3;
      this._isDynamic = false;
      this._initialRepeat = 0;
      this._repeat = 0;
      this._yoyo = false;
      this._isPlaying = false;
      this._reversed = false;
      this._delayTime = 0;
      this._startTime = 0;
      this._easingFunction = Easing.Linear.None;
      this._interpolationFunction = Interpolation.Linear;
      this._chainedTweens = [];
      this._onStartCallbackFired = false;
      this._onEveryStartCallbackFired = false;
      this._id = Sequence.nextId();
      this._isChainStopped = false;
      this._propertiesAreSetUp = false;
      this._goToEnd = false;
    }
    Tween2.prototype.getId = function() {
      return this._id;
    };
    Tween2.prototype.isPlaying = function() {
      return this._isPlaying;
    };
    Tween2.prototype.isPaused = function() {
      return this._isPaused;
    };
    Tween2.prototype.to = function(target, duration) {
      if (duration === void 0) {
        duration = 1e3;
      }
      if (this._isPlaying)
        throw new Error("Can not call Tween.to() while Tween is already started or paused. Stop the Tween first.");
      this._valuesEnd = target;
      this._propertiesAreSetUp = false;
      this._duration = duration;
      return this;
    };
    Tween2.prototype.duration = function(duration) {
      if (duration === void 0) {
        duration = 1e3;
      }
      this._duration = duration;
      return this;
    };
    Tween2.prototype.dynamic = function(dynamic) {
      if (dynamic === void 0) {
        dynamic = false;
      }
      this._isDynamic = dynamic;
      return this;
    };
    Tween2.prototype.start = function(time, overrideStartingValues) {
      if (time === void 0) {
        time = now();
      }
      if (overrideStartingValues === void 0) {
        overrideStartingValues = false;
      }
      if (this._isPlaying) {
        return this;
      }
      this._group && this._group.add(this);
      this._repeat = this._initialRepeat;
      if (this._reversed) {
        this._reversed = false;
        for (var property in this._valuesStartRepeat) {
          this._swapEndStartRepeatValues(property);
          this._valuesStart[property] = this._valuesStartRepeat[property];
        }
      }
      this._isPlaying = true;
      this._isPaused = false;
      this._onStartCallbackFired = false;
      this._onEveryStartCallbackFired = false;
      this._isChainStopped = false;
      this._startTime = time;
      this._startTime += this._delayTime;
      if (!this._propertiesAreSetUp || overrideStartingValues) {
        this._propertiesAreSetUp = true;
        if (!this._isDynamic) {
          var tmp = {};
          for (var prop in this._valuesEnd)
            tmp[prop] = this._valuesEnd[prop];
          this._valuesEnd = tmp;
        }
        this._setupProperties(this._object, this._valuesStart, this._valuesEnd, this._valuesStartRepeat, overrideStartingValues);
      }
      return this;
    };
    Tween2.prototype.startFromCurrentValues = function(time) {
      return this.start(time, true);
    };
    Tween2.prototype._setupProperties = function(_object, _valuesStart, _valuesEnd, _valuesStartRepeat, overrideStartingValues) {
      for (var property in _valuesEnd) {
        var startValue = _object[property];
        var startValueIsArray = Array.isArray(startValue);
        var propType = startValueIsArray ? "array" : typeof startValue;
        var isInterpolationList = !startValueIsArray && Array.isArray(_valuesEnd[property]);
        if (propType === "undefined" || propType === "function") {
          continue;
        }
        if (isInterpolationList) {
          var endValues = _valuesEnd[property];
          if (endValues.length === 0) {
            continue;
          }
          var temp = [startValue];
          for (var i = 0, l = endValues.length; i < l; i += 1) {
            var value = this._handleRelativeValue(startValue, endValues[i]);
            if (isNaN(value)) {
              isInterpolationList = false;
              console.warn("Found invalid interpolation list. Skipping.");
              break;
            }
            temp.push(value);
          }
          if (isInterpolationList) {
            _valuesEnd[property] = temp;
          }
        }
        if ((propType === "object" || startValueIsArray) && startValue && !isInterpolationList) {
          _valuesStart[property] = startValueIsArray ? [] : {};
          var nestedObject = startValue;
          for (var prop in nestedObject) {
            _valuesStart[property][prop] = nestedObject[prop];
          }
          _valuesStartRepeat[property] = startValueIsArray ? [] : {};
          var endValues = _valuesEnd[property];
          if (!this._isDynamic) {
            var tmp = {};
            for (var prop in endValues)
              tmp[prop] = endValues[prop];
            _valuesEnd[property] = endValues = tmp;
          }
          this._setupProperties(nestedObject, _valuesStart[property], endValues, _valuesStartRepeat[property], overrideStartingValues);
        } else {
          if (typeof _valuesStart[property] === "undefined" || overrideStartingValues) {
            _valuesStart[property] = startValue;
          }
          if (!startValueIsArray) {
            _valuesStart[property] *= 1;
          }
          if (isInterpolationList) {
            _valuesStartRepeat[property] = _valuesEnd[property].slice().reverse();
          } else {
            _valuesStartRepeat[property] = _valuesStart[property] || 0;
          }
        }
      }
    };
    Tween2.prototype.stop = function() {
      if (!this._isChainStopped) {
        this._isChainStopped = true;
        this.stopChainedTweens();
      }
      if (!this._isPlaying) {
        return this;
      }
      this._group && this._group.remove(this);
      this._isPlaying = false;
      this._isPaused = false;
      if (this._onStopCallback) {
        this._onStopCallback(this._object);
      }
      return this;
    };
    Tween2.prototype.end = function() {
      this._goToEnd = true;
      this.update(Infinity);
      return this;
    };
    Tween2.prototype.pause = function(time) {
      if (time === void 0) {
        time = now();
      }
      if (this._isPaused || !this._isPlaying) {
        return this;
      }
      this._isPaused = true;
      this._pauseStart = time;
      this._group && this._group.remove(this);
      return this;
    };
    Tween2.prototype.resume = function(time) {
      if (time === void 0) {
        time = now();
      }
      if (!this._isPaused || !this._isPlaying) {
        return this;
      }
      this._isPaused = false;
      this._startTime += time - this._pauseStart;
      this._pauseStart = 0;
      this._group && this._group.add(this);
      return this;
    };
    Tween2.prototype.stopChainedTweens = function() {
      for (var i = 0, numChainedTweens = this._chainedTweens.length; i < numChainedTweens; i++) {
        this._chainedTweens[i].stop();
      }
      return this;
    };
    Tween2.prototype.group = function(group) {
      if (group === void 0) {
        group = mainGroup;
      }
      this._group = group;
      return this;
    };
    Tween2.prototype.delay = function(amount) {
      if (amount === void 0) {
        amount = 0;
      }
      this._delayTime = amount;
      return this;
    };
    Tween2.prototype.repeat = function(times) {
      if (times === void 0) {
        times = 0;
      }
      this._initialRepeat = times;
      this._repeat = times;
      return this;
    };
    Tween2.prototype.repeatDelay = function(amount) {
      this._repeatDelayTime = amount;
      return this;
    };
    Tween2.prototype.yoyo = function(yoyo) {
      if (yoyo === void 0) {
        yoyo = false;
      }
      this._yoyo = yoyo;
      return this;
    };
    Tween2.prototype.easing = function(easingFunction) {
      if (easingFunction === void 0) {
        easingFunction = Easing.Linear.None;
      }
      this._easingFunction = easingFunction;
      return this;
    };
    Tween2.prototype.interpolation = function(interpolationFunction) {
      if (interpolationFunction === void 0) {
        interpolationFunction = Interpolation.Linear;
      }
      this._interpolationFunction = interpolationFunction;
      return this;
    };
    Tween2.prototype.chain = function() {
      var tweens = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        tweens[_i] = arguments[_i];
      }
      this._chainedTweens = tweens;
      return this;
    };
    Tween2.prototype.onStart = function(callback) {
      this._onStartCallback = callback;
      return this;
    };
    Tween2.prototype.onEveryStart = function(callback) {
      this._onEveryStartCallback = callback;
      return this;
    };
    Tween2.prototype.onUpdate = function(callback) {
      this._onUpdateCallback = callback;
      return this;
    };
    Tween2.prototype.onRepeat = function(callback) {
      this._onRepeatCallback = callback;
      return this;
    };
    Tween2.prototype.onComplete = function(callback) {
      this._onCompleteCallback = callback;
      return this;
    };
    Tween2.prototype.onStop = function(callback) {
      this._onStopCallback = callback;
      return this;
    };
    Tween2.prototype.update = function(time, autoStart) {
      if (time === void 0) {
        time = now();
      }
      if (autoStart === void 0) {
        autoStart = true;
      }
      if (this._isPaused)
        return true;
      var property;
      var elapsed;
      var endTime = this._startTime + this._duration;
      if (!this._goToEnd && !this._isPlaying) {
        if (time > endTime)
          return false;
        if (autoStart)
          this.start(time, true);
      }
      this._goToEnd = false;
      if (time < this._startTime) {
        return true;
      }
      if (this._onStartCallbackFired === false) {
        if (this._onStartCallback) {
          this._onStartCallback(this._object);
        }
        this._onStartCallbackFired = true;
      }
      if (this._onEveryStartCallbackFired === false) {
        if (this._onEveryStartCallback) {
          this._onEveryStartCallback(this._object);
        }
        this._onEveryStartCallbackFired = true;
      }
      elapsed = (time - this._startTime) / this._duration;
      elapsed = this._duration === 0 || elapsed > 1 ? 1 : elapsed;
      var value = this._easingFunction(elapsed);
      this._updateProperties(this._object, this._valuesStart, this._valuesEnd, value);
      if (this._onUpdateCallback) {
        this._onUpdateCallback(this._object, elapsed);
      }
      if (elapsed === 1) {
        if (this._repeat > 0) {
          if (isFinite(this._repeat)) {
            this._repeat--;
          }
          for (property in this._valuesStartRepeat) {
            if (!this._yoyo && typeof this._valuesEnd[property] === "string") {
              this._valuesStartRepeat[property] = // eslint-disable-next-line
              // @ts-ignore FIXME?
              this._valuesStartRepeat[property] + parseFloat(this._valuesEnd[property]);
            }
            if (this._yoyo) {
              this._swapEndStartRepeatValues(property);
            }
            this._valuesStart[property] = this._valuesStartRepeat[property];
          }
          if (this._yoyo) {
            this._reversed = !this._reversed;
          }
          if (this._repeatDelayTime !== void 0) {
            this._startTime = time + this._repeatDelayTime;
          } else {
            this._startTime = time + this._delayTime;
          }
          if (this._onRepeatCallback) {
            this._onRepeatCallback(this._object);
          }
          this._onEveryStartCallbackFired = false;
          return true;
        } else {
          if (this._onCompleteCallback) {
            this._onCompleteCallback(this._object);
          }
          for (var i = 0, numChainedTweens = this._chainedTweens.length; i < numChainedTweens; i++) {
            this._chainedTweens[i].start(this._startTime + this._duration, false);
          }
          this._isPlaying = false;
          return false;
        }
      }
      return true;
    };
    Tween2.prototype._updateProperties = function(_object, _valuesStart, _valuesEnd, value) {
      for (var property in _valuesEnd) {
        if (_valuesStart[property] === void 0) {
          continue;
        }
        var start = _valuesStart[property] || 0;
        var end = _valuesEnd[property];
        var startIsArray = Array.isArray(_object[property]);
        var endIsArray = Array.isArray(end);
        var isInterpolationList = !startIsArray && endIsArray;
        if (isInterpolationList) {
          _object[property] = this._interpolationFunction(end, value);
        } else if (typeof end === "object" && end) {
          this._updateProperties(_object[property], start, end, value);
        } else {
          end = this._handleRelativeValue(start, end);
          if (typeof end === "number") {
            _object[property] = start + (end - start) * value;
          }
        }
      }
    };
    Tween2.prototype._handleRelativeValue = function(start, end) {
      if (typeof end !== "string") {
        return end;
      }
      if (end.charAt(0) === "+" || end.charAt(0) === "-") {
        return start + parseFloat(end);
      }
      return parseFloat(end);
    };
    Tween2.prototype._swapEndStartRepeatValues = function(property) {
      var tmp = this._valuesStartRepeat[property];
      var endValue = this._valuesEnd[property];
      if (typeof endValue === "string") {
        this._valuesStartRepeat[property] = this._valuesStartRepeat[property] + parseFloat(endValue);
      } else {
        this._valuesStartRepeat[property] = this._valuesEnd[property];
      }
      this._valuesEnd[property] = tmp;
    };
    return Tween2;
  }()
);
var VERSION = "21.1.1";
var nextId = Sequence.nextId;
var TWEEN = mainGroup;
var getAll = TWEEN.getAll.bind(TWEEN);
var removeAll = TWEEN.removeAll.bind(TWEEN);
var add = TWEEN.add.bind(TWEEN);
var remove = TWEEN.remove.bind(TWEEN);
var update = TWEEN.update.bind(TWEEN);
var exports = {
  Easing,
  Group,
  Interpolation,
  now,
  Sequence,
  nextId,
  Tween: Tween$1,
  VERSION,
  getAll,
  removeAll,
  add,
  remove,
  update
};
const name = "mirror3d";
const version = "2.0.4";
const main = "./dist/mirror3d.js";
const type = "module";
const scripts = {
  dev: "vite --mode dev build --watch",
  build: "vite --mode pro build",
  lint: "eslint .",
  preview: "vite preview",
  "release:patch": "release-it patch --no-git.requireUpstream --git.pushRepo=origin"
};
const dependencies = {
  "@tweenjs/tween.js": "^21.1.1",
  "@types/babel__core": "^7.20.5",
  "rollup-plugin-string": "^3.0.0"
};
const devDependencies = {
  "@eslint/js": "^9.9.0",
  "release-it": "^18.1.2",
  "@release-it/conventional-changelog": "^10.0.0",
  "@types/node": "^22.5.1",
  "@vitejs/plugin-react-swc": "^3.5.0",
  eslint: "^9.9.0",
  globals: "^15.9.0",
  "rollup-plugin-dts": "^6.1.1",
  typescript: "^5.7.3",
  "typescript-eslint": "^8.0.1",
  vite: "^5.4.1",
  "vite-plugin-copy": "^0.1.6",
  "vite-plugin-dts": "^4.5.0"
};
const packageJson = {
  name,
  version,
  main,
  type,
  scripts,
  dependencies,
  devDependencies
};
console.log("Welcome to use mirror3d: v.", packageJson.version);
const _Engine = class _Engine {
  constructor() {
    this._viewerList = [];
    this._isShowFPS = false;
    this._maxfps = 60;
    this._isInRenderLoop = false;
    this._isStopRenderLoop = false;
    this._requestCount = 0;
  }
  static get singleton() {
    if (!_Engine._singleton) {
      _Engine._singleton = new _Engine();
    }
    return _Engine._singleton;
  }
  get isShowFPS() {
    return this._isShowFPS;
  }
  set isShowFPS(v) {
    this._isShowFPS = v;
  }
  getViewerList() {
    return this._viewerList;
  }
  setEnableStatsPanel(enabled) {
    if (enabled) {
      let statsConstruct = Stats;
      if (!this._stats) {
        this._stats = new statsConstruct();
      }
      document.body.appendChild(this._stats.dom);
    } else {
      if (this._stats) {
        document.body.removeChild(this._stats.dom);
      }
    }
  }
  clearAllViews() {
    for (let index = 0, len = this._viewerList.length; index < len; index++) {
      const view = this._viewerList[index];
      this.removeView(view);
    }
    this._viewerList = [];
  }
  removeView(v) {
    const idx = this._viewerList.findIndex((item) => {
      return item === v;
    });
    if (idx !== -1) {
      this._viewerList[idx].clear();
      this._viewerList.splice(idx, 1);
    }
  }
  // createView(container:HTMLElement)
  createDefaultView(container, width, height) {
    if (!container) {
      throw new Error("The container is null,it need a valid dom element.");
    }
    let canvas = document.createElement("canvas");
    if (width && height) {
      canvas.style.position = "relative";
      canvas.style.width = `${width}`;
      canvas.style.height = `${height}`;
      canvas.style.textAlign = "center";
      canvas.width = width;
      canvas.height = height;
    } else {
      canvas.style.position = "absolute";
      canvas.style.left = "0";
      canvas.style.top = "0";
      canvas.style.width = "100%";
      canvas.style.height = "100%";
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
    }
    container.appendChild(canvas);
    let renderer = new Renderer(canvas);
    let viewer = new Viewer({
      canvas,
      renderer,
      container,
      width: canvas.width,
      height: canvas.height
    });
    this._viewerList.push(viewer);
    return viewer;
  }
  /**
   * stop a render loop
   */
  stopRenderLoop() {
    if (this._requestCount > 0) {
      this._requestCount -= 1;
    }
    if (this._isInRenderLoop && this._requestCount === 0) {
      logger.debug("----stop renderloop ----");
      this._isStopRenderLoop = true;
    }
  }
  /**
   * run a render loop
   */
  startRenderLoop() {
    this._requestCount += 1;
    if (this._isStopRenderLoop) {
      this._isStopRenderLoop = false;
    }
    if (this._isInRenderLoop) {
      return;
    }
    this._isInRenderLoop = true;
    if (this._isShowFPS) {
      this.setEnableStatsPanel(true);
    }
    logger.debug("----start scene render loop----");
    let lastTime = performance.now();
    let renderInterval = 1e3 / this._maxfps;
    if (this._maxfps > 1e4) {
      renderInterval = 0.1;
    }
    try {
      let requestUpdateFrame = window.requestAnimationFrame;
      let renderMain = (timestamp) => {
        const currentTime = performance.now();
        const elapsed = currentTime - lastTime;
        if (elapsed > renderInterval) {
          lastTime = currentTime - elapsed % renderInterval;
          this.updateAFrame(timestamp);
          if (this._stats) {
            this._stats.update();
          }
        }
        if (this._isStopRenderLoop) {
          this._isStopRenderLoop = false;
          this._isInRenderLoop = false;
          this.setEnableStatsPanel(false);
          return;
        }
        requestUpdateFrame(renderMain);
      };
      requestUpdateFrame(renderMain);
    } catch (excp) {
      logger.warn("EXCP:" + excp);
    }
  }
  set maxfps(val) {
    if (val < 1) {
      logger.warn("the MaxFPS cant less than 1");
      return;
    }
    this._maxfps = val;
  }
  /**
   * update a frame
   * @param timestamp
   */
  updateAFrame(timestamp) {
    logger.debug("render a frame----------------------");
    exports.update();
    let viewers = this._viewerList;
    for (let index = 0, len = viewers.length; index < len; index++) {
      const view = viewers[index];
      view.render();
    }
  }
};
_Engine.gViewerPriority = 0;
let Engine = _Engine;
var ButtonsType = /* @__PURE__ */ ((ButtonsType2) => {
  ButtonsType2[ButtonsType2["NONE"] = 0] = "NONE";
  ButtonsType2[ButtonsType2["LEFT_BUTTON"] = 1] = "LEFT_BUTTON";
  ButtonsType2[ButtonsType2["RIGHT_BUTTON"] = 2] = "RIGHT_BUTTON";
  ButtonsType2[ButtonsType2["MIDDLE_BUTTON"] = 4] = "MIDDLE_BUTTON";
  return ButtonsType2;
})(ButtonsType || {});
var PointerEventButtonType = /* @__PURE__ */ ((PointerEventButtonType2) => {
  PointerEventButtonType2[PointerEventButtonType2["LEFT"] = 0] = "LEFT";
  PointerEventButtonType2[PointerEventButtonType2["MIDDLE"] = 1] = "MIDDLE";
  PointerEventButtonType2[PointerEventButtonType2["RIGHT"] = 2] = "RIGHT";
  return PointerEventButtonType2;
})(PointerEventButtonType || {});
class CameraControllerIn2DMode {
  constructor() {
    this._wheelTimeoutID = 0;
    this._zoomSpeed = 0.1;
    this._onMiddleButtonPressed = false;
    this._moveSpeed = 1;
    this._panStartPoint = new Vector2();
    this._panEndPoint = new Vector2();
    this._panfactor = 1;
    this._camStartPosition = new Vector3();
    this._panOffset = new Vector2();
  }
  startPan([x, y]) {
    const pos = this._camera.position;
    this._camStartPosition.setByAnother(pos);
    this._panStartPoint.set(x, y);
  }
  stopPan([x, y]) {
    this._panEndPoint.set(x, y);
    this._panOffset = Vector2.sub([x, y], this._panStartPoint);
    Vector2.multiplyScalar(this._panOffset, this._panfactor, this._panOffset);
  }
  /**
   * range is between 0 and 1;
   */
  set zoomSpeed(val) {
    let v = val <= 0.1 ? 0.1 : val;
    v = val >= 1 ? 1 : val;
    this._zoomSpeed = v;
  }
  setup(view, camera) {
    this._camera = camera;
    this._camera.type = ECameraType.Orthographic;
    this._view = view;
    view.eventPointerMove.onFire(this.onMouseMove.bind(this));
    view.eventPointerDown.onFire((e) => {
      if (e.button === PointerEventButtonType.LEFT) {
        this.onMouseLeftPress(e);
        console.log("pointer left");
      } else if (e.button === PointerEventButtonType.MIDDLE) {
        this.onMouseMiddlePress(e);
      }
    });
    view.eventKeydown.onFire(this.onKeyboardEvent.bind(this));
    view.eventPointerUp.onFire((e) => {
      if (e.button === PointerEventButtonType.LEFT) {
        this.onMouseLeftRelease(e);
      } else if (e.button === PointerEventButtonType.MIDDLE) {
        this.onMouseMiddleRelease(e);
      }
    });
    view.eventWheel.onFire((e) => {
      this.onMouseWheel(e);
      if (this._wheelTimeoutID === 0) {
        Engine.singleton.startRenderLoop();
      } else {
        clearTimeout(this._wheelTimeoutID);
      }
      this._wheelTimeoutID = setTimeout(() => {
        Engine.singleton.stopRenderLoop();
        this._wheelTimeoutID = 0;
      }, 300);
    });
  }
  onMouseMove(e) {
    const camera2D = this._camera;
    if (this._onMiddleButtonPressed) {
      this.stopPan([e.clientX, e.clientY]);
      camera2D.position.x = this._camStartPosition.x + -this._panOffset.x;
      camera2D.position.y = this._camStartPosition.y + this._panOffset.y;
      camera2D.setTransformModifyFlag();
    }
  }
  onMouseMiddlePress(e) {
    Engine.singleton.startRenderLoop();
    this._onMiddleButtonPressed = true;
    this.startPan([e.clientX, e.clientY]);
  }
  onMouseMiddleRelease(e) {
    Engine.singleton.stopRenderLoop();
    this._onMiddleButtonPressed = false;
  }
  onMouseLeftPress(e) {
  }
  onMouseLeftRelease(e) {
  }
  onMouseWheel(e) {
    const delta = e.deltaY === 100 ? 1 : -1;
    this._camera.orthoHalfHeight *= 1 + delta * this._zoomSpeed;
    this._camera.setTransformModifyFlag();
  }
  onKeyboardEvent(e) {
    const camera2D = this._camera;
    const moveSpeed = this._moveSpeed;
    switch (e.key) {
      case "ArrowLeft":
        camera2D.position.x += moveSpeed;
        break;
      case "ArrowRight":
        camera2D.position.x -= moveSpeed;
        break;
      case "ArrowUp":
        camera2D.position.y -= moveSpeed;
        break;
      case "ArrowDown":
        camera2D.position.y += moveSpeed;
        break;
    }
    camera2D.setTransformModifyFlag();
    this._view.render();
  }
}
const DEFAULT_MIN_ZOOMSCALE = 0.5;
const DEFAULT_MAX_ZOOMSCALE = 2;
const DEFAULT_ZOOMZISE = 1;
const DEFAULT_PAN_FACTOR = 1;
class Pan {
  constructor(cam, panFactor) {
    this._startPos = new Vector2();
    this._endPos = new Vector2();
    this._factor = DEFAULT_PAN_FACTOR;
    this._panOffset = new Vector3();
    this._2DOffset = new Vector2();
    this._camera = cam;
    this._factor = panFactor ?? DEFAULT_PAN_FACTOR;
  }
  start([x, y]) {
    this._startPos.set(x, y);
  }
  stopPan([x, y]) {
    this._endPos.set(x, y);
    const mousePosOffset = Vector2.sub([x, y], this._startPos);
    Vector2.multiplyScalar(mousePosOffset, this._factor, mousePosOffset);
    this._2DOffset.setByAnother(mousePosOffset);
    const cam = this._camera;
    this.pan(
      [this._2DOffset.x, this._2DOffset.y],
      cam.position,
      cam.lookAt,
      cam.upVector,
      cam.fovy,
      cam.viewportWidth,
      cam.viewportHeight,
      cam.orthoHalfHeight
    );
  }
  get panOffset() {
    return this._panOffset;
  }
  get offset2D() {
    return this._2DOffset;
  }
  pan([deltaX, deltaY], camPos, camLookAt, camUp, camFov, vpWidth, vpHeight, camHalfHeight) {
    if (this._camera.type === ECameraType.Orthographic) {
      this._panOffset.set([0, 0, 0]);
      const viewMatrix = Matrix4.lookAt2(camPos, camLookAt, camUp);
      this._viewMatrixInverse = Matrix4.invert(
        viewMatrix,
        this._viewMatrixInverse
      );
      const distanceLeft = deltaX * (camHalfHeight * 2) / vpHeight;
      const matData = this._viewMatrixInverse.data;
      const leftOffset = new Vector3(matData[0], matData[1], matData[2]);
      Vector3.multiplyScalar(leftOffset, -distanceLeft, leftOffset);
      Vector3.add(this._panOffset, leftOffset, this._panOffset);
      const distanceUp = deltaY * (camHalfHeight * 2) / vpHeight;
      const upOffset = new Vector3(matData[4], matData[5], matData[6]);
      Vector3.multiplyScalar(upOffset, distanceUp, upOffset);
      Vector3.add(this._panOffset, upOffset, this._panOffset);
      return this._panOffset;
    } else if (this._camera.type === ECameraType.Perspective) {
      this._panOffset.set([0, 0, 0]);
      const offset = Vector3.sub(camPos, camLookAt);
      const viewMatrix = Matrix4.lookAt2(camPos, camLookAt, camUp);
      this._viewMatrixInverse = Matrix4.invert(
        viewMatrix,
        this._viewMatrixInverse
      );
      let targetDistance = Vector3.size(offset);
      targetDistance *= Math.tan(camFov / 2 * Math.PI / 180);
      const distanceLeft = 2 * deltaX * targetDistance / vpWidth;
      const matData = this._viewMatrixInverse.data;
      const leftOffset = new Vector3(matData[0], matData[1], matData[2]);
      Vector3.multiplyScalar(leftOffset, -distanceLeft, leftOffset);
      Vector3.add(this._panOffset, leftOffset, this._panOffset);
      const distanceUp = 2 * deltaY * targetDistance / vpHeight;
      const upOffset = new Vector3(matData[4], matData[5], matData[6]);
      Vector3.multiplyScalar(upOffset, distanceUp, upOffset);
      Vector3.add(this._panOffset, upOffset, this._panOffset);
      return this._panOffset;
    }
  }
}
class OrbitCameraController {
  constructor() {
    this._rightAxis = new Vector3();
    this._zoomSpeed = 0.1;
    this._onMiddleButtonPressed = false;
    this._wheelTimeoutID = 0;
    this._onButtonPressed = false;
    this._defaultZoomScale = DEFAULT_ZOOMZISE;
    this._maxZoomScale = DEFAULT_MAX_ZOOMSCALE;
    this._minZoomScale = DEFAULT_MIN_ZOOMSCALE;
    this._currentZoomScale = 1;
  }
  setup(view, camera, options) {
    this._camera = camera;
    this._view = view;
    this._target = options && options.targetPos ? options.targetPos : new Vector3(0, 0, 0);
    this._camera.lookAt = this._target.clone();
    this._oribitActionButtonType = options && options.oribitActionButtonType ? options.oribitActionButtonType : "left";
    let mainButtonFlag = PointerEventButtonType.LEFT;
    if (this._oribitActionButtonType === "right") {
      mainButtonFlag = PointerEventButtonType.RIGHT;
    }
    view.eventPointerMove.onFire(this._onMouseMove.bind(this));
    view.eventPointerDown.onFire((e) => {
      if (e.button === mainButtonFlag) {
        this._onMousePress(e);
      } else if (e.button === PointerEventButtonType.MIDDLE) {
        this._onMouseMiddlePress(e);
      }
    });
    view.eventPointerUp.onFire((e) => {
      if (e.button === mainButtonFlag) {
        this._onMouseRelease(e);
      } else if (e.button === PointerEventButtonType.MIDDLE) {
        this._onMouseMiddleRelease(e);
      }
    });
    view.eventWheel.onFire((e) => {
      if (this._wheelTimeoutID === 0) {
        Engine.singleton.startRenderLoop();
        this._onMouseWheel(e);
      } else {
        this._onMouseWheel(e);
        clearTimeout(this._wheelTimeoutID);
      }
      this._wheelTimeoutID = setTimeout(() => {
        Engine.singleton.stopRenderLoop();
        this._wheelTimeoutID = 0;
      }, 200);
    });
    view.eventPointerLeave.onFire((e) => {
      this._onMouseMiddleRelease(e);
      this._onMouseRelease(e);
    });
    this._originalOrthoHalfHeight = camera.orthoHalfHeight;
    const lookAt = camera.lookAt;
    const posToTarget = Vector3.sub(lookAt, camera.position);
    const distance = Vector3.size(posToTarget);
    this._defaultDistanceToCenter = distance;
    this._pan = new Pan(this._camera);
    if (options) {
      this.setZoomScale(
        options.defaultScale ? options.defaultScale : this._defaultZoomScale,
        options.maxZoomScale ? options.maxZoomScale : this._maxZoomScale,
        options.minZoomScale ? options.minZoomScale : this._minZoomScale
      );
    }
    if (options && options.onZoomChange) {
      this._onZoomChange = options.onZoomChange;
    }
  }
  /**
   * reset the default camera position
   * @param position
   */
  resetCameraPosition(position) {
    this._camera.position = position.clone();
    const lookAt = this._camera.lookAt;
    const posToTarget = Vector3.sub(lookAt, this._camera.position);
    const distance = Vector3.size(posToTarget);
    this._defaultDistanceToCenter = distance;
    this._currentZoomScale = 1;
  }
  /**
   * defaultSize is the default zoom size ,a camera zoom out/in from the default size.
   * @param defaultSize default size used to set the default zoom size
   * @param maxScale maxScale > 1
   * @param minScale 0 < minScale < 1
   */
  setZoomScale(defaultScale, maxScale = DEFAULT_MAX_ZOOMSCALE, minScale = DEFAULT_MIN_ZOOMSCALE) {
    if (maxScale <= 1) {
      logger.warn("maxScale of setZoomScale function is need > 1");
      return;
    }
    if (minScale <= 0 || minScale >= 1) {
      logger.warn(
        "minScale of setZoomScale function is need less than 1 and bigger than 0"
      );
      return;
    }
    this._defaultZoomScale = defaultScale;
    this._maxZoomScale = maxScale > 1 ? maxScale : this._maxZoomScale;
    this._minZoomScale = minScale < 1 && minScale > 0 ? minScale : this._minZoomScale;
    this._updateZoomScale();
  }
  /**
   * if you change the properties of camera , need update the controller.
   */
  update() {
    this._updateZoomScale();
  }
  get currentZoomScale() {
    return this._currentZoomScale;
  }
  set currentZoomScale(val) {
    this._currentZoomScale = val;
    this._currentZoomScale = this._currentZoomScale > this._maxZoomScale ? this._maxZoomScale : this._currentZoomScale;
    this._currentZoomScale = this._currentZoomScale < this._minZoomScale ? this._minZoomScale : this._currentZoomScale;
    this._updateZoomScale();
  }
  set onZoomChange(onZoomChange) {
    this._onZoomChange = onZoomChange;
  }
  _updateZoomScale() {
    const cam = this._camera;
    let camPrePos = this._camera.position.clone();
    const panAccumulatedOffset = this._panAccumulatedOffset;
    if (panAccumulatedOffset) {
      const panEndOffset = this._pan.pan(
        [panAccumulatedOffset.x, panAccumulatedOffset.y],
        camPrePos,
        cam.lookAt,
        cam.upVector,
        cam.fovy,
        cam.viewportWidth,
        cam.viewportHeight,
        cam.orthoHalfHeight
      );
      Vector3.sub(camPrePos, panEndOffset, camPrePos);
    }
    if (cam.type === ECameraType.Perspective) {
      const distance = this._defaultDistanceToCenter * (1 / (this._defaultZoomScale * this._currentZoomScale));
      const lookAt = this._target;
      const posToTarget = Vector3.sub(lookAt, camPrePos);
      Vector3.normalize(posToTarget, posToTarget);
      camPrePos = Vector3.fromArray([
        lookAt[0] - distance * posToTarget[0],
        lookAt[1] - distance * posToTarget[1],
        lookAt[2] - distance * posToTarget[2]
      ]);
    } else if (cam.type === ECameraType.Orthographic) {
      cam.orthoHalfHeight = this._originalOrthoHalfHeight * (1 / (this._defaultZoomScale * this._currentZoomScale));
    }
    if (panAccumulatedOffset) {
      const panEndOffset = this._pan.pan(
        [panAccumulatedOffset.x, panAccumulatedOffset.y],
        camPrePos,
        this._target,
        cam.upVector,
        cam.fovy,
        cam.viewportWidth,
        cam.viewportHeight,
        cam.orthoHalfHeight
      );
      Vector3.add(camPrePos, panEndOffset, camPrePos);
      Vector3.add(this._target, panEndOffset, this._camera.lookAt);
    }
    cam.position.setByAnother(camPrePos);
    cam.setTransformModifyFlag();
  }
  /**
   *  care about e.deltaY only
   * @param e
   */
  _onMouseWheel(e) {
    let amount = e.deltaY < 0 ? this._zoomSpeed + 1 : 1 / (this._zoomSpeed + 1);
    this.currentZoomScale = this.currentZoomScale * amount;
    if (this._onZoomChange) {
      this._onZoomChange(this._currentZoomScale);
    }
  }
  _onMouseMiddlePress(e) {
    Engine.singleton.startRenderLoop();
    this._onMiddleButtonPressed = true;
    this._pan.start([e.clientX, e.clientY]);
    this._panStartPoint = this._camera.position.clone();
    this._panStartLookAt = this._camera.lookAt.clone();
  }
  _onMouseMiddleRelease(e) {
    if (this._onMiddleButtonPressed) {
      this._onMiddleButtonPressed = false;
      Engine.singleton.stopRenderLoop();
      if (!this._panAccumulatedOffset) {
        this._panAccumulatedOffset = new Vector2();
      }
      this._panAccumulatedOffset = Vector2.add(
        this._panAccumulatedOffset,
        this._pan.offset2D,
        this._panAccumulatedOffset
      );
    }
  }
  _onMousePress(e) {
    Engine.singleton.startRenderLoop();
    this._onButtonPressed = true;
  }
  _onMouseRelease(e) {
    if (this._onButtonPressed) {
      Engine.singleton.stopRenderLoop();
      this._onButtonPressed = false;
      this._previousPosition = void 0;
    }
  }
  _onMouseMove(opts) {
    if (this._onButtonPressed) {
      this._rotateOnMouseMove(opts);
    } else if (this._onMiddleButtonPressed) {
      this._pan.stopPan([opts.clientX, opts.clientY]);
      const panOffset = this._pan.panOffset;
      Vector3.add(this._panStartPoint, panOffset, this._camera.position);
      Vector3.add(this._panStartLookAt, panOffset, this._camera.lookAt);
      this._camera.setTransformModifyFlag();
    }
  }
  // _yAxis = new Vector3(0,1,0);
  _rotateOnMouseMove(opts) {
    if (!this._onButtonPressed) {
      return;
    }
    const cam = this._camera;
    const target = this._target;
    const rotationFactor = 0.5;
    let camPrePos = this._camera.position.clone();
    const panAccumulatedOffset = this._panAccumulatedOffset;
    if (panAccumulatedOffset) {
      const panEndOffset = this._pan.pan(
        [panAccumulatedOffset.x, panAccumulatedOffset.y],
        camPrePos,
        cam.lookAt,
        cam.upVector,
        cam.fovy,
        cam.viewportWidth,
        cam.viewportHeight,
        cam.orthoHalfHeight
      );
      Vector3.sub(camPrePos, panEndOffset, camPrePos);
    }
    const mat4 = Matrix4.identity();
    if (!this._previousPosition) {
      this._previousPosition = [opts.clientX, opts.clientY];
    }
    const dx = this._previousPosition[0] - opts.clientX;
    const dy = this._previousPosition[1] - opts.clientY;
    this._dxOffset += dx;
    this._dyOffset += dy;
    const viewSize = [this._view.viewRect.width, this._view.viewRect.height];
    this._previousPosition = [opts.clientX, opts.clientY];
    const viewUp = cam.upVector;
    const vec3temp = Vector3.sub(camPrePos, target);
    Vector3.normalize(vec3temp, vec3temp);
    Vector3.cross(viewUp, vec3temp, this._rightAxis);
    Matrix4.axisRotate(
      mat4,
      viewUp,
      degreesToRadians(360 * dx / viewSize[0] * rotationFactor),
      mat4
    );
    Matrix4.axisRotate(
      mat4,
      this._rightAxis,
      degreesToRadians(360 * dy / viewSize[1] * rotationFactor),
      mat4
    );
    Vector3.transformMat4(viewUp, mat4, viewUp);
    Vector3.sub(camPrePos, this._target, camPrePos);
    Vector3.transformMat4(camPrePos, mat4, camPrePos);
    Vector3.add(camPrePos, this._target, camPrePos);
    if (panAccumulatedOffset) {
      const panEndOffset = this._pan.pan(
        [panAccumulatedOffset.x, panAccumulatedOffset.y],
        camPrePos,
        this._target,
        viewUp,
        cam.fovy,
        cam.viewportWidth,
        cam.viewportHeight,
        cam.orthoHalfHeight
      );
      Vector3.add(camPrePos, panEndOffset, camPrePos);
      Vector3.add(this._target, panEndOffset, this._camera.lookAt);
    }
    cam.position.setByAnother(camPrePos);
    cam.setTransformModifyFlag();
  }
}
const _Entity = class _Entity extends SceneNode {
  constructor(renderType) {
    super();
    this.drawableObjs = [];
    this._components = [];
    this._scripts = [];
    this.renderType = renderType || ERenderGroupType.OPAQUE;
    this._name = `Entity_${_Entity._instanceCount++}`;
    this._className = "Entity";
  }
  /**
   * get all components
   */
  get components() {
    return this._components;
  }
  /**
   * get script components
   */
  get scripts() {
    return this._scripts;
  }
  /**
   * get a component by its class type
   * @param compClass
   * @returns
   */
  getComponent(compClass) {
    for (let i = 0; i < this._components.length; i++) {
      const comp = this._components[i];
      if (compClass === comp.constructor) {
        return comp;
      }
    }
  }
  addComponent(compClass) {
    const index = this._components.findIndex((item) => {
      return item.constructor === compClass;
    });
    if (index === -1) {
      const comp = new compClass();
      comp.bindEntity(this);
      this._components.push(comp);
      if (comp.constructor === ComScript) {
        this._scripts.push(comp);
      }
      return comp;
    } else {
      const comp = this._components[index];
      return comp;
    }
  }
  /**
   * Remove a component from entity
   * @param compClass
   */
  removeComponent(compClass) {
    const findItem = (item) => {
      return item.constructor === compClass;
    };
    const index = this._components.findIndex(findItem);
    if (index !== -1) {
      this._components.splice(index);
      if (compClass.constructor === ComScript) {
        const indexInScripts = this._scripts.findIndex(findItem);
        this._scripts.splice(indexInScripts);
      }
    }
  }
  static createEntFromDrawableObj(drawableObj, renderType) {
    let ent = new _Entity(renderType);
    ent.drawableObjs.push(drawableObj);
    let indicesVertexProperty = drawableObj.vertexInfo.indices;
    let positionVertexProperty = drawableObj.vertexInfo.position;
    if (indicesVertexProperty) {
      drawableObj.drawArrayCount = indicesVertexProperty.buffer.length / indicesVertexProperty.numComponents;
      drawableObj.drawArrayOffset = 0;
    } else {
      drawableObj.drawArrayCount = positionVertexProperty.buffer.length / positionVertexProperty.numComponents;
      drawableObj.drawArrayOffset = 0;
    }
    return ent;
  }
  static createEntFromMesh(mesh) {
    let ent = new _Entity();
    const meshRenderer = ent.addComponent(ComMeshRenderer);
    meshRenderer.mesh = mesh;
    return ent;
  }
};
_Entity._instanceCount = 0;
let Entity = _Entity;
class RenderTarget {
  constructor(gl) {
    this._gl = gl;
  }
  /**
   * create a new framebuffer ,  texture , renderbuffer and bind texture and renderbuffer to framebuffer
   * ..param width 
   * @param height 
   */
  static createTextureRenderTarget(gl, width, height) {
    try {
      let ret = new RenderTarget(gl);
      ret.rttFramebuffer = gl.createFramebuffer();
      let rttFramebuffer = ret.rttFramebuffer;
      gl.bindFramebuffer(gl.FRAMEBUFFER, rttFramebuffer);
      const tex = new Texture({
        width,
        height,
        format: ETextureFormat.RGBA,
        type: ETextureDataType.UNSIGNED_BYTE,
        data: null
      });
      let glTexture = tex.createGlTexture(gl);
      var renderbuffer = gl.createRenderbuffer();
      gl.bindRenderbuffer(gl.RENDERBUFFER, renderbuffer);
      gl.renderbufferStorage(
        gl.RENDERBUFFER,
        gl.DEPTH_COMPONENT16,
        //这两个参数是固定的      
        width,
        height
      );
      gl.framebufferTexture2D(
        gl.FRAMEBUFFER,
        gl.COLOR_ATTACHMENT0,
        gl.TEXTURE_2D,
        //target      
        glTexture,
        //source, A WebGLTexture object whose image to attach.      
        0
      );
      gl.framebufferRenderbuffer(
        gl.FRAMEBUFFER,
        gl.DEPTH_ATTACHMENT,
        gl.RENDERBUFFER,
        //renderBufferTarget, A GLenum specifying the binding point (target) for the render buffer.      
        renderbuffer
      );
      gl.bindTexture(gl.TEXTURE_2D, null);
      gl.bindRenderbuffer(gl.RENDERBUFFER, null);
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
      return ret;
    } catch (err) {
      logger.error("createTextureRenderTarget ERROR:" + err);
      throw err;
    }
  }
  pickColor(x, y, width, height) {
    let gl = this._gl;
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.rttFramebuffer);
    const pixelData = new Uint8Array(4 * width * height);
    gl.readPixels(x, y, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixelData);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    return pixelData;
  }
  pickColorFromRenderTarget(screenX, ScreenY) {
    let gl = this._gl;
    let color;
    logger.debug("bind frame buffer...", this.rttFramebuffer);
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.rttFramebuffer);
    let pixelData = new Uint8Array(4 * 1);
    gl.readPixels(screenX, ScreenY, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixelData);
    if (pixelData && pixelData.length) {
      color = new Vector4(pixelData[0], pixelData[1], pixelData[2], pixelData[3]);
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    return color;
  }
  beginRenderToTarget(gl) {
    logger.debug("begin render to FBO", gl);
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.rttFramebuffer);
  }
  endRenderToTarget(gl) {
    logger.debug("end render to FBO");
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }
}
function calculateOrigin(geometry, result) {
  let ret = result ?? new Vector3();
  const indeices = geometry.vertexInfo.indices.buffer;
  const vertices = geometry.vertexInfo.position.buffer;
  let minX = Infinity;
  let minY = Infinity;
  let minZ = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  let maxZ = -Infinity;
  for (let i = 0; i < indeices.length; i += 3) {
    const trianglesIndexs = [indeices[i], indeices[i + 1], indeices[i + 2]];
    for (let n = 0; n < 3; n++) {
      const ind = trianglesIndexs[n] * 3;
      if (vertices[ind] < minX) minX = vertices[ind];
      if (vertices[ind + 1] < minY) minY = vertices[ind + 1];
      if (vertices[ind + 2] < minZ) minZ = vertices[ind + 2];
      if (vertices[ind] > maxX) maxX = vertices[ind];
      if (vertices[ind + 1] > maxY) maxY = vertices[ind + 1];
      if (vertices[ind + 2] > maxZ) maxZ = vertices[ind + 2];
    }
  }
  ret[0] = (minX + maxX) / 2;
  ret[1] = (minY + maxY) / 2;
  ret[2] = (minZ + maxZ) / 2;
  return ret;
}
class Primitive extends MObject {
  constructor(geometry, material, drawMode) {
    super();
    this.geometry = geometry;
    this.material = material;
    this.drawMode = drawMode;
  }
  clone() {
    let ret = new Primitive();
    ret.copy(this);
    return ret;
  }
  copy(source) {
    this.geometry = source.geometry.clone();
    this.material = source.material.clone();
    this.drawMode = source.drawMode;
    this.name = source.name;
  }
}
class BoundingBox extends MObject {
  constructor(min, max) {
    super();
    this._min = min ?? new Vector3(0, 0, 0);
    this._max = max ?? new Vector3(0, 0, 0);
  }
  get min() {
    return this._min;
  }
  get max() {
    return this._max;
  }
  static getAABBByPoints(points, elementSize = 3, out) {
    let ret = out ?? new BoundingBox();
    if (elementSize === 3) {
      for (let index = 0, len = points.length; index < len; index += elementSize) {
        const point = [points[index], points[index + 1], points[index + 2]];
        Vector3.min(point, ret._min, ret._min);
        Vector3.max(
          [points[index], points[index + 1], points[index + 2]],
          ret._max,
          ret._max
        );
      }
    }
    return ret;
  }
  static merge(box1, box2, out) {
    let ret = out ?? new BoundingBox();
    Vector3.min(box1._min, box2._min, ret._min);
    Vector3.max(box1._max, box2._max, ret._max);
    return ret;
  }
  getVertexes() {
    const ret = [];
    const { _min, _max } = this;
    const minX = _min.x;
    const minY = _min.y;
    const minZ = _min.z;
    const maxX = _max.x;
    const maxY = _max.y;
    const maxZ = _max.z;
    for (let i = 0, l = 8; i < l; ++i) {
      ret[i] = new Vector3();
    }
    ret[0].set([minX, maxY, maxZ]);
    ret[1].set([maxX, maxY, maxZ]);
    ret[2].set([maxX, minY, maxZ]);
    ret[3].set([minX, minY, maxZ]);
    ret[4].set([minX, maxY, minZ]);
    ret[5].set([maxX, maxY, minZ]);
    ret[6].set([maxX, minY, minZ]);
    ret[7].set([minX, minY, minZ]);
    return ret;
  }
  getCenter(out) {
    let ret = out ?? new Vector3();
    const { _min, _max } = this;
    const centerX = (_max[0] + _min[0]) * 0.5;
    const centerY = (_max[1] + _min[1]) * 0.5;
    const centerZ = (_max[2] + _min[2]) * 0.5;
    ret.set([centerX, centerY, centerZ]);
    return ret;
  }
}
class Mesh {
  constructor(primitive, opts) {
    this._primitives = [];
    this._noBounds = false;
    if (primitive) {
      this._primitives.push(...primitive);
      this._noBounds = opts && opts.noBounds ? opts.noBounds : false;
      this._updateBounds();
    }
  }
  get primitives() {
    return this._primitives;
  }
  get bounds() {
    return this._bounds;
  }
  get center() {
    return this._bounds.getCenter();
  }
  _updateBounds() {
    if (this._noBounds) {
      return;
    }
    if (!this._bounds) {
      this._bounds = new BoundingBox();
    }
    const aabb = new BoundingBox();
    for (let i = 0, l = this._primitives.length; i < l; i++) {
      const element = this._primitives[i];
      const vertexes = element.geometry.vertexInfo["position"].buffer;
      BoundingBox.getAABBByPoints(vertexes, 3, aabb);
      BoundingBox.merge(this._bounds, aabb, this._bounds);
    }
  }
  /**
   * easily create a mesh from a geometry
   * @param geometry
   * @param material
   * @returns
   */
  static createMeshWithGeometry(geometry, material, meshOptions) {
    return new Mesh([new Primitive(geometry, material)], meshOptions);
  }
  /**
   * easily create a mesh from a geometry Array
   * @param geometry
   * @param material
   * @returns
   */
  static createMeshWithGeometries(geometryArray, material, meshOptions) {
    const primtives = [];
    for (const geo of geometryArray) {
      primtives.push(new Primitive(geo, material));
    }
    return new Mesh(primtives, meshOptions);
  }
  addPrimitive(primitive) {
    if (!primitive) {
      return;
    }
    this._primitives.push(...primitive);
    this._updateBounds();
  }
  removePrimitive(primitive) {
    if (!primitive) {
      return;
    }
    const indexForDelete = this._primitives.findIndex((val) => {
      return val === primitive;
    });
    this._primitives.splice(indexForDelete, 1);
  }
  copy(source) {
    this._primitives = [];
    source._primitives.forEach((primitive) => {
      this.addPrimitive([primitive.clone()]);
    });
  }
  clone() {
    let ret = new Mesh();
    ret.copy(this);
    return ret;
  }
}
class VisualizeHelper extends MObject {
  constructor() {
    super();
  }
  static generateAABBMesh(mesh, color) {
    const AABBVertexes = mesh.bounds.getVertexes();
    const geometry = new Geometry();
    const vertexes = new Float32Array([
      AABBVertexes[0][0],
      AABBVertexes[0][1],
      AABBVertexes[0][2],
      AABBVertexes[1][0],
      AABBVertexes[1][1],
      AABBVertexes[1][2],
      AABBVertexes[2][0],
      AABBVertexes[2][1],
      AABBVertexes[2][2],
      AABBVertexes[3][0],
      AABBVertexes[3][1],
      AABBVertexes[3][2],
      AABBVertexes[4][0],
      AABBVertexes[4][1],
      AABBVertexes[4][2],
      AABBVertexes[5][0],
      AABBVertexes[5][1],
      AABBVertexes[5][2],
      AABBVertexes[6][0],
      AABBVertexes[6][1],
      AABBVertexes[6][2],
      AABBVertexes[7][0],
      AABBVertexes[7][1],
      AABBVertexes[7][2]
    ]);
    geometry.setVertexAttribute("position", {
      buffer: vertexes,
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    geometry.setVertexAttribute("indices", {
      buffer: new Uint8Array([
        0,
        1,
        1,
        2,
        2,
        3,
        3,
        0,
        0,
        4,
        1,
        5,
        2,
        6,
        3,
        7,
        4,
        5,
        5,
        6,
        6,
        7,
        7,
        4
      ]),
      numComponents: 2,
      sizeofStride: 2,
      offsetofStride: 0
    });
    geometry.drawCount = 24;
    geometry.drawOffset = 0;
    geometry.drawType = EDrawType.LINES;
    const material = new ColorMaterial({ color });
    const outMesh = Mesh.createMeshWithGeometry(geometry, material);
    return outMesh;
  }
  static generateNormalGemometryByGeometry(geo, normalLength) {
    const ret = new Geometry();
    const positionAttribute = geo.vertexInfo["position"];
    const normalAttribute = geo.vertexInfo["normal"];
    const newPos = new Float32Array(positionAttribute.buffer.length * 2);
    const tempVec3 = new Vector3();
    for (let i = 0; i < normalAttribute.buffer.length; i += 3) {
      const normal = [
        normalAttribute.buffer[i],
        normalAttribute.buffer[i + 1],
        normalAttribute.buffer[i + 2]
      ];
      const pos = [
        positionAttribute.buffer[i],
        positionAttribute.buffer[i + 1],
        positionAttribute.buffer[i + 2]
      ];
      Vector3.multiplyScalar(normal, normalLength ?? 1, tempVec3);
      Vector3.add(pos, tempVec3, tempVec3);
      const n = i * 2;
      newPos[n] = pos[0];
      newPos[n + 1] = pos[1];
      newPos[n + 2] = pos[2];
      newPos[n + 3] = tempVec3[0];
      newPos[n + 4] = tempVec3[1];
      newPos[n + 5] = tempVec3[2];
    }
    ret.setVertexAttribute("position", {
      buffer: newPos,
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    return ret;
  }
  static generateWireFrameGeometry(geometry) {
    const edges = /* @__PURE__ */ new Set();
    const isUnique = (start, end) => {
      const hash1 = `${start.x},${start.y},${start.z}-${end.x},${end.y},${end.z}`;
      const hash2 = `${end.x},${end.y},${end.z}-${start.x},${start.y},${start.z}`;
      if (edges.has(hash1) === true || edges.has(hash2) === true) {
        return false;
      } else {
        edges.add(hash1);
        edges.add(hash2);
        return true;
      }
    };
    const setVector3FromAttributeBuffer = (attributeBuffer, index, out) => {
      out.set([
        attributeBuffer[index * 3 + 0],
        attributeBuffer[index * 3 + 1],
        attributeBuffer[index * 3 + 2]
      ]);
    };
    const vertices = [];
    const positions = geometry.vertexInfo["position"];
    const startVec = new Vector3();
    const endVec = new Vector3();
    if (geometry.vertexInfo["indices"]) {
      const indices = geometry.vertexInfo["indices"];
      let start = 0;
      let count = indices.buffer.length;
      for (let i = start; i < count; i += 3) {
        const pos0Index = indices.buffer[i + 0];
        const pos1Index = indices.buffer[i + 1];
        const pos2Index = indices.buffer[i + 2];
        setVector3FromAttributeBuffer(positions.buffer, pos0Index, startVec);
        setVector3FromAttributeBuffer(positions.buffer, pos1Index, endVec);
        if (isUnique(startVec, endVec) === true) {
          vertices.push(startVec.x, startVec.y, startVec.z);
          vertices.push(endVec.x, endVec.y, endVec.z);
        }
        setVector3FromAttributeBuffer(positions.buffer, pos1Index, startVec);
        setVector3FromAttributeBuffer(positions.buffer, pos2Index, endVec);
        if (isUnique(startVec, endVec) === true) {
          vertices.push(startVec.x, startVec.y, startVec.z);
          vertices.push(endVec.x, endVec.y, endVec.z);
        }
        setVector3FromAttributeBuffer(positions.buffer, pos2Index, startVec);
        setVector3FromAttributeBuffer(positions.buffer, pos0Index, endVec);
        if (isUnique(startVec, endVec) === true) {
          vertices.push(startVec.x, startVec.y, startVec.z);
          vertices.push(endVec.x, endVec.y, endVec.z);
        }
      }
    } else {
      const posCount = positions.buffer.length / 3;
      for (let i = 0; i < posCount; i += 3) {
        const pos0Index = i + 0;
        const pos1Index = i + 1;
        const pos2Index = i + 2;
        setVector3FromAttributeBuffer(positions.buffer, pos0Index, startVec);
        setVector3FromAttributeBuffer(positions.buffer, pos1Index, endVec);
        if (isUnique(startVec, endVec) === true) {
          vertices.push(startVec.x, startVec.y, startVec.z);
          vertices.push(endVec.x, endVec.y, endVec.z);
        }
        setVector3FromAttributeBuffer(positions.buffer, pos1Index, startVec);
        setVector3FromAttributeBuffer(positions.buffer, pos2Index, endVec);
        if (isUnique(startVec, endVec) === true) {
          vertices.push(startVec.x, startVec.y, startVec.z);
          vertices.push(endVec.x, endVec.y, endVec.z);
        }
        setVector3FromAttributeBuffer(positions.buffer, pos2Index, startVec);
        setVector3FromAttributeBuffer(positions.buffer, pos0Index, endVec);
        if (isUnique(startVec, endVec) === true) {
          vertices.push(startVec.x, startVec.y, startVec.z);
          vertices.push(endVec.x, endVec.y, endVec.z);
        }
      }
    }
    const retGeometry = new Geometry();
    retGeometry.drawType = EDrawType.LINES;
    retGeometry.setVertexAttribute("position", {
      buffer: new Float32Array(vertices),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    retGeometry.drawOffset = 0;
    retGeometry.drawCount = vertices.length / 2;
    return retGeometry;
  }
  static generateWireFrmeMeshByGeometry(geometry, color) {
    const newGeometry = VisualizeHelper.generateWireFrameGeometry(geometry);
    const material = new ColorMaterial({
      color: color ?? new Vector4(0.1, 0.1, 0.1, 1)
    });
    return new Mesh([new Primitive(newGeometry, material)]);
  }
  static generateNormalMeshByGeometry(geometry, normalLength, color) {
    const newGeo = VisualizeHelper.generateNormalGemometryByGeometry(
      geometry,
      normalLength
    );
    const material = new ColorMaterial({
      color: color ?? new Vector4(1, 0, 0, 1)
    });
    newGeo.drawType = EDrawType.LINES;
    return Mesh.createMeshWithGeometry(newGeo, material);
  }
  static generateNormalMeshByMesh(mesh, color) {
    const material = new ColorMaterial({
      color: color ?? new Vector4(1, 0, 0, 1)
    });
    const newPrimitives = [];
    const primitives = mesh.primitives;
    const targetSize = Vector3.sub(mesh.bounds.max, mesh.bounds.min);
    const distance = Math.max(
      Math.abs(targetSize.x),
      Math.abs(targetSize.y),
      Math.abs(targetSize.z)
    );
    for (const prim of primitives) {
      const newGeo = VisualizeHelper.generateNormalGemometryByGeometry(
        prim.geometry,
        distance * 0.05
      );
      newGeo.drawType = EDrawType.LINES;
      newPrimitives.push(new Primitive(newGeo, material));
    }
    return new Mesh(newPrimitives);
  }
}
var EDrawType = /* @__PURE__ */ ((EDrawType2) => {
  EDrawType2[EDrawType2["POINTS"] = 0] = "POINTS";
  EDrawType2[EDrawType2["LINES"] = 1] = "LINES";
  EDrawType2[EDrawType2["LINES_LOOP"] = 2] = "LINES_LOOP";
  EDrawType2[EDrawType2["LINES_STRIP"] = 3] = "LINES_STRIP";
  EDrawType2[EDrawType2["TRIANGLES"] = 4] = "TRIANGLES";
  EDrawType2[EDrawType2["TRIANGLES_STRIP"] = 5] = "TRIANGLES_STRIP";
  EDrawType2[EDrawType2["TRIANGLE_FAN"] = 6] = "TRIANGLE_FAN";
  return EDrawType2;
})(EDrawType || {});
class DrawableObj {
  constructor() {
    this.vertexAttrBufferCache = null;
    this.uniforms = {};
    this.renderState = new State();
    this.isDrawWithoutVPMatrix = false;
    this.id = Utils.generateUUID();
  }
  bindIBO(gl) {
    GLHelper.bindIBOFromVertexInfo(gl, this);
  }
}
class AttributeInfo {
  constructor(name2, options) {
    this.name = name2;
    this.buffer = options.buffer;
    this.isConstantToShader = options.isConstantToShader || false;
    this.numComponents = options.numComponents;
    this.sizeofStride = options.sizeofStride;
    this.offsetofStride = options.offsetofStride;
    this.normalized = options.normalized || false;
    this._checkBufferType();
  }
  copy(source) {
    this.name = source.name;
    if (source.buffertype) {
      this.buffertype = source.buffertype;
    } else {
      this.buffertype = Float32Array;
    }
    this.buffer = new this.buffertype(source.buffer.buffer.slice(0));
    this.isConstantToShader = source.isConstantToShader;
    this.numComponents = source.numComponents;
    this.sizeofStride = source.sizeofStride;
    this.offsetofStride = source.offsetofStride;
    this.normalized = source.normalized;
  }
  clone() {
    let ret = {};
    ret.copy(this);
    return ret;
  }
  _checkBufferType() {
    if (!this.buffer) {
      this.buffertype = void 0;
    }
    if (this.buffer instanceof Float32Array) {
      this.buffertype = Float32Array;
      this.glType = EGLDataType.FLOAT;
    } else if (this.buffer instanceof Int8Array) {
      this.buffertype = Int8Array;
      this.glType = EGLDataType.BYTE;
    } else if (this.buffer instanceof Uint8Array) {
      this.buffertype = Uint8Array;
      this.glType = EGLDataType.UNSIGNED_BYTE;
    } else if (this.buffer instanceof Int16Array) {
      this.buffertype = Int16Array;
      this.glType = EGLDataType.SHORT;
    } else if (this.buffer instanceof Uint16Array) {
      this.buffertype = Uint16Array;
      this.glType = EGLDataType.UNSIGNED_SHORT;
    } else if (this.buffer instanceof Int32Array) {
      this.buffertype = Int32Array;
      this.glType = EGLDataType.INT;
    } else if (this.buffer instanceof Uint32Array) {
      this.buffertype = Uint32Array;
      this.glType = EGLDataType.UNSIGNED_INT;
    } else if (this.buffer instanceof Float64Array) {
      this.buffertype = Float64Array;
      logger.warn(
        "Geometry Attributes: Unsupported data buffer format: Float64Array."
      );
    } else {
      logger.warn("Geometry Attributes: Invalid buffer data");
      this.buffertype = void 0;
    }
  }
}
class Geometry {
  constructor() {
    this._vertexInfo = {};
    this._drawCount = 3;
    this._drawOffset = 0;
    this._drawType = EDrawType.TRIANGLES;
  }
  get drawType() {
    return this._drawType;
  }
  get drawOffset() {
    return this._drawOffset;
  }
  get drawCount() {
    return this._drawCount;
  }
  set drawType(v) {
    this._drawType = v;
  }
  set drawOffset(val) {
    this._drawOffset = val;
  }
  /**
   * The vertices number of total cells.
   * every type of cell is different.
   * e.g if cell type is triangle the number of one cell is 3.
   */
  set drawCount(val) {
    this._drawCount = val;
  }
  clone() {
    let ret = new Geometry();
    ret.copy(this);
    return ret;
  }
  copy(source) {
    const keys = Object.keys(this._vertexInfo);
    for (const key of keys) {
      this._vertexInfo[key] = source._vertexInfo[key].clone();
    }
    this._drawCount = source._drawCount;
    this._drawOffset = source._drawOffset;
    this._drawType = source._drawType;
  }
  /**
   * to set vertices attributes which is used in glsl script
   * the attribute name will be add flag 'a' in the first of world.
   * @param name
   * @param options
   */
  setVertexAttribute(name2, options) {
    let attribute = new AttributeInfo(name2, options);
    if (name2 === "texcoord" && options.index !== void 0 && options.index !== null) {
      name2 = "texcoord_" + options.index;
    }
    this._vertexInfo[name2] = attribute;
    if (name2 === "indices") {
      this._drawCount = attribute.buffer.length / attribute.numComponents;
      this._drawOffset = 0;
    }
    if (name2 === "position" && !this._vertexInfo.indices) {
      this._drawCount = attribute.buffer.length / (attribute.sizeofStride / 4);
      this._drawOffset = 0;
    }
  }
  get vertexInfo() {
    return this._vertexInfo;
  }
}
const BINARY_EXTENSION_HEADER_MAGIC = "glTF";
const BINARY_EXTENSION_HEADER_LENGTH = 12;
const BINARY_EXTENSION_CHUNK_TYPES = { JSON: 1313821514, BIN: 5130562 };
const EXTENSIONS = {
  KHR_BINARY_GLTF: "KHR_binary_glTF",
  KHR_DRACO_MESH_COMPRESSION: "KHR_draco_mesh_compression",
  KHR_LIGHTS_PUNCTUAL: "KHR_lights_punctual",
  KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS: "KHR_materials_pbrSpecularGlossiness",
  KHR_MATERIALS_UNLIT: "KHR_materials_unlit",
  KHR_TEXTURE_TRANSFORM: "KHR_texture_transform",
  MSFT_TEXTURE_DDS: "MSFT_texture_dds"
};
let ATTRIBUTES = {
  POSITION: "position",
  NORMAL: "normal",
  TANGENT: "tangent",
  TEXCOORD_0: "texcoord_0",
  TEXCOORD_1: "texcoord_1",
  COLOR_0: "color",
  WEIGHTS_0: "skinWeight",
  JOINTS_0: "skinIndex"
};
let WEBGL_COMPONENT_TYPES = {
  5120: Int8Array,
  5121: Uint8Array,
  5122: Int16Array,
  5123: Uint16Array,
  5125: Uint32Array,
  5126: Float32Array
};
let WEBGL_TYPE_SIZES = {
  SCALAR: 1,
  VEC2: 2,
  VEC3: 3,
  VEC4: 4,
  MAT2: 4,
  MAT3: 9,
  MAT4: 16
};
let WEBGL_CONSTANTS = {
  FLOAT: 5126,
  //FLOAT_MAT2: 35674,
  FLOAT_MAT3: 35675,
  FLOAT_MAT4: 35676,
  FLOAT_VEC2: 35664,
  FLOAT_VEC3: 35665,
  FLOAT_VEC4: 35666,
  LINEAR: 9729,
  REPEAT: 10497,
  SAMPLER_2D: 35678,
  POINTS: 0,
  LINES: 1,
  LINE_LOOP: 2,
  LINE_STRIP: 3,
  TRIANGLES: 4,
  TRIANGLE_STRIP: 5,
  TRIANGLE_FAN: 6,
  UNSIGNED_BYTE: 5121,
  UNSIGNED_SHORT: 5123
};
class GLTFLoader extends LoadingManager {
  static get singleton() {
    if (GLTFLoader._singleton === void 0) {
      GLTFLoader._singleton = new GLTFLoader();
    }
    return GLTFLoader._singleton;
  }
  constructor() {
    super();
    this._className = "GLTFLoader";
  }
  load(url, onLoad, onProgress, onError) {
    let loader = fileloader;
    this.resourcePath = LoaderUtils.extractUrlBase(url);
    logger.debug("start load ...", this.resourcePath);
    let _onError = (e) => {
      if (onError) {
        onError(e);
      } else {
        logger.error(e);
      }
      this.itemError(url);
      this.itemEnd(url);
    };
    loader.load(url, (resp) => {
      logger.debug(`load gltf[${url}] : ${resp.ok} }`);
      try {
        resp.arrayBuffer().then((buffer) => {
          this.parse(
            buffer,
            (gltf) => {
              onLoad(gltf);
            },
            _onError
          );
        });
      } catch (e) {
        logger.error("gltfloader exception :" + e);
      }
    });
  }
  parse(data, onLoad, onError) {
    let content;
    let extensions = {};
    if (typeof data === "string") {
      content = data;
    } else {
      var magic = LoaderUtils.decodeText(new Uint8Array(data, 0, 4));
      if (magic === BINARY_EXTENSION_HEADER_MAGIC) {
        logger.debug("it's glb file data stream");
        try {
          extensions[EXTENSIONS.KHR_BINARY_GLTF] = new GLTFBinaryExtension(
            data
          );
        } catch (error) {
          if (onError) onError(error);
          return;
        }
        content = extensions[EXTENSIONS.KHR_BINARY_GLTF].content;
      } else {
        logger.debug("it's gltf file data stream");
        content = LoaderUtils.decodeText(new Uint8Array(data));
      }
    }
    var json = JSON.parse(content);
    if (json.asset === void 0 || json.asset.version[0] < 2) {
      logger.error("gltf Unsupported asset");
      if (onError)
        onError(
          " Unsupported asset. glTF versions >=2.0 are supported. Use LegacyGLTFLoader instead."
        );
      return;
    }
    if (json.extensionsUsed) {
      logger.debug("need extensions!!!");
    }
    var parser = new GLTFParser(json, extensions, { path: this.resourcePath });
    parser.parse(
      (scene, scenes, cameras, animations, json2) => {
        let glTF = {
          scene,
          scenes,
          cameras,
          animations,
          asset: json2.asset,
          parser,
          userData: {}
        };
        logger.debug("[GTLTF >>>]load All ok .......:", glTF);
        onLoad(glTF);
      },
      onError
    );
  }
}
class GLTFBinaryExtension {
  constructor(data) {
    this.name = EXTENSIONS.KHR_BINARY_GLTF;
    this.content = null;
    this.body = null;
    var headerView = new DataView(data, 0, BINARY_EXTENSION_HEADER_LENGTH);
    this.header = {
      magic: LoaderUtils.decodeText(new Uint8Array(data.slice(0, 4))),
      version: headerView.getUint32(4, true),
      length: headerView.getUint32(8, true)
    };
    if (this.header.magic !== BINARY_EXTENSION_HEADER_MAGIC) {
      throw new Error(" Unsupported glTF-Binary header.");
    } else if (this.header.version < 2) {
      throw new Error(
        " Legacy binary file detected. Use LegacyGLTFLoader instead."
      );
    }
    var chunkView = new DataView(data, BINARY_EXTENSION_HEADER_LENGTH);
    var chunkIndex = 0;
    while (chunkIndex < chunkView.byteLength) {
      var chunkLength = chunkView.getUint32(chunkIndex, true);
      chunkIndex += 4;
      var chunkType = chunkView.getUint32(chunkIndex, true);
      chunkIndex += 4;
      if (chunkType === BINARY_EXTENSION_CHUNK_TYPES.JSON) {
        var contentArray = new Uint8Array(
          data,
          BINARY_EXTENSION_HEADER_LENGTH + chunkIndex,
          chunkLength
        );
        this.content = LoaderUtils.decodeText(contentArray);
      } else if (chunkType === BINARY_EXTENSION_CHUNK_TYPES.BIN) {
        var byteOffset = BINARY_EXTENSION_HEADER_LENGTH + chunkIndex;
        this.body = data.slice(byteOffset, byteOffset + chunkLength);
      }
      chunkIndex += chunkLength;
    }
    if (this.content === null) {
      throw new Error(" JSON content not found.");
    }
  }
}
class GLTFParser {
  constructor(json, extensions, options) {
    this.fileLoader = fileloader;
    this.primitiveCache = {};
    this.json = json || {};
    logger.debug("GLTF_json", json);
    this.extensions = extensions || {};
    this.options = options;
    this.cache = new MapCache();
  }
  parse(onLoad, onError) {
    var json = this.json;
    this.markDefs();
    this.getMultiDependencies(["scene", "animation", "camera"]).then((dependencies2) => {
      var scenes = dependencies2.scenes || [];
      var scene = scenes[json.scene || 0];
      var animations = dependencies2.animations || [];
      var cameras = dependencies2.cameras || [];
      onLoad(scene, scenes, cameras, animations, json);
    }).catch(onError);
  }
  markDefs() {
    let nodeDefs = this.json.nodes || [];
    let skinDefs = this.json.skins || [];
    let meshDefs = this.json.meshes || [];
    let meshReferences = {};
    let meshUses = {};
    for (let skinIndex = 0, skinLength = skinDefs.length; skinIndex < skinLength; skinIndex++) {
      let joints = skinDefs[skinIndex].joints;
      for (let i = 0, il = joints.length; i < il; i++) {
        nodeDefs[joints[i]].isBone = true;
      }
    }
    for (let nodeIndex = 0, nodeLength = nodeDefs.length; nodeIndex < nodeLength; nodeIndex++) {
      let nodeDef = nodeDefs[nodeIndex];
      if (nodeDef.mesh !== void 0) {
        if (meshReferences[nodeDef.mesh] === void 0) {
          meshReferences[nodeDef.mesh] = meshUses[nodeDef.mesh] = 0;
        }
        meshReferences[nodeDef.mesh]++;
        if (nodeDef.skin !== void 0) {
          meshDefs[nodeDef.mesh].isSkinnedMesh = true;
        }
      }
    }
    this.json.meshReferences = meshReferences;
    this.json.meshUses = meshUses;
  }
  getMultiDependencies(types) {
    let results = {};
    let pending = [];
    logger.debug("getdependencis:", types);
    for (let i = 0, il = types.length; i < il; i++) {
      let type2 = types[i];
      let promise = this.getDependencies(type2);
      let value = promise.then((value2) => {
        let key = type2 + (type2 === "mesh" ? "es" : "s");
        logger.debug("getDependencies...." + key + " | " + value2);
        results[key] = value2;
      });
      pending.push(value);
    }
    return Promise.all(pending).then(() => {
      logger.debug(
        " getMultiDependencies ... ok :" + Object.getOwnPropertyNames(results)
      );
      return results;
    });
  }
  getDependencies(type2) {
    let dependencies2 = this.cache.get(type2);
    if (!dependencies2) {
      let defs = this.json[type2 + (type2 === "mesh" ? "es" : "s")] || [];
      dependencies2 = Promise.all(
        defs.map((def, index) => {
          return this.getDependency(type2, index);
        })
      );
      this.cache.add(type2, dependencies2);
    }
    return dependencies2;
  }
  /**
   * use this function for cache the resource had loaded.
   * @param type
   * @param index
   * @returns
   */
  getDependency(type2, index) {
    let cacheKey = type2 + ":" + index;
    let dependency = this.cache.get(cacheKey);
    if (!dependency) {
      switch (type2) {
        case "scene":
          logger.debug("load scene....");
          dependency = this.loadScene(index);
          break;
        case "node":
          logger.debug("load node....");
          dependency = this.loadNode(index);
          break;
        case "mesh":
          logger.debug("load mesh....");
          dependency = this.loadMesh(index);
          break;
        case "accessor":
          logger.debug("load accessor....");
          dependency = this.loadAccessor(index);
          break;
        case "bufferView":
          logger.debug("load bufferView....");
          dependency = this.loadBufferView(index);
          break;
        case "buffer":
          logger.debug("load buffer....");
          dependency = this.loadBuffer(index);
          break;
        case "material":
          dependency = this.loadMaterial(index);
          break;
        case "camera":
          dependency = this.loadCamera(index);
          break;
        default:
          throw new Error("Unknown type: " + type2);
      }
      this.cache.add(cacheKey, dependency);
    }
    return dependency;
  }
  resolveURL(url, path) {
    if (typeof url !== "string" || url === "") return "";
    if (/^(https?:)?\/\//i.test(url)) return url;
    if (/^data:.*,.*$/i.test(url)) return url;
    if (/^blob:.*$/i.test(url)) return url;
    return path + url;
  }
  loadBuffer(index) {
    var bufferDef = this.json.buffers[index];
    var loader = this.fileLoader;
    if (bufferDef.type && bufferDef.type !== "arraybuffer") {
      throw new Error(" " + bufferDef.type + " buffer type is not supported.");
    }
    if (bufferDef.uri === void 0 && index === 0) {
      return Promise.resolve(this.extensions[EXTENSIONS.KHR_BINARY_GLTF].body);
    }
    var options = this.options;
    return new Promise((resolve, reject) => {
      loader.load(
        this.resolveURL(bufferDef.uri, options.path),
        (resp) => {
          logger.debug("loadbuffer done ");
          let arraybuffer = resp.arrayBuffer();
          arraybuffer.then((buffer) => {
            logger.debug("loadbuffer ok");
            resolve(buffer);
          }).catch((reson) => {
            logger.debug("loadbuffer false:" + reson);
          });
        },
        void 0,
        () => {
          reject(new Error(' Failed to load buffer "' + bufferDef.uri + '".'));
        }
      );
    });
  }
  async loadCamera(index) {
    logger.debug("loadCamera:", index);
    const camera = this.json.cameras[index];
    const perspective = camera.perspective;
    const camInstance = new Camera({
      fovy: perspective.yfov,
      near: perspective.znear,
      far: perspective.zfar,
      aspect: perspective.aspectRatio
    });
    camInstance.name = camera.name;
    return camInstance;
  }
  async loadImage(index) {
    const source = this.json.images[index];
    const URL2 = self.URL || self.webkitURL;
    let sourceURI = source.uri || "";
    let isObjectURL = false;
    let mimeType = source.mimeType;
    if (sourceURI.bufferView !== void 0) {
      isObjectURL = true;
      const bufferView = await this.getDependency(
        "bufferView",
        source.bufferView
      );
      const blob = new Blob([bufferView], { type: source.mimeType });
      sourceURI = URL2.createObjectURL(blob);
    } else if (source.uri === void 0) {
      throw new Error(
        "GLTFLoader: loadImage " + index + " is missing URI and bufferView"
      );
    }
    return {
      uri: sourceURI,
      isObjectURL,
      mimeType
    };
  }
  async loadTexture(index) {
    const { uri, isObjectURL, mimeType } = await this.loadImage(index);
    const url = this.options.path + uri;
    const imageData = await loadImageBitmap(url);
    const texture = new Texture({
      data: imageData
    });
    if (isObjectURL === true) {
      URL.revokeObjectURL(url);
    }
    return texture;
  }
  async loadMaterial(index) {
    logger.debug("loadMaterial>>>", index);
    let materialDef = this.json.materials[index];
    let output = materialDef;
    let baseColorTexture;
    let baseColorFactor = new Vector3(0, 0, 0);
    if (output["pbrMetallicRoughness"]) {
      const pbrMetallicRoughness = output["pbrMetallicRoughness"];
      if (pbrMetallicRoughness.baseColorTexture) {
        baseColorTexture = await this.loadTexture(
          pbrMetallicRoughness.baseColorTexture.index
        );
      }
      if (pbrMetallicRoughness.metallicRoughnessTexture) {
        await this.loadTexture(
          pbrMetallicRoughness.metallicRoughnessTexture.index
        );
      }
      if (pbrMetallicRoughness.baseColorFactor) {
        baseColorFactor = Vector3.fromArray(
          pbrMetallicRoughness.baseColorFactor
        );
      }
    }
    let normalTexture;
    if (output.normalTexture) {
      normalTexture = await this.loadTexture(output.normalTexture.index);
    }
    if (output.occlusionTexture) {
      await this.loadTexture(output.occlusionTexture.index);
    }
    if (output.emissiveTexture) {
      await this.loadTexture(output.emissiveTexture.index);
    }
    const material = new PhoneMaterial();
    if (baseColorTexture) {
      material.setAttribute({
        diffuseMap: baseColorTexture
      });
    } else {
      material.setAttribute({
        ambientColor: baseColorFactor
      });
    }
    if (normalTexture) {
      material.setAttribute({
        normalMap: normalTexture
      });
    }
    return material;
  }
  async loadBufferView(index) {
    var bufferViewDef = this.json.bufferViews[index];
    return this.getDependency("buffer", bufferViewDef.buffer).then(
      (buffer) => {
        logger.debug("loadbufferview ok");
        var byteLength = bufferViewDef.byteLength || 0;
        var byteOffset = bufferViewDef.byteOffset || 0;
        return buffer.slice(byteOffset, byteOffset + byteLength);
      }
    );
  }
  loadNode(nodeIndex) {
    var nodeDef = this.json.nodes[nodeIndex];
    var meshReferences = this.json.meshReferences;
    var meshUses = this.json.meshUses;
    let parseNode = async () => {
      if (nodeDef.isBone === true) ;
      else if (nodeDef.mesh !== void 0) {
        return this.getDependency("mesh", nodeDef.mesh).then((mesh) => {
          logger.debug("mesh load ok.....!");
          var node;
          if (meshReferences[nodeDef.mesh] > 1) {
            meshUses[nodeDef.mesh]++;
            node = mesh.clone();
          } else {
            node = mesh;
          }
          let ent = Entity.createEntFromMesh(node);
          return ent;
        });
      } else if (nodeDef.camera !== void 0) {
        let camera = await this.getDependency("camera", nodeDef.camera);
        return camera;
      } else if (nodeDef.extensions && nodeDef.extensions[EXTENSIONS.KHR_LIGHTS_PUNCTUAL] && nodeDef.extensions[EXTENSIONS.KHR_LIGHTS_PUNCTUAL].light !== void 0) ;
      return Promise.resolve(new SceneNode());
    };
    return parseNode().then((node) => {
      logger.debug("Entity create ok .................", nodeDef, node);
      node.name = nodeDef.name;
      if (nodeDef.matrix !== void 0) {
        let matrix = Matrix4.fromArray(nodeDef.matrix);
        node.applyMatrix(matrix);
      } else {
        const transform = {};
        if (nodeDef.translation !== void 0) {
          transform.position = Vector3.fromArray(nodeDef.translation);
        }
        if (nodeDef.rotation !== void 0) {
          transform.quaternion = Vector4.fromArray(nodeDef.rotation);
        }
        if (nodeDef.scale !== void 0) {
          transform.scale = Vector3.fromArray(nodeDef.scale);
        }
        node.setTransform(transform);
      }
      return node;
    });
  }
  loadMesh(meshIndex) {
    let meshDef = this.json.meshes[meshIndex];
    let primitives = meshDef.primitives;
    return this.loadGeometries(primitives).then(async (geometries) => {
      logger.debug("geometries load ok ,geometries count:" + geometries.length);
      let submeshes = [];
      for (let i = 0, il = geometries.length; i < il; i++) {
        let geometry = geometries[i];
        let primitive = primitives[i];
        primitive.mode = primitive.mode ? primitive.mode : WEBGL_CONSTANTS.TRIANGLES;
        let submesh;
        let material;
        if (primitive.material !== void 0) {
          material = await this.loadMaterial(primitive.material);
        } else {
          material = new ColorMaterial();
        }
        logger.debug("create material::", material);
        switch (primitive.mode) {
          case WEBGL_CONSTANTS.TRIANGLES:
            submesh = new Primitive(geometry, material, EDrawType.TRIANGLES);
            break;
          case WEBGL_CONSTANTS.TRIANGLE_STRIP:
            submesh = new Primitive(
              geometry,
              material,
              EDrawType.TRIANGLES_STRIP
            );
            break;
          case WEBGL_CONSTANTS.TRIANGLE_FAN:
            submesh = new Primitive(geometry, material, EDrawType.TRIANGLE_FAN);
            break;
          default:
            throw new Error(
              "GLTFLoader : primitive mode unsupported " + primitive.mode
            );
        }
        submesh.name = meshDef.name || "mesh_" + meshIndex;
        submeshes.push(submesh);
      }
      let mesh = new Mesh();
      mesh.addPrimitive(submeshes);
      return mesh;
    });
  }
  loadAccessor(index) {
    var accessorDef = this.json.accessors[index];
    if (accessorDef.bufferView === void 0 && accessorDef.sparse === void 0) {
      return Promise.resolve(null);
    }
    var pendingBufferViews = [];
    if (accessorDef.bufferView !== void 0) {
      pendingBufferViews.push(
        this.getDependency("bufferView", accessorDef.bufferView)
      );
    } else {
      pendingBufferViews.push(null);
    }
    if (accessorDef.sparse !== void 0) {
      pendingBufferViews.push(
        this.getDependency("bufferView", accessorDef.sparse.indices.bufferView)
      );
      pendingBufferViews.push(
        this.getDependency("bufferView", accessorDef.sparse.values.bufferView)
      );
    }
    return Promise.all(pendingBufferViews).then((bufferViews) => {
      logger.debug("load all buffer views ok....");
      let bufferView = bufferViews[0];
      let itemSize = WEBGL_TYPE_SIZES[accessorDef.type];
      let TypedArray = WEBGL_COMPONENT_TYPES[accessorDef.componentType];
      let elementBytes = TypedArray.BYTES_PER_ELEMENT;
      let itemBytes = elementBytes * itemSize;
      let byteOffset = accessorDef.byteOffset || 0;
      let byteStride = accessorDef.bufferView !== void 0 ? this.json.bufferViews[accessorDef.bufferView].byteStride : void 0;
      let normalized = accessorDef.normalized === true;
      let array;
      let bufferAttribute;
      if (byteStride && byteStride !== itemBytes) ;
      else {
        if (bufferView === null) {
          array = new TypedArray(accessorDef.count * itemSize);
        } else {
          array = new TypedArray(
            bufferView,
            byteOffset,
            accessorDef.count * itemSize
          );
        }
        bufferAttribute = new AttributeInfo("", {
          buffer: array,
          numComponents: itemSize,
          sizeofStride: itemBytes,
          offsetofStride: 0,
          normalized
        });
      }
      if (accessorDef.sparse !== void 0) ;
      return bufferAttribute;
    });
  }
  /**
   * create Geometries from primitives.
   * @param primitives
   */
  loadGeometries(primitives) {
    logger.debug("load geometries");
    let cache = this.primitiveCache;
    let isMultiPass = this.isMultiPassGeometry(primitives);
    if (isMultiPass) {
      primitives = [primitives[0]];
    }
    let createDracoPrimitive = (primitive2) => {
      return this.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION].decodePrimitive(primitive2, this).then((geometry) => {
        return this.addPrimitiveAttributes(geometry, primitive2);
      });
    };
    let pending = [];
    for (var i = 0, il = primitives.length; i < il; i++) {
      var primitive = primitives[i];
      var cacheKey = this.createPrimitiveKey(primitive);
      var cached = cache[cacheKey];
      if (cached) {
        pending.push(cached.promise);
      } else {
        var geometryPromise;
        if (primitive.extensions && primitive.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION]) {
          logger.debug("create draco geometry");
          geometryPromise = createDracoPrimitive(primitive);
        } else {
          logger.debug("create new geometry");
          geometryPromise = this.addPrimitiveAttributes(
            new Geometry(),
            primitive
          );
        }
        cache[cacheKey] = { primitive, promise: geometryPromise };
        pending.push(geometryPromise);
      }
    }
    return Promise.all(pending).then((geometries) => {
      logger.debug("all geometry load ok..........");
      return geometries;
    });
  }
  addPrimitiveAttributes(geometry, primitiveDef) {
    let attributes = primitiveDef.attributes;
    var pending = [];
    let assignAttributeAccessor = (accessorIndex, attributeName2) => {
      return this.getDependency("accessor", accessorIndex).then(
        (accessor) => {
          logger.debug(
            "load accessor ok:" + attributeName2 + " | " + accessor.buffer.length
          );
          let attrName = attributeName2;
          if (attributeName2.includes("texcoord")) {
            const strArray = attributeName2.split("_");
            attrName = strArray[0];
            accessor.index = Number.parseInt(strArray[1]);
          }
          geometry.setVertexAttribute(attrName, accessor);
          if (primitiveDef.indices) {
            if (attrName === "indices") {
              geometry.drawCount = accessor.buffer.length / accessor.numComponents;
              geometry.drawOffset = 0;
            }
          } else {
            if (attrName === "position") {
              geometry.drawCount = accessor.buffer.length / accessor.numComponents;
              geometry.drawOffset = 0;
            }
          }
        }
      );
    };
    for (var gltfAttributeName in attributes) {
      var attributeName = ATTRIBUTES[gltfAttributeName];
      if (!attributeName) continue;
      if (attributeName in geometry.vertexInfo) continue;
      logger.debug("setup attribute:" + gltfAttributeName);
      pending.push(
        assignAttributeAccessor(attributes[gltfAttributeName], attributeName)
      );
    }
    if (primitiveDef.indices !== void 0) {
      logger.debug("setup attribute indices");
      pending.push(assignAttributeAccessor(primitiveDef.indices, "indices"));
    }
    return Promise.all(pending).then(() => {
      logger.debug("geometry attribute load ok..........");
      return geometry;
    });
  }
  isMultiPassGeometry(primitives) {
    if (primitives.length < 2) return false;
    var primitive0 = primitives[0];
    var targets0 = primitive0.targets || [];
    if (primitive0.indices === void 0) return false;
    for (var i = 1, il = primitives.length; i < il; i++) {
      var primitive = primitives[i];
      if (primitive0.mode !== primitive.mode) return false;
      if (primitive.indices === void 0) return false;
      if (primitive.extensions && primitive.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION])
        return false;
      if (!this.isObjectEqual(primitive0.attributes, primitive.attributes))
        return false;
      var targets = primitive.targets || [];
      if (targets0.length !== targets.length) return false;
      for (var j = 0, jl = targets0.length; j < jl; j++) {
        if (!this.isObjectEqual(targets0[j], targets[j])) return false;
      }
    }
    return true;
  }
  isObjectEqual(a, b) {
    if (Object.keys(a).length !== Object.keys(b).length) return false;
    for (var key in a) {
      if (a[key] !== b[key]) return false;
    }
    return true;
  }
  createPrimitiveKey(primitiveDef) {
    var dracoExtension = primitiveDef.extensions && primitiveDef.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION];
    var geometryKey;
    if (dracoExtension) {
      geometryKey = "draco:" + dracoExtension.bufferView + ":" + dracoExtension.indices + ":" + this.createAttributesKey(dracoExtension.attributes);
    } else {
      geometryKey = primitiveDef.indices + ":" + this.createAttributesKey(primitiveDef.attributes) + ":" + primitiveDef.mode;
    }
    return geometryKey;
  }
  createAttributesKey(attributes) {
    var attributesKey = "";
    var keys = Object.keys(attributes).sort();
    for (var i = 0, il = keys.length; i < il; i++) {
      attributesKey += keys[i] + ":" + attributes[keys[i]] + ";";
    }
    return attributesKey;
  }
  loadScene(sceneIndex) {
    let buildNodeHierachy = (nodeId, parentObject, json2, parser2) => {
      var nodeDef = json2.nodes[nodeId];
      return this.getDependency("node", nodeId).then((node) => {
        logger.debug("node load ok..............:" + nodeId);
        parentObject.addNode(node);
        var pending2 = [];
        if (nodeDef.children) {
          var children = nodeDef.children;
          for (var i2 = 0, il2 = children.length; i2 < il2; i2++) {
            var child = children[i2];
            pending2.push(buildNodeHierachy(child, node, json2));
          }
        }
        return Promise.all(pending2);
      });
    };
    var json = this.json;
    this.extensions;
    var sceneDef = this.json.scenes[sceneIndex];
    var scene = new Scene();
    if (sceneDef.name !== void 0) scene.name = sceneDef.name;
    var nodeIds = sceneDef.nodes || [];
    var pending = [];
    for (var i = 0, il = nodeIds.length; i < il; i++) {
      pending.push(buildNodeHierachy(nodeIds[i], scene, json));
    }
    return Promise.all(pending).then(function() {
      return scene;
    });
  }
}
function arrayBufferToString(arrayBuffer) {
  const decoder = new TextDecoder("latin1");
  return decoder.decode(arrayBuffer);
}
class STLLoader {
  parseText(content) {
    if (!content) {
      return;
    }
    const addValuesToArray = (src, dst) => {
      for (let i = 0; i < src.length; i++) {
        dst.push(src[i]);
      }
    };
    const readTriangle = (lines2, offset2, points2, cellArray2, cellNormals2) => {
      const normalLine = lines2[offset2];
      if (normalLine === void 0) {
        return -1;
      }
      if (normalLine.indexOf("endfacet") !== -1) {
        return offset2 + 1;
      }
      if (normalLine.indexOf("facet") === -1) {
        return offset2 + 1;
      }
      let nbVertex = 0;
      let nbConsumedLines = 2;
      const firstVertexIndex = points2.length / 3;
      const normal = normalLine.split(/[ \t]+/).filter((i) => i).slice(-3).map(Number);
      while (lines2[offset2 + nbConsumedLines].indexOf("vertex") !== -1) {
        const line = lines2[offset2 + nbConsumedLines];
        const coords = line.split(/[ \t]+/).filter((i) => i).slice(-3).map(Number);
        addValuesToArray(coords, points2);
        addValuesToArray(normal, cellNormals2);
        nbVertex++;
        nbConsumedLines++;
      }
      for (let i = 0; i < nbVertex; i++) {
        cellArray2.push(firstVertexIndex + i);
      }
      while (lines2[offset2 + nbConsumedLines] && lines2[offset2 + nbConsumedLines].indexOf("endfacet") !== -1) {
        nbConsumedLines++;
      }
      return offset2 + nbConsumedLines + 2;
    };
    const lines = content.split("\n");
    let offset = 1;
    const points = [];
    const cellArray = [];
    const cellNormals = [];
    while (offset !== -1) {
      offset = readTriangle(lines, offset, points, cellArray, cellNormals);
    }
    logger.debug("Parse ASCII STL:", points, cellArray, cellNormals);
    const geometry = new Geometry();
    geometry.setVertexAttribute("position", {
      buffer: new Float32Array(points),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    geometry.setVertexAttribute("normal", {
      buffer: new Float32Array(cellNormals),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    geometry.setVertexAttribute("indices", {
      buffer: new Int32Array(cellArray),
      numComponents: 1,
      sizeofStride: 4,
      offsetofStride: 0
    });
    return geometry;
  }
  parseBinary(content) {
    if (!content) {
      return;
    }
    let isBinary = false;
    const dview = new DataView(content, 0, 80 + 4);
    const numTriangles = dview.getUint32(80, true);
    isBinary = 84 + numTriangles * 50 === content.byteLength;
    logger.debug("Is this STL a binary??", isBinary);
    if (!isBinary) {
      return this.parseText(arrayBufferToString(content));
    }
    const headerData = content.slice(0, 80);
    const headerStr = arrayBufferToString(headerData);
    const header = this.parseHeader(headerStr);
    const dataView = new DataView(content, 84);
    const nbFaces = (content.byteLength - 84) / 50;
    const pointValues = new Float32Array(nbFaces * 9);
    const normalValues = new Float32Array(nbFaces * 9);
    const cellValues = new Uint32Array(nbFaces * 3);
    const cellDataValues = new Uint16Array(nbFaces);
    let cellOffset = 0;
    for (let faceIdx = 0; faceIdx < nbFaces; faceIdx++) {
      const offset = faceIdx * 50;
      normalValues[faceIdx * 9 + 0] = dataView.getFloat32(offset + 0, true);
      normalValues[faceIdx * 9 + 1] = dataView.getFloat32(offset + 4, true);
      normalValues[faceIdx * 9 + 2] = dataView.getFloat32(offset + 8, true);
      normalValues[faceIdx * 9 + 3] = dataView.getFloat32(offset + 0, true);
      normalValues[faceIdx * 9 + 4] = dataView.getFloat32(offset + 4, true);
      normalValues[faceIdx * 9 + 5] = dataView.getFloat32(offset + 8, true);
      normalValues[faceIdx * 9 + 6] = dataView.getFloat32(offset + 0, true);
      normalValues[faceIdx * 9 + 7] = dataView.getFloat32(offset + 4, true);
      normalValues[faceIdx * 9 + 8] = dataView.getFloat32(offset + 8, true);
      pointValues[faceIdx * 9 + 0] = dataView.getFloat32(offset + 12, true);
      pointValues[faceIdx * 9 + 1] = dataView.getFloat32(offset + 16, true);
      pointValues[faceIdx * 9 + 2] = dataView.getFloat32(offset + 20, true);
      pointValues[faceIdx * 9 + 3] = dataView.getFloat32(offset + 24, true);
      pointValues[faceIdx * 9 + 4] = dataView.getFloat32(offset + 28, true);
      pointValues[faceIdx * 9 + 5] = dataView.getFloat32(offset + 32, true);
      pointValues[faceIdx * 9 + 6] = dataView.getFloat32(offset + 36, true);
      pointValues[faceIdx * 9 + 7] = dataView.getFloat32(offset + 40, true);
      pointValues[faceIdx * 9 + 8] = dataView.getFloat32(offset + 44, true);
      cellValues[cellOffset++] = faceIdx * 3 + 0;
      cellValues[cellOffset++] = faceIdx * 3 + 1;
      cellValues[cellOffset++] = faceIdx * 3 + 2;
      cellDataValues[faceIdx] = dataView.getUint16(offset + 48, true);
    }
    logger.debug("header::", header);
    const orientationField = "SPACE";
    if (orientationField in header && header[orientationField] !== "LPS") {
      const XYZ = header[orientationField];
      const mat4 = new Float32Array(16);
      mat4[15] = 1;
      switch (XYZ[0]) {
        case "L":
          mat4[0] = 1;
          break;
        case "R":
          mat4[0] = -1;
          break;
        default:
          logger.error(
            `Can not convert STL file from ${XYZ} to LPS space: permutations not supported. Use itk.js STL reader instead.`
          );
          return;
      }
      switch (XYZ[1]) {
        case "P":
          mat4[5] = 1;
          break;
        case "A":
          mat4[5] = -1;
          break;
        default:
          logger.error(
            `Can not convert STL file from ${XYZ} to LPS space: permutations not supported. Use itk.js STL reader instead.`
          );
          return;
      }
      switch (XYZ[2]) {
        case "S":
          mat4[10] = 1;
          break;
        case "I":
          mat4[10] = -1;
          break;
        default:
          logger.error(
            `Can not convert STL file from ${XYZ} to LPS space: permutations not supported. Use itk.js STL reader instead.`
          );
          return;
      }
      let tempV3 = new Vector3();
      let transformMat = Matrix4.fromArray(mat4);
      for (let index = 0; index < pointValues.length; index += 3) {
        const x = pointValues[index];
        const y = pointValues[index + 1];
        const z = pointValues[index + 2];
        Vector3.transformMat4([x, y, z], transformMat, tempV3);
        pointValues[index] = tempV3[0];
        pointValues[index + 1] = tempV3[1];
        pointValues[index + 2] = tempV3[2];
      }
      for (let index = 0; index < normalValues.length; index += 3) {
        const x = normalValues[index];
        const y = normalValues[index + 1];
        const z = normalValues[index + 2];
        Vector3.transformMat4([x, y, z], transformMat, tempV3);
        normalValues[index] = tempV3[0];
        normalValues[index + 1] = tempV3[1];
        normalValues[index + 2] = tempV3[2];
      }
    }
    logger.debug(
      "Parse Binary STL:",
      pointValues,
      normalValues,
      cellValues,
      cellDataValues
    );
    const geometry = new Geometry();
    geometry.setVertexAttribute("position", {
      buffer: pointValues,
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    geometry.setVertexAttribute("normal", {
      buffer: normalValues,
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    geometry.setVertexAttribute("indices", {
      buffer: cellValues,
      numComponents: 1,
      sizeofStride: 4,
      offsetofStride: 0
    });
    return geometry;
  }
  parseHeader(headerString) {
    const headerSubStr = headerString.split(" ");
    const fieldValues = headerSubStr.filter((e) => e.indexOf("=") > -1);
    const header = {};
    for (let i = 0; i < fieldValues.length; ++i) {
      const fieldValueStr = fieldValues[i];
      const fieldValueSubStr = fieldValueStr.split("=");
      if (fieldValueSubStr.length === 2) {
        header[fieldValueSubStr[0]] = fieldValueSubStr[1];
      }
    }
    return header;
  }
}
var LZMA = LZMA || {};
window.LZMA = LZMA;
LZMA.OutWindow = function() {
  this._windowSize = 0;
};
LZMA.OutWindow.prototype.create = function(windowSize) {
  if (!this._buffer || this._windowSize !== windowSize) {
    this._buffer = [];
  }
  this._windowSize = windowSize;
  this._pos = 0;
  this._streamPos = 0;
};
LZMA.OutWindow.prototype.flush = function() {
  let size = this._pos - this._streamPos;
  if (size !== 0) {
    while (size--) {
      this._stream.writeByte(this._buffer[this._streamPos++]);
    }
    if (this._pos >= this._windowSize) {
      this._pos = 0;
    }
    this._streamPos = this._pos;
  }
};
LZMA.OutWindow.prototype.releaseStream = function() {
  this.flush();
  this._stream = null;
};
LZMA.OutWindow.prototype.setStream = function(stream) {
  this.releaseStream();
  this._stream = stream;
};
LZMA.OutWindow.prototype.init = function(solid) {
  if (!solid) {
    this._streamPos = 0;
    this._pos = 0;
  }
};
LZMA.OutWindow.prototype.copyBlock = function(distance, len) {
  let pos = this._pos - distance - 1;
  if (pos < 0) {
    pos += this._windowSize;
  }
  while (len--) {
    if (pos >= this._windowSize) {
      pos = 0;
    }
    this._buffer[this._pos++] = this._buffer[pos++];
    if (this._pos >= this._windowSize) {
      this.flush();
    }
  }
};
LZMA.OutWindow.prototype.putByte = function(b) {
  this._buffer[this._pos++] = b;
  if (this._pos >= this._windowSize) {
    this.flush();
  }
};
LZMA.OutWindow.prototype.getByte = function(distance) {
  let pos = this._pos - distance - 1;
  if (pos < 0) {
    pos += this._windowSize;
  }
  return this._buffer[pos];
};
LZMA.RangeDecoder = function() {
};
LZMA.RangeDecoder.prototype.setStream = function(stream) {
  this._stream = stream;
};
LZMA.RangeDecoder.prototype.releaseStream = function() {
  this._stream = null;
};
LZMA.RangeDecoder.prototype.init = function() {
  let i = 5;
  this._code = 0;
  this._range = -1;
  while (i--) {
    this._code = this._code << 8 | this._stream.readByte();
  }
};
LZMA.RangeDecoder.prototype.decodeDirectBits = function(numTotalBits) {
  let result = 0;
  let i = numTotalBits;
  let t;
  while (i--) {
    this._range >>>= 1;
    t = this._code - this._range >>> 31;
    this._code -= this._range & t - 1;
    result = result << 1 | 1 - t;
    if ((this._range & 4278190080) === 0) {
      this._code = this._code << 8 | this._stream.readByte();
      this._range <<= 8;
    }
  }
  return result;
};
LZMA.RangeDecoder.prototype.decodeBit = function(probs, index) {
  const prob = probs[index];
  const newBound = (this._range >>> 11) * prob;
  if ((this._code ^ 2147483648) < (newBound ^ 2147483648)) {
    this._range = newBound;
    probs[index] += 2048 - prob >>> 5;
    if ((this._range & 4278190080) === 0) {
      this._code = this._code << 8 | this._stream.readByte();
      this._range <<= 8;
    }
    return 0;
  }
  this._range -= newBound;
  this._code -= newBound;
  probs[index] -= prob >>> 5;
  if ((this._range & 4278190080) === 0) {
    this._code = this._code << 8 | this._stream.readByte();
    this._range <<= 8;
  }
  return 1;
};
LZMA.initBitModels = function(probs, len) {
  while (len--) {
    probs[len] = 1024;
  }
};
LZMA.BitTreeDecoder = function(numBitLevels) {
  this._models = [];
  this._numBitLevels = numBitLevels;
};
LZMA.BitTreeDecoder.prototype.init = function() {
  LZMA.initBitModels(this._models, 1 << this._numBitLevels);
};
LZMA.BitTreeDecoder.prototype.decode = function(rangeDecoder) {
  let m = 1;
  let i = this._numBitLevels;
  while (i--) {
    m = m << 1 | rangeDecoder.decodeBit(this._models, m);
  }
  return m - (1 << this._numBitLevels);
};
LZMA.BitTreeDecoder.prototype.reverseDecode = function(rangeDecoder) {
  let m = 1;
  let symbol = 0;
  let i = 0;
  let bit;
  for (; i < this._numBitLevels; ++i) {
    bit = rangeDecoder.decodeBit(this._models, m);
    m = m << 1 | bit;
    symbol |= bit << i;
  }
  return symbol;
};
LZMA.reverseDecode2 = function(models, startIndex, rangeDecoder, numBitLevels) {
  let m = 1;
  let symbol = 0;
  let i = 0;
  let bit;
  for (; i < numBitLevels; ++i) {
    bit = rangeDecoder.decodeBit(models, startIndex + m);
    m = m << 1 | bit;
    symbol |= bit << i;
  }
  return symbol;
};
LZMA.LenDecoder = function() {
  this._choice = [];
  this._lowCoder = [];
  this._midCoder = [];
  this._highCoder = new LZMA.BitTreeDecoder(8);
  this._numPosStates = 0;
};
LZMA.LenDecoder.prototype.create = function(numPosStates) {
  for (; this._numPosStates < numPosStates; ++this._numPosStates) {
    this._lowCoder[this._numPosStates] = new LZMA.BitTreeDecoder(3);
    this._midCoder[this._numPosStates] = new LZMA.BitTreeDecoder(3);
  }
};
LZMA.LenDecoder.prototype.init = function() {
  let i = this._numPosStates;
  LZMA.initBitModels(this._choice, 2);
  while (i--) {
    this._lowCoder[i].init();
    this._midCoder[i].init();
  }
  this._highCoder.init();
};
LZMA.LenDecoder.prototype.decode = function(rangeDecoder, posState) {
  if (rangeDecoder.decodeBit(this._choice, 0) === 0) {
    return this._lowCoder[posState].decode(rangeDecoder);
  }
  if (rangeDecoder.decodeBit(this._choice, 1) === 0) {
    return 8 + this._midCoder[posState].decode(rangeDecoder);
  }
  return 16 + this._highCoder.decode(rangeDecoder);
};
LZMA.Decoder2 = function() {
  this._decoders = [];
};
LZMA.Decoder2.prototype.init = function() {
  LZMA.initBitModels(this._decoders, 768);
};
LZMA.Decoder2.prototype.decodeNormal = function(rangeDecoder) {
  let symbol = 1;
  do {
    symbol = symbol << 1 | rangeDecoder.decodeBit(this._decoders, symbol);
  } while (symbol < 256);
  return symbol & 255;
};
LZMA.Decoder2.prototype.decodeWithMatchByte = function(rangeDecoder, matchByte) {
  let symbol = 1;
  let matchBit;
  let bit;
  do {
    matchBit = matchByte >> 7 & 1;
    matchByte <<= 1;
    bit = rangeDecoder.decodeBit(
      this._decoders,
      (1 + matchBit << 8) + symbol
    );
    symbol = symbol << 1 | bit;
    if (matchBit !== bit) {
      while (symbol < 256) {
        symbol = symbol << 1 | rangeDecoder.decodeBit(this._decoders, symbol);
      }
      break;
    }
  } while (symbol < 256);
  return symbol & 255;
};
LZMA.LiteralDecoder = function() {
};
LZMA.LiteralDecoder.prototype.create = function(numPosBits, numPrevBits) {
  let i;
  if (this._coders && this._numPrevBits === numPrevBits && this._numPosBits === numPosBits) {
    return;
  }
  this._numPosBits = numPosBits;
  this._posMask = (1 << numPosBits) - 1;
  this._numPrevBits = numPrevBits;
  this._coders = [];
  i = 1 << this._numPrevBits + this._numPosBits;
  while (i--) {
    this._coders[i] = new LZMA.Decoder2();
  }
};
LZMA.LiteralDecoder.prototype.init = function() {
  let i = 1 << this._numPrevBits + this._numPosBits;
  while (i--) {
    this._coders[i].init();
  }
};
LZMA.LiteralDecoder.prototype.getDecoder = function(pos, prevByte) {
  return this._coders[((pos & this._posMask) << this._numPrevBits) + ((prevByte & 255) >>> 8 - this._numPrevBits)];
};
LZMA.Decoder = function() {
  this._outWindow = new LZMA.OutWindow();
  this._rangeDecoder = new LZMA.RangeDecoder();
  this._isMatchDecoders = [];
  this._isRepDecoders = [];
  this._isRepG0Decoders = [];
  this._isRepG1Decoders = [];
  this._isRepG2Decoders = [];
  this._isRep0LongDecoders = [];
  this._posSlotDecoder = [];
  this._posDecoders = [];
  this._posAlignDecoder = new LZMA.BitTreeDecoder(4);
  this._lenDecoder = new LZMA.LenDecoder();
  this._repLenDecoder = new LZMA.LenDecoder();
  this._literalDecoder = new LZMA.LiteralDecoder();
  this._dictionarySize = -1;
  this._dictionarySizeCheck = -1;
  this._posSlotDecoder[0] = new LZMA.BitTreeDecoder(6);
  this._posSlotDecoder[1] = new LZMA.BitTreeDecoder(6);
  this._posSlotDecoder[2] = new LZMA.BitTreeDecoder(6);
  this._posSlotDecoder[3] = new LZMA.BitTreeDecoder(6);
};
LZMA.Decoder.prototype.setDictionarySize = function(dictionarySize) {
  if (dictionarySize < 0) {
    return false;
  }
  if (this._dictionarySize !== dictionarySize) {
    this._dictionarySize = dictionarySize;
    this._dictionarySizeCheck = Math.max(this._dictionarySize, 1);
    this._outWindow.create(Math.max(this._dictionarySizeCheck, 4096));
  }
  return true;
};
LZMA.Decoder.prototype.setLcLpPb = function(lc, lp, pb) {
  const numPosStates = 1 << pb;
  if (lc > 8 || lp > 4 || pb > 4) {
    return false;
  }
  this._literalDecoder.create(lp, lc);
  this._lenDecoder.create(numPosStates);
  this._repLenDecoder.create(numPosStates);
  this._posStateMask = numPosStates - 1;
  return true;
};
LZMA.Decoder.prototype.init = function() {
  let i = 4;
  this._outWindow.init(false);
  LZMA.initBitModels(this._isMatchDecoders, 192);
  LZMA.initBitModels(this._isRep0LongDecoders, 192);
  LZMA.initBitModels(this._isRepDecoders, 12);
  LZMA.initBitModels(this._isRepG0Decoders, 12);
  LZMA.initBitModels(this._isRepG1Decoders, 12);
  LZMA.initBitModels(this._isRepG2Decoders, 12);
  LZMA.initBitModels(this._posDecoders, 114);
  this._literalDecoder.init();
  while (i--) {
    this._posSlotDecoder[i].init();
  }
  this._lenDecoder.init();
  this._repLenDecoder.init();
  this._posAlignDecoder.init();
  this._rangeDecoder.init();
};
LZMA.Decoder.prototype.decode = function(inStream, outStream, outSize) {
  let state = 0;
  let rep0 = 0;
  let rep1 = 0;
  let rep2 = 0;
  let rep3 = 0;
  let nowPos64 = 0;
  let prevByte = 0;
  let posState;
  let decoder2;
  let len;
  let distance;
  let posSlot;
  let numDirectBits;
  this._rangeDecoder.setStream(inStream);
  this._outWindow.setStream(outStream);
  this.init();
  while (outSize < 0 || nowPos64 < outSize) {
    posState = nowPos64 & this._posStateMask;
    if (this._rangeDecoder.decodeBit(
      this._isMatchDecoders,
      (state << 4) + posState
    ) === 0) {
      decoder2 = this._literalDecoder.getDecoder(nowPos64++, prevByte);
      if (state >= 7) {
        prevByte = decoder2.decodeWithMatchByte(
          this._rangeDecoder,
          this._outWindow.getByte(rep0)
        );
      } else {
        prevByte = decoder2.decodeNormal(this._rangeDecoder);
      }
      this._outWindow.putByte(prevByte);
      state = state < 4 ? 0 : state - (state < 10 ? 3 : 6);
    } else {
      if (this._rangeDecoder.decodeBit(this._isRepDecoders, state) === 1) {
        len = 0;
        if (this._rangeDecoder.decodeBit(this._isRepG0Decoders, state) === 0) {
          if (this._rangeDecoder.decodeBit(
            this._isRep0LongDecoders,
            (state << 4) + posState
          ) === 0) {
            state = state < 7 ? 9 : 11;
            len = 1;
          }
        } else {
          if (this._rangeDecoder.decodeBit(this._isRepG1Decoders, state) === 0) {
            distance = rep1;
          } else {
            if (this._rangeDecoder.decodeBit(this._isRepG2Decoders, state) === 0) {
              distance = rep2;
            } else {
              distance = rep3;
              rep3 = rep2;
            }
            rep2 = rep1;
          }
          rep1 = rep0;
          rep0 = distance;
        }
        if (len === 0) {
          len = 2 + this._repLenDecoder.decode(this._rangeDecoder, posState);
          state = state < 7 ? 8 : 11;
        }
      } else {
        rep3 = rep2;
        rep2 = rep1;
        rep1 = rep0;
        len = 2 + this._lenDecoder.decode(this._rangeDecoder, posState);
        state = state < 7 ? 7 : 10;
        posSlot = this._posSlotDecoder[len <= 5 ? len - 2 : 3].decode(
          this._rangeDecoder
        );
        if (posSlot >= 4) {
          numDirectBits = (posSlot >> 1) - 1;
          rep0 = (2 | posSlot & 1) << numDirectBits;
          if (posSlot < 14) {
            rep0 += LZMA.reverseDecode2(
              this._posDecoders,
              rep0 - posSlot - 1,
              this._rangeDecoder,
              numDirectBits
            );
          } else {
            rep0 += this._rangeDecoder.decodeDirectBits(numDirectBits - 4) << 4;
            rep0 += this._posAlignDecoder.reverseDecode(this._rangeDecoder);
            if (rep0 < 0) {
              if (rep0 === -1) {
                break;
              }
              return false;
            }
          }
        } else {
          rep0 = posSlot;
        }
      }
      if (rep0 >= nowPos64 || rep0 >= this._dictionarySizeCheck) {
        return false;
      }
      this._outWindow.copyBlock(rep0, len);
      nowPos64 += len;
      prevByte = this._outWindow.getByte(0);
    }
  }
  this._outWindow.flush();
  this._outWindow.releaseStream();
  this._rangeDecoder.releaseStream();
  return true;
};
LZMA.Decoder.prototype.setDecoderProperties = function(properties) {
  let value, lc, lp, pb, dictionarySize;
  if (properties.size < 5) {
    return false;
  }
  value = properties.readByte();
  lc = value % 9;
  value = ~~(value / 9);
  lp = value % 5;
  pb = ~~(value / 5);
  if (!this.setLcLpPb(lc, lp, pb)) {
    return false;
  }
  dictionarySize = properties.readByte();
  dictionarySize |= properties.readByte() << 8;
  dictionarySize |= properties.readByte() << 16;
  dictionarySize += properties.readByte() * 16777216;
  return this.setDictionarySize(dictionarySize);
};
LZMA.decompress = function(properties, inStream, outStream, outSize) {
  const decoder = new LZMA.Decoder();
  if (!decoder.setDecoderProperties(properties)) {
    throw "Incorrect stream properties";
  }
  if (!decoder.decode(inStream, outStream, outSize)) {
    throw "Error in data stream";
  }
  return true;
};
LZMA.decompressFile = function(inStream, outStream) {
  const decoder = new LZMA.Decoder();
  let outSize;
  if (!decoder.setDecoderProperties(inStream)) {
    throw "Incorrect stream properties";
  }
  outSize = inStream.readByte();
  outSize |= inStream.readByte() << 8;
  outSize |= inStream.readByte() << 16;
  outSize += inStream.readByte() * 16777216;
  inStream.readByte();
  inStream.readByte();
  inStream.readByte();
  inStream.readByte();
  if (!decoder.decode(inStream, outStream, outSize)) {
    throw "Error in data stream";
  }
  return true;
};
var CTM = CTM || {};
CTM.CompressionMethod = {
  RAW: 5718354,
  MG1: 3229517,
  MG2: 3295053
};
CTM.Flags = {
  NORMALS: 1
};
CTM.File = function(stream) {
  this.load(stream);
};
CTM.File.prototype.load = function(stream) {
  this.header = new CTM.FileHeader(stream);
  this.body = new CTM.FileBody(this.header);
  this.getReader().read(stream, this.body);
};
CTM.File.prototype.getReader = function() {
  var reader;
  switch (this.header.compressionMethod) {
    case CTM.CompressionMethod.RAW:
      reader = new CTM.ReaderRAW();
      break;
    case CTM.CompressionMethod.MG1:
      reader = new CTM.ReaderMG1();
      break;
    case CTM.CompressionMethod.MG2:
      reader = new CTM.ReaderMG2();
      break;
  }
  return reader;
};
CTM.FileHeader = function(stream) {
  stream.readInt32();
  this.fileFormat = stream.readInt32();
  this.compressionMethod = stream.readInt32();
  this.vertexCount = stream.readInt32();
  this.triangleCount = stream.readInt32();
  this.uvMapCount = stream.readInt32();
  this.attrMapCount = stream.readInt32();
  this.flags = stream.readInt32();
  this.comment = stream.readString();
};
CTM.FileHeader.prototype.hasNormals = function() {
  return this.flags & CTM.Flags.NORMALS;
};
CTM.FileBody = function(header) {
  var i = header.triangleCount * 3, v = header.vertexCount * 3, n = header.hasNormals() ? header.vertexCount * 3 : 0, u = header.vertexCount * 2, a = header.vertexCount * 4, j = 0;
  var data = new ArrayBuffer(
    (i + v + n + u * header.uvMapCount + a * header.attrMapCount) * 4
  );
  this.indices = new Uint32Array(data, 0, i);
  this.vertices = new Float32Array(data, i * 4, v);
  if (header.hasNormals()) {
    this.normals = new Float32Array(data, (i + v) * 4, n);
  }
  if (header.uvMapCount) {
    this.uvMaps = [];
    for (j = 0; j < header.uvMapCount; ++j) {
      this.uvMaps[j] = {
        uv: new Float32Array(data, (i + v + n + j * u) * 4, u)
      };
    }
  }
  if (header.attrMapCount) {
    this.attrMaps = [];
    for (j = 0; j < header.attrMapCount; ++j) {
      this.attrMaps[j] = {
        attr: new Float32Array(
          data,
          (i + v + n + u * header.uvMapCount + j * a) * 4,
          a
        )
      };
    }
  }
};
CTM.FileMG2Header = function(stream) {
  stream.readInt32();
  this.vertexPrecision = stream.readFloat32();
  this.normalPrecision = stream.readFloat32();
  this.lowerBoundx = stream.readFloat32();
  this.lowerBoundy = stream.readFloat32();
  this.lowerBoundz = stream.readFloat32();
  this.higherBoundx = stream.readFloat32();
  this.higherBoundy = stream.readFloat32();
  this.higherBoundz = stream.readFloat32();
  this.divx = stream.readInt32();
  this.divy = stream.readInt32();
  this.divz = stream.readInt32();
  this.sizex = (this.higherBoundx - this.lowerBoundx) / this.divx;
  this.sizey = (this.higherBoundy - this.lowerBoundy) / this.divy;
  this.sizez = (this.higherBoundz - this.lowerBoundz) / this.divz;
};
CTM.ReaderRAW = function() {
};
CTM.ReaderRAW.prototype.read = function(stream, body) {
  this.readIndices(stream, body.indices);
  this.readVertices(stream, body.vertices);
  if (body.normals) {
    this.readNormals(stream, body.normals);
  }
  if (body.uvMaps) {
    this.readUVMaps(stream, body.uvMaps);
  }
  if (body.attrMaps) {
    this.readAttrMaps(stream, body.attrMaps);
  }
};
CTM.ReaderRAW.prototype.readIndices = function(stream, indices) {
  stream.readInt32();
  stream.readArrayInt32(indices);
};
CTM.ReaderRAW.prototype.readVertices = function(stream, vertices) {
  stream.readInt32();
  stream.readArrayFloat32(vertices);
};
CTM.ReaderRAW.prototype.readNormals = function(stream, normals) {
  stream.readInt32();
  stream.readArrayFloat32(normals);
};
CTM.ReaderRAW.prototype.readUVMaps = function(stream, uvMaps) {
  var i = 0;
  for (; i < uvMaps.length; ++i) {
    stream.readInt32();
    uvMaps[i].name = stream.readString();
    uvMaps[i].filename = stream.readString();
    stream.readArrayFloat32(uvMaps[i].uv);
  }
};
CTM.ReaderRAW.prototype.readAttrMaps = function(stream, attrMaps) {
  var i = 0;
  for (; i < attrMaps.length; ++i) {
    stream.readInt32();
    attrMaps[i].name = stream.readString();
    stream.readArrayFloat32(attrMaps[i].attr);
  }
};
CTM.ReaderMG1 = function() {
};
CTM.ReaderMG1.prototype.read = function(stream, body) {
  this.readIndices(stream, body.indices);
  this.readVertices(stream, body.vertices);
  if (body.normals) {
    this.readNormals(stream, body.normals);
  }
  if (body.uvMaps) {
    this.readUVMaps(stream, body.uvMaps);
  }
  if (body.attrMaps) {
    this.readAttrMaps(stream, body.attrMaps);
  }
};
CTM.ReaderMG1.prototype.readIndices = function(stream, indices) {
  stream.readInt32();
  stream.readInt32();
  var interleaved = new CTM.InterleavedStream(indices, 3);
  LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
  CTM.restoreIndices(indices, indices.length);
};
CTM.ReaderMG1.prototype.readVertices = function(stream, vertices) {
  stream.readInt32();
  stream.readInt32();
  var interleaved = new CTM.InterleavedStream(vertices, 1);
  LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
};
CTM.ReaderMG1.prototype.readNormals = function(stream, normals) {
  stream.readInt32();
  stream.readInt32();
  var interleaved = new CTM.InterleavedStream(normals, 3);
  LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
};
CTM.ReaderMG1.prototype.readUVMaps = function(stream, uvMaps) {
  var i = 0;
  for (; i < uvMaps.length; ++i) {
    stream.readInt32();
    uvMaps[i].name = stream.readString();
    uvMaps[i].filename = stream.readString();
    stream.readInt32();
    var interleaved = new CTM.InterleavedStream(uvMaps[i].uv, 2);
    LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
  }
};
CTM.ReaderMG1.prototype.readAttrMaps = function(stream, attrMaps) {
  var i = 0;
  for (; i < attrMaps.length; ++i) {
    stream.readInt32();
    attrMaps[i].name = stream.readString();
    stream.readInt32();
    var interleaved = new CTM.InterleavedStream(attrMaps[i].attr, 4);
    LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
  }
};
CTM.ReaderMG2 = function() {
};
CTM.ReaderMG2.prototype.read = function(stream, body) {
  this.MG2Header = new CTM.FileMG2Header(stream);
  this.readVertices(stream, body.vertices);
  this.readIndices(stream, body.indices);
  if (body.normals) {
    this.readNormals(stream, body);
  }
  if (body.uvMaps) {
    this.readUVMaps(stream, body.uvMaps);
  }
  if (body.attrMaps) {
    this.readAttrMaps(stream, body.attrMaps);
  }
};
CTM.ReaderMG2.prototype.readVertices = function(stream, vertices) {
  stream.readInt32();
  stream.readInt32();
  var interleaved = new CTM.InterleavedStream(vertices, 3);
  LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
  var gridIndices = this.readGridIndices(stream, vertices);
  CTM.restoreVertices(
    vertices,
    this.MG2Header,
    gridIndices,
    this.MG2Header.vertexPrecision
  );
};
CTM.ReaderMG2.prototype.readGridIndices = function(stream, vertices) {
  stream.readInt32();
  stream.readInt32();
  var gridIndices = new Uint32Array(vertices.length / 3);
  var interleaved = new CTM.InterleavedStream(gridIndices, 1);
  LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
  CTM.restoreGridIndices(gridIndices, gridIndices.length);
  return gridIndices;
};
CTM.ReaderMG2.prototype.readIndices = function(stream, indices) {
  stream.readInt32();
  stream.readInt32();
  var interleaved = new CTM.InterleavedStream(indices, 3);
  LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
  CTM.restoreIndices(indices, indices.length);
};
CTM.ReaderMG2.prototype.readNormals = function(stream, body) {
  stream.readInt32();
  stream.readInt32();
  var interleaved = new CTM.InterleavedStream(body.normals, 3);
  LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
  var smooth = CTM.calcSmoothNormals(body.indices, body.vertices);
  CTM.restoreNormals(body.normals, smooth, this.MG2Header.normalPrecision);
};
CTM.ReaderMG2.prototype.readUVMaps = function(stream, uvMaps) {
  var i = 0;
  for (; i < uvMaps.length; ++i) {
    stream.readInt32();
    uvMaps[i].name = stream.readString();
    uvMaps[i].filename = stream.readString();
    var precision = stream.readFloat32();
    stream.readInt32();
    var interleaved = new CTM.InterleavedStream(uvMaps[i].uv, 2);
    LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
    CTM.restoreMap(uvMaps[i].uv, 2, precision);
  }
};
CTM.ReaderMG2.prototype.readAttrMaps = function(stream, attrMaps) {
  var i = 0;
  for (; i < attrMaps.length; ++i) {
    stream.readInt32();
    attrMaps[i].name = stream.readString();
    var precision = stream.readFloat32();
    stream.readInt32();
    var interleaved = new CTM.InterleavedStream(attrMaps[i].attr, 4);
    LZMA.decompress(stream, stream, interleaved, interleaved.data.length);
    CTM.restoreMap(attrMaps[i].attr, 4, precision);
  }
};
CTM.restoreIndices = function(indices, len) {
  var i = 3;
  if (len > 0) {
    indices[2] += indices[0];
    indices[1] += indices[0];
  }
  for (; i < len; i += 3) {
    indices[i] += indices[i - 3];
    if (indices[i] === indices[i - 3]) {
      indices[i + 1] += indices[i - 2];
    } else {
      indices[i + 1] += indices[i];
    }
    indices[i + 2] += indices[i];
  }
};
CTM.restoreGridIndices = function(gridIndices, len) {
  var i = 1;
  for (; i < len; ++i) {
    gridIndices[i] += gridIndices[i - 1];
  }
};
CTM.restoreVertices = function(vertices, grid, gridIndices, precision) {
  var gridIdx, delta, x, y, z, intVertices = new Uint32Array(
    vertices.buffer,
    vertices.byteOffset,
    vertices.length
  ), ydiv = grid.divx, zdiv = ydiv * grid.divy, prevGridIdx = 2147483647, prevDelta = 0, i = 0, j = 0, len = gridIndices.length;
  for (; i < len; j += 3) {
    x = gridIdx = gridIndices[i++];
    z = ~~(x / zdiv);
    x -= ~~(z * zdiv);
    y = ~~(x / ydiv);
    x -= ~~(y * ydiv);
    delta = intVertices[j];
    if (gridIdx === prevGridIdx) {
      delta += prevDelta;
    }
    vertices[j] = grid.lowerBoundx + x * grid.sizex + precision * delta;
    vertices[j + 1] = grid.lowerBoundy + y * grid.sizey + precision * intVertices[j + 1];
    vertices[j + 2] = grid.lowerBoundz + z * grid.sizez + precision * intVertices[j + 2];
    prevGridIdx = gridIdx;
    prevDelta = delta;
  }
};
CTM.restoreNormals = function(normals, smooth, precision) {
  var ro, phi, theta, sinPhi, nx, ny, nz, by, bz, len, intNormals = new Uint32Array(
    normals.buffer,
    normals.byteOffset,
    normals.length
  ), i = 0, k = normals.length, PI_DIV_2 = 3.141592653589793 * 0.5;
  for (; i < k; i += 3) {
    ro = intNormals[i] * precision;
    phi = intNormals[i + 1];
    if (phi === 0) {
      normals[i] = smooth[i] * ro;
      normals[i + 1] = smooth[i + 1] * ro;
      normals[i + 2] = smooth[i + 2] * ro;
    } else {
      if (phi <= 4) {
        theta = (intNormals[i + 2] - 2) * PI_DIV_2;
      } else {
        theta = (intNormals[i + 2] * 4 / phi - 2) * PI_DIV_2;
      }
      phi *= precision * PI_DIV_2;
      sinPhi = ro * Math.sin(phi);
      nx = sinPhi * Math.cos(theta);
      ny = sinPhi * Math.sin(theta);
      nz = ro * Math.cos(phi);
      bz = smooth[i + 1];
      by = smooth[i] - smooth[i + 2];
      len = Math.sqrt(2 * bz * bz + by * by);
      if (len > 1e-20) {
        by /= len;
        bz /= len;
      }
      normals[i] = smooth[i] * nz + (smooth[i + 1] * bz - smooth[i + 2] * by) * ny - bz * nx;
      normals[i + 1] = smooth[i + 1] * nz - (smooth[i + 2] + smooth[i]) * bz * ny + by * nx;
      normals[i + 2] = smooth[i + 2] * nz + (smooth[i] * by + smooth[i + 1] * bz) * ny + bz * nx;
    }
  }
};
CTM.restoreMap = function(map, count, precision) {
  var delta, value, intMap = new Uint32Array(map.buffer, map.byteOffset, map.length), i = 0, j, len = map.length;
  for (; i < count; ++i) {
    delta = 0;
    for (j = i; j < len; j += count) {
      value = intMap[j];
      delta += value & 1 ? -(value + 1 >> 1) : value >> 1;
      map[j] = delta * precision;
    }
  }
};
CTM.calcSmoothNormals = function(indices, vertices) {
  var smooth = new Float32Array(vertices.length), indx, indy, indz, nx, ny, nz, v1x, v1y, v1z, v2x, v2y, v2z, len, i, k;
  for (i = 0, k = indices.length; i < k; ) {
    indx = indices[i++] * 3;
    indy = indices[i++] * 3;
    indz = indices[i++] * 3;
    v1x = vertices[indy] - vertices[indx];
    v2x = vertices[indz] - vertices[indx];
    v1y = vertices[indy + 1] - vertices[indx + 1];
    v2y = vertices[indz + 1] - vertices[indx + 1];
    v1z = vertices[indy + 2] - vertices[indx + 2];
    v2z = vertices[indz + 2] - vertices[indx + 2];
    nx = v1y * v2z - v1z * v2y;
    ny = v1z * v2x - v1x * v2z;
    nz = v1x * v2y - v1y * v2x;
    len = Math.sqrt(nx * nx + ny * ny + nz * nz);
    if (len > 1e-10) {
      nx /= len;
      ny /= len;
      nz /= len;
    }
    smooth[indx] += nx;
    smooth[indx + 1] += ny;
    smooth[indx + 2] += nz;
    smooth[indy] += nx;
    smooth[indy + 1] += ny;
    smooth[indy + 2] += nz;
    smooth[indz] += nx;
    smooth[indz + 1] += ny;
    smooth[indz + 2] += nz;
  }
  for (i = 0, k = smooth.length; i < k; i += 3) {
    len = Math.sqrt(
      smooth[i] * smooth[i] + smooth[i + 1] * smooth[i + 1] + smooth[i + 2] * smooth[i + 2]
    );
    if (len > 1e-10) {
      smooth[i] /= len;
      smooth[i + 1] /= len;
      smooth[i + 2] /= len;
    }
  }
  return smooth;
};
CTM.isLittleEndian = function() {
  var buffer = new ArrayBuffer(2), bytes = new Uint8Array(buffer), ints = new Uint16Array(buffer);
  bytes[0] = 1;
  return ints[0] === 1;
}();
CTM.InterleavedStream = function(data, count) {
  this.data = new Uint8Array(data.buffer, data.byteOffset, data.byteLength);
  this.offset = CTM.isLittleEndian ? 3 : 0;
  this.count = count * 4;
  this.len = this.data.length;
};
CTM.InterleavedStream.prototype.writeByte = function(value) {
  this.data[this.offset] = value;
  this.offset += this.count;
  if (this.offset >= this.len) {
    this.offset -= this.len - 4;
    if (this.offset >= this.count) {
      this.offset -= this.count + (CTM.isLittleEndian ? 1 : -1);
    }
  }
};
CTM.Stream = function(data) {
  this.data = data;
  this.offset = 0;
};
CTM.Stream.prototype.TWO_POW_MINUS23 = Math.pow(2, -23);
CTM.Stream.prototype.TWO_POW_MINUS126 = Math.pow(2, -126);
CTM.Stream.prototype.readByte = function() {
  return this.data[this.offset++] & 255;
};
CTM.Stream.prototype.readInt32 = function() {
  var i = this.readByte();
  i |= this.readByte() << 8;
  i |= this.readByte() << 16;
  return i | this.readByte() << 24;
};
CTM.Stream.prototype.readFloat32 = function() {
  var m = this.readByte();
  m += this.readByte() << 8;
  var b1 = this.readByte();
  var b2 = this.readByte();
  m += (b1 & 127) << 16;
  var e = (b2 & 127) << 1 | (b1 & 128) >>> 7;
  var s = b2 & 128 ? -1 : 1;
  if (e === 255) {
    return m !== 0 ? NaN : s * Infinity;
  }
  if (e > 0) {
    return s * (1 + m * this.TWO_POW_MINUS23) * Math.pow(2, e - 127);
  }
  if (m !== 0) {
    return s * m * this.TWO_POW_MINUS126;
  }
  return s * 0;
};
CTM.Stream.prototype.readString = function() {
  var len = this.readInt32();
  this.offset += len;
  return String.fromCharCode.apply(
    null,
    this.data.subarray(this.offset - len, this.offset)
  );
};
CTM.Stream.prototype.readArrayInt32 = function(array) {
  var i = 0, len = array.length;
  while (i < len) {
    array[i++] = this.readInt32();
  }
  return array;
};
CTM.Stream.prototype.readArrayFloat32 = function(array) {
  var i = 0, len = array.length;
  while (i < len) {
    array[i++] = this.readFloat32();
  }
  return array;
};
class CTMLoader extends LoadingManager {
  static get singleton() {
    if (CTMLoader._singleton === void 0) {
      CTMLoader._singleton = new CTMLoader();
    }
    return CTMLoader._singleton;
  }
  constructor() {
    super();
    this._className = "CTMLoader";
  }
  load(url, onLoad, onProgress, onError) {
    LoaderUtils.extractUrlBase(url);
    fileloader.load(url, async (resp) => {
      try {
        const blob = await resp.blob();
        const blobBuffer = await blob.arrayBuffer();
        const geometry = this.parseBinary(blobBuffer);
        onLoad(geometry);
      } catch (e) {
        onError && onError(e);
      }
    });
  }
  parseBinary(arrayBuffer) {
    if (!arrayBuffer) {
      throw new Error("CTMLoader: No arraybuffer found.");
    }
    const int8a = new Int8Array(arrayBuffer);
    const stream = new CTM.Stream(int8a);
    const file = new CTM.File(stream);
    const { vertices, indices, normals } = file.body;
    const newVertices = new Float32Array(indices.length * 3);
    const newIndices = new Uint32Array(indices.length);
    for (let i = 0; i < indices.length; i++) {
      const index = indices[i];
      newVertices[i * 3] = vertices[index * 3 + 0];
      newVertices[i * 3 + 1] = vertices[index * 3 + 1];
      newVertices[i * 3 + 2] = vertices[index * 3 + 2];
      newIndices[i] = i;
    }
    const geometry = new Geometry();
    geometry.setVertexAttribute("position", {
      buffer: newVertices,
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    geometry.setVertexAttribute("indices", {
      buffer: newIndices,
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    if (normals) ;
    else {
      calculateNormals(geometry);
    }
    geometry.drawCount = indices.length;
    geometry.drawOffset = 0;
    return geometry;
  }
}
function calculateNormals(geometry) {
  const vecters = geometry.vertexInfo.position.buffer;
  const normals = new Float32Array(vecters.length);
  const polys = geometry.vertexInfo.indices.buffer;
  const tempVectors = {
    vB: new Vector3(),
    vC: new Vector3(),
    cb: new Vector3(),
    ab: new Vector3(),
    cross: new Vector3(),
    normalA: new Vector3(),
    normalB: new Vector3(),
    normalC: new Vector3()
  };
  for (let f = 0; f < polys.length; f += 3) {
    const pA = polys[f], pB = polys[f + 1], pC = polys[f + 2];
    const aIdx = pA * 3, bIdx = pB * 3, cIdx = pC * 3;
    Vector3.sub(
      [vecters[cIdx], vecters[cIdx + 1], vecters[cIdx + 2]],
      [vecters[bIdx], vecters[bIdx + 1], vecters[bIdx + 2]],
      tempVectors.cb
    );
    Vector3.sub(
      [vecters[aIdx], vecters[aIdx + 1], vecters[aIdx + 2]],
      [vecters[bIdx], vecters[bIdx + 1], vecters[bIdx + 2]],
      tempVectors.ab
    );
    Vector3.cross(tempVectors.cb, tempVectors.ab, tempVectors.cross);
    normals[aIdx] += tempVectors.cross.x;
    normals[aIdx + 1] += tempVectors.cross.y;
    normals[aIdx + 2] += tempVectors.cross.z;
    normals[bIdx] += tempVectors.cross.x;
    normals[bIdx + 1] += tempVectors.cross.y;
    normals[bIdx + 2] += tempVectors.cross.z;
    normals[cIdx] += tempVectors.cross.x;
    normals[cIdx + 1] += tempVectors.cross.y;
    normals[cIdx + 2] += tempVectors.cross.z;
  }
  for (let i = 0; i < normals.length; i += 3) {
    Vector3.normalize(
      [normals[i], normals[i + 1], normals[i + 2]],
      tempVectors.normalA
    );
    normals[i] = tempVectors.normalA.x;
    normals[i + 1] = tempVectors.normalA.y;
    normals[i + 2] = tempVectors.normalA.z;
  }
  geometry.setVertexAttribute("normal", {
    buffer: normals,
    numComponents: 3,
    sizeofStride: 12,
    offsetofStride: 0
  });
}
class BoxGeometry extends Geometry {
  constructor(options) {
    super();
    let width = options.width || 1;
    let height = options.height || 1;
    let length = options.length || 1;
    let nbFaces = 6;
    let vertices = [
      1,
      -1,
      1,
      -1,
      -1,
      1,
      -1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      -1,
      -1,
      1,
      -1,
      -1,
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      1,
      -1,
      1,
      -1,
      -1,
      1,
      -1,
      1,
      1,
      1,
      1,
      -1,
      1,
      1,
      -1,
      -1,
      1,
      -1,
      -1,
      -1,
      -1,
      1,
      -1,
      -1,
      1,
      1,
      -1,
      1,
      -1,
      1,
      1,
      -1,
      1,
      1,
      1,
      1,
      -1,
      1,
      1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      -1,
      1
    ];
    let normals = [
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0
    ];
    let indices = [
      0,
      3,
      2,
      0,
      2,
      1,
      4,
      7,
      6,
      4,
      6,
      5,
      8,
      11,
      10,
      8,
      10,
      9,
      12,
      15,
      14,
      12,
      14,
      13,
      16,
      19,
      18,
      16,
      18,
      17,
      20,
      23,
      22,
      20,
      22,
      21
    ];
    let uvs = [];
    let colors = [];
    let faceUV = options.faceUV || new Array(6);
    let faceColors = options.faceColors;
    for (let f = 0; f < 6; f++) {
      if (faceUV[f] === void 0) {
        faceUV[f] = new Vector4(0, 0, 1, 1);
      }
      if (faceColors && faceColors[f] === void 0) {
        faceColors[f] = new Vector4(1, 1, 1, 1);
      }
    }
    for (let index = 0; index < nbFaces; index++) {
      uvs.push(faceUV[index][2], faceUV[index][3]);
      uvs.push(faceUV[index][0], faceUV[index][3]);
      uvs.push(faceUV[index][0], faceUV[index][1]);
      uvs.push(faceUV[index][2], faceUV[index][1]);
      if (faceColors) {
        for (let c = 0; c < 4; c++) {
          colors.push(
            faceColors[index][0],
            faceColors[index][1],
            faceColors[index][2],
            faceColors[index][3]
          );
        }
      }
    }
    let scaleArray = [width / 2, height / 2, length / 2];
    for (let index = 0; index < vertices.length; index++) {
      vertices[index] = vertices[index] * scaleArray[index % 3];
    }
    this.setVertexAttribute("position", {
      buffer: new Float32Array(vertices),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    this.setVertexAttribute("normal", {
      buffer: new Float32Array(normals),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    this.setVertexAttribute("texcoord", {
      buffer: new Float32Array(uvs),
      numComponents: 2,
      sizeofStride: 8,
      offsetofStride: 0,
      index: 0
    });
    this.setVertexAttribute("indices", {
      buffer: new Uint16Array(indices),
      numComponents: 1,
      sizeofStride: 2,
      offsetofStride: 0
    });
    if (faceColors) {
      this.setVertexAttribute("color", {
        buffer: new Float32Array(colors),
        numComponents: 4,
        sizeofStride: 16,
        offsetofStride: 0
      });
    }
  }
}
class SphereGeometry extends Geometry {
  get radius() {
    return this._radius;
  }
  get segments() {
    return this._segments;
  }
  set radius(val) {
    this._radius = val;
    this._generate();
  }
  set segments(val) {
    this._segments = val;
    this._generate();
  }
  updateGeomerty(opts) {
    if (opts.radius) this._radius = opts.radius;
    if (opts.segments) this._segments = opts.segments;
    this._generate();
  }
  constructor(opts) {
    super();
    this._radius = opts.radius || 1;
    this._segments = opts.segments || 16;
    this._generate();
  }
  _generate() {
    const segments = this._segments;
    const r = this._radius;
    const vertices = [];
    const normals = [];
    const indices = [];
    for (let i = 0; i <= segments; i++) {
      const theta = i * Math.PI / segments;
      for (let j = 0; j <= segments * 2; j++) {
        const phi = j * 2 * Math.PI / (segments * 2);
        const x = r * Math.sin(theta) * Math.cos(phi);
        const y = r * Math.sin(theta) * Math.sin(phi);
        const z = r * Math.cos(theta);
        vertices.push(x, y, z);
        const normal = [x / r, y / r, z / r];
        normals.push(...normal);
      }
    }
    for (let i = 0; i < segments; i++) {
      for (let j = 0; j < segments * 2; j++) {
        const first = i * (segments * 2 + 1) + j;
        const second = first + segments * 2 + 1;
        indices.push(first, second, first + 1);
        indices.push(second, second + 1, first + 1);
      }
    }
    this.setVertexAttribute("position", {
      buffer: new Float32Array(vertices),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    this.setVertexAttribute("normal", {
      buffer: new Float32Array(normals),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    this.setVertexAttribute("indices", {
      buffer: new Uint16Array(indices),
      numComponents: 1,
      sizeofStride: 2,
      offsetofStride: 0
    });
  }
}
class CylinderGeometry extends Geometry {
  get radius() {
    return this._radius;
  }
  get segments() {
    return this._segments;
  }
  get height() {
    return this._height;
  }
  set radius(val) {
    this._radius = val;
    this._generate();
  }
  set segments(val) {
    this._segments = val;
    this._generate();
  }
  set height(val) {
    this._height = val;
    this._generate();
  }
  updateGeomerty(opts) {
    if (opts.radius) this._radius = opts.radius;
    if (opts.height) this._height = opts.height;
    if (opts.segments) this._segments = opts.segments;
    this._generate();
  }
  constructor(opts) {
    super();
    this._radius = opts.radius || 1;
    this._height = opts.height || 1;
    this._segments = opts.segments || 16;
    this._generate();
  }
  _generate() {
    const vertices = [];
    const normals = [];
    const indices = [];
    const segments = this._segments;
    const radius = this._radius;
    const height = this._height;
    for (let i = 0; i <= segments; ++i) {
      const theta = i / segments * 2 * Math.PI;
      const x = radius * Math.cos(theta);
      const z = radius * Math.sin(theta);
      vertices.push(x, 0, z);
      vertices.push(x, height, z);
      const nx = Math.cos(theta);
      const nz = Math.sin(theta);
      normals.push(nx, 0, nz);
      normals.push(nx, 0, nz);
      if (i < segments) {
        const offset = i * 2;
        indices.push(offset, offset + 1, offset + 3);
        indices.push(offset, offset + 3, offset + 2);
      }
    }
    const buildCap = (startY, normalY) => {
      const centerIndex = vertices.length / 3;
      vertices.push(0, startY, 0);
      normals.push(0, normalY, 0);
      for (let i = 0; i <= segments; ++i) {
        const theta = i / segments * 2 * Math.PI;
        const x = radius * Math.cos(theta);
        const z = radius * Math.sin(theta);
        vertices.push(x, startY, z);
        normals.push(0, normalY, 0);
        if (i < segments) {
          if (normalY > 0) {
            indices.push(centerIndex, centerIndex + i + 2, centerIndex + i + 1);
          } else {
            indices.push(centerIndex, centerIndex + i + 1, centerIndex + i + 2);
          }
        }
      }
    };
    buildCap(0, -1);
    buildCap(height, 1);
    this.setVertexAttribute("position", {
      buffer: new Float32Array(vertices),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    this.setVertexAttribute("normal", {
      buffer: new Float32Array(normals),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0
    });
    this.setVertexAttribute("indices", {
      buffer: new Uint16Array(indices),
      numComponents: 1,
      sizeofStride: 2,
      offsetofStride: 0
    });
  }
}
const TweenEasingType = {
  Linear: Easing.Linear,
  Quadratic: Easing.Quadratic
};
class Tween extends MObject {
  constructor(opts) {
    super();
    this._obj = opts.src;
    let tween = new Tween$1(opts.src);
    tween.dynamic(true);
    if (opts.to) {
      tween.to(opts.to.updateobj, opts.to.costtime);
      if (opts.easing) {
        tween.easing(opts.easing);
      }
      if (opts.onUpdate) {
        tween.onUpdate(opts.onUpdate);
      }
      if (opts.onStart) {
        tween.onStop(opts.onStart);
      }
      if (opts.onStop) {
        tween.onStop(opts.onStop);
      }
      if (opts.repeat) {
        tween.repeat(opts.repeat);
      }
      if (opts.yoyo) {
        tween.yoyo(opts.yoyo);
      }
      if (opts.chain) {
        tween.chain(opts.chain);
      }
    }
    this._tween = tween;
  }
  get tween() {
    return this._tween;
  }
  start() {
    this._tween.start();
  }
  stop() {
    this._tween.stop();
  }
  pause() {
    this._tween.pause();
  }
  end() {
    this._tween.end();
  }
  resume(time) {
    this._tween.resume(time);
  }
  repeat(times = Infinity) {
    this._tween.repeat(times);
  }
  yoyo(isEnable) {
    this._tween.yoyo(isEnable);
  }
  chain(tw2) {
    this._tween.chain(tw2._tween);
  }
  onComplete(callback) {
    this._tween.onComplete(callback);
  }
  onRepeat(callback) {
    this._tween.onRepeat(callback);
  }
  onStop(callback) {
    this._tween.onStop(callback);
  }
  onStart(callback) {
    this._tween.onStart(callback);
  }
  onUpdate(callback) {
    this._tween.onUpdate(callback);
  }
}
class Animation {
  constructor() {
    this._tweens = [];
  }
  addTween(tw) {
    this._tweens.push(tw);
  }
  removeTween(tx) {
    tx.stop();
    const indx = this._tweens.findIndex((item) => {
      return item.id === tx.id;
    });
    this._tweens.splice(indx, 1);
  }
  removeAll() {
    this.stop();
    this._tweens = [];
  }
  calTweenCostTime(tw) {
    const seltCosttime = tw._duration * (1 + tw._repeat);
    const chains = tw._chainedTweens;
    let ret = seltCosttime;
    for (let index = 0, len = chains.length; index < len; index++) {
      const stw = chains[index];
      ret += this.calTweenCostTime(stw);
    }
    return ret;
  }
  start(opts) {
    for (let index = 0, len = this._tweens.length; index < len; index++) {
      const tw = this._tweens[index];
      tw.start();
    }
    let maxCosttime = 0;
    let lastTw;
    for (let index = 0, len = this._tweens.length; index < len; index++) {
      const tw = this._tweens[index];
      const costtime = tw.tween._startTime + this.calTweenCostTime(tw.tween);
      if (costtime > maxCosttime) {
        maxCosttime = costtime;
        lastTw = tw;
      }
    }
    if (lastTw) {
      const chains = lastTw.tween._chainedTweens;
      if (chains.length > 0) {
        chains[chains.length - 1].onComplete(() => {
          Engine.singleton.stopRenderLoop();
        });
      } else {
        lastTw.onComplete(() => {
          Engine.singleton.stopRenderLoop();
        });
      }
    }
    Engine.singleton.startRenderLoop();
  }
  stop() {
    for (let index = 0, len = this._tweens.length; index < len; index++) {
      const tw = this._tweens[index];
      tw.stop();
    }
    Engine.singleton.stopRenderLoop();
  }
  pause() {
    for (let index = 0, len = this._tweens.length; index < len; index++) {
      const tw = this._tweens[index];
      tw.pause();
    }
  }
}
class AxisMesh {
  constructor() {
    this._axisLength = 20;
    this._xRotation = new Vector3(0, 0, -90);
    this._yRotation = new Vector3(0, 0, 0);
    this._zRotation = new Vector3(90, 0, 0);
  }
  createEntity() {
    this._axisGeometry = new CylinderGeometry({
      radius: 0.5,
      height: this._axisLength,
      segments: 32
    });
    const xAxisMateril = new ColorMaterial({
      color: new Vector4(1, 0, 0, 1)
    });
    const yAxisMateril = new ColorMaterial({
      color: new Vector4(0, 1, 0, 1)
    });
    const zAxisMateril = new ColorMaterial({
      color: new Vector4(0, 0, 1, 1)
    });
    const xAxisEntity = new Entity();
    const meshRendererComponentX = xAxisEntity.addComponent(ComMeshRenderer);
    meshRendererComponentX.mesh = Mesh.createMeshWithGeometry(this._axisGeometry, xAxisMateril);
    const yAxisEntity = new Entity();
    const meshRendererComponentY = yAxisEntity.addComponent(ComMeshRenderer);
    meshRendererComponentY.mesh = Mesh.createMeshWithGeometry(this._axisGeometry, yAxisMateril);
    const zAxisEntity = new Entity();
    const meshRendererComponentZ = zAxisEntity.addComponent(ComMeshRenderer);
    meshRendererComponentZ.mesh = Mesh.createMeshWithGeometry(this._axisGeometry, zAxisMateril);
    xAxisEntity.rotation = this._xRotation;
    yAxisEntity.rotation = this._yRotation;
    zAxisEntity.rotation = this._zRotation;
    const rootEnt = new Entity();
    rootEnt.addNode(xAxisEntity);
    rootEnt.addNode(yAxisEntity);
    rootEnt.addNode(zAxisEntity);
    rootEnt.layer = Layer.Layer2;
    xAxisEntity.layer = Layer.Layer2;
    yAxisEntity.layer = Layer.Layer2;
    zAxisEntity.layer = Layer.Layer2;
    return rootEnt;
  }
}
class ImageAssetsLoader {
  async load(resourcePath) {
    const response = await fetch(resourcePath);
    if (!response.ok) {
      throw new Error(`Failed to load resource: ${resourcePath}`);
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const image = new Image();
    image.src = url;
    await new Promise((resolve) => {
      image.onload = resolve;
    });
    return {
      data: image,
      meta: {
        id: Utils.generateUUID(),
        path: resourcePath,
        size: blob.size,
        type: "image",
        width: image.width,
        height: image.height
      }
    };
  }
}
class ScriptAssetsLoader {
  async load(resourcePath) {
    const module = await import(
      /* webpackIgnore: true */
      /* @vite-ignore */
      resourcePath
    );
    const script = module.default;
    const response = await fetch(resourcePath);
    const scriptText = await response.text();
    const size = new Blob([scriptText]).size;
    return {
      data: script,
      meta: {
        id: Utils.generateUUID(),
        path: resourcePath,
        size,
        type: "script",
        language: "javascript"
      }
    };
  }
}
class ResourceManager {
  constructor() {
    this.cache = /* @__PURE__ */ new Map();
    this.loaders = /* @__PURE__ */ new Map();
  }
  static getSingleton() {
    if (!ResourceManager.singleton) {
      ResourceManager.singleton = new ResourceManager();
    }
    return ResourceManager.singleton;
  }
  registerLoader(type2, loader) {
    this.loaders.set(type2, loader);
  }
  async loadResource(resourcePath, type2) {
    if (this.cache.has(resourcePath)) {
      return this.cache.get(resourcePath);
    }
    const loader = this.loaders.get(type2);
    if (!loader) {
      throw new Error(`No loader registered for type: ${type2}`);
    }
    try {
      const resource = await loader.load(resourcePath);
      this.cache.set(resourcePath, resource);
      return resource;
    } catch (error) {
      logger.warn(`Failed to load resource: ${resourcePath}, error: ${error}`);
      throw error;
    }
  }
  getResource(resourcePath) {
    return this.cache.get(resourcePath);
  }
  clearResource(resourcePath) {
    this.cache.delete(resourcePath);
  }
  clearAllResources() {
    this.cache.clear();
  }
}
const context = {
  getNodeById(id) {
    return { id, name: "NodeName" };
  }
  // 其他引擎相关的方法和属性
};
window.mr = context;
const resourceManager = ResourceManager.getSingleton();
resourceManager.registerLoader("script", new ScriptAssetsLoader());
resourceManager.registerLoader("image", new ImageAssetsLoader());
export {
  Animation,
  Attribute,
  AttributeInfo,
  Attributes,
  AxisMesh,
  BlendFactor,
  BoxGeometry,
  ButtonsType,
  C180_DIVIDE_PI,
  CPI_DIVIDE_180,
  CTMLoader,
  Camera,
  CameraControllerIn2DMode,
  ColorMaterial,
  ComMeshRenderer,
  ComMeshRendererSystem,
  ComScript,
  ComScriptSystem,
  ComTextRenderer,
  ComTextRendererSystem,
  Component,
  ComponentList,
  ComponentSystem,
  CustomMaterial,
  CylinderGeometry,
  DirectionLight,
  DrawableObj,
  ECSSystem,
  ECameraClearFlagType,
  ECameraType,
  ECullType,
  EDrawType,
  EEnabledCapability,
  EPSILON,
  ERenderGroupType,
  ESetterType,
  ETextureDataType,
  ETextureFormat,
  ETextureMagFilterType,
  ETextureMinFilterType,
  ETextureWrapType,
  Engine,
  Entity,
  GLTFLoader,
  Geometry,
  ImageAssetsLoader,
  ImageLoader,
  Layer,
  Light,
  LogLevel,
  Material,
  utils as MathUtils,
  Matrix4,
  Mesh,
  Mirror,
  OrbitCameraController,
  Pan,
  PhoneMaterial,
  PointLight,
  PointerEventButtonType,
  Primitive,
  Program,
  ProgramInfo,
  quat as Quat,
  RGBELoader,
  RenderGroup,
  RenderGroups,
  RenderList,
  RenderTarget,
  Renderer,
  Res_FNT,
  ResourceManager,
  STLLoader,
  Scene,
  SceneECSPair,
  SceneNode,
  ScriptAssetsLoader,
  SphereGeometry,
  SpotLight,
  State,
  Texture,
  Tween,
  TweenEasingType,
  Uniform,
  Utils,
  Vector2,
  Vector3,
  Vector4,
  Viewer,
  Viewport,
  VisualizeHelper,
  calculateNormals,
  calculateOrigin,
  fetchFile,
  fetchJson,
  fetchScript,
  fetchText,
  loadImageBitmap,
  loadImageElement,
  logger
};
