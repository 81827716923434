import React from 'react';

const IprIconSimple = (props) => {

  const position = (props.position === undefined || props.position === null ? "unset" : props.position)
  const left = (props.left === undefined || props.left === null ? "unset" : (props.left - 1))
  const top = (props.top === undefined || props.top === null ? "unset" : (props.top))

  return (
    props.direction === "up" ? 

    <div style={{
      width: "2px",
      height: "6px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: position,
      left: left,
      top: top,
      visibility: props.visibility,
      // background: "#02AFEC",
    }}>
      
      <div style={{
        width: "6px",
        height: "6px",
        borderTop: "6px solid #FA181C",
        borderLeft: "3px solid transparent",
        borderRight: "3px solid transparent",
      }}/>

    </div>

    :

    <div style={{
      width: "2px",
      height: "6px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: position,
      left: left,
      top: top,
      visibility: props.visibility,
      // background: "#02AFEC",
    }}>

      <div style={{
        width: "6px",
        height: "6px",
        borderLeft: "3px solid transparent",
        borderRight: "3px solid transparent",
        borderBottom: "6px solid #FA181C",
      }}/>
      
    </div>
  );
};
 
export default IprIconSimple;