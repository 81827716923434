import React from 'react';
import { Box, Stack, IconButton, Grid, Input, Typography, Button, Paper, experimentalStyled as styled, Hidden } from "@mui/material";
import { FC, useEffect, useState, useRef, useImperativeHandle } from "react";
import TriangleIcon from './triangleIcon';
import StageAddIcon from './stageAddIcon';
import IprIcon from './iprIcon';
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"

const StageIndexItemSimple = (props) => {

  // console.info("props.stageMinCount: ", props.stageMinCount)

  const ButtonItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  }));

  const curStageArchQuick3Index = -1//(props.archType === 0 ? props.upQuick3Index : props.downQuick3Index)
  
  const topAreaHeight = 12
  const addMargin = 3
  const triangleMargin = 1
  const triangleHeight = 5
  const stageWidth = props.singleStageWidth

  return (

    <div style={{ 
      width: props.actualStageWidth + props.stageDiv, 
      height: (props.singleStageHeight),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex:0,
    }}>
      <Stack direction="row"
        spacing={0}>

        <div style={{
          width: stageWidth,
          height: (props.singleStageHeight),
          position: "relative",
        }}>

          <ButtonItem sx={{
            width: stageWidth,
            height: props.singleStageHeight,
            position: "absolute",
            top: 0,
            // alignItems: 'center',
            // justifyContent: 'center',
            borderRadius: "4px",
            border: props.stageCurrentStep === props.index ? 'solid 1px #02AFEC' : (props.index <= curStageArchQuick3Index ? 'solid 1px #b1b1b1' : 'solid 1px transparent'),
            color: props.stageCurrentStep === props.index ? "white" : "black",
            backgroundColor: props.stageCurrentStep === props.index ? '#02AFEC' : 'transparent',
            fontSize: 14,
            userSelect: 'none',
            zIndex: 0,
          }}
            onClick={() => props.stageButtonClickHandle(props.item, props.index, props.itemArray)}
          >

            <span style={{
              userSelect: "none",
              width: stageWidth - 2,
              height: props.singleStageHeight,
              fontSize: 15,
              position: "absolute",
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color:  ((props.index % props.countOfFoldGroup) === 0 || props.index === (props.itemArray.length-1) || (props.stageMinCount-1) === props.index || props.stageCurrentStep === props.index) ? (props.stageCurrentStep === props.index ? "white" : (props.stageAnotherStep === props.index? "#02AFEC" : (props.index <= curStageArchQuick3Index ? "gray" : "black")) ) : ("transparent"),
            }}>
              {props.item.indexStr}
            </span>

          </ButtonItem>
        </div>

        <Box sx={{ width: props.stageDiv, height: 0 }}>
          {/* {props.archType === 0 ? <IprIcon visibility={props.isNodeContains(props.item.nodeTypes, 1) ? "" : "hidden"} position={"relative"} left={props.stageDiv / 2} top={topAreaHeight - 5} direction="up"/> : <IprIcon visibility={props.isNodeContains(props.item.nodeTypes, 1) ? "" : "hidden"} position={"relative"} left={props.stageDiv / 2} top={triangleHeight} direction="down"/>} */}
        </Box>

      </Stack>
    </div>

    
  );
};

export default StageIndexItemSimple;