import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { IAddOptionType, attachmentModule, wasmModule } from "@/gluelayer"
import { protocalData } from "@/modules/Clinical/components/AuxPanel/columns"
import AuxFold from "@/modules/Clinical/components/AuxPanel/components/AuxFold/auxFold"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
export const UDAuxProtocol = () => {
  const {
    clinicalSettings: { DefinAttachmentTemplate },
  } = useAppSelector((state: RootState) => state.userService)

  const [isAlert, setIsAlert] = useState(false)

  const clearAll = () => {
    attachmentModule.deleteAllAttachment()
  }

  useEffect(() => {
    attachmentModule.setAttachmentSelectAbleType("NormalAttachment")
  }, [])

  const testData = [
    {
      id: 1,
      name: "Distalization22",
    },
    {
      id: 2,
      name: "Genetics 1222",
    },
    {
      id: 3,
      name: "Genetics 2",
    },
    {
      id: 4,
      name: "Elastic slits",
    },
  ]

  return (
    <Box>
      {/* <Stack direction="row-reverse">
        <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={() => {
            // clearAll()
            setIsAlert(true)
          }}
        >
          CLEAR ALL
        </Button>
      </Stack> */}

      <Box sx={{ flexGrow: 1 }}>
        {DefinAttachmentTemplate.map((i) => {
          return (
            <Grid marginTop={1} key={i.id} container alignItems={"center"}>
              <Grid item xs={4}>
                <Typography>{i.name}</Typography>
              </Grid>
              {i.name === "Distalization" ? (
                <>
                  <Grid item xs={2.5} alignItems={"center"}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        attachmentModule.setDistalization(
                          i,
                          IAddOptionType.Left,
                        )
                      }}
                    >
                      Left
                    </Button>
                  </Grid>
                  <Grid item xs={2.5} alignItems={"center"}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        attachmentModule.setDistalization(
                          i,
                          IAddOptionType.Right,
                        )
                      }}
                    >
                      Right
                    </Button>
                  </Grid>
                  <Grid item xs={2.5} alignItems={"center"}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        attachmentModule.setDistalization(i, IAddOptionType.All)
                      }}
                    >
                      Both
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={8} alignItems={"center"}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      attachmentModule.setProtocolData(i)
                    }}
                  >
                    Apply
                  </Button>
                </Grid>
              )}
            </Grid>
          )
        })}
      </Box>

      {/* <Dialog
        sx={{
          width: 550,
          height: 330,
          alignSelf: "center",
          justifySelf: "center",
        }}
        open={isAlert}
      >
        <DialogTitle
          sx={{
            fontsize: "20px",
            fontstyle: "normal",
            fontweight: 500,
            lineheight: "160%",
            letterspacing: " 0.15px",
          }}
        >
          Attachment
        </DialogTitle>
        <DialogContent
          sx={{
            fontsize: "16px",
            fontstyle: "normal",
            fontweight: 400,
            lineheight: "150%",
            letterspacing: "0.15px",
          }}
        >
          Are you sure you want to delete all attachments?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsAlert(false)
            }}
            sx={{
              textTransform: "uppercase",
              backgroundColor: "#E0E0E0",
              "&:hover": {
                backgroundColor: "#BDBDBD",
              },
            }}
            size="medium"
          >
            <Typography
              sx={{
                color: "var(--inherit-textPrimary-main, rgba(0, 0, 0, 0.87))",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            onClick={() => {
              clearAll()
              setIsAlert(false)
            }}
            sx={{
              textTransform: "uppercase",
              backgroundColor: "#215ECD",
              "&:hover": {
                backgroundColor: "#4C8BFF",
              },
            }}
            size="medium"
          >
            <Typography
              sx={{
                color: "var(--primary-contrast, #FFF)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
              }}
            >
              YES,CONTINUE
            </Typography>
          </Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  )
}
