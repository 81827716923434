import { wasmModule } from "../wasm/wasmModule"

class RelabelModule {
  wasmRelabelModule = null
  zoomValue: number = 0.93
  zoomValueForPicker: number = 1.2
  wasmToothLabelCorrectionForRelabelModule = null
  //open or close relabel
  openRelabelModule(isOpen: boolean) {
    if(!wasmModule.isInit) return
    if(isOpen) {
      console.log("openRelabelModule")
    } else {
      console.log("closeRelabelModule")
    }

    wasmModule.moduleManager.ToggleRelabel(isOpen)
    this.wasmRelabelModule = wasmModule.moduleManager.GetRelabelModule()
      if(this.wasmRelabelModule === null) {
        console.log(
          "wasmRelabelModule is null")
      } else {
        console.log(
          "wasmRelabelModule is not null")
      }
      this.wasmToothLabelCorrectionForRelabelModule = wasmModule.moduleManager.GetToothLabelCorrectionForRelabelModule()
      if(this.wasmToothLabelCorrectionForRelabelModule === null) {
        console.log(
          "wasmToothLabelCorrectionForRelabelModule is null")
      } else {
        this.SetZoomValue(this.zoomValue)
        this.SetZoomValueForPicker(this.zoomValueForPicker)
        console.log(
          "wasmToothLabelCorrectionForRelabelModule is not null")
      }
  }

  //have same label, or no
  IsExistSameLabel() {
    let isExistSameLabel = false
    this.wasmToothLabelCorrectionForRelabelModule = wasmModule.moduleManager.GetToothLabelCorrectionForRelabelModule()
    if(this.wasmToothLabelCorrectionForRelabelModule !== null) {
      console.log(
        "wasmToothLabelCorrectionForRelabelModule is opened, isExistSameLabel")
      isExistSameLabel = this.wasmToothLabelCorrectionForRelabelModule.IsExistSameLabel()
    }
    console.log(
      "IsExistSameLabel: ", isExistSameLabel)
    return isExistSameLabel
  }

  //reset label
  ResetLabel(archType: number) {
    this.wasmToothLabelCorrectionForRelabelModule = wasmModule.moduleManager.GetToothLabelCorrectionForRelabelModule()
    if(this.wasmToothLabelCorrectionForRelabelModule !== null) {
      console.log(
        "wasmToothLabelCorrectionForRelabelModule is opened, ResetLabel")
        this.wasmToothLabelCorrectionForRelabelModule.ResetLabel(archType)
    }
  }

  //manual label
  GotoManualModel(bManualModel: boolean, archType: number) {
    this.wasmToothLabelCorrectionForRelabelModule = wasmModule.moduleManager.GetToothLabelCorrectionForRelabelModule()
    if(this.wasmToothLabelCorrectionForRelabelModule !== null) {
      console.log(
        "wasmToothLabelCorrectionForRelabelModule is opened, GotoManualModel")
        this.wasmToothLabelCorrectionForRelabelModule.GotoManualModel(bManualModel, archType)
    }
  }

  IsRelabelModuleOpened() {
    let isRelabelModuleOpened = false
    this.wasmRelabelModule = wasmModule.moduleManager.GetRelabelModule()
    if(this.wasmRelabelModule !== null) {
      console.log(
        "wasmRelabelModule is opened")
        isRelabelModuleOpened = true
    } else {
      console.log(
        "wasmRelabelModule is closed")
        isRelabelModuleOpened = false
    }
    return isRelabelModuleOpened
  }

  IsExistSameLabelForSingleJaw(archType: number) {
    let isExistSameLabel = false
    this.wasmToothLabelCorrectionForRelabelModule = wasmModule.moduleManager.GetToothLabelCorrectionForRelabelModule()
    if(this.wasmToothLabelCorrectionForRelabelModule !== null) {
        isExistSameLabel = this.wasmToothLabelCorrectionForRelabelModule.IsExistSameLabelForSingleJaw(archType)
        console.log(
          "wasmToothLabelCorrectionForRelabelModule is opened, IsExistSameLabelForSingleJaw", isExistSameLabel)
    }
    return isExistSameLabel
  }

  setZoomCallback(cb: (val: number) => void) {
    if (!wasmModule.isWASMInit) {
      return
    }
    if (!(window as any).treatment) {
      ;(window as any).treatment = {}
    }
    ;(window as any).treatment.RelabelViewZoomCallback = cb

    this.wasmToothLabelCorrectionForRelabelModule = wasmModule.moduleManager.GetToothLabelCorrectionForRelabelModule()
    if (this.wasmToothLabelCorrectionForRelabelModule === null) {
      return
    }
    this.wasmToothLabelCorrectionForRelabelModule.SetUpdateZoomCB("treatment.RelabelViewZoomCallback")
  }

  SetZoomValue(zoomValue: number) {
    this.wasmToothLabelCorrectionForRelabelModule = wasmModule.moduleManager.GetToothLabelCorrectionForRelabelModule()
    if(this.wasmToothLabelCorrectionForRelabelModule !== null) {
        this.wasmToothLabelCorrectionForRelabelModule.SetZoomValue(zoomValue)
        console.log(
          "wasmToothLabelCorrectionForRelabelModule is opened, SetZoomValue", zoomValue)
    }
  }

  SetZoomValueForPicker(zoomValueForPicker: number) {
    this.wasmToothLabelCorrectionForRelabelModule = wasmModule.moduleManager.GetToothLabelCorrectionForRelabelModule()
    if(this.wasmToothLabelCorrectionForRelabelModule !== null) {
        this.wasmToothLabelCorrectionForRelabelModule.SetZoomValueForPicker(zoomValueForPicker)
        console.log(
          "wasmToothLabelCorrectionForRelabelModule is opened, SetZoomValueForPicker", zoomValueForPicker)
    }
  }
}

export const relabelModule = new RelabelModule();