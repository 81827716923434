import { FC, useEffect, useState } from "react"
import { PhotosAndXRays } from "@/UDTreat/left/PhotosAndXRaysForCreatingProcess"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { requestCloudPresetup } from "@/core/app/slices/v2/v2apis"
import { updateCaseById } from "@/core/app/slices/case/caseThunkApi"
import { useParams, useLocation } from "react-router-dom"
import { Box } from "@mui/material"

const Photograph: FC<{
  setPhotographErrors: (value: boolean) => void
  setIsDragEmpty: (value: boolean) => void
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  setIsDragOpen: (Value: boolean) => void
  isDragOpen: boolean
  title: string
  description?: string
  isRefinement?: boolean
  isShowRecords?: boolean
  postMessageUpdate?: () => void
  setNeedDoPresetup: (value: boolean) => void
}> = ({ setPhotographErrors, setNeedDoPresetup }) => {
  const dispatch = useAppDispatch()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { uassist_round } = caseDetail
  const { caseId, patientId } = useParams()
  const { current_orgId } = useAppSelector(
    (state: RootState) => state.userService.user,
  )
  const location = useLocation();
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    return () => {
      setPhotographErrors(false)
    }
  }, [])

  const requestAiPresetup = async (
    hasUpper,
    hasLower,
    udesign_json,
    force = true
  ) => {
    await dispatch(
      requestCloudPresetup({
        orgId: current_orgId,
        upper: hasUpper,
        lower: hasLower,
        patientId,
        caseId,
        force
      }),
    ).then((res) => {
      if (res.payload) {
        const newUdesignJsonObj = {
          ...udesign_json,
          needPresetup: false,
        }
        dispatch(
          updateCaseById({
            patientId,
            caseId,
            payload: {
              case_extra_attrs: JSON.stringify(newUdesignJsonObj),
            },
          }),
        )
      }
    })
  }
  useEffect(() => {
    const requestPresetup = async (udesign_json) => {
      let hasUpper = true,
        hasLower = true
      hasUpper = udesign_json.upperUploaded
      hasLower = udesign_json.lowerUploaded
      requestAiPresetup(hasUpper, hasLower, udesign_json)
    }
    if (location.state?.doPresetup === true) {
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      if (udesign_json.needPresetup) {
        requestPresetup(udesign_json)
        setNeedDoPresetup(false)
      }
    }
  }, [location.state])

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ uassist_round:", uassist_round)
    if (uassist_round >= 0) setIsReady(true)
  }, [uassist_round])
  return (
    isReady && (
      <Box sx={{
        width:'70vw',
        maxWidth:'1000px',
        minWidth:'800px',
        height:'420px',
        position:'relative'
        }}>      
        <PhotosAndXRays
          sx={{position:'relative',top:'10px'}}
          isZoomoutAbled={true}
          isDragAbled={true}
          isDeleteAbled={true}
          refinementIndex={uassist_round}
        />
      </Box>

    )
  )
}

export default Photograph
