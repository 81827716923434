import { useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import EditFilled from "@mui/icons-material/Edit"
import {
  Avatar,
  Backdrop,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { FileItem } from "@/core/app/slices/records/photograph/photograph.type"
import {
  downloadPhotographs1_1,
  downloadPhotoJSON,
  fetchFilesList,
} from "@/core/app/slices/records/photograph/photographThunkApi"

import {
  setRefinementNum,
} from "@/UDTreat/udTreatSlice"
import { downloadProfilePhoto } from "@/core/app/slices/records/xrays"
import { RootState } from "@/core/app/store"
import EditPatientInfoForm from "@/modules/Patient/Overview/components/EditPatientInfoForm"

//import { setStageShowType } from "@/UDTreat/udTreatSlice"


const EditUser = ({ open, handleClose, userName }) => {
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (patientData?.id) {
      dispatch(
        downloadProfilePhoto({
          patientId: patientData.id,
          fileName: `${patientData.id}.png`,
        }),
      )
    }
  }, [dispatch, patientData])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ backgroundColor: "#E4E8EA" }}>
        <Typography variant="h6">{userName}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: "0px" }}>
        <EditPatientInfoForm
          patientData={patientData}
          handleClose={handleClose}
          isFromClinicalView={true}
        ></EditPatientInfoForm>
      </DialogContent>
      {/* <DialogActions sx={{ background: "#E4E8EA" }}>
        <Button onClick={handleClose} size="large">
          CANCEL
        </Button>
        <Button variant="contained" type="submit" form="userinfo" size="large">
          SAVE
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

const typeNameToShowName = {
  composite: "Composite",
  front_smile: "Smile",
  front_normal: "Front",
  lateral_photo: "Profile",
  upper: "Upper Occlusal",
  lower: "Lower Occlusal",
  front: "Anterior",
  left: "Left",
  right: "Right",
  x_lateral: "Ceph",
  x_panorama: "Pano",
}
export const PatientNameID = ({
  firstName,
  lastName,
  caseId,
  patientId,
  creatDate,
}) => {
  const [hoverAvatar, sethoverAvatar] = useState(false)
  const [openEditUser, setopenEditUser] = useState(false)
  const dispatch = useAppDispatch()

  const [frontPhoto, setFrontPhoto] = useState()
  const [photoURL, setPhotoURL] = useState<string>()

  const { profile } = useAppSelector((state: RootState) => state.xrayService)
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    downLoadPhotoJson()

    return () => {
      if (photoURL) URL.revokeObjectURL(photoURL)
      setPhotoURL(null)
      dispatch(setRefinementNum(0))
    }
  }, [])


  useEffect(() => {
    if (profile) {
      if (profile[0] && profile[0].src) setPhotoURL(`url(${profile[0].src})`)
    }

    if (frontPhoto && !profile[0])
      setPhotoURL(`url(${URL.createObjectURL(frontPhoto)})`)

    if (!frontPhoto && !profile[0]) setPhotoURL(null)
  }, [frontPhoto, profile])

  const downLoadPhotoJson = async () => {
    const fileList = await dispatch(fetchFilesList({ patientId, caseId }))
    const listData = fileList.payload.data
    if (listData.response_code !== 200) {
      console.warn("Fetch flle list false::", listData)
      return
    }

    const typeFileInfo = (listData.result as Array<FileItem>).filter((item) => {
      return (
        item.file_name === "type.json" &&
        item.attachment_type === "photopositiontype"
      )
    })

    console.log("🚀 ~ typeFileInfo ~ typeFileInfo:", typeFileInfo)
    if (typeFileInfo && typeFileInfo.length > 0) {
      const ret = await dispatch(
        downloadPhotoJSON({
          caseId,
          patientId,
        }),
      )
      const retData = ret.payload
      if (retData.data) {
        const typeJson: any = retData.data
        console.log("🚀 ~ downLoadPhoto ~ typeJson:", typeJson)
        // PhotoThumbMap = typeJson.thumbs ? {...typeJson.thumbs} : {};
        downloadFrontPhoto(typeJson)
        // setIsNoData(false);
      }
    }
  }
  const downloadFrontPhoto = async (photoJson) => {
    for (const key in photoJson) {
      if (key === undefined || key === "undefined") continue
      if (photoJson[key] === "front_normal") {
        console.log("front photo name:  " + key)

        const photos = await dispatch(
          downloadPhotographs1_1({ patientId, caseId, fileNames: [key] }),
        )
        console.log("🚀 ~ downloadFrontPhoto ~ photos:", photos)

        const frontPhoto = photos.payload[key]
        console.log("🚀 ~ downloadFrontPhoto ~ frontPhoto:", frontPhoto)
        if (frontPhoto) {
          setFrontPhoto(frontPhoto)
        }
        break // 如果只需要第一个找到的，可以加上这行，否则会输出所有满足条件的键名
      }
    }
  }


 
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Backdrop
        sx={{
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.modal + 10,
        }}
        open={isLoading}
      >
        <CircularProgress key={isLoading ? "open" : "closed"}/>
      </Backdrop>
      <Avatar
        variant="circular"
        sx={{
          bgcolor: "#FFF",
          width: 72,
          height: 72,
          top:8,
          fontSize: "32px",
          color: "rgba(0, 0, 0, 0.60)",
          fontweight: "500",
          position: "relative",
          backgroundImage: photoURL ? photoURL : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onMouseEnter={() => {
          sethoverAvatar(true)
        }}
        onMouseLeave={() => {
          sethoverAvatar(false)
        }}
      >
        {!photoURL &&
          firstName.substring(0, 1).toUpperCase() +
            lastName.substring(0, 1).toUpperCase()}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
          }}
          open={hoverAvatar}
          onClick={() => {
            setopenEditUser(true)
          }}
        >
          <IconButton size="medium">
            <EditFilled sx={{ color: "#FFF" }}></EditFilled>
          </IconButton>
        </Backdrop>
      </Avatar>

      <EditUser
        open={openEditUser}
        handleClose={() => setopenEditUser(false)}
        userName={firstName + " " + lastName}
      ></EditUser>
    </Box>
  )
}
